import React from 'react';

export const ReceivablesIndexView = () => (
  <div className="row">
    <div className="col-xl-3 col-lg-0"></div>
    <div className="col-xl-7 col-md">
      <div className="row">
        <div className="col-xl-10 col-md">
          <h5>Дебиторская задолженность</h5>
          <div className="alert alert-info zalert-info-card" role="alert">
            <p>Отображение дебиторской задолженности в реальном времени</p>
            <hr/>
            <p>Вы можете отслеживать состояние оплаты по выставленным ранее счетам и таким образом управлять дебиторской задолженностью</p>
          </div>
        </div>
      </div>
    </div>
  </div>
)
