import { connect } from 'react-redux';
import { getChildAccouts, setWorkClientId } from '../../actions/authorization';
import { ChildAccountsDropDownView } from '../../components/Desktop/Main';

const mapStateToProps = (state) => ({
  token: state.authorization.token,
  customers: state.authorization.childUserId || state.authorization.userId,

  childAccountsRequestInProcess: state.authorization.childAccountsRequestInProcess,
  childAccountsRequestError: state.authorization.childAccountsRequestError,
  childAccountsRequestSuccess: state.authorization.childAccountsRequestSuccess,
  childAccountsRequestStatus: state.authorization.childAccountsRequestStatus,

  downViewEnabled: (
    !(state.personalOrders.requestCatalogInProcess ||
      state.personalOrders.requestActiveOrdersInProcess ||
      state.personalOrders.requestOrderDetailsInProcess ||
      state.personalOrders.requestOrdersHistoryInProcess ||
      state.personalOrders.requestOrderHistoryInProcess ||
      state.personalOrders.importOrderTryOpenOrderFileProcess ||
      state.personalOrders.importOrderRequestApplyOrderInProcess)
  ),
});

const mapDispathToProps = (dispath) => {
  return {
    getChildAccouts:
      ( token )=>
        dispath(getChildAccouts( token )),

    setWorkClientId:
      ( token, workClientId ) =>
        dispath(setWorkClientId( token, workClientId )),
  };
};

export default connect(
  mapStateToProps, mapDispathToProps
)(ChildAccountsDropDownView)
