import { backendServiceURL } from '../config/backendService'

export const REQUEST_PASSWORD_RESET_REQUEST = 'REQUEST_PASSWORD_RESET_REQUEST'
export const REQUEST_PASSWORD_RESET_SUCCESS = 'REQUEST_PASSWORD_RESET_SUCCESS'
export const REQUEST_PASSWORD_RESET_ERROR = 'REQUEST_PASSWORD_RESET_ERROR'
export const REQUEST_PASSWORD_RESET_INIT = 'REQUEST_PASSWORD_RESET_INIT'

export const PROCESS_PASSWORD_RESET_REQUEST = 'PROCESS_PASSWORD_RESET_REQUEST'
export const PROCESS_PASSWORD_RESET_SUCCESS = 'PROCESS_PASSWORD_RESET_SUCCESS'
export const PROCESS_PASSWORD_RESET_ERROR = 'PROCESS_PASSWORD_RESET_ERROR'


// Команды API интерфейса
//
// Clients - post - запрос сброса пароля (уведомление по email)
export const apiCommandPOSTRequestPasswordReset = backendServiceURL + 'api/v1/clients/reset'

// Clients - post - изменеие пароля с применением краткосрочного токена из электронного письма
export const apiCommandPOSTPasswordReset = backendServiceURL + 'api/v1/Clients/reset-password'
