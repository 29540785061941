import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const CartConfirmError = ()=>(
  <div className="alert alert-danger" role="alert">
    <h5>Ошибка оформления заказа</h5>
  Пожалуйста, повторите попытку позднее или свяжитесь с личным менеджером
  </div>
)

const CartEmtyInfo = ()=>(
  <div>
    <div className="alert alert-info zalert-info-card" role="alert">
      <h5>В настоящее время в заказ не добавлено ни одного товара</h5>
      Для добавления товаров в корзину воспользуйтесь&nbsp;
      <Link to={ '/PersonalOrders/Catalog' } className="internal-page-link-calm" >
        <b>каталогом</b>
      </Link>
    </div>
  </div>
)

export const CartConfirmView = ( { history, cart, DataGrid, doClearCart, doApplyCart, setCommentInput } )=> {
  return (
    <div className="row">
      <div className="col-xl-1 col-lg-0"></div>

      <div className="col-2 border-right">
        <div className="row">
          <div className="col">
            <button
              type="button"
              className="btn btn-outline-info btn-sm btn-block mb-1"
              onClick={ history.goBack.bind(this) }
              title="Вернуться назад">
              <i className="fa fa-arrow-left" aria-hidden="true"></i>
                &nbsp;Назад
            </button>
          </div>
        </div>

        {(cart.cartItemsQnt > 0) &&
          <div className="row mt-2">
            <div className="col">
              <div className="row no-gutters mt-1">
                <div className="col-6 text-left">Количество товаров</div>
                <div className="col-6 text-right"><b>{ cart.cartGoodsQnt }</b></div>
              </div>

              <div className="row no-gutters mt-1">
                <div className="col-6 text-left">Количество позиций</div>
                <div className="col-6 text-right"><b>{ cart.cartItemsQnt }</b></div>
              </div>

              <div className="row no-gutters border-top mt-1">
                <div className="col-6 text-left">Сумма заказа</div>
                <div className="col-6 text-right"><b>{ cart.cartSum } р.</b></div>
              </div>

              <textarea
                ref={ (input)=>setCommentInput(input) }
                className="form-control input-lg mt-3"
                maxLength="250"
                placeholder="Комментарий к заказу"
                disabled={ cart.applyInProcess }/>
            </div>
          </div>
        }

        {(cart.cartItemsQnt > 0) &&
          <div className="row mt-3">
            <div className="col">
              <button
                type="button"
                className="btn btn-outline-success btn-md btn-block"
                onClick={ doApplyCart }
                disabled={ cart.applyInProcess }
                title="Оформить заказ">

                {(cart.applyInProcess) &&
                      <span>
                        Оформление...&nbsp;
                        <i className="fa fa-circle-o-notch fa-spin fa-2x fa-fw"></i>
                      </span>
                }
                {(!cart.applyInProcess) &&
                      <span>
                        Оформить &nbsp;
                        <i className="fa fa-check-square-o" aria-hidden="true"></i>
                      </span>
                }
              </button>
            </div>
          </div>
        }
      </div>

      <div className="col-xl-7 col-md">
        <div className="row">
          <div className="col">

            <div className="row mb-3 border-bottom">
              <div className="col text-left">
                <h3>
                  Корзина
                </h3>
              </div>
              <div className="col text-right">
                {(cart.cartItemsQnt > 0) &&
                  <div
                    className="btn btn-link"
                    onClick={ doClearCart }>
                    Очистить корзину
                  </div>
                }
              </div>
            </div>

            <div className="row">
              <div className="col">
                { (cart.cartGoodsQnt > 0) && <p>Содержимое Вашего заказа</p> }
                { (cart.cartGoodsQnt === 0) && <CartEmtyInfo/> }
                { (cart.applyError) && <CartConfirmError/>}
              </div>
            </div>

            <div className="row">
              <div className="col col-md">
                { (cart.cartGoodsQnt > 0) && <DataGrid/> }
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

CartConfirmView.propTypes = {
  history: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  doClearCart: PropTypes.func.isRequired,
  doApplyCart: PropTypes.func.isRequired,
  DataGrid: PropTypes.func.isRequired,
  setCommentInput: PropTypes.func.isRequired,
};


