import React from 'react';
import { Link } from 'react-router-dom'

export const MainViewHelpView = ()=> (
  <div className="container-fluid">
    <br/>
    <div className="row">
      <div className="col-xl-3 col-lg-0">
        {/* Доп. окно главной страницы */}
      </div>
      <div className="col-xl-7 col-md">
        {/* Основное окно главной страницы */}
        <div className="row">
          <div className="col-xl-10 col-md">
            <h5>Обновления</h5>
            <div className="alert alert-info zalert-info-card" role="alert">
              <p>Сервис <strong>zVision</strong> постоянно развивается, мы
          стараемся сделать его удобнее и полезнее для Вас</p>
              <hr/>
              <p>Для того, чтобы всегда иметь доступ ко всем новшествам сервиса,
            необходимо всего лишь <br /><strong>обновить страницу</strong> Вашего браузера<br /><br />
              <strong>Обновление страницы</strong> происходит при нажатии сочетание клавиш <br />
              <kbd> Ctrl</kbd> <kbd>F5</kbd> или <kbd> Ctrl</kbd> <kbd>R</kbd></p>
              Также для обновления страницы можно <strong>закрыть её</strong> и заново <strong>открыть</strong>
              <hr/>
              Начиная с версии <strong>zVision 1.1.8 </strong>  система научилась уведомлять Вас о наличии обновлений
            </div>
            <div className="alert alert-info" role="alert">
              <h5>Полезный совет</h5> <hr/> <p> Некоторые проблемы, с которыми Вы сталкиваетесь при работе с различными веб-сайтами,
          могут быть решены путем <strong>обновления страницы </strong> указанным способом</p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-3 col-lg-0"></div>
          <div className="col-xl-7 col-md">
            <div className="row">
              <div className="col-xl-6 col-md"></div>
              <div className="col-md-6">
                <Link to={ '/UpdateApp' } className="internal-page-link-calm-nu" title="Обновить zVision">
                  <button className="btn btn-block btn-outline-info btn-sm">
                    Обновить zVision <i className="fa fa-refresh"/>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
)
