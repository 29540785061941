import { connect } from 'react-redux';
import { ClaimsCreateNew } from '../../components/Desktop/Claims';

import { getOutWaybillDetails,
  createNewClaimInitProcess,
  createNewClaimSetOutWaybillId,
  createNewClaimSetOutWaybillLineId,
} from '../../actions/Main/Claims';


const mapStateToProps = (state) => ({
  authorized: state.authorization.authorized,
  token: state.authorization.token,
  login: state.authorization.login,
  // customers: state.authorization.userId,
  customers: state.authorization.childUserId || state.authorization.userId,

  requestOutWaybillDetailsSuccess: state.claims.requestOutWaybillDetailsSuccess,
  requestOutWaybillDetailsInProcess: state.claims.requestOutWaybillDetailsInProcess,
  requestOutWaybillDetailsError: state.claims.requestOutWaybillDetailsError,
  requestOutWaybillDetailsStatus: state.claims.requestOutWaybillDetailsStatus,
  requestOutWaybillId: state.claims.requestOutWaybillId,

  createNewClaimStep: state.claims.createNewClaimStep,
  createNewClaimOutWaybillId: state.claims.createNewClaimOutWaybillId,

});

const mapDispathToProps = (dispath) => {
  return {
    createNewClaimInitProcess:
      ()=> dispath(createNewClaimInitProcess()),
    getOutWaybillDetails:
      ( token, customers, outWaybillId )=>
        dispath(getOutWaybillDetails(token, customers, outWaybillId )),
    createNewClaimSetOutWaybillId:
      ( outWaybillId ) => dispath(createNewClaimSetOutWaybillId(outWaybillId)),
    createNewClaimSetOutWaybillLineId:
      ( outWaybillLineId, meds, medName, serial, amount, lastSerialReturnDate ) =>
        dispath(createNewClaimSetOutWaybillLineId(outWaybillLineId,
          meds, medName, serial, amount, lastSerialReturnDate)),
  };
};

export default connect(
  mapStateToProps, mapDispathToProps
)(ClaimsCreateNew)
