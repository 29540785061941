import React, { Component } from 'react';
import DropDownObjects from '../DropDownObjects/DropDownObjects';

export default class ChildAccountsDropDownView extends Component {
  static childAccoutsDetails = {};
  state = {
    childAccoutsLoaded: false,
  }

  componentWillMount() {
    this.props.getChildAccouts(this.props.token);
  }

  tryRenderChildAccoutsInfo(newProps) {
    if ( newProps.childAccountsRequestSuccess ) {
      let childAccouts = JSON.parse(localStorage.getItem('ChildAccounts'));
      if (childAccouts !== null && typeof childAccouts === 'object') {
        ChildAccountsDropDownView.childAccoutsDetails = childAccouts;
        this.setState({
          childAccoutsLoaded: true,
        });
      }
    } else{
      this.setState({
        childAccoutsLoaded: false,
      });
    }
  }

  componentWillReceiveProps(nextProps){
    this.tryRenderChildAccoutsInfo(nextProps);
  }

  selectChildAccount = (childAccount) => {
    this.props.setWorkClientId(this.props.token, childAccount.customers);
  }

  render() {
    return (
      <div className="border-left  drop-down-float-box">
        { this.props.childAccountsRequestSuccess &&
        <DropDownObjects
          buttonClassName=""
          buttonTitle="Выбирете грузополучателя"
          items={ ChildAccountsDropDownView.childAccoutsDetails }
          listField = {'fullName'}
          keyField = {'customers'}
          enabled = { this.props.downViewEnabled }
          activeItemKey = { (this.props.customers) }
          onSelectItem={ this.selectChildAccount }/>
        }
      </div>
    );
  }
}
