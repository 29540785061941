import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '../../../../lib/utils'
import { Link } from 'react-router-dom'

export const ClaimDetailsHeadView = ({ claimData }) => {
  if (claimData.length > 0) {
    const { DOC, STATE, DDATE, WB_ID } = claimData[0];
    return (
      <div>
        <h5><b> Перетензия #{ DOC } </b> </h5>
        <div className="data-horisontal-grid">
          <table className="table table-sm table-responsive data-horisontal-grid-table">
            <tbody>
              { STATE &&
              <tr className="data-horisontal-grid-item">
                <td>Состояние</td>
                <td>
                  <b>
                    <Link to="/Claims/ClaimStatuses" className="internal-page-link-calm">
                      { STATE }
                    </Link>
                  </b>
                </td>
              </tr>
              }
              <tr className="data-horisontal-grid-item">
                <td>Дата создания</td>
                <td>{ formatDate(DDATE) }</td>
              </tr>
              <tr className="data-horisontal-grid-item">
                <td>Расходный документ</td>
                <td>
                  <b>
                    <Link to={ '/Claims/CreateNew/' + WB_ID } className="internal-page-link-calm">
                      #{ WB_ID }
                    </Link>
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  } else return null;
};
ClaimDetailsHeadView.propTypes = {
  claimData: PropTypes.array.isRequired,
}
