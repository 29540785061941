import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute } from '..';

// Настройки модуля
import ClaimsIndex from '../../routes/Claims/ClaimsIndex';
// Строка меню модуля
import { MenuLine as Menu} from '../../components/Desktop';
// Список дочерних аккаунтов
import ConnectedChildAccountsDropDown from '../UserProfile/ConnectedChildAccountsDropDown';

export const ClaimsMenu = () => (
  <Menu items={ ClaimsIndex } RightSide = { ConnectedChildAccountsDropDown }/>
)

export const ClaimsRoutes = () => (
  // <Switch> - т.к. внутри Claims могут быть вызваны не существующие маршруты
  <Switch>
    {ClaimsIndex.map(
      (props, i)=>(
        props.privateRoute ?
          <PrivateRoute key={ i } { ...props }/> :
          <Route key={ i } { ...props }/>
      )
    )}
  </Switch>
)

export const ClaimsFilter = () => (
  ClaimsIndex.map(
    (props, i)=>(
      props.privateRoute ?
        <PrivateRoute key={ i } { ...props } component = { props.filterСomponent }/> :
        <Route        key={ i } { ...props } component = { props.filterСomponent }/>
    )
  )
)
