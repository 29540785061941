import { connect } from 'react-redux';
import { PersonalOrdersActiveOrders }  from '../../components/Desktop/PersonalOrders';
import { getActiveOrders } from '../../actions/Main/PersonalOrders';

const mapStateToProps = (state) => ({
  authorized: state.authorization.authorized,
  token: state.authorization.token,
  login: state.authorization.login,

  // customers: state.authorization.userId,
  customers: state.authorization.childUserId || state.authorization.userId,

  requestActiveOrdersInProcess: state.personalOrders.requestActiveOrdersInProcess,
  requestActiveOrdersError: state.personalOrders.requestActiveOrdersError,
  requestActiveOrdersSuccess: state.personalOrders.requestActiveOrdersSuccess,
  requestActiveOrdersStatus: state.personalOrders.requestActiveOrdersStatus,
  requestActiveOrdersFilter: state.personalOrders.requestActiveOrdersFilter,
});

const mapDispathToProps = (dispath) => {
  return {
    doGetActiveOrders:
      ( token, customers, filter )=>
        dispath(getActiveOrders(token, customers, filter )),
  };
};

export default connect(
  mapStateToProps,
  mapDispathToProps
)(PersonalOrdersActiveOrders)
