import React, { Component } from 'react';
import { PersonalOrdersOrderDetails } from '../PersonalOrdersOrderDetails'
import { LoadingView }  from '../../Main'

export default class PersonalOrdersOrder extends Component {
  static orderDetails = {};
 
  state = {
    orderDetailsLoaded: false,
  }

  tryRenderOrderDetails(newProps){
    if( newProps.requestOrderDetailsSuccess ){
      let orderDetails = JSON.parse(localStorage.getItem('OrderDetails'));
      if (orderDetails !== null && typeof orderDetails === 'object') {
        if (orderDetails.items && Array.isArray(orderDetails.items)) {
          PersonalOrdersOrder.orderDetails = orderDetails;
          this.setState({
            orderDetailsLoaded: true,
          });
        }else{
          this.setState({
            orderDetailsLoaded: false,
          });
        }
      }else{
          this.setState({
            orderDetailsLoaded: false,
          });
      }
    }
  }

  doGetOrderDetails(orderId){
    this.props.doGetOrderDetails(this.props.token,
      this.props.customers,
      orderId);
  }
  
  componentWillReceiveProps(nextProps){
    this.tryRenderOrderDetails(nextProps);
  }

  componentWillMount(){
    const { OrderId } = this.props.match.params;
    this.doGetOrderDetails( OrderId );
  }

  handleRowSelection = ()=>{
  }

  render() {
    const { orderId } = this.props.match.params;
    const {
      requestOrderDetailsError,
      requestOrderDetailsInProcess,
      requestOrderDetailsSuccess
    } = this.props;
    return (
      <div>
        {
          requestOrderDetailsError&&
          <div>
            <font color="red">
              <p>Ошибка получения данных активных заказов...</p>
              <p>Сервис временно не доступен.</p>
            </font>
          </div>
        }
        {
          !requestOrderDetailsError&&
          requestOrderDetailsInProcess&&
          <LoadingView text={'Получение данных по заказу #' + orderId + '...'}/>
        }
        {
          requestOrderDetailsSuccess&&this.state.orderDetailsLoaded&&
          <PersonalOrdersOrderDetails
            orderData= { PersonalOrdersOrder.orderDetails }
            onSelectItem= { this.handleRowSelection }
            RowControls= { this.GridRowControls }
          />
        }
      </div>
    );
  }
}
