import { connect } from 'react-redux';
import { ClaimsUndoClaim } from '../../components/Desktop/Claims';

import { undoClaim, initUndoClaim } from '../../actions/Main/Claims';

const mapStateToProps = (state) => ({
  authorized: state.authorization.authorized,
  token: state.authorization.token,
  login: state.authorization.login,
  // customers: state.authorization.userId,
  customers: state.authorization.childUserId || state.authorization.userId,

  requestUndoClaimInProcess: state.claims.requestUndoClaimInProcess,
  requestUndoClaimError: state.claims.requestUndoClaimError,
  requestUndoClaimSuccess: state.claims.requestUndoClaimSuccess,
  requestUndoClaimStatus: state.claims.requestUndoClaimStatus,
  undoClaimId: state.claims.undoClaimId,
});

const mapDispathToProps = (dispath) => {
  return {
    doUndoClaim:
      (token, customers, claimId)=> dispath(undoClaim(token, customers, claimId)),
    doInitUndoClaim:
      ()=> dispath(initUndoClaim()),
  };
};

export default connect(
  mapStateToProps, mapDispathToProps)(ClaimsUndoClaim)
