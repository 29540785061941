import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PersonalOrdersOrdersHistoryGrid } from '../PersonalOrdersOrdersHistoryGrid'
import { LoadingView }  from '../../Main'
import { Link } from 'react-router-dom';

export default class PersonalOrdersOrdersHistory extends Component {
  static propTypes = {
    doGetActiveOrders: PropTypes.func.isRequired,
  }

  static ordersHistory = {}; 
  
  state = {
    ordersHistoryLoaded: false,
    ordersHistoryCount: null,
  }




  tryRenderOrdersHistory(newProps){
    if( newProps.requestOrdersHistorySuccess ){
      let partOfOrdersHistory = JSON.parse(localStorage.getItem('OrdersHistoryItems'));
      if (Array.isArray(partOfOrdersHistory)) {
        PersonalOrdersOrdersHistory.ordersHistory = partOfOrdersHistory.slice(0,10000);
        this.setState({
          ordersHistoryLoaded: true,
          ordersHistoryCount: partOfOrdersHistory.length,
        });
      }
    }
  }

  doGetOrdersHistory(){
    this.props.doGetActiveOrders(this.props.token,
      this.props.customers,
      'no filters');
  }

  componentWillReceiveProps(nextProps){
    this.tryRenderOrdersHistory(nextProps);
  }

  componentWillMount(){
    this.doGetOrdersHistory();
  }

  handleRowSelection = ()=> {

  }

  GridRowControls = (item) => 
    (
    <div className="col-1">
      <Link to={ '/PersonalOrders/Order/'+item.dataRow.orderId } title="Детали заказа">
        <i className="fa fa-sliders" aria-hidden="true"></i>
      </Link>
    </div>
  )

  render() {
    const {
      requestOrdersHistoryError,
      requestOrdersHistoryInProcess,
      requestOrdersHistorySuccess
    } = this.props;

    return (
      <div className="orders-history-view">
        {
          requestOrdersHistoryError&&
          <div>
            <font color="red">
              <p>Ошибка получения данных истории заказов...</p>
              <p>Сервис временно не доступен.</p>
            </font>
          </div>
        }
        {
          !requestOrdersHistoryError&&
          requestOrdersHistoryInProcess&&
          <LoadingView text='Получение данных...'/>
        }
        {requestOrdersHistorySuccess&&this.state.ordersHistoryLoaded&&
        <PersonalOrdersOrdersHistoryGrid
          gridData= { PersonalOrdersOrdersHistory.ordersHistory }
          onSelectItem= { this.handleRowSelection }
          RowControls= { this.GridRowControls }
        />
        }
        {
          requestOrdersHistorySuccess&&this.state.ordersHistoryCount === 0 &&
          <div>
            <p> В настоящее время история заказов не содержит данных </p>
          </div>
        }
      </div>
    )
  }
}
