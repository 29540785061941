import React, { Component } from 'react';
export default class ClaimsClaimCreateError extends Component {

  componentDidMount() {
    // Инициалиализация процесса
    this.props.createNewClaimInitProcess();
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col">
            <p className="text-danger">
              Ошибка - <b>Не удалось отправить претензию, сервис временно не доступен</b>
              <br/>
            </p>
            <p>
              Пожалуйста, попробуйте повторить операцию позже
            </p>
          </div>
        </div>
      </div>
    )
  }
}
