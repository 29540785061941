import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute } from '..';

// Настройки модуля
import PersonalOrdersModules from '../../routes/PersonalOrders/PersonalOrdersIndex';
// Строка меню модуля
import { MenuLine as Menu} from '../../components/Desktop';
// Список дочерних аккаунтов
import ConnectedChildAccountsDropDown from '../UserProfile/ConnectedChildAccountsDropDown';


export const PersonalOrdersMenu = () => (
  <Menu items={ PersonalOrdersModules } RightSide = { ConnectedChildAccountsDropDown }/>
)

export const PersonalOrdersRoutes = () => (
  // <Switch> - т.к. внутри PersonalOrders могут быть вызваны не сужествующие маршруты
  <Switch>
    {PersonalOrdersModules.map(
      (props, i)=>(
        props.privateRoute ?
          <PrivateRoute key={ i } { ...props }/> :
          <Route key={ i } { ...props }/>
      )
    )}
  </Switch>
)

export const PersonalOrdersFilter = () => (
  PersonalOrdersModules.map(
    (props, i)=>(
      props.privateRoute ?
        <PrivateRoute key={ i } { ...props } component = { props.filterСomponent }/> :
        <Route        key={ i } { ...props } component = { props.filterСomponent }/>
    )
  )
)
