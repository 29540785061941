import	{
  AUTHORIZATION_REQUEST,
  AUTHORIZATION_ERROR,
  AUTHORIZATION_SUCCESS,
  AUTHORIZATION_LOGOUT,

  CHILD_ACOUNTS_REQUEST,
  CHILD_ACOUNTS_REQUEST_SUCCESS,
  CHILD_ACOUNTS_REQUEST_ERROR,

  SET_WORK_USER_ID
}	from	'../constants/authorization';

const initialState = {
  authorizationInProcess: false,
  authorizationError: false,
  authorized: false,
  login: null,
  token: null,
  userId: null,
  status: null,

  childAccountsRequestInProcess: false,
  childAccountsRequestError : false,
  childAccountsRequestSuccess: false,
  childAccountsRequestStatus: null,

  childUserId: null,
};

export default function authorization(state = initialState, action) {
  switch (action.type) {
    case AUTHORIZATION_REQUEST:
      if (process.env.NODE_ENV === 'development') console.log('raise action - AUTHORIZATION_REQUEST', action);
      
      return { ...state, 
        authorizationInProcess: action.authorizationInProcess, // true
        status: 'Авторизация...'
      }
      
    case AUTHORIZATION_SUCCESS:
    if (process.env.NODE_ENV === 'development') console.log('raise action - AUTHORIZATION_SUCCESS', action);
      return { ...state, 
        authorizationInProcess: false,
        authorizationError: false,
        authorized: true,
        login: action.login,
        token: action.token,
        userId: action.userId,
        status: 'Пользователь авторизован'
      }

    case AUTHORIZATION_ERROR:
    if (process.env.NODE_ENV === 'development') console.log('raise action - AUTHORIZATION_ERROR', action);
      return { ...state, 
        authorizationInProcess: false,
        authorizationError: true,
        authorized: false,
        userId: null,
        login: null,
        token: null,
        status: action.userMessage,
      }

      case AUTHORIZATION_LOGOUT:
      if (process.env.NODE_ENV === 'development') console.log('raise action - AUTHORIZATION_LOGOUT', action);
      return {...initialState,
        status: 'Сеанс завершен'
      }


      case CHILD_ACOUNTS_REQUEST:
      if (process.env.NODE_ENV === 'development') console.log('raise action - CHILD_ACOUNTS_REQUEST', action);
      return { ...state, 
        childAccountsRequestInProcess: true,
        childAccountsRequestError : false,
        childAccountsRequestSuccess: false,
        childAccountsRequestStatus: 'Получение списка дочерних клиентов',
      }

    case CHILD_ACOUNTS_REQUEST_SUCCESS:
      if (process.env.NODE_ENV === 'development') console.log('raise action - CHILD_ACOUNTS_REQUEST_SUCCESS', action);
      return { ...state, 
        childAccountsRequestInProcess: false,
        childAccountsRequestError: false,
        childAccountsRequestSuccess: true,
        childAccountsRequestStatus: 'Список дочерних клиентов получен',
      }

    case CHILD_ACOUNTS_REQUEST_ERROR:
      if (process.env.NODE_ENV === 'development') console.log('raise action - CHILD_ACOUNTS_REQUEST_ERROR', action);
      return { ...state,
        childAccountsRequestInProcess: false,
        childAccountsRequestError: true,
        childAccountsRequestSuccess: false,
        childAccountsRequestStatus: `Ошибка получения списка дочерних клиентов: 
          ${action.error.errorCode}
          ${action.error.errorMessage}`,
        childUserId: null,
      }

      case SET_WORK_USER_ID:
      if (process.env.NODE_ENV === 'development') console.log('raise action - SET_WORK_USER_ID', action);
      return { ...state, 
        childUserId: action.childUserId,
      }

    default:
      return state;
  }
}
