import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LogoView, FooterView } from '..';
import { validateInn } from '../../../lib/utils';
import { LoadingView, DropDownItems } from '../Main';

  // Регионы и их коды
  const regionsList = {
    22 	:"Алтайский край",
    29 	:"Архангельская область",
    30 	:"Астраханская область",
    31 	:"Белгородская область",
    32 	:"Брянская область",
    33 	:"Владимирская область",
    35 	:"Вологодская область",
    36 	:"Воронежская область",
    37 	:"Ивановская область",
    38 	:"Иркутская область",
    7  	:"Кабардино-Балкарская Республика",
    40 	:"Калужская область",
    23 	:"Краснодарский край",
    24 	:"Красноярский край",
    45 	:"Курганская область",
    46 	:"Курская область",
    47 	:"Ленинградская область",
    48 	:"Липецкая область",
    77 	:"Москва",
    90 	:"Московская область",
    52 	:"Нижегородская область",
    53 	:"Новгородская область",
    54 	:"Новосибирская область",
    55 	:"Омская область",
    57 	:"Орловская область",
    59 	:"Пермский край",
    25 	:"Приморский край",
    60 	:"Псковская область",
    1  	:"Республика Адыгея",
    2  	:"Республика Башкортостан",
    5  	:"Республика Дагестан",
    6  	:"Республика Ингушетия",
    9  	:"Республика Карачаево-Черкессия",
    10 	:"Республика Карелия",
    82 	:"Республика Крым",
    14 	:"Республика Саха (Якутия)",
    15 	:"Республика Северная Осетия — Алания",
    16 	:"Республика Татарстан",
    61 	:"Ростовская область",
    62 	:"Рязанская область",
    63 	:"Самарская область",
    78 	:"Санкт-Петербург",
    66 	:"Свердловская область",
    67 	:"Смоленская область",
    26 	:"Ставропольский край",
    68 	:"Тамбовская область",
    69 	:"Тверская область",
    70 	:"Томская область",
    71 	:"Тульская область",
    72 	:"Тюменская область",
    18 	:"Удмуртская Республика",
    73 	:"Ульяновская область",
    74 	:"Челябинская область",
    21 	:"Чувашская Республика",
    172	:"Ямало-ненецкий а.о.",
    76 	:"Ярославская область",
  }

export default class Covid19View extends Component {
  static propTypes = { //Заглушка для линтера (((
    saveClientNeeds: PropTypes.func.isRequired
  }

  state={
    badINNError: {message: null, code: 0},
    regionId: 0
  };

  changeRegion(e) {
    let elemForTopScroll = this.ForTopScroll;

    if(elemForTopScroll.scrollIntoView){
        elemForTopScroll.scrollIntoView(true)
      }else{
        elemForTopScroll.scrollIntoViewIfNeeded(true)
      }

    this.setState({
      regionId: e
    });
  }

  doRequestNeeds = (event) => {
    event.preventDefault();
    let errINN = {code: null, message: null};
    validateInn(this.companyINN.value,errINN)
    this.setState({badINNError : errINN });

/*
  ДЕЗ. СРЕДСТВА	
    sku#1	Кожные антисептки для рук и универсальные
    sku#2	Антисептик для экспресс-дезинфекции
    sku#3	Универсальные Дез.средства (Все виды поверхностей,дезинфекция ИМН+ПСО, столовая и лабораторная посуда, утилизация мед.отходов, сантехника, ген.уборки)
    sku#4	Дез.средства для стоматологии
    sku#5	Салфетки
    sku#6	Антисептическое мыло
  
  ПЕРЧАТКИ	
    sku#7	Перчатки диагностические
    sku#8	Перчатки смотровые
    sku#9	Перчатки хирургические
  
  МАРЛЯ	
    sku#10	Марля медицинская
    sku#11	Маска защитная
*/

    if (errINN.code===0) {
      let personFullName = this.personFullName.value;
      let personEmail = this.personEmail.value;
      let personPhone = this.personPhone.value;
      let companyName = this.companyName.value;
      let companyINN = this.companyINN.value;
      let regionName = this.state.regionId;
      let deliveryAddress = this.deliveryAddress.value;
      let needsData = '';

      if (this.sku1.value > 0) needsData += 'sku1='+this.sku1.value+';';
      if (this.sku2.value > 0) needsData += 'sku2='+this.sku2.value+';';
      if (this.sku3.value > 0) needsData += 'sku3='+this.sku3.value+';';
      if (this.sku4.value > 0) needsData += 'sku4='+this.sku4.value+';';
      if (this.sku5.value > 0) needsData += 'sku5='+this.sku5.value+';';
      if (this.sku6.value > 0) needsData += 'sku6='+this.sku6.value+';';
      if (this.sku7.value > 0) needsData += 'sku7='+this.sku7.value+';';
      if (this.sku8.value > 0) needsData += 'sku8='+this.sku8.value+';';
      if (this.sku9.value > 0) needsData += 'sku9='+this.sku9.value+';';
      if (this.sku10.value > 0) needsData += 'sku10='+this.sku10.value+';';
      if (this.sku11.value > 0) needsData += 'sku11='+this.sku11.value+';';

      this.props.saveClientNeeds(personFullName, personEmail, personPhone,
        companyName, companyINN, regionName, deliveryAddress, needsData);
    }
  }

  render() {
    const { badINNError, regionId } = this.state;
    const { authorizationInProcess, saveClientNeedsProcess, 
      saveClientNeedsError, saveClientNeedsStatus,
      newClientNeedsId } = this.props;
    return (
      <div className="zApp">
        <div className="container app-modules">
            <div className="row">
                <div className="col-lg-3 col-md-0">
                  <LogoView/>
                </div>
                <div className="col-lg-7 col-md mt-2">
                  <h2>Заявка на поставку медицинских изделий</h2>
                </div>
            </div>

            <div className="row">
                <div className="col">
                  <div className="d-md-none">
                    <hr/>
                  </div>
                </div>
            </div>

           { newClientNeedsId &&
            <div className="row">
                <div className="col-lg-3 col-md-1"></div>
                <div className="col-lg-7 col-md-10">
                  <div className="alert alert-info zalert-info-card" role="alert">
                    <h4>Ваша заявка зарегистрирована под номером #<b>{ newClientNeedsId }</b></h4>
                    <hr/>
                    Уточнить статус рассмотрения заявки Вы можете по телефонам 
                    <ul className="list-group mt-2">
                      <li  className="list-group-item"><b> +7 (4872)25-30-20 </b> </li>
                      <li className="list-group-item"> +7 (910)945-56-07 </li>
                    </ul>
                    </div>
                </div>
              </div>
            }

            { !newClientNeedsId &&
              <div className="row">
                <div className="col-lg-3 col-md-1"></div>
                <div className="col-lg-7 col-md-10">

                  <form onSubmit={ (e)=>e.preventDefault() }>

                  <div className="form-group">
                      <input type="text"
                        ref={ (input)=>{this.personFullName = input} }
                        className="form-control input-lg"
                        required_="required"
                        maxLength="255"
                        placeholder="Введите Ваше ФИО" />
                    </div>

                    <div className="form-group">
                      <input type="email"
                        ref={ (input)=>{this.personEmail = input} }
                        className="form-control input-lg"
                        required_="required"
                        maxLength="50"
                        placeholder="Введите адрес Вашей электронной почты" />
                    </div>

                    <div className="form-group">
                      <input type="text"
                        ref={ (input)=>{this.personPhone = input} }
                        className="form-control input-lg"
                        required_="required"
                        maxLength="50"
                        placeholder="Введите номер Вашего телефона" />
                    </div>

                    <div className="form-group">
                      <input type="text"
                        ref={ (input)=>{this.companyName = input} }
                        className="form-control input-lg"
                        required_="required"
                        maxLength="255"
                        placeholder="Какую компанию вы представляете?" />
                    </div>

                    <div className="form-group">
                      { badINNError.code!==0 &&
                        <span className="text-danger">{badINNError.message}</span>
                      }
                      <input type="text"
                        ref={ (input)=>{this.companyINN = input} }
                        className="form-control input-lg"
                        required_="required"
                        maxLength="20"
                        placeholder="Введите ИНН Вашей компании" />
                    </div>

                    <div className="form-group" ref={(div) => { this.ForTopScroll = div; }}>
                      <DropDownItems
                        buttonClassName="btn-block btn-outline-info"
                        buttonTitle="Ваш регион"
                        items={ regionsList }
                        onSelectItem={ this.changeRegion.bind(this) }
                      />
                      </div>

                    <textarea
                      ref={  (input)=>{this.deliveryAddress = input} }
                      className="form-control input-lg"
                      maxLength="1000"
                      placeholder="Введите Адрес, куда требуется осуществить поставку"
                    />

                    <div className="col-lg-7 col-md mt-4">
                      <h3>Требуемая продукция</h3>
                      
                    </div><hr/>

                    <div className="col-lg-7 col-md mt-2">
                      <h5>ДЕЗ. СРЕДСТВА</h5>
                    </div>

                    <div className="form-group">
                      <span className="mb-3">Кожные антисептки для рук и универсальные</span>
                      <input type="text"
                        ref={ (input)=>{this.sku1 = input} }
                        className="form-control input-lg"
                        maxLength="7"
                        placeholder="0" />
                    </div>

                    <div className="form-group">
                      <span className="mb-3">Антисептик для экспресс-дезинфекции</span>
                      <input type="text"
                        ref={ (input)=>{this.sku2 = input} }
                        className="form-control input-lg"
                        maxLength="7"
                        placeholder="0" />
                    </div>

                    <div className="form-group">
                      <span className="mb-3">Универсальные Дез.средства (Все виды поверхностей,дезинфекция ИМН+ПСО, столовая и лабораторная посуда, утилизация мед.отходов, сантехника, ген.уборки)</span>
                      <input type="text"
                        ref={ (input)=>{this.sku3 = input} }
                        className="form-control input-lg"
                        maxLength="7"
                        placeholder="0" />
                    </div>

                    <div className="form-group">
                      <span className="mb-3">Дез.средства для стоматологии</span>
                      <input type="text"
                        ref={ (input)=>{this.sku4 = input} }
                        className="form-control input-lg"
                        maxLength="7"
                        placeholder="0" />
                    </div>

                    <div className="form-group">
                      <span className="mb-3">Салфетки</span>
                      <input type="text"
                        ref={ (input)=>{this.sku5 = input} }
                        className="form-control input-lg"
                        maxLength="7"
                        placeholder="0" />
                    </div>

                    <div className="form-group">
                      <span className="mb-3">Антисептическое мыло</span>
                      <input type="text"
                        ref={ (input)=>{this.sku6 = input} }
                        className="form-control input-lg"
                        maxLength="7"
                        placeholder="0" />
                    </div>

                    <div className="col-lg-7 col-md mt-2">
                      <h5>ПЕРЧАТКИ</h5>
                    </div>

                    <div className="form-group">
                      <span className="mb-3">Перчатки диагностические</span>
                      <input type="text"
                        ref={ (input)=>{this.sku7 = input} }
                        className="form-control input-lg"
                        maxLength="7"
                        placeholder="0" />
                    </div>

                    <div className="form-group">
                      <span className="mb-3">Перчатки смотровые</span>
                      <input type="text"
                        ref={ (input)=>{this.sku8 = input} }
                        className="form-control input-lg"
                        maxLength="7"
                        placeholder="0" />
                    </div>

                    <div className="form-group">
                      <span className="mb-3">Перчатки хирургические</span>
                      <input type="text"
                        ref={ (input)=>{this.sku9 = input} }
                        className="form-control input-lg"
                        maxLength="7"
                        placeholder="0" />
                    </div>

                    <div className="col-lg-7 col-md mt-2">
                      <h5>МАРЛЯ</h5>
                    </div>

                    <div className="form-group">
                      <span className="mb-3">Марля медицинская</span>
                      <input type="text"
                        ref={ (input)=>{this.sku10 = input} }
                        className="form-control input-lg"
                        maxLength="7"
                        placeholder="0" />
                    </div>

                    <div className="col-lg-7 col-md mt-2">
                      <h5>МАСКИ ЗАЩИТНЫЕ</h5>
                    </div>

                    <div className="form-group">
                      <span className="mb-3">Маска защитная</span>
                      <input type="text"
                        ref={ (input)=>{this.sku11 = input} }
                        className="form-control input-lg"
                        maxLength="7"
                        placeholder="0" />
                    </div>

                      <div>
                        { !saveClientNeedsError && saveClientNeedsProcess &&
                        <LoadingView text={'Отправка заявки...'}/>
                        }
                      </div>

                      {  badINNError.code!==0 &&
                        <div className="row">
                          <div className="col">
                            <div className="alert alert-warning" role="alert">
                               Ошибка: {badINNError.message}
                            </div>
                          </div>
                        </div>
                      }

                      { (saveClientNeedsError) &&
                        <div className="row">
                          <div className="col">
                            <div className="alert alert-warning" role="alert">
                              { saveClientNeedsStatus }
                            </div>
                          </div>
                        </div>
                      }

                      {!saveClientNeedsProcess &&
                      <div
                        onClick={this.doRequestNeeds.bind(this)}
                        disabled={saveClientNeedsProcess}
                        className="btn btn-outline-info btn-block">
                            {(authorizationInProcess) &&
                            <div>
                              Отправка...&nbsp;
                              <i className="fa fa-circle-o-notch fa-spin fa-lg fa-fw"></i>
                            </div>
                            }
                            {(!authorizationInProcess) &&
                            <div>
                              Отправить заявку * 
                            </div>
                            }
                      </div>
                      }
                    <hr/>
                    <p>* Оставляя заявку, Вы соглашаетесь на обработку персональных данных</p>

                  </form>
                </div>
            </div>
          }
        </div>
        <FooterView/>
      </div>
    );
  }
}
