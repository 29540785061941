import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class PersonalOrdersOrderHistoryFilter extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    customers: PropTypes.number.isRequired,
    getOrdersHistory: PropTypes.func,
  };

  doRefreshOrderHistory() {
    const { OrderId } = this.props.match.params;
    this.props.getOrdersHistory(this.props.token,
      this.props.customers,
      OrderId);
  }

  render() {
    const { history } = this.props;
    return (
      <div>
        <button
          type="button"
          className="btn btn-link"
          onClick={ history.goBack.bind(this) } >
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
          &nbsp; Назад
        </button>
        <div onClick={ this.doRefreshOrderHistory.bind(this) }
          className="btn btn-outline-info btn-sm btn-block">
          <i className="fa fa-2x fa-refresh"/>
        </div>
      </div>
    );
  }
}
