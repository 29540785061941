import { PersonalOrdersOrderHeadView } from './PersonalOrdersOrderDetailsHeadView';
export const OrderDetailsGridHeader = [
  { caption: 'Код',
    fieldName: 'itemCode',
    flexWidth: 1,
  },
  { caption: 'Наименование',
    fieldName: 'name',
    flexWidth: 7,
  },
  { caption: 'Кол-во',
    fieldName: 'qtty',
    flexWidth: 1,
  },
  { caption: 'Цена',
    isCurrency: true,
    currencyName: 'руб.',
    fieldName: 'price',
    roundTo: 2,
    flexWidth: 1,
  },
];

export { PersonalOrdersOrderHeadView };
export { default as PersonalOrdersOrderDetails } from './PersonalOrdersOrderDetails';

/*
Ответ на детали заказа
{"orderId":329,
"statusCode":1,
"statusName":"Новый",
"creationDate":"2018-05-14T13:48:51.000Z",
"commentary":"Тестовый заказ 67",
"deliveryDate":"2018-05-14T13:48:51.000Z",
"items":[
	{
	"orderBodyId":544,
	"itemCode":60000,
	"name":"5-нок табл. п.о. 50мг N50",
	"vendor":null,
	"qtty":1,
	"price":175,
	"commentary":null,
	"orderId":329
	},
	{
	"orderBodyId":545,
	"itemCode":547913,
	"name":"Consumed L-Карнитин (БАД к пище \"Витус с L-Карнитином\" №20)",
	"vendor":null,
	"qtty":1,
	"price":165.67000000000002,
	"commentary":null,
	"orderId":329
	}
	]
}

*/
