import	{
  SAVE_CLIENT_NEEDS_REQUEST,
  SAVE_CLIENT_NEEDS_SUCCESS,
  SAVE_CLIENT_NEEDS_ERROR
}	from	'../constants/covid19';

const initialState = {
  saveClientNeedsProcess: false,
  saveClientNeedsError: false,
  saveClientNeedsSuccess: false,
  saveClientNeedsStatus: null,
  personFullName: null,
  personEmail: null,
  personPhone: null,
  companyName: null,
  companyINN: null,
  regionName: null,
  deliveryAddress: null,
  needsData: null,
  needsId: null,
};

export default function covid19(state = initialState, action) {
  switch (action.type) {

    case SAVE_CLIENT_NEEDS_REQUEST:
      if (process.env.NODE_ENV === 'development') console.log('raise action - SAVE_CLIENT_NEEDS_REQUEST', action);
      return { ...state,
        saveClientNeedsProcess: true,
        saveClientNeedsError: false,
        saveClientNeedsSuccess: false,
        personFullName: action.personFullName,
        personEmail: action.personEmail,
        personPhone: action.personPhone,
        companyName: action.companyName,
        companyINN : action.companyINN ,
        regionName : action.regionName ,
        deliveryAddress : action.deliveryAddress ,
        needsData : action.needsData ,
        saveClientNeedsStatus: 'Отправка заявки...'
      }

    case SAVE_CLIENT_NEEDS_SUCCESS:
      if (process.env.NODE_ENV === 'development') console.log('raise action - SAVE_CLIENT_NEEDS_SUCCESS', action);
      return { ...state,
        saveClientNeedsProcess: false,
        saveClientNeedsError: false,
        saveClientNeedsSuccess: true,
        needsId: action.needsId,
        saveClientNeedsStatus: 'Заявка зарегистрирована'
      }

    case SAVE_CLIENT_NEEDS_ERROR:
      if (process.env.NODE_ENV === 'development') console.log('raise action - SAVE_CLIENT_NEEDS_ERROR', action);
      return { ...state, 
        saveClientNeedsProcess: false,
        saveClientNeedsError: true,
        saveClientNeedsSuccess: false,
        needsId: action.needsId,
        saveClientNeedsStatus: `${action.error.errorMessage}`
      }

      default:
      return state;
  }
}
