import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LogoView, FooterView } from '..';
import { Redirect } from 'react-router-dom'
import { validatePassword } from '../../../lib/utils'

const ErrorCard = () => (
  <ul className="list-group">
    <li className="list-group-item alert-danger d-flex">
      <i className="fa fa-exclamation-triangle fa-2x" aria-hidden="true"></i>
      <span className="ml-3">
        Отсутствует возможность выполнения изменения пароля<br/>
      </span >
    </li>
    <li className="list-group-item d-flex">
      <i className="fa fa-cog fa-2x" aria-hidden="true"></i>
      <span className="ml-3">Вероятно, текущее окно отображается сервисом по ошибке</span >
    </li>
  </ul>
)

class PasswordResetProcessView extends Component {
  static propTypes = {
    doPasswordResetProcess: PropTypes.func.isRequired
  }

  state={
    badPasswordError: {message: null, code: 0},
    tokenNotFound: true,
  };  

  doPasswordResetProcess(event) {
    event.preventDefault();//Чтобы не перезагружалась форма

    let errPassword = {code: null, message: null};
    validatePassword(this.PasswordInput.value,this.PasswordInputTest.value, errPassword)
    this.setState({badPasswordError : errPassword });

    if (errPassword.code===0) {
      const token = new URLSearchParams(this.props.location.search).get('access_token');
      this.props.doPasswordResetProcess (
        token,
        this.PasswordInput.value,
      );
    }
  }

  componentWillMount(){
    const token = new URLSearchParams(this.props.location.search).get('access_token');
    if (token) this.setState({ tokenNotFound: false });
  }

  readPasswordResetRequestStatus(){
    const status = this.props.processPasswordResetStatus;
    if (status===null) return status;
    if(~status.indexOf('Email not found'))
    return 'Ошибка изменения пароля: указанный email не зарегистрирован в системе';
    if(~status.indexOf('NetworkError'))
    return 'Ошибка изменения пароля: сервер временно не доступен'; else
    if(~status.indexOf('Authorization Required'))
    return 'Ошибка изменения пароля: время отведенное на операцию изменения пароля время исчерпано, повторите попытку восстановления пароля'; else
    return status;
  }

  render() {
    const { badPasswordError, tokenNotFound } = this.state;
    const { processPasswordResetSuccess,
            processPasswordResetError,
            processPasswordResetInProcess } = this.props;
    return (
      <div className="zApp">
        <div className="container app-modules">
          { 
            processPasswordResetSuccess ?
              <Redirect to={ "/PasswordReset/Success" }/> :
              true
          }
          <div className="row">
            <div className="col-lg-3 col-md-0">
              <LogoView/>
            </div>
            <div className="col-lg-6 col-md mt-2">
              <h3>Изменение утраченного пароля</h3>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="d-md-none">
                <hr/>
              </div>
            </div>
          </div>

          { tokenNotFound && 
            <div className="row">
              <div className="col-lg-3 col-md-1"></div>
              <div className="col-lg-6 col-md-10">
                <ErrorCard/>
              </div>
            </div>
          }
          { !tokenNotFound && 
            <div>
              <div className="row">
                <div className="col-lg-3 col-md-0"></div>
                <div className="col-lg-6 col-md-0">
                  <div className="alert alert-info zalert-info-card" role="alert">
                    Пожалуйста, введите новый пароль
                    <hr/>
                    Длина пароля не должна быть менее 6 символов<br/>
                    </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-3 col-md-1"></div>
                  <div className="col-lg-6 col-md-10">

                    <form onSubmit={ this.doPasswordResetProcess.bind(this) }>
                      { processPasswordResetError &&
                          <p className="text-danger">{ this.readPasswordResetRequestStatus() }</p>
                      }
                      { processPasswordResetInProcess &&
                          <p className="text-info">{ this.readPasswordResetRequestStatus() }</p>
                      }
                      { badPasswordError.code!==0 &&
                        <span className="text-danger">{ badPasswordError.message }</span>
                      }

                      <div className="form-group">
                        <input type="password"
                          ref={ (input)=>{ this.PasswordInput = input } }
                          className="form-control input-lg"
                          required="required"
                          size="50"
                          minLength="6"
                          placeholder="Новый пароль" />
                      </div>

                      <div className="form-group">
                        <input type="password"
                          ref={ (input)=>{ this.PasswordInputTest = input } }
                          className="form-control input-lg"
                          required="required"
                          size="50"
                          minLength="6"
                          placeholder="Подтверждение пароля" />
                      </div>

                      <div className="row">
                        <div className="col-lg col-md-0"></div>
                        <div className="col-lg col-md-0">
                          <button type="submit"
                            disabled={ processPasswordResetInProcess }
                            className="btn btn-outline-info btn-block">
                              {(processPasswordResetInProcess) &&
                              <div>
                                Сохранение...&nbsp;
                                <i className="fa fa-circle-o-notch fa-spin fa-lg fa-fw"></i>
                              </div>
                              }
                              {(!processPasswordResetInProcess) &&
                              <div>
                                Сохранить
                              </div>
                              }
                          </button>
                        </div>
                      </div>
                    </form>
                </div>
            </div>
          </div>
        }

        </div>
        <FooterView/>
      </div>
    );
  }
}

export default PasswordResetProcessView;
