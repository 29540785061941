import React from 'react';
import { DataGrid, CartSuccessView, CartConfirmView } from '../../Main'
import PropTypes from 'prop-types';
// Элементы управления состоянием корзины
import CartControls from '../../../../containers/CartControls'

export default class CartView extends React.Component {
  static propTypes = {
    authorized: PropTypes.bool.isRequired,
    token: PropTypes.string,
    login: PropTypes.string,
    customers: PropTypes.number,
    systemName: PropTypes.string,
    systemVersion: PropTypes.string,
    cart: PropTypes.object.isRequired,
    cartGridHeader: PropTypes.array.isRequired,
    ParentTradeModuleMenu: PropTypes.func.isRequired,
    cartControlsKeyFieldsMap: PropTypes.object.isRequired,
  };
  
  state = {
    activeLine: {},
    cartBody: [],
  }

  GridRowControls = (row) => {
    const getFieldValFromDataRow = (fieldName) => row.dataRow[this.props.cartControlsKeyFieldsMap[fieldName]];
    return(<CartControls
      dataRow = {
       {
         goodsId: getFieldValFromDataRow('goodsId'),
         goodsName: getFieldValFromDataRow('goodsName'),
         goodsPrice: getFieldValFromDataRow('goodsPrice'),
         goodsLine: row.dataRow,
       }
      }
      cart = { this.props.cart }
      addToCart = { this.props.doAddToCart }
      removeFromCart = { this.props.doRemoveFromCart }
    />)
  }

  handleItemSelection = (item) => {
    this.setState({activeLine : item});
  }

  componentWillMount(){
    if (this.props.location.pathname === '/Cart/Apply') 
      this.doApplyCart();

    this.setState({
      cartBody: this.props.cart.items.map((item)=>item.goodsLine)
    });
  }

  componentWillUnmount(){
    if(this.props.cart.cartItemsQnt === 0) {
      this.props.doInitCart();
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      cartBody: nextProps.cart.items.map((item)=>item.goodsLine)
    });
  }

  ParentTradeModuleMenu = () => this.props.ParentTradeModuleMenu();

  doApplyCart = () => {
    this.props.doApplyCart(
        this.props.token,
        this.props.cart, 
        this.props.customers, 
        { comment: this.CartCommentInput?this.CartCommentInput.value:null }
    );
  }

  getDataGrid = () => {
    return <DataGrid
    header = { this.props.cartGridHeader }
    body = { this.state.cartBody }
    onItemClick = { this.handleItemSelection }
    limit = { 1000 }
    rowControls = { this.GridRowControls }
    />
  }

  setCommentInput = (val) => {
    this.CartCommentInput = val;
  }

  render() {
    const { history, cart, doClearCart } = this.props;
    return (
      <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-xl-1 col-lg-0 module-menu-line-color border-bottom"></div>
        <div className="col module-menu-line-color border-bottom">
          {/* Главное меню модуля */}
          { this.ParentTradeModuleMenu() }
        </div>
      </div>

        {
          (cart.applySuccess) && 
            <CartSuccessView orderId={cart.orderId}/>
        }
        {
          (!cart.applySuccess) && 
            <CartConfirmView 
              doApplyCart = { this.doApplyCart }
              history={ history }
              cart={ cart } 
              doClearCart={ doClearCart } 
              DataGrid={ this.getDataGrid }
              setCommentInput = { this.setCommentInput }
            />
        }
    </div>
    );
  }
}
