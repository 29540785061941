export const ClaimDetailsGridHeader = [
  { caption: 'Наименование',
    hint: 'Наименование товара по расходному документу',
    fieldName: 'WB_MEDS_NAME',
    flexWidth: 3,
  },
  { caption: 'Кол-во РН',
    hint: 'Количество товара по расходному документу',
    fieldName: 'WB_MEDS_AMO',
    flexWidth: 1,
  },
  { caption: 'Характер претензии',
    hint: 'Характер претензии',
    fieldName: 'CLAIM_DEFINITION',
    flexWidth: 3,
  },
  { caption: 'Кол-во претензия',
    hint: 'Количество по претензии',
    fieldName: 'CLAIM_AMOUNT',
    flexWidth: 1,
  },
  { caption: 'Примечание',
    hint: 'Примечание к претензии',
    fieldName: 'CLAIM_COMMENTARY',
    flexWidth: 2,
  },
  { caption: 'Решение',
    hint: 'Принятое решение',
    fieldName: 'CLAIM_SOLVE',
    flexWidth: 2,
  },
];

export { default as ClaimDetailsGrid } from './ClaimDetailsGrid';

/**
 CLAIM_AMOUNT: 1                             "Количество по претензии"
 CLAIM_COMMENTARY: null                     "Комментарий по строке"
 CLAIM_DEFINITION: "Брак (бой, Вн. вид)"    "Характер претензии по строке"
 CLAIM_SOLVE: "Обмен товара"                "Решение"
DDATE: "2017-11-09T21:00:00.000Z"
DOC: 16502245
OVER_AMO_MEDS: 10                                    "Товар с пересортом meds"
OVER_AMO_MEDS_NAME: " Неопределенное торговое "      "Товар с пересортом"
STATE: "Удовлетворено"
 WB_MEDS: 457219                                     "Товар из РН meds"
 WB_MEDS_AMO: 2                                      "Количество товара в РН"
 WB_MEDS_NAME: "Курносики бутылочка ..."             "Товар из РН"
*/
