import { apiCommandGETReceivablesInfo } from '../../constants/receivables';
import { validationJSON } from '../../lib/utils'
import { checkAuthorization } from '../authorization';

// Получение истории одного заказа
export function requestReceivables(customers) {
  return {
    type: 'RECEIVABLES_INFO_REQUEST',
    customers: customers,
  }
}

export function requestReceivablesSuccess(customers, data) {
  data = data.receivablesInfo.slice(0, 1001);
  try {
    window.localStorage.setItem('ReceivablesInfo', JSON.stringify(data));
  } catch (e) {
    alert( 'Ошибка при работе с локальным хранилищем браузера ', e );
  }
  return {
    type: 'RECEIVABLES_INFO_REQUEST_SUCCESS',
  }
}

export function requestReceivablesError(errorCode, errorMessage) {
  return {
    type: 'RECEIVABLES_INFO_REQUEST_ERROR',
    error: {errorCode, errorMessage},
  }
}

export function getReceivables(token, customers) {
  return function(dispatch) {
    dispatch(requestReceivables(customers));
    let httpStatusCode = null;
    let apiErrorCode = null;
    let apiErrorMessage = null;

    return fetch(apiCommandGETReceivablesInfo + '?customers=' + customers + '&access_token=' + token, {
      // cors - Сервис API может быть на любом сервере, same-origin - Тот же самый сервер
      mode: 'cors',
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response)=>{
        // Извлекаем код ответа HTTP
        httpStatusCode = response.status;
        return response;
      })
      .then((response) => checkAuthorization(dispatch, response))
      .then(validationJSON) // Раскрывает промис
      .then((data)=> {
        // data содержит ответ от API
        if (httpStatusCode === 200) {
          dispatch(requestReceivablesSuccess(customers, data));
        }
        else {
          // data содержит расшифровку ошибки в объекте error
          apiErrorCode = data.error.code;
          apiErrorMessage = data.error.message;
          const error = new Error()
          throw error
        }
      })
      .catch((error) => {
        dispatch(
          requestReceivablesError(
            apiErrorCode || httpStatusCode,
            apiErrorMessage || error.message
          ));
      })
  }
}

