import {backendServiceURL} from '../config/backendService'

export const CLAIMS_LIST_REQUEST = 'CLAIMS_LIST_REQUEST'
export const CLAIMS_LIST_REQUEST_SUCCESS = 'CLAIMS_LIST_REQUEST_SUCCESS'
export const CLAIMS_LIST_REQUEST_ERROR = 'CLAIMS_LIST_REQUEST_ERROR'

export const CLAIM_DETAILS_REQUEST = 'CLAIM_DETAILS_REQUEST'
export const CLAIM_DETAILS_REQUEST_SUCCESS = 'CLAIM_DETAILS_REQUEST_SUCCESS'
export const CLAIM_DETAILS_REQUEST_ERROR = 'CLAIM_DETAILS_REQUEST_ERROR'

export const OUT_WAYBILL_DETAILS_REQUEST = 'OUT_WAYBILL_DETAILS_REQUEST'
export const OUT_WAYBILL_DETAILS_REQUEST_SUCCESS = 'OUT_WAYBILL_DETAILS_REQUEST_SUCCESS'
export const OUT_WAYBILL_DETAILS_REQUEST_ERROR = 'OUT_WAYBILL_DETAILS_REQUEST_ERROR'
export const OUT_WAYBILL_DETAILS_INIT = 'OUT_WAYBILL_DETAILS_INIT'

export const CREATE_NEW_CLAIM_INIT_PROCESS = 'CREATE_NEW_CLAIM_INIT_PROCESS'
export const CREATE_NEW_CLAIM_SET_OUT_WAYBILL_ID = 'CREATE_NEW_CLAIM_SET_OUT_WAYBILL_ID'
export const CREATE_NEW_CLAIM_SET_OUT_WAYBILL_LINE_ID = 'CREATE_NEW_CLAIM_SET_OUT_WAYBILL_LINE_ID'
export const CREATE_NEW_CLAIM_REQUEST = 'CREATE_NEW_CLAIM_REQUEST'
export const CREATE_NEW_CLAIM_REQUEST_SUCCESS = 'CREATE_NEW_CLAIM_REQUEST_SUCCESS'
export const CREATE_NEW_CLAIM_REQUEST_ERROR = 'CREATE_NEW_CLAIM_REQUEST_ERROR'

export const UNDO_CLAIM_INIT = 'UNDO_CLAIM_INIT'
export const UNDO_CLAIM_REQUEST = 'UNDO_CLAIM_REQUEST'
export const UNDO_CLAIM_REQUEST_SUCCESS = 'UNDO_CLAIM_REQUEST_SUCCESS'
export const UNDO_CLAIM_REQUEST_ERROR = 'UNDO_CLAIM_REQUEST_ERROR'

// Команды API интерфейса
//
// Получить список документов Претензия
export const apiCommandGETClaimsList = backendServiceURL + 'api/v1/Claims/getClaims'
// Получить детали документа Претензия
export const apiCommandGETClaimDetails = backendServiceURL + 'api/v1/Claims/getClaimDetails'
// Получить детали расходного документа
export const apiCommandGETOutWaybillDetails = backendServiceURL + 'api/v1/Claims/getOutWaybillDetails'
// Создать претензию
export const apiCommandPOSTCreateClaim = backendServiceURL + 'api/v1/Claims/createClaim'
// Отменить претензию
export const apiCommandPATCHUndoClaim = backendServiceURL + 'api/v1/Claims/undoClaim'
