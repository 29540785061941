import { connect } from 'react-redux';
import { ClaimsClaimDetails } from '../../components/Desktop/Claims';

import { getClaimDetails } from '../../actions/Main/Claims';

const mapStateToProps = (state) => ({
  authorized: state.authorization.authorized,
  token: state.authorization.token,
  login: state.authorization.login,
  // customers: state.authorization.userId,
  customers: state.authorization.childUserId || state.authorization.userId,

  requestClaimDetailsInProcess: state.claims.requestClaimDetailsInProcess,
  requestClaimDetailsError: state.claims.requestClaimDetailsError,
  requestClaimDetailsSuccess: state.claims.requestClaimDetailsSuccess,
  requestClaimDetailsStatus: state.claims.requestClaimDetailsStatus,
});

const mapDispathToProps = (dispath) => {
  return {
    getClaimDetails:
      ( token, customers, claimId )=>
        dispath(getClaimDetails(token, customers, claimId )),
  };
};

export default connect(
  mapStateToProps, mapDispathToProps
)(ClaimsClaimDetails)

