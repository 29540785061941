export const OrdersHistoryHeader = [
  { caption: 'Код',
    fieldName: 'orderId',
    flexWidth: 1,
  },
  { caption: 'Дата',
    hint: 'Дата формирования заказа',
    fieldName: 'creationDate',
    isDateTimeFormat: true,
    flexWidth: 2,
  },
  { caption: 'Комментарий',
    fieldName: 'commentary',
    flexWidth: 5,
  },
  { caption: 'Статус',
    fieldName: 'statusName',
    href: '/PersonalOrders/OrderStatuses',
    hint: 'Статус обработки заказа',
    rowHrefTemplate: '/PersonalOrders/OrderHistory/{%orderId}',
    flexWidth: 1,
  },
  { caption: 'Доставка',
    fieldName: 'deliveryDate',
    hint: 'Согласованная дата доставки',
    // Если не дата то значение выведется без преобразований к формату даты
    isDateFormat: true,
    flexWidth: 1,
    columnSpacer: 'ml-3',
  },
  { caption: 'Детали',
    hint: 'Детали заказа',
    fieldName: 'ROW_CONTROLS',
    flexWidth: 1,
    columnSpacer: 'ml-3',
  },
];

export { default as PersonalOrdersOrdersHistoryGrid } from './PersonalOrdersOrdersHistoryGrid';

/*
clientId: 502938
commentary: null
creationDate: "2018-05-14T18:29:38.000Z"
deliveryDate: "2018-05-14T21:29:38.000Z"
orderId: 333
status: 1
*/
