import React, { Component } from 'react';
import { LoadingView }  from '../../Main'
// import { ReceivablesInfoGrid } from '../ReceivablesInfo'

import { Link } from 'react-router-dom'
import { ClaimDetailsGrid } from '../ClaimsClaimDetails'
import { ClaimDetailsHeadView } from '../ClaimsClaimDetails/ClaimDetailsHeadView'

export default class ClaimsClaimDetails extends Component {
  
  static claimDetails = {};

  state = {
    claimDetailsLoaded: false,
    claimDetailsCount: null,
  }

  tryRenderClaimDetails(newProps){
    if( newProps.requestClaimDetailsSuccess ){
      let lsData = JSON.parse(localStorage.getItem('ClaimDetails'));
      if (lsData !== null && typeof lsData === 'object') {
        ClaimsClaimDetails.claimDetails = lsData;
          this.setState({
            claimDetailsLoaded: true,
            claimDetailsCount: lsData.length
          });
        }
      }else{
        this.setState({
          claimDetailsLoaded: false,
          claimDetailsCount: null,
        });
      }
  }

  componentWillReceiveProps(nextProps){
    this.tryRenderClaimDetails(nextProps);
  }

  doGetClaimDetails(){
    const { ClaimId } = this.props.match.params;
    this.props.getClaimDetails(this.props.token,
      this.props.customers,
      ClaimId);
  }

  getGoBackLinkPath(){
      //  !! - true если переменная undefined
      const previousLocation = !!window.previousLocation?window.previousLocation:'';
      // Перечисление управляющих маршрутов
      const goBackConditions = {
      conditions : 
        [
          {
            prevPath : '/Claims/List',
            backPathTitle : 'Список претензий',
            backPath : '/Claims/List'
          },
          {
            prevPath : '/Claims/CreateNew',
            backPathTitle : 'Расходный документ',
            purePrevPath: true,//Путь назад точно совпадает с previousLocation (опция)
          },
        ],
      default : {
        backPath : '/Claims/List',
        backPathTitle : 'Список претензий',
      }
    }

    const trueGoBackPath = goBackConditions.conditions.filter(function(item){
        return ~previousLocation.indexOf(item.prevPath);
    });

    //Если обратный маршрут должен совпадать с previousLocation (опция)
    if (!!trueGoBackPath[0]) {
      trueGoBackPath[0].backPath = trueGoBackPath[0].purePrevPath ? previousLocation : trueGoBackPath[0].backPath;
    }

    return !!trueGoBackPath[0]
    ? trueGoBackPath[0]
    :goBackConditions.default;
  }

  componentWillMount(){
    this.doGetClaimDetails();
  }

  handleRowSelection = () => {
  }

  render() {
    const {
      requestClaimDetailsInProcess,
      requestClaimDetailsError,
      requestClaimDetailsSuccess } = this.props;
    const { ClaimId } = this.props.match.params;
    const {
      claimDetailsCount
    } = this.state;
    const backLinkDetails = this.getGoBackLinkPath();

    let isCanceled = 0;
    if ( Array.isArray(ClaimsClaimDetails.claimDetails) && ClaimsClaimDetails.claimDetails.length > 0 )
      isCanceled = !!ClaimsClaimDetails.claimDetails[0].CANCELED
        ?ClaimsClaimDetails.claimDetails[0].CANCELED
        :0;

    return (
      <div>
        <Link to={ backLinkDetails.backPath } className="internal-page-link-calm">
          <div
            type="button"
            className="btn btn-outline-info btn-sm mb-3">
            <i className="fa fa-arrow-left" aria-hidden="true"></i>
            &nbsp;{ backLinkDetails.backPathTitle }
          </div>
        </Link>

        {
          requestClaimDetailsError &&
          <div>
            <font color="red">
              <p>Ошибка получения данных о претензиях...</p>
              <p>Сервис временно не доступен.</p>
            </font>
          </div>
        }

        {
          !requestClaimDetailsError && requestClaimDetailsInProcess &&
          <LoadingView text={'Получение деталей претензии...'}/>
        }

        {
          requestClaimDetailsSuccess && this.state.claimDetailsLoaded &&
          <div>
            <ClaimDetailsHeadView claimData={ ClaimsClaimDetails.claimDetails }/>
            <hr/>

            { isCanceled === 1 &&
            <Link to={ '/Claims/UndoClaim/' + ClaimId } className="internal-page-link-calm">
              <div
                type="button"
                className="btn btn-outline-success btn-sm mb-3">
                <i className="fa fa-thumbs-o-up" aria-hidden="true"></i>&nbsp;
                Отменить претензию
              </div>
            </Link>}

            { isCanceled === 0 &&
             <div className="row">
               <div className="col">
                 <div className="alert alert-warning" role="alert">
                   <p>В текущем состоянии обработки документа возможность <b>отмены претензии</b> отсутствует.<br/>
                   Если требуется отменить претензию, пожалуйста, свяжитесь с Вашим персональным менеджером.
                   </p>
                  </div>
                </div>
              </div>
            }

            <ClaimDetailsGrid
              gridData= { ClaimsClaimDetails.claimDetails }
              onSelectItem= { this.handleRowSelection }
              RowControls= { this.GridRowControls }
            />
          </div>
        }

        {
          requestClaimDetailsSuccess && this.state.claimDetailsLoaded && (claimDetailsCount === 0) &&
          <p>Детали претензии #{ ClaimId } отсутствуют</p>
        }
      </div>
    );
  }
}
