import React from 'react';
import './styles';

export const ClaimsIndexView = () => (
  <div className="row">
    <div className="col-xl-10 col-md">
      <h5>Работа с претензиями</h5>
      <div className="alert alert-info zalert-info-card" role="alert">
        <p>Управление претензиями к доставленному товару </p>
        <hr/>
        <p>Вы можете создавать новые претензии, просматривать созданные ранее документы и контролировать состояние актуальных претензионных заявок</p>
      </div>
    </div>
  </div>
)
