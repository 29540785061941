import { apiCommandGETCatalog,
  apiCommandGETActiveOrders,
  apiCommandGETOrderDetails,
  apiCommandGETOrdersHistory,
  apiCommandGETOrderHistory,
  apiCommandPOSTApplyOrder } from '../../constants/personalOrders';
import { validationJSON, makeJSON } from '../../lib/utils'
import { checkAuthorization } from '../authorization';

import { idbKeyval } from '../../lib/db';

const ONLY_FROM_LOCAL_STORAGE = true;

function catalogIsExpired(LastCatalogRefreshTime) {
  const freshMinutes = 15;
  return ((((new Date()).getTime() - (new Date(LastCatalogRefreshTime)).getTime()) / 60000) > freshMinutes);
}


export function requestCatalog( token, customers, filter ) {
  return {
    type: 'CATALOG_REQUEST',
    token: token,
    requestCatalogFilter: filter,
    clientId: customers,
  }
}

export function requestCatalogSuccess(filter, goodsCatalog, linesCount, refreshTime, customers) {
  // Сохранение каталога товаров в IndexedDB
  idbKeyval.set('GoodsCatalogItems', JSON.stringify(goodsCatalog))
    // .then(() => console.log('GoodsCatalogItems - saved!'))
    .catch(() => alert( 'Ошибка при работе с IndexedDb-хранилищем браузера' ));

  try {
    window.localStorage.setItem('GoodsCatalogLinesCount', linesCount);
    window.localStorage.setItem('GoogsCatalogRefreshTime', refreshTime);
    window.localStorage.setItem('GoodsCatalogFilter', filter);
    window.localStorage.setItem('GoodsCatalogCustomers', customers);
  } catch (e) {
    alert( 'Ошибка при работе с локальным хранилищем браузера' );
  }

  return {
    type: 'CATALOG_REQUEST_SUCCESS',
    requestCatalogFilter: filter,
    catalogLinesCount: linesCount,
    catalogRefreshTime: refreshTime,
    expired: false,
  }
}

export function requestCatalogError(errorCode, errorMessage) {
  return {
    type: 'CATALOG_REQUEST_ERROR',
    requestCatalogError: true,
    error: {errorCode, errorMessage},
  }
}

/* eslint-disable */
/* eslint-enable */
/**
 *
 * @param {Токен безопасности} token
 * @param {Код ERP контрагента} customers
 * @param {Фильтр при получении каталога от API} filter
 * @param {Метод который позволит восстановить состояние из LS} restoreCatalogState
 */
export function getGoodsCatlog( token, customers, filter, restoreCatalogState) {
  return function(dispatch) {
    dispatch(requestCatalog( token, customers, filter ));
    let httpStatusCode = null;
    let apiErrorCode = null;
    let apiErrorMessage = null;

    return fetch(apiCommandGETCatalog + '?customers=' + customers + '&access_token=' + token, {
      // cors - Сервис API может быть на любом сервере, same-origin - Тот же самый сервер
      mode: 'cors',
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response)=>{
        // Извлекаем код ответа HTTP
        httpStatusCode = response.status;
        return response;
      })
      .then((response) => checkAuthorization(dispatch, response))
      .then(validationJSON) // Раскрывает промис
      .then((data)=> {
        // data содержит ответ от API
        if (httpStatusCode === 200) {
          dispatch(requestCatalogSuccess(filter, data.priceList, data.priceList.length, new Date(), customers));
        }
        else {
          // data содержит расшифровку ошибки в объекте error
          apiErrorCode = data.error.code;
          apiErrorMessage = data.error.message;
          const error = new Error()
          throw error
        }
      })
      .catch((error) => {
        dispatch(
          requestCatalogError(
            apiErrorCode || httpStatusCode,
            apiErrorMessage || error.message
          ));
        // Если не удалось получить данные с сервера пытаемся восстановиться из LS
        // в случае если такая возможность существует
        if (typeof (restoreCatalogState) === 'function') {
          // Т.к. метод определен, то известно, что LS содержит данные, но они устарели
          // восстанавливаем состояние из LS и указываем режим
          // ONLY_FROM_LOCAL_STORAGE - чтобы после восстановлениия не обращаться к API
          dispatch(restoreCatalogState(ONLY_FROM_LOCAL_STORAGE, token, customers, filter));
        }
      })
  }
}

// Попытка восстановить состояние каталога из LocalStorage
export function requestRestoreCatalogLS() {
  return {
    type: 'RESTORE_CATALOG_FROM_LS_REQUEST',
  }
}

// Отказ восстановить состояние каталога из LocalStorage
export function restoreCatalogLSError(errorCode, errorMessage) {
  return {
    type: 'RESTORE_CATALOG_FROM_LS_ERROR',
    error: {errorCode, errorMessage},
  }
}

// Удалось восстановить состояние каталога из LocalStorage
export function restoreCatalogLSSuccess(expired, filter, goodsCatalog, linesCount, refreshTime, clientId) {
  return {
    type: 'RESTORE_CATALOG_FROM_LS_SUCCESS',
    requestCatalogFilter: filter,
    catalogLinesCount: linesCount,
    catalogRefreshTime: refreshTime,
    expired: catalogIsExpired(refreshTime),
    clientId: clientId,
  }
}

/**
 * @param {Режим - не обращаться к API} localStorageOnly
 * @param {Токен безопасности} token
 * @param {Код ERP контрагента} customers
 * @param {Фильтр при получении каталога от API} filter
 */

export function restoreCatalogState(localStorageOnly, token, customers, filter) {
  return function(dispatch) {
    dispatch(requestRestoreCatalogLS());
    let goodsCatalogLinesCount = localStorage.getItem('GoodsCatalogLinesCount');
    let goodsCatalogRefreshTime = localStorage.getItem('GoogsCatalogRefreshTime');
    let goodsCatalogFilter = localStorage.getItem('GoodsCatalogFilter');

    // Если ранее загруженный каталог принадлежал другому клиенту, то из LS не восстанавливаем
    // Вместо этого обновляем данные через API
    let goodsCatalogCustomers = localStorage.getItem('GoodsCatalogCustomers');
    if (Number(goodsCatalogCustomers) !== Number(customers)) {
      goodsCatalogLinesCount = 0;
    }

    // Каталог был ранее загружен в LocalStorage и
    // в настоящее время еще актуален - можно инициировать восстановление состояния
    if (goodsCatalogLinesCount > 0) {
      let goodsCatalogItems = JSON.parse(localStorage.getItem('GoodsCatalogItems'));
      dispatch(restoreCatalogLSSuccess(
        catalogIsExpired(goodsCatalogRefreshTime),
        goodsCatalogFilter, goodsCatalogItems,
        goodsCatalogLinesCount, goodsCatalogRefreshTime,
        goodsCatalogCustomers
      ));

      if (localStorageOnly) return; // Если нельзя обращаться к API то выходим

      // Состояние восстановлено из LS но требуется проверить данные на актуальность
      if (catalogIsExpired(goodsCatalogRefreshTime)) {
        // Вызываем обращение к API в режиме "при сбое восстановить данные из локального состояния"
        dispatch(getGoodsCatlog(token, customers, filter, restoreCatalogState));
      }

    } else {
      dispatch(restoreCatalogLSError(404, 'Каталог не был загружен ранее в LS'));
      // Вызываем обращение к API в режиме "при сбое НЕ восстанавливать данные из локального состояния"
      dispatch(getGoodsCatlog(token, customers, filter));
    }
  }
}

// Восстанавливаем состояние из LS с [разрешением к обращению к API]
// Если удалось восстанавливить состояние из LS, и каталог актуален, то OK

// Если не удалось восстанавливить состояние из LS, и если есть "разрешение для обращению к API"
//  обращаемся к API в режме "при сбое НЕ восстановить данные из локального состояния"

// Если удалось восстанавливить состояние из LS, и каталог актуален, то OK

// Если удалось восстанавливить состояние из LS, и каталог НЕ актуален, то
// если есть "разрешение для обращению к API", то
// обращаемся к API в режме "при сбое НЕ восстановить данные из локального состояния"

export function requestActiveOrders(filter) {
  return {
    type: 'ACTIVE_ORDERS_REQUEST',
    activeOrdersFilter: filter,
  }
}

export function requestActiveOrdersSuccess(filter, activeOrders) {
  try {
    window.localStorage.setItem('ActiveOrdersItems', JSON.stringify(activeOrders));
  } catch (e) {
    alert( 'Ошибка при работе с локальным хранилищем браузера' );
  }
  return {
    type: 'ACTIVE_ORDERS_REQUEST_SUCCESS',
    requestCatalogFilter: filter,
  }
}

export function requestActiveOrdersError(errorCode, errorMessage) {
  return {
    type: 'ACTIVE_ORDERS_REQUEST_ERROR',
    requestCatalogError: true,
    error: {errorCode, errorMessage},
  }
}


export function getActiveOrders(token, customers, filter) {
  return function(dispatch) {
    dispatch(requestActiveOrders(filter));
    let httpStatusCode = null;
    let apiErrorCode = null;
    let apiErrorMessage = null;

    return fetch(apiCommandGETActiveOrders + '?customers=' + customers + '&access_token=' + token, {
      // cors - Сервис API может быть на любом сервере, same-origin - Тот же самый сервер
      mode: 'cors',
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response)=>{
        // Извлекаем код ответа HTTP
        httpStatusCode = response.status;
        return response;
      })
      .then((response) => checkAuthorization(dispatch, response))
      .then(validationJSON) // Раскрывает промис
      .then((data)=> {
        // data содержит ответ от API
        if (httpStatusCode === 200) {
          dispatch(requestActiveOrdersSuccess(filter, data.orders));
        }
        else {
          // data содержит расшифровку ошибки в объекте error
          apiErrorCode = data.error.code;
          apiErrorMessage = data.error.message;
          const error = new Error()
          throw error
        }
      })
      .catch((error) => {
        dispatch(
          requestActiveOrdersError(
            apiErrorCode || httpStatusCode,
            apiErrorMessage || error.message
          ));
      })
  }
}

// Получение деталей заказа
export function requestOrderDetails(orderId) {
  return {
    type: 'ORDER_DETAILS_REQUEST',
    orderId: orderId,
  }
}

export function requestOrderDetailsSuccess(orderId, orderDetails) {
  try {
    window.localStorage.setItem('OrderDetails', JSON.stringify(orderDetails));
  } catch (e) {
    alert( 'Ошибка при работе с локальным хранилищем браузера' );
  }
  return {
    type: 'ORDER_DETAILS_REQUEST_SUCCESS',
    orderId: orderId,
  }
}

export function requestOrderDetailsError(errorCode, errorMessage) {
  return {
    type: 'ORDER_DETAILS_REQUEST_ERROR',
    requestCatalogError: true,
    error: {errorCode, errorMessage},
  }
}

export function getOrderDetails(token, customers, orderId) {
  return function(dispatch) {
    dispatch(requestOrderDetails(orderId));
    let httpStatusCode = null;
    let apiErrorCode = null;
    let apiErrorMessage = null;

    return fetch(apiCommandGETOrderDetails +
        '?customers=' + customers +
        '&orderId=' + orderId +
        '&access_token=' + token, {
      // cors - Сервис API может быть на любом сервере, same-origin - Тот же самый сервер
      mode: 'cors',
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response)=>{
        // Извлекаем код ответа HTTP
        httpStatusCode = response.status;
        return response;
      })
      .then((response) => checkAuthorization(dispatch, response))
      .then(validationJSON) // Раскрывает промис
      .then((data)=> {
        // data содержит ответ от API
        if (httpStatusCode === 200) {
          dispatch(requestOrderDetailsSuccess(orderId, data.orderDetails));
        }
        else {
          // data содержит расшифровку ошибки в объекте error
          apiErrorCode = data.error.code;
          apiErrorMessage = data.error.message;
          const error = new Error()
          throw error
        }
      })
      .catch((error) => {
        dispatch(
          requestOrderDetailsError(
            apiErrorCode || httpStatusCode,
            apiErrorMessage || error.message
          ));
      })
  }
}

// Получение истории заказов
export function requestOrdersHistory(filter) {
  return {
    type: 'ORDERS_HISTORY_REQUEST',
    requestOrdersHistoryFilter: filter,
  }
}

export function requestOrdersHistorySuccess(filter, ordersHistory) {
  try {
    window.localStorage.setItem('OrdersHistoryItems', JSON.stringify(ordersHistory));
  } catch (e) {
    alert( 'Ошибка при работе с локальным хранилищем браузера' );
  }
  return {
    type: 'ORDERS_HISTORY_REQUEST_SUCCESS',
    requestOrdersHistoryFilter: filter,
  }
}

export function requestOrdersHistoryError(errorCode, errorMessage) {
  return {
    type: 'ORDERS_HISTORY_REQUEST_ERROR',
    requestCatalogError: true,
    error: {errorCode, errorMessage},
  }
}

export function getOrdersHistory(token, customers, filter) {
  return function(dispatch) {
    dispatch(requestOrdersHistory(filter));
    let httpStatusCode = null;
    let apiErrorCode = null;
    let apiErrorMessage = null;

    return fetch(apiCommandGETOrdersHistory + '?customers=' + customers + '&access_token=' + token, {
      // cors - Сервис API может быть на любом сервере, same-origin - Тот же самый сервер
      mode: 'cors',
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response)=>{
        // Извлекаем код ответа HTTP
        httpStatusCode = response.status;
        return response;
      })
      .then((response) => checkAuthorization(dispatch, response))
      .then(validationJSON) // Раскрывает промис
      .then((data)=> {
        // data содержит ответ от API
        if (httpStatusCode === 200) {
          dispatch(requestOrdersHistorySuccess(filter, data.orders));
        }
        else {
          // data содержит расшифровку ошибки в объекте error
          apiErrorCode = data.error.code;
          apiErrorMessage = data.error.message;
          const error = new Error()
          throw error
        }
      })
      .catch((error) => {
        dispatch(
          requestOrdersHistoryError(
            apiErrorCode || httpStatusCode,
            apiErrorMessage || error.message
          ));
      })
  }
}


// Получение истории одного заказа
export function requestOrderHistory(customers, orderId) {
  return {
    type: 'ORDER_HISTORY_REQUEST',
    requestOrderHistoryOrderId: orderId,
    requestOrderHistoryCustomers: customers,
  }
}

export function requestOrderHistorySuccess(customers, orderId, orderHistory) {
  try {
    window.localStorage.setItem('OrderHistoryItems', JSON.stringify(orderHistory));
  } catch (e) {
    alert( 'Ошибка при работе с локальным хранилищем браузера' );
  }
  return {
    type: 'ORDER_HISTORY_REQUEST_SUCCESS',
    requestOrderHistoryOrderId: orderId,
    requestOrderHistoryCustomers: customers,
  }
}

export function requestOrderHistoryError(errorCode, errorMessage) {
  return {
    type: 'ORDER_HISTORY_REQUEST_ERROR',
    requestCatalogError: true,
    error: {errorCode, errorMessage},
  }
}

export function getOrderHistory(token, customers, orderId) {
  return function(dispatch) {
    dispatch(requestOrderHistory(customers, orderId));
    let httpStatusCode = null;
    let apiErrorCode = null;
    let apiErrorMessage = null;

    return fetch(apiCommandGETOrderHistory + '?customers=' + customers + '&orderId=' + orderId + '&access_token=' + token, {
      // cors - Сервис API может быть на любом сервере, same-origin - Тот же самый сервер
      mode: 'cors',
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response)=>{
        // Извлекаем код ответа HTTP
        httpStatusCode = response.status;
        return response;
      })
      .then((response) => checkAuthorization(dispatch, response))
      .then(validationJSON) // Раскрывает промис
      .then((data)=> {
        // data содержит ответ от API
        if (httpStatusCode === 200) {
          dispatch(requestOrderHistorySuccess(orderId, customers, data.orderHistory));
        }
        else {
          // data содержит расшифровку ошибки в объекте error
          apiErrorCode = data.error.code;
          apiErrorMessage = data.error.message;
          const error = new Error()
          throw error
        }
      })
      .catch((error) => {
        dispatch(
          requestOrderHistoryError(
            apiErrorCode || httpStatusCode,
            apiErrorMessage || error.message
          ));
      })
  }
}

// Импорт заказа из файла
export function openOrderFileTry(fileName, rawFileData) {
  return {
    type: 'IMPORT_ORDER_OPEN_ORDER_FILE_TRY',
    fileName: fileName,
    rawOrderData: rawFileData,
  }
}

export function openOrderFileSuccess(order) {
  return {
    type: 'IMPORT_ORDER_OPEN_ORDER_FILE_SUCCESS',
    order: order,
  }
}

export function openOrderFileError(status, errorCode) {
  return {
    type: 'IMPORT_ORDER_OPEN_ORDER_FILE_ERROR',
    importStatus: status,
    errorCode: errorCode,
  }
}

// Отправка заказа на сервер

/**
 * Формирование тела заказа в соответствии с форматом API
 * @param {*Содержимое корзины} orderData
 * @param {*Свойства заказа} props
 */
export function getApiOrderBody(orderData, props) {

  const orderItemsToAPIOrderMapping = {
    MEDS: 'itemCode',
    MEDS_NAME: 'name',
    AMOUNT: 'qtty',
    PRICE: 'price',
    COMMENTARY: 'commentary',
  };

  const order =
  {
    comment: props.comment,
    items: [],
  }

  order.items = orderData.items.map((item)=>{
    let orderLine = {};
    for (let key in item) orderLine[orderItemsToAPIOrderMapping[key]] = item[key]
    return orderLine;
  });
  return order;
}

export function importOrderApplyRequest() {
  return {
    type: 'IMPORT_ORDER_APPLY_REQUEST',
  }
}

export function importOrderApplySuccess(orderId) {
  return {
    type: 'IMPORT_ORDER_APPLY_SUCCESS',
    importedOrderId: orderId,
  }
}

export function importOrderApplyError(errorMessage, errorCode) {
  return {
    type: 'IMPORT_ORDER_APPLY_ERROR',
    error: {errorCode, errorMessage},
  }
}

export function applyOrder( token, order, clientId, props) {
  return function(dispatch) {
    dispatch(importOrderApplyRequest(order, clientId));
    let httpStatusCode = null;
    let apiErrorCode = null;
    let apiErrorMessage = null;

    return fetch(apiCommandPOSTApplyOrder + '?customers=' + clientId + '&access_token=' + token, {
      // cors - Сервис API может быть на любом сервере, same-origin - Тот же самый сервер
      mode: 'cors',
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: makeJSON(getApiOrderBody(order, props)),
    })
      .then((response)=>{
        // Извлекаем код ответа HTTP
        httpStatusCode = response.status;
        return response;
      })
      .then((response) => checkAuthorization(dispatch, response))
      .then(validationJSON) // Раскрывает промис
      .then((data)=> {
        // data содержит ответ от API
        if (httpStatusCode === 200)
        //        { "statusCode": 1,          "statusName": "Новый",          "orderId": 301        }
          dispatch(importOrderApplySuccess( data.orderId))
        else {
          // data содержит расшифровку ошибки в объекте error
          apiErrorCode = data.error.code;
          apiErrorMessage = data.error.message;
          const error = new Error()
          throw error
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === 'development') console.log('applyOrder() - Error: ', error);
        dispatch(
          importOrderApplyError(
            apiErrorCode || httpStatusCode,
            apiErrorMessage || error.message
          ));
      })
  }
}

export function prepareToImportOrderFromFile() {
  return {
    type: 'IMPORT_ORDER_PREPARE',
  }
}
