import React from 'react';
import { Link } from 'react-router-dom'

export const MainViewIndexView = (authorized)=> (
  <div className="container-fluid">
    <br/>
    <div className="row">
      <div className="col align-items-center">
        {/* Дополнительное меню главной страницы */}
      </div>
    </div>
    <div className="row mb-5">
      <div className="col-xl-3 col-lg-0">
        {/* Доп. окно главной страницы */}
      </div>
      <div className="col-xl-7 col-md">
        {/* Основное окно главной страницы */}
        <div className="row">
          <div className="col-xl-10 col-md">
            <p><strong>zVision</strong> - продукт для цифрового доступа к нашим ресурсам</p>

            { ((new Date(2020, 3, 24) <= new Date()) === true) &&
            <div className="row">
              <div className="col">
                <div className="alert alert-info zalert-info-card" role="alert">
                  <h5>Заявка на поставку медицинских изделий</h5>
                  <hr/>
                  <p>
                    Даже если Вы не еще не являетесь нашим клиентом, мы предоставляем возможность оставить 
                    <Link to={ '/DesNeeds' } className="internal-page-link-calm" >
                      <b> заявку на поставку </b>
                    </Link> средств индивидуальной защиты и дезинфицирующих средств
                  </p>
                  <div className="row">
                    <div className="col-8">
                    </div>
                    <div className="col-lg col-md-0">
                      <Link to={ '/DesNeeds' } className="internal-page-link-calm-nu" title="Оставить заявку на поставку средств индивидуальной защиты и дезинфицирующих средств">
                        <button className="btn btn-block btn-outline-info btn-sm">
                          Оставить заявку
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
}

            <h5>За <strong>4 минуты</strong> работы с zVision Вы cможете успеть</h5>
            <ul className="list-group">
              <li className="list-group-item d-flex">
                <i className="fa fa-check fa-2x" aria-hidden="true"></i>
                <span className="ml-3">Cоздать пару&nbsp;
                  <Link to="/PersonalOrders" className="internal-page-link-calm-nu"><b>заказов</b></Link>
                &nbsp;самого необходимого товара</span >
              </li>
              <li className="list-group-item d-flex">
                <i className="fa fa-check fa-2x" aria-hidden="true"></i>
                <span className="ml-3">Уточнить детали своей &nbsp;
                  <Link to="/Receivables" className="internal-page-link-calm-nu"><b>дебиторской задолженности</b></Link>
                </span >
              </li>
              <li className="list-group-item d-flex">
                <i className="fa fa-check fa-2x" aria-hidden="true"></i>
                <span className="ml-3">Создать&nbsp;
                  <Link to="/Claims" className="internal-page-link-calm-nu"><b>претензию</b></Link>
                  &nbsp;по проблемной накладной</span >
              </li>
              <li className="list-group-item d-flex">
                <i className="fa fa-check fa-2x" aria-hidden="true"></i>
                <span className="ml-3">Уточнить&nbsp;
                  <Link to="/PersonalOrders/History" className="internal-page-link-calm-nu"><b>статус</b></Link>
                  &nbsp;выполнения заказов и&nbsp;
                  <Link to="/Claims/List" className="internal-page-link-calm-nu"><b>претензий</b></Link>
                </span>
              </li>
            </ul>

          </div>
        </div>
      </div>
    </div>


    <div className="row">
      <div className="col-xl-3 col-lg-0">
        {/* Доп. окно главной страницы */}
      </div>
      <div className="col-xl-7 col-md">
        <div className="row">
          <div className="col-xl-10 col-md">
            { !authorized &&
          <p>Для того, чтобы <strong>воспользоваться возможностями сервиса zVision</strong> Вам потребуется
            <Link to="/Registration" className="internal-page-link-calm-nu"><b> зарегистрироваться </b></Link> или
            <Link to="/Authorization" className="internal-page-link-calm-nu"><b> войти в систему</b></Link>
          </p>
            }
            <p>Подробную информацию о сервисе Вы можете получить, связавшись с вашим персональным менеджером</p>
          </div>
        </div>
      </div>
    </div>


    <div className="row">
      <div className="col-xl-3 col-lg-0">
        {/* Доп. окно главной страницы */}
      </div>
      <div className="col-xl-7 col-md">
        <div className="row">
          <div className="col-xl-10 col-md border-top">
            <p className="text-right mt-1"><strong>zVision - эффективность в действии!</strong></p>
          </div>
        </div>
      </div>
    </div>

  </div>
)
