import { connect } from 'react-redux';
import { registrationUser, registrationComplete } from '../actions/registration';

// Основные визуальные модули
import { RegistrationView } from '../components/Desktop';

const mapStateToProps = (state) => ({
  registrationInProcess: state.registration.registrationInProcess,
  registrationError: state.registration.registrationError,
  registrered: state.registration.registrered,
  status: state.registration.status,

  inn: state.registration.inn,
  titleName: state.registration.titleName,
  fullName: state.registration.fullName,
  realm: state.registration.realm,
  username: state.registration.username,
  email: state.registration.email,

  userId: state.registration.userId,
});

const mapDispathToProps = (dispath) => {
  return {
    doRegistration: (titleName, fullName, inn, realm, username, email, password) =>
      dispath(registrationUser(titleName, fullName, inn, realm, username, email, password)),
    doRegistrationComplete: ()=>dispath(registrationComplete()),
  };
};

export default connect(
  mapStateToProps,
  mapDispathToProps
)(RegistrationView)
