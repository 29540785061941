import React, { Component } from 'react';
/**
 * Каждый модуль имеет три основных компонента
 * Routes - маршруты
 * Menu - меню
 * Filter - фильтры
 */
import { ReceivablesRoutes, ReceivablesMenu }
  from '../../../containers/Receivables';

export default class ReceivablesView extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-xl-1 col-lg-0 module-menu-line-color border-bottom"></div>
          <div className="col module-menu-line-color border-bottom">
            {/* Главное меню модуля */}
            <ReceivablesMenu/>
          </div>
        </div>
        {/* Основное окно модуля */}
        {/* модуль имеет динамический шаблон - различный для каждого дочернего модуля */}
        <ReceivablesRoutes/>
      </div>
    )
  }
}
