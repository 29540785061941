import {  waitForServiceWorkerCache } from '../config/systemConfig'

export function checkAppUpdate(newAppVersionAvailable) {
  return {
    type: 'NEW_APP_VERSION_AVAILABLE',
    newAppVersionAvailable: newAppVersionAvailable,
  }
}

export function checkAppVersionForUpdate(store) {
  let timerId = null;
  timerId = setInterval(()=>{
    clearTimeout(timerId);
    const newAppVersionAvailable = localStorage.getItem('NewVersionAvailable') === 'Y';
    store.dispatch(checkAppUpdate(newAppVersionAvailable));
  }, waitForServiceWorkerCache);
}

export function closeUpdateAppMessage() {
  return {
    type: 'UPDATE_APP_MESSAGE_CLOSED',
  }
}

export function сloseUpdateAppMessage() {
  return function(dispatch) {
    dispatch(closeUpdateAppMessage());
  }
}
