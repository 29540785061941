import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formatDate, xssfilter }  from '../../../../lib/utils'
import './styles';
/*
      {
        title: 'title of message #1',
        message: 'text of message #1text of message #1text of message #1text of message #1text of message #1',
        id: 1,
        viewed: 1,
      },
*/

const accordionCard = (item, active, index, onChangeItem, onDetailsClick)=> {
  let activeItemClass = "collapse";
  let cardHeader = "";
  if (active === true) {
    activeItemClass = "collapse show ";
    cardHeader = "active-card-header";
  }
  let titleClassName = "";
  if (item.viewed === 0) titleClassName = "font-weight-bold";

  return (
    <div className="card" key = { 'card' + item.id }>
      <div className={ "card-header " + cardHeader } onClick={ () => onChangeItem(item) }>
        <div className="page-header">
          <div className={ "text-left "+titleClassName }>
            { item.title } 
          </div>
          <div className={ "text-right small" + titleClassName }>
            {formatDate(item.public_date)}
          </div>
        </div>
      </div>

      <div className={ "card-body " + activeItemClass }>
        {<div dangerouslySetInnerHTML={{ __html: xssfilter(item.message) }}/>}
        { (!!item.url)&&
          <div>
            <hr/>
              <div className="btn btn-outline-info btn-sm mr-3" onClick={ () => onDetailsClick(item) }>
                { (!!item.title_url) ? item.title_url : "Подробнее..." }
              </div>
          </div>
        }
      </div>
    </div>
  );
}

export default class MessagesAccordion extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    onSelectItem: PropTypes.func,
    onDetailsClick: PropTypes.func,
  }

  state = {
    activeItemId: undefined
  }

  onToggleView(activeItemId){
    this.setState({
      activeItemId: activeItemId
    });
  }

  onChangeItem(item){
    this.setState({
      activeItemId: item.id
    });
    this.props.onSelectItem(item);
  }

  onDetailsClick(item){
    this.props.onDetailsClick(item);
  }

  render(){
    const { activeItemId } = this.state;
    return(
    <div>
      {
        this.props.items.map((item,index) =>{
          return accordionCard(
            item,
            (activeItemId === item.id),
            index,
            this.onChangeItem.bind(this),
            this.onDetailsClick.bind(this),
          );
        })
      }
    </div>);
  }
}
