import {backendServiceURL} from '../config/backendService'

export const CATALOG_REQUEST = 'CATALOG_REQUEST'
export const CATALOG_REQUEST_SUCCESS = 'CATALOG_REQUEST_SUCCESS'
export const CATALOG_REQUEST_ERROR = 'CATALOG_REQUEST_ERROR'

// Попытка восстановить состояние каталога из LocalStorage
export const RESTORE_CATALOG_FROM_LS_REQUEST = 'RESTORE_CATALOG_FROM_LS_REQUEST'

// Отказ восстановить состояние каталога из LocalStorage
export const RESTORE_CATALOG_FROM_LS_ERROR = 'RESTORE_CATALOG_FROM_LS_ERROR'

// Удалось восстановить состояние каталога из LocalStorage
export const RESTORE_CATALOG_FROM_LS_SUCCESS = 'RESTORE_CATALOG_FROM_LS_SUCCESS'

export const ACTIVE_ORDERS_REQUEST = 'ACTIVE_ORDERS_REQUEST'
export const ACTIVE_ORDERS_REQUEST_SUCCESS = 'ACTIVE_ORDERS_REQUEST_SUCCESS'
export const ACTIVE_ORDERS_REQUEST_ERROR = 'ACTIVE_ORDERS_REQUEST_ERROR'


export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST'
export const ORDER_DETAILS_REQUEST_SUCCESS = 'ORDER_DETAILS_REQUEST_SUCCESS'
export const ORDER_DETAILS_REQUEST_ERROR = 'ORDER_DETAILS_REQUEST_ERROR'

export const ORDERS_HISTORY_REQUEST = 'ORDERS_HISTORY_REQUEST'
export const ORDERS_HISTORY_REQUEST_SUCCESS = 'ORDERS_HISTORY_REQUEST_SUCCESS'
export const ORDERS_HISTORY_REQUEST_ERROR = 'ORDERS_HISTORY_REQUEST_ERROR'

export const ORDER_HISTORY_REQUEST = 'ORDER_HISTORY_REQUEST'
export const ORDER_HISTORY_REQUEST_SUCCESS = 'ORDER_HISTORY_REQUEST_SUCCESS'
export const ORDER_HISTORY_REQUEST_ERROR = 'ORDER_HISTORY_REQUEST_ERROR'

export const IMPORT_ORDER_OPEN_ORDER_FILE_TRY = 'IMPORT_ORDER_OPEN_ORDER_FILE_TRY'
export const IMPORT_ORDER_OPEN_ORDER_FILE_SUCCESS = 'IMPORT_ORDER_OPEN_ORDER_FILE_SUCCESS'
export const IMPORT_ORDER_OPEN_ORDER_FILE_ERROR = 'IMPORT_ORDER_OPEN_ORDER_FILE_ERROR'

export const IMPORT_ORDER_APPLY_REQUEST = 'IMPORT_ORDER_APPLY_REQUEST'
export const IMPORT_ORDER_APPLY_SUCCESS = 'IMPORT_ORDER_APPLY_SUCCESS'
export const IMPORT_ORDER_APPLY_ERROR = 'IMPORT_ORDER_APPLY_ERROR'
export const IMPORT_ORDER_PREPARE = 'IMPORT_ORDER_PREPARE'

// Команды API интерфейса
//
// PersonalOrders - get - получение каталога товаров
export const apiCommandGETCatalog = backendServiceURL + 'api/v1/PersonalOrders/getPriceList'
// PersonalOrders - get - получение списка активных заказов
export const apiCommandGETActiveOrders = backendServiceURL + 'api/v1/PersonalOrders/getActiveOrdersList'
// PersonalOrders - get - получение деталей заказа
export const apiCommandGETOrderDetails = backendServiceURL + 'api/v1/PersonalOrders/getOrderDetails'
// PersonalOrders - get - получение списка истории заказов
export const apiCommandGETOrdersHistory = backendServiceURL + 'api/v1/PersonalOrders/getOrdersHistory'
// PersonalOrders - get - получение списка истории заказов
export const apiCommandGETOrderHistory = backendServiceURL + 'api/v1/PersonalOrders/getOrderHistory'
// PersonalOrders - post - сохранить заказ
export const apiCommandPOSTApplyOrder = backendServiceURL + 'api/v1/PersonalOrders/createOrder'
