import { backendServiceURL } from '../config/backendService'

export const SAVE_CLIENT_NEEDS_REQUEST = 'SAVE_CLIENT_NEEDS_REQUEST'
export const SAVE_CLIENT_NEEDS_SUCCESS = 'SAVE_CLIENT_NEEDS_SUCCESS'
export const SAVE_CLIENT_NEEDS_ERROR = 'SAVE_CLIENT_NEEDS_ERROR'

// Команды API интерфейса
//
// Covid19s - saveClientNeeds - post - созранение потребности организации в средствах индивидуальной защиты
export const apiCommandPOSTSaveClientNeeds = backendServiceURL + 'api/v1/Covid19s/saveClientNeeds'
