import {backendServiceURL} from '../config/backendService'

export const NEW_NOTIFICATIONS_REQUEST = 'NEW_NOTIFICATIONS_REQUEST'
export const NEW_NOTIFICATIONS_REQUEST_SUCCESS = 'NEW_NOTIFICATIONS_REQUEST_SUCCESS'
export const NEW_NOTIFICATIONS_REQUEST_ERROR = 'NEW_NOTIFICATIONS_REQUEST_ERROR'

export const READ_NOTIFY_REQUEST = 'READ_NOTIFY_REQUEST'
export const READ_NOTIFY_REQUEST_SUCCESS = 'READ_NOTIFY_REQUEST_SUCCESS'
export const READ_NOTIFY_REQUEST_ERROR = 'READ_NOTIFY_REQUEST_ERROR'

export const NOTIFICATIONS_REQUEST = 'NOTIFICATIONS_REQUEST'
export const NOTIFICATIONS_REQUEST_SUCCESS = 'NOTIFICATIONS_REQUEST_SUCCESS'
export const NOTIFICATIONS_REQUEST_ERROR = 'NOTIFICATIONS_REQUEST_ERROR'

export const FOLLOWED_NOTIFY_REQUEST = 'FOLLOWED_NOTIFY_REQUEST'
export const FOLLOWED_NOTIFY_REQUEST_SUCCESS = 'FOLLOWED_NOTIFY_REQUEST_SUCCESS'
export const FOLLOWED_NOTIFY_REQUEST_ERROR = 'FOLLOWED_NOTIFY_REQUEST_ERROR'

// Команды API интерфейса
//
// Получить список не прочитаных уведомлений
export const apiCommandGETNewNotifications = backendServiceURL + 'api/v1/clients/getNewNotifications'
// Отметить уведомление как прочитанное
export const apiCommandPOSTViewNotify = backendServiceURL + 'api/v1/clients/viewNotify'
// Получить список уведомлений
export const apiCommandGETNotifications = backendServiceURL + 'api/v1/clients/getNotifications'
// Клиент перешел к деталям сообщения
export const apiCommandPOSTFollowNotify = backendServiceURL + 'api/v1/clients/followNotify'
