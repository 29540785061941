import React from 'react';
import ReactDOM from 'react-dom';
import configureStore from './config/configureStore';
import Root from './containers/Root';
import { register } from './lib/registerServiceWorker';
import { tryRestoreSession } from './actions/authorization';
import { checkAppVersionForUpdate } from './actions/application';

import { UdpateConfig } from './lib/appUpdateHandler';

const store = configureStore();
if (process.env.NODE_ENV === 'development') store.subscribe(()=>console.log('Контейнер изменен', store.getState()));

tryRestoreSession(store);
checkAppVersionForUpdate(store);

ReactDOM.render(
  <Root store={ store }/>,
  document.getElementById('root'));

const updateConfig = new UdpateConfig(store);
register(updateConfig);
