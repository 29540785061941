import React from 'react';
import orderFlow from './img/order-flow.png';
import './styles';
export const PersonalOrderIndexView = () => (
  <div>
    <div className="row mb-3">
      <div className="col-xl-10 col-md">
        <h5>Индивидуальные заказы</h5>
        <div className="alert alert-info zalert-info-card">
          <p>Оперативный заказ товаров</p>
          <hr/>
          <p>В отличие от традиционных способов формирования и размещения заказов, использование <strong>zVision</strong> позволяет получить Вам <strong>ряд преимуществ </strong>:</p>
          <ul>
            <li>Не требуется специально импортировать актуальный прайс лист в Вашу учетную систему <strong>Каталог</strong> товаров содержит<strong> актуальную </strong>и персонифицированную информацию </li>
            &nbsp;<li>Каталог товаров включает производительную систему контекстного поиска</li>
            &nbsp;<li>Появляется возможность воспользоваться оперативной информацией о статусе заказа, тем самым точнее настроить внутренние логистические процессы</li>
            &nbsp;<li>Хранение доступной в любой момент времени истории заказов</li>
          </ul>
        </div>
      </div>
    </div>

    <div className="row mb-3">
      <div className="col-xl-10 col-md">
        <img src={ orderFlow } className="img-fluid rounded" alt="Процесс обработки заказа"/>
      </div>
    </div>

    <div className="row">
      <div className="col-5">
        <h5>Экономит время <span>менеджерам</span></h5>
        <ul>
          <li>На согласовании заказа</li>
          <li>На выписке счетов, счет-фактур, актов и накладных</li>
          <li>На обновлении цен и остатков товаров для клиентов</li>
        </ul>
      </div>
      <div className="col-5">
        <h5>Экономит время <span>клиентам</span></h5>
        <ul>
          <li>На поиске товаров</li>
          <li>На получении подробной информации о товарах</li>
          <li>На оформлении заказа</li>
        </ul>
      </div>
    </div>
  </div>
)
