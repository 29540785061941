import { backendServiceURL } from '../config/backendService'

export const INIT_CART = 'INIT_CART'
export const EDT_CART = 'EDT_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const CLEAR_CART = 'CLEAR_CART'
export const APPLY_CART_REQUEST = 'APPLY_CART_REQUEST'
export const APPLY_CART_SUCCESS = 'APPLY_CART_SUCCESS'
export const APPLY_CART_ERROR = 'APPLY_CART_ERROR'

// Команды API интерфейса
//
// PersonalOrders - post - сохранить корзину
export const apiCommandPOSTSaveCart = backendServiceURL + 'api/v1/PersonalOrders/createOrder'
// Соответствия полей корзины полям заказа на API
export const cartItemsToAPIOrderMapping = {
  goodsId: 'itemCode',
  goodsName: 'name',
  goodsAmount: 'qtty',
  goodsPrice: 'price',
};
