import { connect } from 'react-redux';
import { passwordResetRequest, passwordResetInit } from '../actions/passwordReset';

// Основные визуальные модули
import { PasswordResetRequestView } from '../components/Desktop';
import { systemVersion, systemName } from '../config/systemConfig';

const mapStateToProps = (state) => ({
  requestPasswordResetInProcess: state.passwordReset.requestPasswordResetInProcess,
  requestPasswordResetError: state.passwordReset.requestPasswordResetError,
  requestPasswordResetSuccess: state.passwordReset.requestPasswordResetSuccess,
  requestPasswordResetStatus: state.passwordReset.requestPasswordResetStatus,
  systemName: systemName,
  systemVersion: systemVersion,
});

const mapDispathToProps = (dispath) => {
  return {
    doPasswordResetRequest: (email) => dispath(passwordResetRequest(email)),
    doPasswordResetInit: () =>  dispath(passwordResetInit()),
  };
};

export default connect(
  mapStateToProps,
  mapDispathToProps
)(PasswordResetRequestView)
