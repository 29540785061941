import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class DropDownButtonList extends Component {
  static propTypes = {
    buttonClassName: PropTypes.string,
    buttonTitle: PropTypes.string,
    items: PropTypes.array.isRequired,
  }

  state = {
    visible: false,
  }

  onToggleView(){
    this.setState({
      visible: !this.state.visible
    });
  }

  render(){
    let className=" d-block ";
    if (this.state.visible === false) className = " d-none ";
    return(
      <div className="dropdown">
        <button className={"btn dropdown-toggle "+ this.props.buttonClassName} onClick={this.onToggleView.bind(this)}>
          {this.props.buttonTitle}
        </button>
        <ul className={"list-group" + className}>
        { 
          this.props.items.map((m, index) =>
          <li className="list-group-item" key={ index }>{m}</li>) 
        }
        </ul>
      </div>
    );
  }
}
