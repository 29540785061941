import { connect } from 'react-redux';
import { PersonalOrdersOrdersHistoryFilter } from '../../components/Desktop/PersonalOrders';
import { getOrdersHistory } from '../../actions/Main/PersonalOrders';

const mapStateToProps = (state) => ({
  authorized: state.authorization.authorized,
  token: state.authorization.token,
  login: state.authorization.login,

  // customers: state.authorization.userId,
  customers: state.authorization.childUserId || state.authorization.userId,

  requestOrdersHistoryInProcess: state.personalOrders.requestOrdersHistoryInProcess,
  requestOrdersHistoryError: state.personalOrders.requestOrdersHistoryError,
  requestOrdersHistorySuccess: state.personalOrders.requestOrdersHistorySuccess,
  requestOrdersHistoryStatus: state.personalOrders.requestOrdersHistoryStatus,
  requestOrdersHistoryFilter: state.personalOrders.requestOrdersHistoryFilter,
});

const mapDispathToProps = (dispath) => {
  return {
    getOrdersHistory:
      ( token, customers, filter )=>
        dispath(getOrdersHistory(token, customers, filter )),
  };
};

export default connect(
  mapStateToProps,
  mapDispathToProps
)(PersonalOrdersOrdersHistoryFilter)
