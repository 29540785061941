import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'

export default class ClaimsClaimCreateSucces extends Component {
  state = {
    createNewClaimStep: undefined,
    createNewClaimSuccess: undefined,
    newClaimId: undefined,
    createNewClaimOutWaybillId: undefined,
  }

  componentDidMount(){
    // Инициалиализация процесса
    this.props.createNewClaimInitProcess();
  }

  componentWillMount() {
    const {
      createNewClaimStep,
      createNewClaimSuccess,
      newClaimId,
      createNewClaimOutWaybillId
    } = this.props;
    // Сохраняем в стейт состояние, т.к. оно будет инициализировано
    this.setState({
      createNewClaimStep,
      createNewClaimSuccess,
      newClaimId,
      createNewClaimOutWaybillId
    });
  }

  render() {
    const {
      createNewClaimStep,
      newClaimId,
      createNewClaimOutWaybillId
    } = this.state;

    return (
      <div>
        <div className="row">
          <div className="col-9">
            { createNewClaimStep !== 4 ?
              <Redirect to='/Claims/CreateNew'/> : true
            }

            <p>Претензия &nbsp;
              <b>
                <Link to={ '/Claims/Details/' + newClaimId } className="internal-page-link-calm">
                  #{ newClaimId }
                </Link>
              </b>
              &nbsp; успешно отправлена
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-9">
            <div className="alert alert-warning" role="alert">
              Вы можете добавить очередную претензию к текущему документу #{createNewClaimOutWaybillId} или создать новую претензию
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Link to={ '/Claims/CreateNew/' + createNewClaimOutWaybillId } className="internal-page-link-calm">
              <div className="btn btn-outline-info btn-sm mr-3">
                Добавить &nbsp; <i className="fa fa-comment-o" aria-hidden="true"></i>
              </div>
            </Link>

            <Link to={ '/Claims/CreateNew' } className="internal-page-link-calm">
              <div className="btn btn-outline-info btn-sm">
                Создать &nbsp; <i className="fa fa-plus" aria-hidden="true"></i>
              </div>
            </Link>
          </div>
        </div>

      </div>
    )
  }
}
