import { connect } from 'react-redux';
import { PersonalOrdersImportOrder }  from '../../components/Desktop/PersonalOrders';
import { openOrderFileTry, openOrderFileSuccess, openOrderFileError,
  applyOrder, prepareToImportOrderFromFile } from '../../actions/Main/PersonalOrders';

const mapStateToProps = (state) => ({
  authorized: state.authorization.authorized,
  token: state.authorization.token,

  // customers: state.authorization.userId,
  customers: state.authorization.childUserId || state.authorization.userId,


  importOrderTryOpenOrderFile: // Попытка открыть файл с заказом
    state.personalOrders.importOrderTryOpenOrderFile,
  importOrderErrorCode: // Код статуса процесса импорта
    state.personalOrders.importOrderErrorCode,
  importOrderStatus: // Статус процесса импорта
    state.personalOrders.importOrderStatus,
  importOrder: // Тело заказа
    state.personalOrders.importOrder,
  importOrderTryOpenOrderFileProcess:
    state.personalOrders.importOrderTryOpenOrderFileProcess, // Открытие файла с заказом
  importOrderRequestApplyOrderInProcess: // Отправка заказа на сервер - в процессе
    state.personalOrders.importOrderRequestApplyOrderInProcess,
  importOrderRequestApplyOrderSuccess: // Отправка заказа на сервер - ok
    state.personalOrders.importOrderRequestApplyOrderSuccess,
  importOrderResultOrderId: // Результирующий код заказа после импорта
    state.personalOrders.importOrderResultOrderId,
});

const mapDispathToProps = (dispath) => {
  return {
    prepareToImportOrderFromFile:
      ()=> dispath(prepareToImportOrderFromFile()),
    openOrderFileTry:
      ( fileName, rawFileData )=> dispath(openOrderFileTry( fileName, rawFileData )),
    openOrderFileSuccess:
      ( order )=> dispath(openOrderFileSuccess( order )),
    openOrderFileError:
      ( status, errorCode )=> dispath(openOrderFileError( status, errorCode )),

    // Далее методы сохранения данных на сервер
    importOrderApplyError:
      (errorCode, errorMessage)=>dispath(openOrderFileError(errorCode, errorMessage)),
    applyOrder:
    ( token, cart, clientId, props)=> dispath(applyOrder( token, cart, clientId, props)),
  };
};

export default connect(
  mapStateToProps,
  mapDispathToProps
)(PersonalOrdersImportOrder)
