import { connect } from 'react-redux';
import { PersonalOrdersImportOrderFilter }  from '../../components/Desktop/PersonalOrders';

const mapStateToProps = (state) => ({
  authorized: state.authorization.authorized,
  token: state.authorization.token,
  // customers: state.authorization.userId,
  customers: state.authorization.childUserId || state.authorization.userId,

});

export default connect(
  mapStateToProps
)(PersonalOrdersImportOrderFilter)
