import { apiCommandPOSTSaveCart, cartItemsToAPIOrderMapping } from '../constants/cart';
import { validationJSON, makeJSON } from '../lib/utils'
import { checkAuthorization } from './authorization';

export function initCart() {
  return {
    type: 'INIT_CART',
  }
}

export function edtCart( goodsId, goodsName, goodsAmount, goodsPrice, item ) {
  return {
    type: 'EDT_CART',
    goodsId: goodsId,
    goodsName: goodsName,
    goodsAmount: goodsAmount,
    goodsPrice: goodsPrice,
    goodsLine: item,
  }
}

export function removeFromCart( goodsId ) {
  return {
    type: 'REMOVE_FROM_CART',
    goodsId: goodsId,
  }
}

export function clearCart(  ) {
  return {
    type: 'CLEAR_CART',
  }
}

export function requestAppyCart( cart, clientId ) {
  return {
    type: 'APPLY_CART_REQUEST',
    cart: cart,
    clientId: clientId,
  }
}

export function appyCartSuccess( orderId ) {
  return {
    type: 'APPLY_CART_SUCCESS',
    orderId: orderId,
  }
}

export function appyCartError(errorCode, errorMessage) {
  return {
    type: 'APPLY_CART_ERROR',
    error: {errorCode, errorMessage},
  }
}

/**
 * Формирование тела заказа в соответствии с форматом API
 * @param {*Содержимое корзины} cart
 * @param {*Свойства заказа} props
 */
function getApiOrderBody(cart, props) {
  const order =
  {
    comment: props.comment,
    items: [],
  }
  //
  order.items = cart.items.map((item)=>{
    let orderLine = {};
    for (let key in item) orderLine[cartItemsToAPIOrderMapping[key]] = item[key]
    return orderLine;
  });

  return order;
}


/* eslint-disable */
/* eslint-enable */
export function applyCart( token, cart, clientId, props) {
  return function(dispatch) {
    dispatch(requestAppyCart(cart, clientId));
    let httpStatusCode = null;
    let apiErrorCode = null;
    let apiErrorMessage = null;

    return fetch(apiCommandPOSTSaveCart + '?customers=' + clientId + '&access_token=' + token, {
      // cors - Сервис API может быть на любом сервере, same-origin - Тот же самый сервер
      mode: 'cors',
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: makeJSON(getApiOrderBody(cart, props)),
    })
      .then((response)=>{
        // Извлекаем код ответа HTTP
        httpStatusCode = response.status;
        return response;
      })
      .then((response) => checkAuthorization(dispatch, response))
      .then(validationJSON) // Раскрывает промис
      .then((data)=> {
        // data содержит ответ от API
        if (httpStatusCode === 200)
        //        { "statusCode": 1,          "statusName": "Новый",          "orderId": 301        }
          dispatch(appyCartSuccess( data.orderId))
        else {
          // data содержит расшифровку ошибки в объекте error
          apiErrorCode = data.error.code;
          apiErrorMessage = data.error.message;
          const error = new Error()
          throw error
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === 'development') console.log('applyCart() - Error: ', error);
        dispatch(
          appyCartError(
            apiErrorCode || httpStatusCode,
            apiErrorMessage || error.message
          ));
      })
  }
}
