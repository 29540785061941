import React from 'react';
export const ClaimsStatesInfo = () => (
  <div>
    <h3>Состояния претензий</h3>
    Обработка претензий по расходным документам осуществляется в несколько этапов
    <p>Для определения степени готовности решения каждому этапу обработки претензии мы присваиваем одно из следующих состояний</p>
    <p><b>«Передана на обработку»</b> — мы получили детали претензии и направили задачу в соответствующий отдел</p>
    <p><b>«Обрабатывается»</b> — мы обрабатываем Вашу претензию</p>
    <p><b>«Отклонена»</b> — в силу сложившихся обстоятельств мы вынуждены отклонить Вашу претензию</p>
    <p><b>«Удовлетворена»</b> — нам удалось уладить все детали по указанной претензии</p>
    <p><b>«Отмена»</b> — претензия не потребовала обработки и была отменена</p>
    <hr/>
  </div>
)
