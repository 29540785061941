import	{
  INIT_CART,
  EDT_CART,
  REMOVE_FROM_CART,
  CLEAR_CART,

  APPLY_CART_REQUEST,
  APPLY_CART_SUCCESS,
  APPLY_CART_ERROR,
}	from	'../constants/cart';

const initialState = {
  items : [],
  cartItemsQnt: 0,
  cartSum: 0,
  cartGoodsQnt: 0,

  applyInProcess: false,
  applyError: false,
  applySuccess: false,
  orderId: null,
  status: null,
};

//goodsId, goodsName, goodsAmount, goodsPrice
export default function cart(state = initialState, action) {
  const cartItems = state.items.map(i=>i);
  const { goodsId, goodsName, goodsAmount, goodsPrice, goodsLine } = action;
  //Ищем ранее добавленный товар
  let goodsItem = cartItems.filter((item)=>item.goodsId===goodsId);
  let goodsAlreadyInCart = goodsItem.length > 0;

      // Подсчет сумм корзины
      const cartSumm = () => (
        //перед подсчетом удаляем элементы у которых количество товара 0
        cartItems.filter((item)=>item.goodsAmount>0).reduce(function(sum, current) {
          return { 
            buySum: sum.buySum+current.goodsSumm, 
            buyGoodsQnt: sum.buyGoodsQnt+current.goodsAmount, 
            buyItemsQnt: sum.buyItemsQnt+1,
          }
        }, { buySum: 0, buyGoodsQnt: 0, buyItemsQnt: 0})        
      )
      let cartSumms = {};

  switch (action.type) {

    case INIT_CART:
    if (process.env.NODE_ENV === 'development') console.log('raise action - INIT_CART', action);
      return initialState;

    case EDT_CART:
      // указанного товара еще нет в корзине - добавляем
      if (!goodsAlreadyInCart){
        cartItems.push({goodsId, goodsName, goodsAmount, goodsPrice, goodsSumm :  goodsAmount * goodsPrice, goodsLine}); 
      } else {
        // в корзине есть такой товар обновляем
        cartItems.forEach((item,i,arr)=>{
          let cartItem = arr[i];
          if(item.goodsId===goodsId){
            cartItem.goodsAmount = goodsAmount;
            cartItem.goodsSumm = cartItem.goodsAmount * cartItem.goodsPrice;
          }
        });
      }      

      cartSumms = cartSumm();
      return { ...state,
        items: cartItems,//cartItems.filter((item)=>item.goodsAmount>0), // Если количество товара 0 то удаляем его
        cartItemsQnt: cartSumms.buyItemsQnt,
        cartSum: cartSumms.buySum.toFixed(2),
        cartGoodsQnt: cartSumms.buyGoodsQnt,
      }

    case REMOVE_FROM_CART:
    if (process.env.NODE_ENV === 'development') console.log('raise action - REMOVE_FROM_CART', action);
      cartSumms = cartSumm();

      return { ...state,
        items: cartItems.filter((item)=>item.goodsId !== goodsId),
        cartItemsQnt: cartSumms.buyItemsQnt,
        cartSum: cartSumms.buySum,
        cartGoodsQnt: cartSumms.buyGoodsQnt,
      }

    case CLEAR_CART:
    if (process.env.NODE_ENV === 'development') console.log('raise action - CLEAR_CART', action);
      return { ...state,
        items: [],
        cartItemsQnt: 0,
        cartSum: 0,
        cartGoodsQnt: 0,        
      }

    case APPLY_CART_REQUEST:
    if (process.env.NODE_ENV === 'development') console.log('raise action - APPLY_CART_REQUEST', action);
      return { ...state,
        applySuccess: false,
        applyInProcess: true,
        applyError: false,
        status: 'Оформление заказа...'
      }

    case APPLY_CART_SUCCESS:
    if (process.env.NODE_ENV === 'development') console.log('raise action - APPLY_CART_SUCCESS', action);
      return { ...state,
        orderId: action.orderId,
        applySuccess: true,
        applyInProcess: false,
        applyError: false,        
        status: 'Заказ оформлен',

        items : [],
        cartItemsQnt: 0,
        cartSum: 0,
        cartGoodsQnt: 0,
      }

    case APPLY_CART_ERROR:
      if (process.env.NODE_ENV === 'development') console.log('raise action - APPLY_CART_ERROR', action);
      return { ...state,
        applySuccess: false,
        applyInProcess: false,
        applyError: true,
        status: `Ошибка оформления заказа: 
          ${action.error.errorCode} 
          ${action.error.errorMessage}`
      }
      
    default:
      return state;
  }
}
