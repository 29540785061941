import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles/MenuLine.css';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router'

class MenuLine extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      focused: 0,
    };
  }
  // Важно чтобы имена разделов в меню начинались с различных слов 
  // чтобы indexOf верно идентифицировал активный элемент
  setCurrentActiveItem(props){
    let currentPath = props.location.pathname;
    let activeItemIndex = -1;
    for(let i=0; i<props.items.length; i++){
      let currentLink = props.items[i].link;
      if (currentPath.indexOf(currentLink) !== -1) {
        activeItemIndex = i;
        break;
      }
    }
    this.setState({focused: activeItemIndex});
  }

  getInitialState() {
    return { focused: 0 };
  }

  clicked(index) {
    this.setState({focused: index});
    const { items, history } = this.props;
    history.push(items[index].link);
  }

  componentWillMount(){
    this.setCurrentActiveItem(this.props);
  }

  componentWillReceiveProps(nextProps){
    this.setCurrentActiveItem(nextProps);
  }

  render() {
    let self = this;
    return (
      <div className="menu-line">
        <ul>{ this.props.items.map(function(m, index) {
          if (!m.link) return null; // Если не указан аттрибут link элемент не выводится 
          let style = undefined;
          if (self.state.focused === index) {
            style = ' focused ';
          }
          if (m.attention === true) {
            style += ' attention ';
          }

          // bind() делает index доступным в функции clicked:
          return (
          <li className={ style } key={ index } onClick={ self.clicked.bind(self, index) }>
            <Link replace ={ m.replace } to={ m.link }>{m.caption}</Link>
            { typeof(m.badgeVal ) === 'function' ? 
              <span className="badge badge-light" title="Новые уведомления!">
                { m.badgeVal() }
              </span>:null }
          </li>);
        }) }
        </ul>
      </div>
    );
  }
}

export default withRouter(MenuLine)
