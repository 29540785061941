export const OutWaybillDetailsGridHeader = [
/*  { caption: 'Артикул',
    hint: 'Артикул товара',
    fieldName: 'MEDS',
    flexWidth: 1,
  }, */
  { caption: 'Наименование',
    hint: 'Наименование товара',
    fieldName: 'FULL_NAME',
    flexWidth: 7,
  },
  { caption: 'Серия',
    hint: 'Серия товара',
    fieldName: 'SERNAME',
    flexWidth: 2,
  },
  { caption: 'Количество',
    hint: 'Количество товара по строке расхоного документа',
    fieldName: 'AMOUNT',
    flexWidth: 1,
  },
  { caption: 'Действие',
    hint: 'Детали заказа',
    fieldName: 'ROW_CONTROLS',
    flexWidth: 1,
    columnSpacer: 'ml-3',
  },
];

export { default as OutWaybillDetailsGrid } from './OutWaybillDetailsGrid';

/**
DDATE: "2017-06-12T21:00:00.000Z"
DOC: 14858745
DSUM1: 2027.64
LST_CNT: 15

MEDS: 334250
FULL_NAME: "Нитрокор табл. подъязычные 0,5мг №40 (Фармстандарт-Лексредства, Россия)"
SERNAME: "100317"
AMOUNT: 2


*/
