import React from 'react';
import PropTypes from 'prop-types';
import './styles';
import { formatDate, formatDateTime } from '../../../../lib/utils';
import { Link } from 'react-router-dom';

function moneyFormat(n) {
  return parseFloat(n).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1 ').replace('.', ',');
}

function GridBodyLine({ item, dataFields, onClick, RowControls }) {
  const line = dataFields.map((field, index) => {
    let className = '';
    let flexWidth = '';
    if (field.columnSpacer) className += ' ' + field.columnSpacer;

    if (field.flexWidth) flexWidth = 'col-' + field.flexWidth;
    else flexWidth = 'col';

    className += ' ' + flexWidth;

    if (field.value) item[field.fieldName] = field.value;
    if (item[field.fieldName]) {
      if (field.isCurrency)
        return (
          <td  className={ className }  nowrap="true"
            align="right"
            key = { index }
          >
            { moneyFormat(item[field.fieldName].toFixed(field.roundTo)) } { field.currencyName }
          </td>);

      if (field.isDateFormat)
        return (
          <td  className={ className } nowrap="true" key = { index }>
            {
              formatDate(item[field.fieldName])
            }
          </td>);

      if (field.isDateTimeFormat)
        return (
          <td  className={ className } nowrap="true" key = { index }>
            {
              formatDateTime(item[field.fieldName])
            }
          </td>);
      // Значение является гиперссылкой
      if (field.rowHrefTemplate) {
        // Замена [%] на значение данной ячейки
        let resLink = field.rowHrefTemplate.replace('{%}', item[field.fieldName])


        // Замена [FIELDNAME] на значение поля FIELDNAME из текущей строки
        // если найден соответствующий щаблон
        const fieldNameSearch = field.rowHrefTemplate.match(/{%(.*?)}/);
        if (fieldNameSearch !== null && fieldNameSearch.length === 2) {
          // Имя поля из которого извлекаются данные
          const fieldName = fieldNameSearch[1];
          const fieldVal = item[fieldName];
          resLink = resLink.replace(/{%(.*?)}/, fieldVal);
        }
        return (
          <td  className={ className } key = { index }>
            <Link className="internal-page-link-calm" to={ resLink }>{ item[field.fieldName] }</Link>
          </td>
        );
      } else return (<td className={ className }  key = { index }>{item[field.fieldName]}</td>);
    } else
    {
      if (field.fieldName !== 'ROW_CONTROLS') return <td className={ className } key = { index }></td>;
      else return undefined;
    }
  });


  const rowControls = dataFields.filter(i=>i.fieldName === 'ROW_CONTROLS');
  if ( Array.isArray(rowControls) && rowControls.length > 0) {
    const rowControlsField = rowControls.shift();
    // В строке данных есть элементы управления
    let className = '';
    let flexWidth = '';
    if (rowControlsField.flexWidth)
      flexWidth = 'col-' + rowControlsField.flexWidth;
    else flexWidth = 'col';

    if (rowControlsField.columnSpacer) className += ' ' + rowControlsField.columnSpacer;

    className  += ' ' + flexWidth;
    return (
      <tr onClick={ () => onClick(item) } className="row data-grid-item">
        { line }
        { RowControls ? <td className={ className }> <RowControls dataRow={ item }/> </td> : null }
      </tr>
    );
  } else
  {
  // В строке данных нет элементов управления
    return (
      <tr onClick={ () => onClick(item) } className="row  data-grid-item">
        { line }
      </tr>
    );
  }
}

GridBodyLine.propTypes = {
  onClick: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  dataFields: PropTypes.array.isRequired,
  RowControls: PropTypes.func,
};

export const DataGrid = ( { header, body, limit, onItemClick, rowControls, keyFieldName } ) => {
  if (!body || !header) { return (<p>Loading...</p>); }
  if (!limit) limit = 10;

  const gridHeader = header.map(
    (column, index)=>{
      let flexWidth;
      if (column.flexWidth) flexWidth = 'col-' + column.flexWidth;
      else flexWidth = 'col';
      let className = flexWidth + ' data-grid-table-header';

      if (column.columnSpacer) className += ' ' + column.columnSpacer;
      return (
        <th className={ className }  title={ column.hint } key={ index }>
          {column.href
            ? <Link to={ column.href }>{ column.caption }</Link>
            : column.caption
          }
        </th>);
    }
  );

  const gridBody = body.map(
    (bodyItem, index) => (
      index < limit
        ? <GridBodyLine
          item={ bodyItem }
          dataFields={ header }
          onClick={ onItemClick }
          // key={ index /* + '-' + bodyItem.MEDS */ }
          key={ index + '-' + bodyItem[keyFieldName] }
          RowControls = { rowControls ? rowControls : null }
        />
        : null
    )
  );

  return (
    <div className="container-fluid data-grid ">
      <table className="table table-sm data-grid-table table-hover table-striped ">
        <thead>
          <tr className="row">
            {gridHeader}
          </tr>
        </thead>
        <tbody>
          {gridBody}
        </tbody>
      </table>
    </div>
  );
}

DataGrid.propTypes = {
  header: PropTypes.array.isRequired,
  body: PropTypes.array.isRequired,
  limit: PropTypes.number,
  onItemClick: PropTypes.func.isRequired,
  rowControls: PropTypes.func,
  keyFieldName: PropTypes.string,
}
