import { connect } from 'react-redux';
import { ClaimsClaimCreateEditor } from '../../components/Desktop/Claims';

import { createNewClaim } from '../../actions/Main/Claims';

import { claimsTroubles } from './ClaimsConfig';


const mapStateToProps = (state) => ({
  authorized: state.authorization.authorized,
  token: state.authorization.token,
  login: state.authorization.login,
  // customers: state.authorization.userId,
  customers: state.authorization.childUserId || state.authorization.userId,

  claimsTroubles: claimsTroubles,

  createNewClaimStep: state.claims.createNewClaimStep,
  createNewClaimOutWaybillId: state.claims.createNewClaimOutWaybillId,
  createNewClaimOutWaybillLineId: state.claims.createNewClaimOutWaybillLineId,
  createNewClaimOutWaybillMeds: state.claims.createNewClaimOutWaybillMeds,
  createNewClaimOutWaybillMedsName: state.claims.createNewClaimOutWaybillMedsName,
  createNewClaimOutWaybillSerial: state.claims.createNewClaimOutWaybillSerial,
  createNewClaimOutWaybillAmount: state.claims.createNewClaimOutWaybillAmount,
  createNewClaimOutWaybillSerialLastReturnDate: state.claims.createNewClaimOutWaybillSerialLastReturnDate,

  createNewClaimInProcess: state.claims.createNewClaimInProcess,
  createNewClaimError: state.claims.createNewClaimError,
  createNewClaimSuccess: state.claims.createNewClaimSuccess,
  createNewClaimStatus: state.claims.createNewClaimStatus,
  newClaimId: state.claims.newClaimId,
});

const mapDispathToProps = (dispath) => {
  return {
    createNewClaim:
      (token, claimData)=> dispath(createNewClaim(token, claimData)),
  };
};

export default connect(
  mapStateToProps, mapDispathToProps
)(ClaimsClaimCreateEditor)
