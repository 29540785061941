import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles';

export default class Toast extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    onCloseMessage: PropTypes.func,
    visible: PropTypes.bool,
    title: PropTypes.string.isRequired,
    buttonTitle: PropTypes.string.isRequired,
  }

  state = {
    visible: false,
    title: undefined,
    message: undefined,
    buttonTitle: undefined
  }

  onToggleView(){
    this.setState({
      visible: !this.state.visible
    });
  }

  onClick(){
    this.props.onClick();
  }

  onClose(){
    this.onToggleView();
    this.props.onCloseMessage();
  }
  
  componentWillMount() {
    const { title, visible, message, buttonTitle } = this.props;
    this.setState({
      title, visible, message, buttonTitle
    });
  }

  render(){
      const { title, visible, message, buttonTitle } = this.state;
      let visibleClassName = visible?"show":"";
      return(
      <div>
        { visible &&
          <div role="alert" aria-live="assertive" aria-atomic="true"
            className={ "toast fade " + visibleClassName}>
            <div className="toast-header">
              <svg className="bd-placeholder-img rounded mr-2" width="20" height="20" 
                xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" 
                focusable="false" role="img">
                <rect fill="#009719" width="100%" height="100%"></rect>
              </svg>
              <strong className="mr-auto">{ title }</strong>
              <small className="text-muted"></small>
              <button type="button" className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close"
              onClick={this.onClose.bind(this)}>
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="toast-body">
              { message }
              <hr/>
              <div className="btn btn-outline-success btn-sm col-mr-auto" onClick={ this.onClick.bind(this) }>
                { buttonTitle }
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}
