import {
  CLAIMS_LIST_REQUEST,
  CLAIMS_LIST_REQUEST_SUCCESS,
  CLAIMS_LIST_REQUEST_ERROR,

  CLAIM_DETAILS_REQUEST,
  CLAIM_DETAILS_REQUEST_SUCCESS,
  CLAIM_DETAILS_REQUEST_ERROR,

  OUT_WAYBILL_DETAILS_REQUEST,
  OUT_WAYBILL_DETAILS_REQUEST_SUCCESS,
  OUT_WAYBILL_DETAILS_REQUEST_ERROR,
  OUT_WAYBILL_DETAILS_INIT,

  CREATE_NEW_CLAIM_INIT_PROCESS,
  CREATE_NEW_CLAIM_SET_OUT_WAYBILL_ID,
  CREATE_NEW_CLAIM_SET_OUT_WAYBILL_LINE_ID,
  CREATE_NEW_CLAIM_REQUEST,
  CREATE_NEW_CLAIM_REQUEST_SUCCESS,
  CREATE_NEW_CLAIM_REQUEST_ERROR,

  UNDO_CLAIM_INIT,
  UNDO_CLAIM_REQUEST,
  UNDO_CLAIM_REQUEST_SUCCESS,
  UNDO_CLAIM_REQUEST_ERROR,

} from '../constants/claims';

const initialState = {
  requestClaimsListInProcess: false,
  requestClaimsListError: false,
  requestClaimsListSuccess: false,
  requestClaimsListStatus: null,

  requestClaimDetailsInProcess: false,
  requestClaimDetailsError: false,
  requestClaimDetailsSuccess: false,
  requestClaimDetailsStatus: null,
  requestClaimDetailsClaimId: null,

  requestOutWaybillDetailsInProcess: false,
  requestOutWaybillDetailsError: false,
  requestOutWaybillDetailsSuccess: false,
  requestOutWaybillDetailsStatus: null,
  requestOutWaybillId: null,

  createNewClaimStep: null,
  createNewClaimOutWaybillId: null,
  createNewClaimOutWaybillLineId: null,
  createNewClaimOutWaybillMeds: null,
  createNewClaimOutWaybillMedsName: null,
  createNewClaimOutWaybillSerial: null,
  createNewClaimOutWaybillAmount: null,
  createNewClaimOutWaybillSerialLastReturnDate: null,

  createNewClaimInProcess: false,
  createNewClaimError: false,
  createNewClaimSuccess: false,
  createNewClaimStatus: null,
  newClaimId: null,

  requestUndoClaimInProcess: false,
  requestUndoClaimError: false,
  requestUndoClaimSuccess: false,
  requestUndoClaimStatus: null,
  undoClaimId: null,
};

export default function receivables(state = initialState, action) {
  switch (action.type) {
    case CLAIMS_LIST_REQUEST:
      if (process.env.NODE_ENV === 'development') console.log('raise action - CLAIMS_LIST_REQUEST', action);
      return { ...state,
        requestClaimsListSuccess: false,
        requestClaimsListInProcess: true,
        requestClaimsListError: false,
        requestClaimsListStatus: 'Получение списка претензий...',
      }
      
    case CLAIMS_LIST_REQUEST_SUCCESS:
    if (process.env.NODE_ENV === 'development') console.log('raise action - CLAIMS_LIST_REQUEST_SUCCESS', action);
      return { ...state,
        requestClaimsListSuccess: true,
        requestClaimsListInProcess: false,
        requestClaimsListError: false,
        requestClaimsListStatus: 'Список претензий получен'
      }

    case CLAIMS_LIST_REQUEST_ERROR:
    if (process.env.NODE_ENV === 'development') console.log('raise action - CLAIMS_LIST_REQUEST_ERROR', action);
      return { ...state,
        requestClaimsListSuccess: false,
        requestClaimsListInProcess: false,
        requestClaimsListError: true,
        requestClaimsListStatus: `Ошибка получнения списка претензий: 
          ${action.error.errorCode}
          ${action.error.errorMessage}`,
      }




    case CLAIM_DETAILS_REQUEST:
      if (process.env.NODE_ENV === 'development') console.log('raise action - CLAIM_DETAILS_REQUEST', action);
      return { ...state,
        requestClaimDetailsSuccess: false,
        requestClaimDetailsInProcess: true,
        requestClaimDetailsError: false,
        requestClaimDetailsStatus: 'Получение списка претензий...',
        requestClaimDetailsClaimId: action.claimId,
      }

    case CLAIM_DETAILS_REQUEST_SUCCESS:
    if (process.env.NODE_ENV === 'development') console.log('raise action - CLAIM_DETAILS_REQUEST_SUCCESS', action);
      return { ...state,
        requestClaimDetailsSuccess: true,
        requestClaimDetailsInProcess: false,
        requestClaimDetailsError: false,
        requestClaimDetailsStatus: 'Список претензий получен'
      }

    case CLAIM_DETAILS_REQUEST_ERROR:
    if (process.env.NODE_ENV === 'development') console.log('raise action - CLAIM_DETAILS_REQUEST_ERROR', action);
      return { ...state,
        requestClaimDetailsSuccess: false,
        requestClaimDetailsInProcess: false,
        requestClaimDetailsError: true,
        requestClaimDetailsStatus: `Ошибка получнения списка претензий: 
          ${action.error.errorCode}
          ${action.error.errorMessage}`,
      }



















    case OUT_WAYBILL_DETAILS_REQUEST:
      if (process.env.NODE_ENV === 'development') console.log('raise action - OUT_WAYBILL_DETAILS_REQUEST', action);
      return { ...state,
        requestOutWaybillDetailsSuccess: false,
        requestOutWaybillDetailsInProcess: true,
        requestOutWaybillDetailsError: false,
        requestOutWaybillDetailsStatus: 'Получение деталей расходного документа...',
        requestOutWaybillId: action.outWaybillId,
      }

    case OUT_WAYBILL_DETAILS_REQUEST_SUCCESS:
    if (process.env.NODE_ENV === 'development') console.log('raise action - OUT_WAYBILL_DETAILS_REQUEST_SUCCESS', action);
      return { ...state,
        requestOutWaybillDetailsSuccess: true,
        requestOutWaybillDetailsInProcess: false,
        requestOutWaybillDetailsError: false,
        requestOutWaybillDetailsStatus: 'Детали расходного документа получены'
      }

    case OUT_WAYBILL_DETAILS_REQUEST_ERROR:
    if (process.env.NODE_ENV === 'development') console.log('raise action - OUT_WAYBILL_DETAILS_REQUEST_ERROR', action);
      return { ...state,
        requestOutWaybillDetailsSuccess: false,
        requestOutWaybillDetailsInProcess: false,
        requestOutWaybillDetailsError: true,
        requestOutWaybillDetailsStatus: `Ошибка получнения деталей расходного документа: 
          ${action.error.errorCode}
          ${action.error.errorMessage}`,
      }

    case OUT_WAYBILL_DETAILS_INIT:
      if (process.env.NODE_ENV === 'development') console.log('raise action - OUT_WAYBILL_DETAILS_INIT', action);
        return { ...state,
          requestOutWaybillDetailsInProcess: false,
          requestOutWaybillDetailsError: false,
          requestOutWaybillDetailsSuccess: false,
          requestOutWaybillDetailsStatus: null,
          requestOutWaybillId: null,
        }










    case CREATE_NEW_CLAIM_INIT_PROCESS:
      if (process.env.NODE_ENV === 'development') console.log('raise action - CREATE_NEW_CLAIM_INIT_PROCESS', action);
        return { ...state,

          requestOutWaybillDetailsInProcess: false,
          requestOutWaybillDetailsError: false,
          requestOutWaybillDetailsSuccess: false,
          requestOutWaybillDetailsStatus: null,
          requestOutWaybillId: null,

          createNewClaimStep: null,
          createNewClaimOutWaybillId: null,
          createNewClaimOutWaybillLineId: null,
          createNewClaimOutWaybillMeds: null,
          createNewClaimOutWaybillMedsName: null,
          createNewClaimOutWaybillSerial: null,
          createNewClaimOutWaybillAmount: null,
          createNewClaimOutWaybillSerialLastReturnDate: null,
          newClaimId: null,
        }

    case CREATE_NEW_CLAIM_SET_OUT_WAYBILL_ID:
       if (process.env.NODE_ENV === 'development') console.log('raise action - CREATE_NEW_CLAIM_SET_OUT_WAYBILL_ID', action);
        return { ...state,
          createNewClaimStep: 1,
          createNewClaimOutWaybillId: action.outWaybillId,
        }

    case CREATE_NEW_CLAIM_SET_OUT_WAYBILL_LINE_ID:
      if (process.env.NODE_ENV === 'development') console.log('raise action - CREATE_NEW_CLAIM_SET_OUT_WAYBILL_LINE_ID', action);
        return { ...state,
          createNewClaimStep: 2,
          createNewClaimOutWaybillLineId: action.outWaybillLineId,
          createNewClaimOutWaybillMeds: action.meds,
          createNewClaimOutWaybillMedsName: action.medName,
          createNewClaimOutWaybillSerial: action.serial,
          createNewClaimOutWaybillAmount: action.amount,
          createNewClaimOutWaybillSerialLastReturnDate: action.lastSerialReturnDate,
        }

    case CREATE_NEW_CLAIM_REQUEST:
      if (process.env.NODE_ENV === 'development') console.log('raise action - CREATE_NEW_CLAIM_REQUEST', action);
        return { ...state,
          createNewClaimStep: 3,
          createNewClaimSuccess: false,
          createNewClaimInProcess: true,
          createNewClaimError: false,
          createNewClaimStatus: 'Создание новой претензии...',
        }

    case CREATE_NEW_CLAIM_REQUEST_SUCCESS:
      if (process.env.NODE_ENV === 'development') console.log('raise action - CREATE_NEW_CLAIM_REQUEST_SUCCESS', action);
        return { ...state,
          createNewClaimStep: 4,
          newClaimId: action.newClaimId,

          createNewClaimSuccess: true,
          createNewClaimInProcess: false,
          createNewClaimError: false,
          createNewClaimStatus: 'Претензия успешно сформирована',
        }

    case CREATE_NEW_CLAIM_REQUEST_ERROR:
      if (process.env.NODE_ENV === 'development') console.log('raise action - CREATE_NEW_CLAIM_REQUEST_ERROR', action);
        return { ...state,
          createNewClaimSuccess: false,
          createNewClaimInProcess: false,
          createNewClaimError: true,
          createNewClaimStatus: `Ошибка формирования претензии: 
            ${action.error.errorCode}
            ${action.error.errorMessage}`,
        }




        
    case UNDO_CLAIM_INIT:
      if (process.env.UNDO_CLAIM_INIT === 'development') console.log('raise action - UNDO_CLAIM_INIT', action);
      return { ...state,
        requestUndoClaimInProcess: false,
        requestUndoClaimError: false,
        requestUndoClaimSuccess: false,
        requestUndoClaimStatus: null,
        undoClaimId: null,
      }

    case UNDO_CLAIM_REQUEST:
      if (process.env.NODE_ENV === 'development') console.log('raise action - UNDO_CLAIM_REQUEST', action);
      return { ...state,
        requestUndoClaimSuccess: false,
        requestUndoClaimInProcess: true,
        requestUndoClaimError: false,
        requestUndoClaimStatus: 'Отмена претензии...',
        undoClaimId: action.claimId,
      }

    case UNDO_CLAIM_REQUEST_SUCCESS:
    if (process.env.NODE_ENV === 'development') console.log('raise action - UNDO_CLAIM_REQUEST_SUCCESS', action);
      return { ...state,
        requestUndoClaimSuccess: true,
        requestUndoClaimInProcess: false,
        requestUndoClaimError: false,
        requestUndoClaimStatus: 'Претензия успешно отменена'
      }

    case UNDO_CLAIM_REQUEST_ERROR:
    if (process.env.NODE_ENV === 'development') console.log('raise action - UNDO_CLAIM_REQUEST_ERROR', action);
      return { ...state,
        requestUndoClaimSuccess: false,
        requestUndoClaimInProcess: false,
        requestUndoClaimError: true,
        requestUndoClaimStatus: `Ошибка отмены претензии: #
          ${action.error.errorCode} - 
          ${action.error.errorMessage}`,
      }

    default:
      return state;
  }
}
