// Компонент для перенаправления
import { RedirectTo } from '../../containers/RedirectTo';
// Основные визуальные модули
// import {  UserProfileIndexView  } from '../../components/Desktop/UserProfile'
import  UserProfileNotFoundView   from '../../components/Desktop/UserProfile/UserProfileNotFoundView'

// Содержимое основного окна модуля
import ConnectedUserProfileState from '../../containers/UserProfile/ConnectedUserProfileState';
import ConnectedUserProfileNotification from '../../containers/UserProfile/ConnectedUserProfileNotification';
import { getNotificationCount } from '../../containers/UserProfile';

// Содержимое окна фильтров модуля

// Внутренние информационные страницы модуля

// Основной роут модуля
const ModuleRootPath = '/Profile';

// Настройки модуля Receivables
const UserProfileModules = [
  {
    caption: 'Статус',
    exact: false,
    privateRoute: true,
    link: ModuleRootPath + '/State',
    path: ModuleRootPath + '/State',
    component: ConnectedUserProfileState,
    filterСomponent: ()=>(null),
  },

  {
    caption: 'Уведомления',
    exact: false,
    privateRoute: true,
    link: ModuleRootPath + '/Notification',
    path: ModuleRootPath + '/Notification',
    component: ConnectedUserProfileNotification,
    badgeVal: getNotificationCount,
    filterСomponent: ()=>(null),
  },
  { // Индексная страница модуля PersonalOrders
    exact: false,
    privateRoute: true,
    path: ModuleRootPath + '/',
    component: ()=>RedirectTo(ModuleRootPath + '/State'), // UserProfileIndexView,
    filterСomponent: ()=>(null),
  },
  { // Сообщение "Объект не найден" - вывод в основное окно модуля
    exact: false,
    privateRoute: true,
    component: UserProfileNotFoundView,
    filterСomponent: ()=>(null),
  },
];

export default UserProfileModules;
