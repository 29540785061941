import React, { Component } from 'react';
import { PersonalOrdersCatalogGrid } from '../PersonalOrdersCatalogGrid'
import PropTypes from 'prop-types';

// Элементы управления состоянием корзины
import CartControls from '../../../../containers/CartControls'

import { CartWidgetView }  from '../../Main'
import { LoadingView }  from '../../Main'
import { idbKeyval } from '../../../../lib/db';

export default class PersonalOrdersCatalog extends Component {
  static propTypes = {
    doRefreshCatalog: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    customers: PropTypes.number.isRequired,
  };

  state = {
    catalogLoaded: false,
    activeGoodsLine: null,
  }

  static goodsCatalog = {};

  tryRenderCatalog(newProps){
    if( newProps.requestCatalogSuccess || newProps.catalogRestoredFromLSSuccess ){
      let partOfCatalog = null;
      idbKeyval.get('GoodsCatalogItems')
      .then((val)=>JSON.parse(val))
      .then((val) => {
        partOfCatalog = val;
        if (Array.isArray(partOfCatalog)) {
          PersonalOrdersCatalog.goodsCatalog = partOfCatalog.slice(0,20000);
          this.setState({
            catalogLoaded: true
          });
        }
      })
      .catch(err => {
        this.setState({
          catalogLoaded: false
        });
      }
      );
    }
  }

  handleGoodsSelection = (goodsLine) => {
    this.setState({
      activeGoodsLine : goodsLine,
    });
  }

  componentWillMount(){
    this.props.doRefreshCatalog(false, this.props.token, this.props.customers, 'no filter');
    this.tryRenderCatalog(this.props);
  }

  componentWillReceiveProps(nextProps){
    this.tryRenderCatalog(nextProps);
  }

  GridRowControls = (row) => (
   <CartControls
    dataRow = { { goodsId: row.dataRow.MEDS, goodsName: row.dataRow.MEDS_NAME, goodsPrice: row.dataRow.PRICE, goodsLine: row.dataRow } }
    cart = { this.props.cart }
    addToCart = { this.props.doAddToCart }
    removeFromCart = { this.props.doRemoveFromCart }
   />
  )

  doConfirmCart(){
    this.props.history.push('/Cart/Apply');
  }

  render() {
    const catalogIsOk = this.state.catalogLoaded;
    const catalogIsError = this.props.catalogRestoreFromLSError&&
    this.props.requestCatalogError;
    const catalogFetching = this.props.catalogRestoreFromLSRequest ||
      this.props.requestCatalogInProcess;

    return (
      <div>
        { catalogIsError&&
          this.props.catalogRestoreFromLSError&&
          this.props.requestCatalogError&&
            <div className="row">
              <div className="col">
                <font color="red">
                  <p>Ошибка получения данных каталога...</p>
                  <p>Сервис временно не доступен.</p>
                </font>
              </div>
          </div>
        }

        { catalogFetching&&
            <div className="row">
              <div className="col">
                <LoadingView text='Получение данных...'/>
              </div>
            </div>
        }

        { catalogIsOk&&
          <div className="row">
            <div className="col-2 ml-auto">
              <CartWidgetView
                cartSum  = { parseFloat(this.props.cart.cartSum,10) } 
                cartGoodsQnt = { this.props.cart.cartGoodsQnt }
                doApplyCart = { this.doConfirmCart.bind(this) }
              />
            </div>
          </div>
        }

        {catalogIsOk&&
          <div className="row">
            <div className="col">
              <PersonalOrdersCatalogGrid
                catalogData= { PersonalOrdersCatalog.goodsCatalog }
                onSelectItem= { this.handleGoodsSelection.bind(this) }
                CartControls= { this.GridRowControls }
              />
            </div>
          </div>
        }

      </div>
    )
  }
}
