import React, { Component } from 'react';
import { MessagesAccordion } from '../../Main';

export default class UserProfileNotification extends Component {
  static newNotifies = {};
  static notifies = {};
  static allNotifies = {};

  state = {
    notifiesLoaded: false,
    newNotifiesLoaded: false,
    allNotifiesCount: null,
  }

  getAllNotifications(){
    this.setState({
      notifiesLoaded: false,
      newNotifiesLoaded: false,
      allNotifiesCount: null,
    });
    UserProfileNotification.newNotifies = {};
    UserProfileNotification.notifies = {};
    UserProfileNotification.allNotifies = {};
    this.props.getNewNotifications(this.props.token);
    this.props.getNotifications(this.props.token);
  }

  componentWillMount() {
    this.getAllNotifications();
  }

  componentWillReceiveProps(nextProps) {
    this.tryRenderNewNotifies(nextProps);
    this.tryRenderNotifies(nextProps);
    this.getAllNotifies();
  }

  isNotifiAlereadyViewed(id) {
    if (!Array.isArray(UserProfileNotification.allNotifies))return 0;
    const viewedItem = UserProfileNotification.allNotifies.filter(
      i=>i.id===id && i.viewed===1
    );
    if (viewedItem.length > 0) return 1; else return 0;
  }

  tryRenderNotifies(newProps){
    if ( newProps.requestNotificationSuccess ) {
      let notifies = JSON.parse(localStorage.getItem('Notifications'));
      if (notifies !== null && typeof notifies === 'object') {
        // Подготовка списка к отображению
        const preparedNotifies =  notifies.map(i=>{
          let resItem = {};
          Object.keys(i).forEach((item) =>{
            const fieldName = item;
            const val = i[fieldName];
            switch(fieldName) {
              case 'ID':
                resItem.id = val
                break;
              case 'TITLE':
                resItem.title = val
                break;
              case 'MESSAGE':
                resItem.message = val
                break;
              case 'PUBLIC_DATE':
                resItem.public_date = val
                break;
              case 'URL':
                resItem.url = val
                break;
              case 'TITLE_URL':
                resItem.title_url = val
                break;
              case 'VIEWED':
                resItem.viewed = val
                break;
              default: resItem.fieldName = val;
            };
            })
          return resItem;
        }).sort((a,b)=>a.id<b.id);

        UserProfileNotification.notifies = preparedNotifies;
        this.setState({
          notifiesLoaded: true,
        });
      }
    } else{
      this.setState({
        notifiesLoaded: false,
      });
    }
  }

  tryRenderNewNotifies(newProps) {
    if ( newProps.requestNewNotificationSuccess ) {
      let newNotifies = JSON.parse(localStorage.getItem('NewNotifications'));
      if (newNotifies !== null && typeof newNotifies === 'object') {
        // Подготовка списка к отображению
        const preparedNotifies =  newNotifies.map(i=>{
          const notifiAlereadyViewed = this.isNotifiAlereadyViewed(i.ID);
          // Если это не первое посроение компонента, то возможно
          // сообщение было просмотрено ранее и тогда при инициализации указываем viewed = 1
          let resItem = {viewed: notifiAlereadyViewed};
          Object.keys(i).forEach((item) =>{
            const fieldName = item;
            const val = i[fieldName];
            switch(fieldName) {
              case 'ID':
                resItem.id = val
                break;
              case 'TITLE':
                resItem.title = val
                break;
              case 'MESSAGE':
                resItem.message = val
                break;
              case 'PUBLIC_DATE':
                resItem.public_date = val
                break;
              case 'URL':
                resItem.url = val
                break;
              case 'TITLE_URL':
                resItem.title_url = val
                break;
              default: resItem.fieldName = val;
            };
            })
          return resItem;
        }).sort((a,b)=>a.id<b.id);
        UserProfileNotification.newNotifies = preparedNotifies;
        this.setState({
          newNotifiesLoaded: true,
        });
      }
    } else{
      this.setState({
        newNotifiesLoaded: false,
      });
    }
  }

  setReadNotify(notify) {
    if (this.isNotifiAlereadyViewed(notify.id)) return;
    let markedAsViewedNotifications = UserProfileNotification.newNotifies.map(i=> {
      if ((i.id === notify.id) && (i.viewed === 0)) i.viewed = 1;
      return i;});
    this.props.sendReadNotify(this.props.token, Number(notify.id));
    UserProfileNotification.notifies = markedAsViewedNotifications;
  }

  sendFollowNotify(notify){
    if (~notify.url.indexOf('zvision:')){
      // Внутренняя ссылка
      this.props.history.push(notify.url.replace('zvision:', ''));
    } else {
      // Внешняя ссылка
      this.props.sendFollowNotify(this.props.token,notify.id);
      // Переход через 700 мс, для того чтобы успеть сохранить факт перехода
      let timerId = null;
      timerId = setInterval(()=>{
        clearTimeout(timerId);
        window.location = notify.url;
      }, 700);
    }
  }

  getAllNotifies(){
    let arr = [];
    let a = []; let b = [];
    if (Array.isArray(UserProfileNotification.notifies)) 
      a = arr.concat(UserProfileNotification.notifies).sort((i,j)=>(i.public_date < j.public_date));
    if (Array.isArray(UserProfileNotification.newNotifies))
      b = arr.concat(UserProfileNotification.newNotifies).sort((i,j)=>(i.public_date < j.public_date));;
    UserProfileNotification.allNotifies = arr.concat(b,a);
    this.setState({ allNotifiesCount: UserProfileNotification.allNotifies.length });
  }

  render() {
    const notifies = UserProfileNotification.allNotifies;
    const { requestNotificationInProcess, requestNewNotificationInProcess, 
      requestNewNotificationError, requestNotificationError } = this.props;
    return (
      <div>
        { 
          (!requestNotificationInProcess&&!requestNewNotificationInProcess) &&
          (requestNotificationError || requestNewNotificationError) &&
          <div className="row">
            <div className="col-xl-10 col-md">
              <p>При получении 

                { requestNewNotificationError?' новых ':'' }
                { requestNewNotificationError&&requestNotificationError?' и ':'' }
                { requestNotificationError?' прочитанных ранее ':'' }

                уведомлений возникла ошибка<br/>
              Попробуйте повторить действие через пару минут
              </p>
              <button onClick = { this.getAllNotifications.bind(this) }
                disabled = { requestNewNotificationInProcess||requestNotificationInProcess }
                className="btn btn-outline-info">
                Повторить <i className="fa fa-refresh"/>
              </button>
            </div>
          </div>
        }
        <div className="row user-profile-notifications">
          <div className="col col-xl-10 col-md">
            { notifies.length > 0 &&
              <MessagesAccordion
                items={ notifies }
                onSelectItem={ this.setReadNotify.bind(this) }
                onDetailsClick={ this.sendFollowNotify.bind(this) }
              />
            }
            { (notifies.length === 0) && (!requestNotificationInProcess) &&
                (!requestNewNotificationInProcess)&&
              <p>В настоящее время уведомления отсутствуют</p>
            }
          </div>
        </div>
      </div>
    )
  }
}
