import { connect } from 'react-redux';
import { ClaimsListFilter } from '../../components/Desktop/Claims';
import { getClaimsList } from '../../actions/Main/Claims';

const mapStateToProps = (state) => ({
  authorized: state.authorization.authorized,
  token: state.authorization.token,
  login: state.authorization.login,
  // customers: state.authorization.userId,
  customers: state.authorization.childUserId || state.authorization.userId,

  requestClaimsListInProcess: state.claims.requestClaimsListInProcess,
  requestClaimsListError: state.claims.requestClaimsListError,
  requestClaimsListSuccess: state.claims.requestClaimsListSuccess,
  requestClaimsListStatus: state.claims.requestClaimsListStatus,

});

const mapDispathToProps = (dispath) => {
  return {
    getClaimsList:
      ( token, customers )=>
        dispath(getClaimsList(token, customers )),
  };
};

export default connect(
  mapStateToProps, mapDispathToProps
)(ClaimsListFilter)
