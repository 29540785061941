import { connect } from 'react-redux';
import { doEmailVerifycation } from '../actions/registration';

// Основные визуальные модули
import { RegistrationEmailVerifyView } from '../components/Desktop';

const mapStateToProps = (state) => ({
  userId: state.registration.userId,
  emailVerifyInProcess: state.registration.emailVerifyInProcess,
  emailVerifyError: state.registration.emailVerifyError,
  emailVerified: state.registration.emailVerified,
  status: state.registration.status,
});

const mapDispathToProps = (dispath) => {
  return {
    doEmailVerifycation: (token, userId) => dispath(doEmailVerifycation(token, userId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispathToProps
)(RegistrationEmailVerifyView)
