import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LogoView, FooterView } from '..';
import { Redirect } from 'react-router-dom'
import { validateInn, validatePassword } from '../../../lib/utils'

function validateTitleName(titleName, error) {
  error.code = 0;

  if ((titleName === null) || (titleName.length === 0)) {
    error.code = 1;
    error.message = 'Не указано название организации';
    return false;
  }

  if (titleName.length > 120) {
    error.code = 2;
    error.message = 'Длина названия организации не может быть более 120 символов';
    return false;
  }

  if (titleName.length < 6) {
    error.code = 3;
    error.message = 'Длина названия организации не может быть менее 6 символов';
    return false;
  }
}

function validateFullName(fullName, error) {
  error.code = 0;

  if ((fullName === null) || (fullName.length === 0)) {
    error.code = 1;
    error.message = 'Не указано полное название организации';
    return false;
  }

  if (fullName.length > 500) {
    error.code = 2;
    error.message = 'Длина полного названия организации не может быть более 500 символов';
    return false;
  }

  if (fullName.length < 6) {
    error.code = 3;
    error.message = 'Длина полного названия организации не может быть менее 6 символов';
    return false;
  }
}


class RegistrationView extends Component {
  static propTypes = { //Заглушка для линтера (((
    doRegistration: PropTypes.func.isRequired
  }

  state={
    badINNError: {message: null, code: 0},
    badPasswordError: {message: null, code: 0},
    badTitleNameError: {message: null, code: 0},
    badFullNameError: {message: null, code: 0},
  };


  doAPIRegistration = (event) => {
    event.preventDefault();//Чтобы не перезагружалась форма

    let errINN = {code: null, message: null};
    validateInn(this.INNInput.value,errINN)
    this.setState({badINNError : errINN });

    let errPassword = {code: null, message: null};
    validatePassword(this.PasswordInput.value,this.PasswordInputTest.value, errPassword)
    this.setState({badPasswordError : errPassword })

    let errTitleName = {code: null, message: null};
    validateTitleName(this.TitleNameInput.value, errTitleName)
    this.setState({badTitleNameError : errTitleName })

    let errFullName = {code: null, message: null};
    validateFullName(this.FullNameInput.value, errFullName)
    this.setState({badFullNameError : errFullName })

    if (errINN.code===0 && errPassword.code===0 && errTitleName.code===0 && errFullName.code ===0) {
      this.props.doRegistration (
        this.TitleNameInput.value,
        this.FullNameInput.value,
        this.INNInput.value,
        'zWEBServices',
        this.UserNameInput.value,
        this.EmainInput.value,
        this.PasswordInput.value);
    };
  }

  componentWillUnmount(){
    this.props.doRegistrationComplete();
  }

  readRegistrationStatus(){
    if (this.props.status===null) return this.props.status;
    if(~this.props.status.indexOf('Email already exists'))
    return 'Ошибка регистрации: указанный email адрес занят';
    if(~this.props.status.indexOf('NetworkError'))
    return 'Ошибка регистрации: сервер временно не доступен'; else
    return this.props.status;
  }

  render() {
    const { badINNError, badPasswordError, badTitleNameError, badFullNameError } = this.state;
    const { registrationInProcess, registrered } = this.props;
    return (
      <div className="zApp">
        <div className="container app-modules">
            { 
              registrered ?
                <Redirect to={ "/Registration/Success" }/> : 
                true
            }
            <div className="row">
                <div className="col-lg-3 col-md-0">
                  <LogoView/>
                </div>
                <div className="col-lg-6 col-md mt-2">
                  <h3>Регистрация пользователя</h3>
                  <hr/>
                  <p><span className="text-danger">{this.readRegistrationStatus()}</span></p>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-3 col-md-1"></div>
                <div className="col-lg-6 col-md-10">
                  <form onSubmit={ this.doAPIRegistration }>
                    <p>Регистрационные данные</p>
                    <div className="form-group">
                      { badTitleNameError.code!==0 &&
                      <span className="text-danger">{badTitleNameError.message}</span>
                      }
                      <input type="text"
                        ref={ (input)=>{this.TitleNameInput = input} }
                        className="form-control input-lg"
                        required="required"
                        minLength="6"
                        maxLength="120"
                        placeholder="Наименование организации" />
                    </div>
                    <div className="form-group">
                      { badFullNameError.code!==0 &&
                      <span className="text-danger">{badFullNameError.message}</span>
                      }
                      <input type="text"
                        ref={ (input)=>{ this.FullNameInput = input } }
                        className="form-control input-lg"
                        required="required"
                        minLength="6"
                        maxLength="500"
                        placeholder="Адрес грузополучателя" />
                    </div>
                    <div className="form-group">
                      { badINNError.code!==0 &&
                      <span className="text-danger">{badINNError.message}</span>
                      }
                      <input type="text"
                        ref={ (input)=>{ this.INNInput = input } }
                        className="form-control input-lg"
                        required="required"
                        size="12"
                        placeholder="ИНН" />
                    </div>

                    <div className="form-group">
                      <input type="text"
                        ref={ (input)=>{ this.UserNameInput = input } }
                        className="form-control input-lg"
                        required="required"
                        placeholder="Логин" />
                    </div>

                    <div className="form-group">
                      <input type="email"
                        ref={ (input)=>{ this.EmainInput = input } }
                        className="form-control input-lg"
                        required="required"
                        placeholder="Email" />
                    </div>
                    { badPasswordError.code!==0 &&
                      <span className="text-danger">{badPasswordError.message}</span>
                    }
                    <div className="form-group">
                      <input type="password"
                        ref={ (input)=>{ this.PasswordInput = input } }
                        className="form-control input-lg"
                        required="required"
                        size="50"
                        minLength="6"
                        placeholder="Пароль" />
                    </div>

                    <div className="form-group">
                      <input type="password"
                        ref={ (input)=>{ this.PasswordInputTest = input } }
                        className="form-control input-lg"
                        required="required"
                        size="50"
                        minLength="6"
                        placeholder="Подтверждение пароля" />
                    </div>

                    <div className="row justify-content-end">
                        <div className="col-md-6 col-sm-0">
                          <button type="submit"
                            disabled={ registrationInProcess }
                            className="btn btn-outline-success btn-block">
                            {(registrationInProcess) &&
                            <span>
                                Регистрация...&nbsp;
                                <i className="fa fa-circle-o-notch fa-spin fa-2x fa-fw"></i>
                              </span>
                            }
                            {(!registrationInProcess) &&
                              <span>
                                Зарегистрироваться &nbsp;
                                <i className="fa fa-check-square-o" aria-hidden="true"></i>
                              </span>
                            }
                          </button>
                        </div>
                    </div>

                  </form>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-lg-3 col-md-0"></div>
                <div className="col-lg-6 col-md">
                  <ul className="list-group">
                    <li className="list-group-item d-flex">
                      <i className="fa fa-envelope-o fa-2x" aria-hidden="true"></i>
                      <span className="ml-3">После нажатия на кнопку "Зарегистрироваться" на указанный Вами почтовый адрес <strong> придет письмо </strong> для подтверждения регистрации</span >
                    </li>
                    <li className="list-group-item d-flex">
                      <i className="fa fa-clock-o fa-2x" aria-hidden="true"></i>
                      <span className="ml-3">Затем мы активируем Ваш личный кабинет</span >
                    </li>
                    <li className="list-group-item d-flex list-group-item-success">
                      <i className="fa fa-check fa-2x" aria-hidden="true"></i>
                      <span className="ml-3">Когда профиль будет <strong>готов к работе</strong>, мы обязательно уведомим Вас об этом</span >
                    </li>
                  </ul>
                </div>
            </div>
        </div>
        <FooterView/>
      </div>
    );
  }
}

export default RegistrationView;
