import { connect } from 'react-redux';
import { ClaimsClaimCreateSucces } from '../../components/Desktop/Claims';

import { createNewClaimInitProcess } from '../../actions/Main/Claims';

const mapStateToProps = (state) => ({
  authorized: state.authorization.authorized,
  token: state.authorization.token,
  login: state.authorization.login,
  // customers: state.authorization.userId,
  customers: state.authorization.childUserId || state.authorization.userId,

  createNewClaimStep: state.claims.createNewClaimStep,
  createNewClaimError: state.claims.createNewClaimError,
  createNewClaimSuccess: state.claims.createNewClaimSuccess,
  createNewClaimStatus: state.claims.createNewClaimStatus,
  createNewClaimOutWaybillId: state.claims.createNewClaimOutWaybillId,
  newClaimId: state.claims.newClaimId,
});

const mapDispathToProps = (dispath) => {
  return {
    createNewClaimInitProcess:
      ( )=>
        dispath(createNewClaimInitProcess( )),
  };
};


export default connect(
  mapStateToProps, mapDispathToProps
)(ClaimsClaimCreateSucces)
