import React from 'react';
import { Route, Switch } from 'react-router-dom';

// Контейнеры
import { App, Authorization, Registration,
  PrivateRoute, PasswordResetRequest,
  PasswordResetProcess, RegistrationEmailVerify, Covid19} from '../containers';

// Основные визуальные модули
import { NotFoundView, RegistrationSuccessView, PasswordResetSuccessView } from '../components/Desktop';
import { killServiceWorkers, f1, cleanCache, refreshAll } from '../lib/appUpdateHandler';
export default(
  <div>
    <Switch>
      <Route exact path="/index.html" component={ App }/>
      <Route exact path="/" component={ App }/>
      <Route path="/About" component={ App }/>
      <Route path="/DesNeeds" component={ Covid19 }/>
      <Route path="/Authorization" component={ Authorization }/>
      <Route exact path="/Registration" component={ Registration }/>
      <Route path="/Registration/Success" component={ RegistrationSuccessView }/>
      <Route path="/Registration/EmailVerify" component={ RegistrationEmailVerify }/>
      <PrivateRoute path="/Profile" component={ App }/>
      <PrivateRoute path="/Cart" component={ App }/>
      <PrivateRoute path="/PersonalOrders" component={ App } />
      <PrivateRoute path="/Receivables" component={ App } />
      <PrivateRoute path="/Claims" component={ App } />
      <Route path="/Help" component={ App } />
      <Route exact path="/PasswordReset" component={ PasswordResetProcess }/>
      <Route exact path="/PasswordReset/Success" component={ PasswordResetSuccessView }/>
      <Route path="/PasswordReset/:Command" component={ PasswordResetRequest }/>
      <Route path="/ksw" component={ killServiceWorkers }/>
      <Route path="/f1" component={ f1 }/>
      <Route path="/cc" component={ cleanCache }/>
      <Route path="/UpdateApp" component={ refreshAll }/>
      <Route component={ NotFoundView }/>
    </Switch>
  </div>
)
