import React, { Component } from 'react';
import { DropDownItems } from '../../Main';
import { Redirect } from 'react-router-dom'
import { validateAmount } from '../../../../lib/utils';
import { OutWaybillLineDetailsView } from '../ClaimsClaimCreateEditor/OutWaybillLineDetailsView';

export default class ClaimsClaimCreateEditor extends Component {
  state = {
    troubleId: null,
    claimAmount: null,
    badClaimAmount: { message: null, code: 0 },
  }

  componentWillMount() {
    if ((this.props.createNewClaimStep !== 2) &&
        (this.props.createNewClaimStep !== 3) &&
        (this.props.createNewClaimStep !== 4))
      this.props.history.push('/Claims/List');
    const { OutWaybillId, OutWaybillLineId } = this.props.match.params;
    const { createNewClaimOutWaybillId, createNewClaimOutWaybillLineId } = this.props;
    if ( (Number(OutWaybillId) !== Number(createNewClaimOutWaybillId)) ||
       (Number(OutWaybillLineId) !== Number(createNewClaimOutWaybillLineId)))
      this.props.history.push('/Claims/List');
  }

  changeTroubleId(e) {
    this.setState({
      troubleId: e
    });
  }

  checkAmountInputValue(){
    let badClaimAmount = {code: null, message: null};
    validateAmount(this.claimAmountInput.value, badClaimAmount)
    this.setState({
      claimAmount : this.claimAmountInput.value,
      badClaimAmount : badClaimAmount,
    });
    return  badClaimAmount.code === 0;
  }

  createClaim(event) {
    event.preventDefault();
    let badAmountValue = this.checkAmountInputValue();
    if ( badAmountValue === true ) {
      //alert('отправка претензии');

      let claimSeriesInput = null;
      // Поле ввода серии доступно только в при определенных типах проблемы
      if ((Number(this.state.troubleId) === 10406)||(Number(this.state.troubleId) === 10405)) 
        claimSeriesInput = this.claimSeriesInput.value;
      const claimData = {
        customers: Number(this.props.customers),
        waybillId: Number(this.props.createNewClaimOutWaybillId),
        waybillLineId: Number(this.props.createNewClaimOutWaybillLineId),
        troubleId: Number(this.state.troubleId),
        amount: Number(this.state.claimAmount),
        series: claimSeriesInput||'',
        medsSub: undefined,
        amountSub: undefined,
        claimComment: this.claimCommentInput.value||'',
      };
      this.props.createNewClaim(this.props.token, claimData);
    }
  }

  render() {
    const {createNewClaimOutWaybillAmount,
      createNewClaimOutWaybillId,
      createNewClaimOutWaybillLineId,
      createNewClaimOutWaybillMeds,
      createNewClaimOutWaybillMedsName,
      createNewClaimOutWaybillSerial,
      createNewClaimStep,
      createNewClaimSuccess,
      createNewClaimError,
      createNewClaimInProcess,
      createNewClaimOutWaybillSerialLastReturnDate,
    } = this.props;
    const { troubleId, badClaimAmount } = this.state;
    const badSerialLastReturnDateExpired = (this.state.troubleId == 10428) && (createNewClaimOutWaybillSerialLastReturnDate);

    return (
      <div className="claim-editor">
        { (createNewClaimStep === 4 && createNewClaimSuccess === true) ?
           <Redirect to={ "/Claims/CreateNew/Success" }/> : true
        }

        { createNewClaimError && (createNewClaimStep === 4 || createNewClaimStep === 3) &&
           <Redirect to={ "/Claims/CreateNew/Error" }/>
        }

        <div className="row">
          <div className="col">
            <button
              type="button"
              className="btn btn-link"
              onClick={ this.props.history.goBack.bind(this) }>
              <i className="fa fa-arrow-left" aria-hidden="true"></i>
              &nbsp;Документ #{createNewClaimOutWaybillId}
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-9">
            <OutWaybillLineDetailsView data = {{
              createNewClaimOutWaybillId,
              createNewClaimOutWaybillLineId,
              createNewClaimOutWaybillMeds,
              createNewClaimOutWaybillMedsName,
              createNewClaimOutWaybillAmount,
              createNewClaimOutWaybillSerial,
            }}/>
            <hr/>
          </div>
        </div>



        { (!badSerialLastReturnDateExpired) &&
          <div className="row">
            <div className="col-9">
              <div className="alert alert-warning" role="alert">
                Пожалуйста, уточните детали Вашей претензии
                {Number(troubleId) > 0 &&  ` и нажмите кнопку "Отправить"`} 
              </div>
            </div>
          </div>
        }

        <form onSubmit={ this.createClaim.bind(this) }>
          <div className="row">
            <div className="col-9">
              <div className="form-group">
                <DropDownItems
                  buttonClassName="btn-block btn-outline-info"
                  buttonTitle="Укажите характер претензии"
                  items={ this.props.claimsTroubles }
                  onSelectItem={ this.changeTroubleId.bind(this) }
                />
              </div>
            </div>
          </div>


          { badSerialLastReturnDateExpired &&
            <div className="row">
              <div className="col-9">
                <div className="mb-1">
                  <div className="alert alert-warning" role="alert">
                    <span className="text-danger">{
                      'Крайняя дата для возврата федеральной забраковки ' + 
                      this.props.createNewClaimOutWaybillSerialLastReturnDate }
                    </span>
                  </div>
                </div>
              </div>
            </div>
          }

          { (!badSerialLastReturnDateExpired) &&
            <div>
              { (Number(troubleId) > 0) &&
              <div className="row">
                <div className="col-9">
                  <div className="form-group">
                    { badClaimAmount.code!==0 &&
                    <div className="mb-1">
                      <span className="text-danger">{ badClaimAmount.message }</span>
                    </div>
                    }
                    <input type="text"
                      ref={ (input)=>{this.claimAmountInput = input} }
                      className="form-control input-lg"
                      onBlur={ this.checkAmountInputValue.bind(this) }
                      required="required"
                      minLength="1"
                      maxLength="9"
                      placeholder="Количество"/>
                  </div>
                  </div>
              </div>
              }

              { ((Number(troubleId) === 10406)||(Number(troubleId) === 10405)) &&
              <div className="row">
                <div className="col-9">
                  <div className="form-group">
                    <input type="text"
                        ref={ (input)=>{this.claimSeriesInput = input} }
                        className="form-control input-lg"
                        required="required"
                        minLength="1"
                        maxLength="32"
                        placeholder="Наименование проблемной серии"/>
                  </div>
                </div>
              </div>
              }

              { (Number(troubleId) > 0) &&
              <div className="row">
                <div className="col-9">
                  <div className="form-group">
                    <textarea
                      ref={ (input)=>{ this.claimCommentInput = input } }
                      className="form-control input-lg"
                      minLength="5"
                      maxLength="120"
                      placeholder="Коментарий" />
                  </div>
                </div>
              </div>
              }

              { (Number(troubleId) > 0) &&
              <div className="row">
                <div className="col-5"></div>
                <div className="col-4">
                  <button type="submit"
                    disabled={ createNewClaimInProcess }
                    className="btn btn-outline-info btn-block">
                    Отправить
                  </button>
                </div>
              </div>
              }
            </div>
          }
        </form>
      </div>
    )
  }
}
