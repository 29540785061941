import {
  NEW_NOTIFICATIONS_REQUEST,
  NEW_NOTIFICATIONS_REQUEST_SUCCESS,
  NEW_NOTIFICATIONS_REQUEST_ERROR,
  READ_NOTIFY_REQUEST,
  READ_NOTIFY_REQUEST_SUCCESS,
  READ_NOTIFY_REQUEST_ERROR,
  NOTIFICATIONS_REQUEST,
  NOTIFICATIONS_REQUEST_SUCCESS,
  NOTIFICATIONS_REQUEST_ERROR,
  FOLLOWED_NOTIFY_REQUEST,
  FOLLOWED_NOTIFY_REQUEST_SUCCESS,
  FOLLOWED_NOTIFY_REQUEST_ERROR,
} from '../constants/notifications';

const initialState = {
  requestNewNotificationInProcess: false,
  requestNewNotificationError: false,
  requestNewNotificationSuccess: false,
  requestNewNotificationStatus: null,
  newNotificationCount: 0,

  readNotifyInProcess: false,
  readNotifyError: false,
  readNotifySuccess: false,
  readNotifyStatus: null,

  requestNotificationInProcess: false,
  requestNotificationError: false,
  requestNotificationSuccess: false,
  requestNotificationStatus: null,

  followNotifyInProcess: false,
  followNotifyError: false,
  followNotifySuccess: false,

};

export default function notifications(state = initialState, action) {
  switch (action.type) {
// Получение списка не прочитанных уведомлений
    case NEW_NOTIFICATIONS_REQUEST:
      if (process.env.NODE_ENV === 'development') console.log('raise action - NEW_NOTIFICATIONS_REQUEST', action);
      return { ...state,
        requestNewNotificationSuccess: false,
        requestNewNotificationInProcess: true,
        requestNewNotificationError: false,
        requestNewNotificationStatus: 'Получение новых уведомлений...',
      }

    case NEW_NOTIFICATIONS_REQUEST_SUCCESS:
    if (process.env.NODE_ENV === 'development') console.log('raise action - NEW_NOTIFICATIONS_REQUEST_SUCCESS', action);
      return { ...state,
        requestNewNotificationSuccess: true,
        requestNewNotificationInProcess: false,
        requestNewNotificationError: false,
        requestNewNotificationStatus: 'Не просмотренные уведомления получены',
        newNotificationCount: action.newNotificationCount,
      }

    case NEW_NOTIFICATIONS_REQUEST_ERROR:
    if (process.env.NODE_ENV === 'development') console.log('raise action - NEW_NOTIFICATIONS_REQUEST_ERROR', action);
      return { ...state,
        requestNewNotificationSuccess: false,
        requestNewNotificationInProcess: false,
        requestNewNotificationError: true,
        requestNewNotificationStatus: `Ошибка получение не просмотренных уведомлений: 
          ${action.error.errorCode}
          ${action.error.errorMessage}`,
      }
// Получение списка уведомлений
      case NOTIFICATIONS_REQUEST:
      if (process.env.NODE_ENV === 'development') console.log('raise action - NOTIFICATIONS_REQUEST', action);
      return { ...state,
        requestNotificationSuccess: false,
        requestNotificationInProcess: true,
        requestNotificationError: false,
        requestNotificationStatus: 'Получение новых уведомлений...',
      }

    case NOTIFICATIONS_REQUEST_SUCCESS:
    if (process.env.NODE_ENV === 'development') console.log('raise action - NOTIFICATIONS_REQUEST_SUCCESS', action);
      return { ...state,
        requestNotificationSuccess: true,
        requestNotificationInProcess: false,
        requestNotificationError: false,
        requestNotificationStatus: 'Не просмотренные уведомления получены',
      }

    case NOTIFICATIONS_REQUEST_ERROR:
    if (process.env.NODE_ENV === 'development') console.log('raise action - NOTIFICATIONS_REQUEST_ERROR', action);
      return { ...state,
        requestNotificationSuccess: false,
        requestNotificationInProcess: false,
        requestNotificationError: true,
        requestNotificationStatus: `Ошибка получение не просмотренных уведомлений: 
          ${action.error.errorCode}
          ${action.error.errorMessage}`,
      }
      
// Отправить уведомление о прочтении
      case READ_NOTIFY_REQUEST:
      if (process.env.NODE_ENV === 'development') console.log('raise action - NEW_NOTIFICATIONS_REQUEST', action);
      return { ...state,
        readNotifySuccess: false,
        readNotifyInProcess: true,
        readNotifyError: false,
        readNotifyStatus: 'Отправка отметки о прочтении уведомления...',
      }

    case READ_NOTIFY_REQUEST_SUCCESS:
    if (process.env.NODE_ENV === 'development') console.log('raise action - NEW_NOTIFICATIONS_REQUEST_SUCCESS', action);
      return { ...state,
        readNotifySuccess: true,
        readNotifyInProcess: false,
        readNotifyError: false,
        readNotifyStatus: 'Уведомление о прочтении отправлено',
        newNotificationCount: action.newNotificationCount,
      }

    case READ_NOTIFY_REQUEST_ERROR:
    if (process.env.NODE_ENV === 'development') console.log('raise action - NEW_NOTIFICATIONS_REQUEST_ERROR', action);
      return { ...state,
        readNotifySuccess: false,
        readNotifyInProcess: false,
        readNotifyError: true,
        readNotifyStatus: `Ошибка отправки отметки о прочтении уведомления: 
          ${action.error.errorCode}
          ${action.error.errorMessage}`,
      }


    // Клиент перешел к деталям уведомления
    case FOLLOWED_NOTIFY_REQUEST:
    if (process.env.NODE_ENV === 'development') console.log('raise action - NEW_NOTIFICATIONS_REQUEST', action);
    return { ...state,
      followNotifySuccess: false,
      followNotifyInProcess: true,
      followNotifyError: false,
    }

    case FOLLOWED_NOTIFY_REQUEST_SUCCESS:
    if (process.env.NODE_ENV === 'development') console.log('raise action - NEW_NOTIFICATIONS_REQUEST_SUCCESS', action);
    return { ...state,
      followNotifySuccess: true,
      followNotifyInProcess: false,
      followNotifyError: false,
    }

    case FOLLOWED_NOTIFY_REQUEST_ERROR:
    if (process.env.NODE_ENV === 'development') console.log('raise action - NEW_NOTIFICATIONS_REQUEST_ERROR', action);
    return { ...state,
      followNotifySuccess: false,
      followNotifyInProcess: false,
      followNotifyError: true,
    }

    default:
      return state;
  }
}
