import React from 'react';
import { Link } from 'react-router-dom';
export const MainAboutView = (systemVersion) => (
  <div className="container-fluid">
    <br/>
    <div className="row">
      <div className="col align-items-center">
        {/* Дополнительное меню главной страницы */}
      </div>
    </div>
    <div className="row">
      <div className="col-xl-3 col-lg-0">
        {/* Доп. окно главной страницы */}
      </div>
      <div className="col-xl-7 col-md">
        {/* Основное окно главной страницы */}
        <div className="row">
          <div className="col-xl-10 col-md">
            <h5>О системе</h5>
            <div className="alert alert-info zalert-info-card" role="alert">
              <p><b><Link to="/" className="internal-page-link-calm" >zVision</Link></b> веб-ресурс компании ООО Здравсервис</p>
              <hr/>
              <p>Двигаясь навстречу клиентам, мы расширяем спектр способов
                взаимодействия и сотрудничества</p>
              <p>zVision продукт для цифрового доступа к нашим ресурсам</p>
              Сейчас Вы пользуетесь версией <b>zVision { systemVersion }</b>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="row">
      <div className="col-xl-3 col-lg-0"></div>
      <div className="col-xl-7 col-md">
        <div className="row">
          <div className="col-xl-4 col-md"></div>

          <div className="col-md-3">
            <Link to="/Help" className="internal-page-link-calm-nu" title="Обновление системы">
              <button className="btn btn-block btn-outline-info btn-sm">
                <b>Обновление</b>
              </button>
            </Link>
          </div>

          <div className="col-md-3">
            <Link to={ '/' } className="internal-page-link-calm-nu" title="Главная zVision">
              <button className="btn btn-block btn-outline-info btn-sm">
                <i className="fa fa-home" aria-hidden="true"></i>&nbsp;
                <b>zVision { systemVersion }</b>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>

  </div>
);
