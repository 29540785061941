import { apiCommandPOSTRequestPasswordReset,
  apiCommandPOSTPasswordReset } from '../constants/passwordReset';
import {validationJSON, makeJSON} from '../lib/utils'

export function requestPasswordReset(email) {
  return {
    type: 'REQUEST_PASSWORD_RESET_REQUEST',
    email: email,
  }
}

export function passwordResetSuccess(email) {
  return {
    type: 'REQUEST_PASSWORD_RESET_SUCCESS',
    email: email,
  }
}

export function passwordResetError(errorCode, errorMessage) {
  return {
    type: 'REQUEST_PASSWORD_RESET_ERROR',
    error: {errorCode, errorMessage},
  }
}

export function passwordResetInit() {
  return {
    type: 'REQUEST_PASSWORD_RESET_INIT',
  }
}

/* eslint-disable */
/* eslint-enable */
export function passwordResetRequest(email) {
  return function(dispatch) {
    dispatch(requestPasswordReset(email));
    let httpStatusCode = null;
    let apiErrorCode = null;
    let apiErrorMessage = null;

    return fetch(apiCommandPOSTRequestPasswordReset, {
      // cors - Сервис API может быть на любом сервере, same-origin - Тот же самый сервер
      mode: 'cors',
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: makeJSON({
        email: email,
      }),
    })
      .then((response)=>{
        // Извлекаем код ответа HTTP
        httpStatusCode = response.status;
        if (httpStatusCode === 204)
          dispatch(passwordResetSuccess(email));
        return response;
      })
      .then((response)=>{
        if (httpStatusCode !== 204)
          return validationJSON(response);
      }) // Раскрывает промис
      .then((data)=> {
        if ( data !== undefined ) {
          apiErrorCode = data.error.code;
          apiErrorMessage = data.error.message;
          const error = new Error();
          throw error;
        }
      }
      )
      .catch((error) => {
        if (process.env.NODE_ENV === 'development') console.log('passwordResetRequest() - Error: ', error);
        dispatch(
          passwordResetError(
            apiErrorCode || httpStatusCode,
            apiErrorMessage || error.message
          ));
      })
  }
}

// Изменение забытого пароля

export function requestProcessPasswordReset() {
  return {
    type: 'PROCESS_PASSWORD_RESET_REQUEST',
  }
}

export function processPasswordResetSuccess() {
  return {
    type: 'PROCESS_PASSWORD_RESET_SUCCESS',
  }
}

export function processPasswordResetError(errorCode, errorMessage) {
  return {
    type: 'PROCESS_PASSWORD_RESET_ERROR',
    error: {errorCode, errorMessage},
  }
}


/* eslint-disable */
/* eslint-enable */
export function processPasswordResetRequest(token, newPassword) {
  return function(dispatch) {
    dispatch(requestProcessPasswordReset());
    let httpStatusCode = null;
    let apiErrorCode = null;
    let apiErrorMessage = null;
    return fetch(apiCommandPOSTPasswordReset + '?access_token=' + token, {
      // cors - Сервис API может быть на любом сервере, same-origin - Тот же самый сервер
      mode: 'cors',
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: makeJSON({
        newPassword: newPassword,
      }),
    })
      .then((response)=>{
        // Извлекаем код ответа HTTP
        httpStatusCode = response.status;
        if (httpStatusCode === 204)
          dispatch(processPasswordResetSuccess());
        return response;
      })
      .then((response)=>{
        if (httpStatusCode !== 204)
          return validationJSON(response);
      }) // Раскрывает промис
      .then((data)=> {
        if ( data !== undefined ) {
          apiErrorCode = data.error.code;
          apiErrorMessage = data.error.message;
          const error = new Error();
          throw error;
        }
      }
      )
      .catch((error) => {
        if (process.env.NODE_ENV === 'development') console.log('processPasswordResetRequest() - Error: ', error);
        dispatch(
          processPasswordResetError(
            apiErrorCode || httpStatusCode,
            apiErrorMessage || error.message
          ));
      })
  }
}

