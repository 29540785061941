import { connect } from 'react-redux';
import { PersonalOrdersOrderHistory }  from '../../components/Desktop/PersonalOrders';
import { getOrderHistory } from '../../actions/Main/PersonalOrders';

const mapStateToProps = (state) => ({
  authorized: state.authorization.authorized,
  token: state.authorization.token,
  login: state.authorization.login,

  // customers: state.authorization.userId,
  customers: state.authorization.childUserId || state.authorization.userId,

  requestOrderHistoryInProcess: state.personalOrders.requestOrderHistoryInProcess,
  requestOrderHistoryError: state.personalOrders.requestOrderHistoryError,
  requestOrderHistorySuccess: state.personalOrders.requestOrderHistorySuccess,
  requestOrderHistoryStatus: state.personalOrders.requestOrderHistoryStatus,

  requestOrderHistoryCustomers: state.personalOrders.requestOrderHistoryCustomers,
  requestOrderHistoryOderId: state.personalOrders.requestOrderHistoryOderId,
});

const mapDispathToProps = (dispath) => {
  return {
    doGetOrderHistory:
      ( token, customers, orderId )=>
        dispath(getOrderHistory(token, customers, orderId )),
  };
};

export default connect(
  mapStateToProps,
  mapDispathToProps
)(PersonalOrdersOrderHistory)
