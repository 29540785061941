import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class UserProfileBasic extends Component {
  static propTypes = {
    customers: PropTypes.number.isRequired,
    login: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
  }

  render() {
    const { customers, login } = this.props;
    return (
      <div className="row">
        <div className="col-xl-10 col-md">

          <div className="alert alert-success" role="alert">
            Статус профиля - <b> Активен </b>
          </div>
          <div className="input-group input-group-sm mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroup-sizing-sm">Персональный ERP идентификатор</span>
            </div>
            <div className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm">
              { customers }
            </div>
          </div>

          <div className="input-group input-group-sm mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroup-sizing-sm">Email</span>
            </div>
            <div className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm">
              {login}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
