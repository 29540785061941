import { connect } from 'react-redux';
import { PersonalOrdersOrderFilter } from '../../components/Desktop/PersonalOrders';

const mapStateToProps = (state) => ({
  authorized: state.authorization.authorized,
  token: state.authorization.token,
  login: state.authorization.login,
});

export default connect(
  mapStateToProps,
  null
)(PersonalOrdersOrderFilter)
