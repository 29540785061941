import { connect } from 'react-redux';
import { PersonalOrdersCatalogFilter } from '../../components/Desktop/PersonalOrders';
import { restoreCatalogState } from '../../actions/Main/PersonalOrders';

const mapStateToProps = (state) => ({
  authorized: state.authorization.authorized,
  token: state.authorization.token,
  login: state.authorization.login,

  // customers: state.authorization.userId,
  customers: Number(state.authorization.childUserId) || Number(state.authorization.userId),


  requestCatalogInProcess: state.personalOrders.requestCatalogInProcess,
  requestCatalogSuccess: state.personalOrders.requestCatalogSuccess,
  requestCatalogError: state.personalOrders.requestCatalogError,

  catalogClientId: state.personalOrders.catalogClientId,

  filter: state.personalOrders.filter,
  catalogLinesCount: state.personalOrders.catalogLinesCount,
  requestCatalogStatus: state.personalOrders.requestCatalogStatus,
  catalogRefreshTime: state.personalOrders.catalogRefreshTime,
  catalogExpired: state.personalOrders.catalogExpired,
});

const mapDispathToProps = (dispath) => {
  return {
    doRefreshCatalog:
      (localStorageOnly, token, customers, filter)=>
        dispath(restoreCatalogState(localStorageOnly, token, customers, filter)),
  };
};

export default connect(
  mapStateToProps,
  mapDispathToProps
)(PersonalOrdersCatalogFilter)
