import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LogoView, FooterView } from '..';
// import { Redirect } from 'react-router-dom'
// import { Link } from 'react-router-dom';

class PasswordResetRequestView extends Component {
  static propTypes = {
    doPasswordResetRequest: PropTypes.func.isRequired
  }

  doRequestPasswordReset(event) {
    event.preventDefault();
    this.props.doPasswordResetRequest (
      this.EmainInput.value,
    );
  }

  readPasswordResetRequestStatus(){
    const status = this.props.requestPasswordResetStatus;
    if (status===null) return status;
    if(~status.indexOf('Email not found'))
    return 'Ошибка запроса на изменение пароля: указанный email не зарегистрирован в системе';
    if(~status.indexOf('NetworkError'))
    return 'Ошибка запроса на изменение пароля: сервер временно не доступен'; else
    return status;
  }

  componentWillUnmount(){
    this.props.doPasswordResetInit();
  }

  render() {
    const { requestPasswordResetInProcess } = this.props;
    return (
      <div className="zApp">
        <div className="container app-modules">

          <div className="row">
              <div className="col-lg-3 col-md-0">
                <LogoView/>
              </div>
              <div className="col-lg-6 col-md mt-2">
                <h3>Восстановление пароля</h3>
              </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="d-md-none">
                <hr/>
              </div>
            </div>
          </div>

          { this.props.requestPasswordResetSuccess &&
            <div className="row">
              <div className="col-lg-3 col-md-0"></div>
              <div className="col-lg-6 col-md-0 mb-2">
                <div className="alert alert-info zalert-info-card" role="alert">
                  <p>Информация для восстановления пароля отправлена на 
                  указанный электронный адрес</p>

                  <p>Данная ссылка <strong>действительна в течение 15 минут</strong> после отправки данного сообщения</p>
                </div>

                <ul className="list-group">
                  <li className="list-group-item alert alert-danger d-flex">
                    <i className="fa fa-exclamation-triangle fa-2x" aria-hidden="true"></i>
                    <span className="ml-3">Если Вы не видите письма в папке входящих, пожалуйста, проверьте папку «спам»</span >
                  </li>
                </ul>

              </div>
            </div>
          }

          { !this.props.requestPasswordResetSuccess &&
            <div>
              <div className="row">
                <div className="col-lg-3 col-md-0"></div>
                <div className="col-lg-6 col-md-0">
                  <div className="alert alert-info zalert-info-card" role="alert">
                    Для восстановления забытого пароля потребуется ввести адрес электронной почты, который был указан Вами при регистрации
                    <hr/>
                    При необоходимости эту информацию Вы можете получить, связавшись с вашим персональным менеджером
                    </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-3 col-md-0"></div>
                <div className="col-lg-6 col-md-0">
                  <form onSubmit={ this.doRequestPasswordReset.bind(this) }>
                    {
                      this.props.requestPasswordResetError &&
                        <p className="text-danger">{this.readPasswordResetRequestStatus()}</p>
                    }
                    {
                      this.props.requestPasswordResetInProcess &&
                        <p className="text-info">{this.readPasswordResetRequestStatus()}</p>
                    }
                    <div className="form-group">
                      <input type="email"
                        ref={ (input)=>{ this.EmainInput = input } }
                        className="form-control input-lg"
                        required="required"
                        placeholder="Email" />
                    </div>
                    <div className="row no-gutters">
                      <div className="col-md-6 col-sm ml-auto">
                        <button type="submit"
                          disabled={requestPasswordResetInProcess}
                          className="btn btn-outline-info btn-block">
                          {(requestPasswordResetInProcess) &&
                            <div>
                              Отправка...&nbsp;
                              <i className="fa fa-circle-o-notch fa-spin fa-lg fa-fw"></i>
                            </div>
                            }
                            {(!requestPasswordResetInProcess) &&
                            <div>
                              Восстановить
                            </div>
                            }
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

            </div>
          }

        </div>
        <FooterView/>
      </div>
    );
  }
}

export default PasswordResetRequestView;
