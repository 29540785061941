import {
  RECEIVABLES_INFO_REQUEST,
  RECEIVABLES_INFO_REQUEST_SUCCESS,
  RECEIVABLES_INFO_REQUEST_ERROR,
} from '../constants/receivables';

const initialState = {
  requestReceivablesInProcess: false,
  requestReceivablesError: false,
  requestReceivablesSuccess: false,
  requestReceivablesStatus: null,
  requestReceivablesCustomers: null,
};

export default function receivables(state = initialState, action) {
  switch (action.type) {
    case RECEIVABLES_INFO_REQUEST:
      if (process.env.NODE_ENV === 'development') console.log('raise action - RECEIVABLES_INFO_REQUEST', action);
      return { ...state,
        requestReceivablesSuccess: false,
        requestReceivablesInProcess: true,
        requestReceivablesError: false,
        requestReceivablesStatus: 'Получение дебиторской задолженности...',
        requestReceivablesCustomers: action.customers,
      }
      
    case RECEIVABLES_INFO_REQUEST_SUCCESS:
    if (process.env.NODE_ENV === 'development') console.log('raise action - RECEIVABLES_INFO_REQUEST_SUCCESS', action);
      return { ...state,
        requestReceivablesSuccess: true,
        requestReceivablesInProcess: false,
        requestReceivablesError: false,
        requestReceivablesStatus: 'Информация о дебиторской задолженности получена'
      }

    case RECEIVABLES_INFO_REQUEST_ERROR:
    if (process.env.NODE_ENV === 'development') console.log('raise action - RECEIVABLES_INFO_REQUEST_ERROR', action);
      return { ...state,
        requestReceivablesSuccess: false,
        requestReceivablesInProcess: false,
        requestReceivablesError: true,
        requestReceivablesStatus: `Ошибка получнения информации о дебиторской задолженности: 
          ${action.error.errorCode}
          ${action.error.errorMessage}`,
      }

    default:
      return state;
  }
}
