import	{
  REGISTRATION_REQUEST,
  REGISTRATION_SUCCESS,
  REGISTRATION_ERROR,
  REGISTRATION_COMPLETE,

  REGISTRATION_EMAIL_VERIFY_REQUEST,
  REGISTRATION_EMAIL_VERIFY_SUCCESS,
  REGISTRATION_EMAIL_VERIFY_ERROR

}	from	'../constants/registration';

const initialState = {
  registrationInProcess: false,
  registrationError: false,
  registrered: false,
  status: null,

  inn: null,
  titleName: null,
  fullName: null,
  realm: null,
  username: null,
  email: null,

  userId: null,

  emailVerifyInProcess: false,
  emailVerifyError: false,
  emailVerified: false,
};

export default function registration(state = initialState, action) {
  switch (action.type) {

    case REGISTRATION_REQUEST:
      if (process.env.NODE_ENV === 'development') console.log('raise action - REGISTRATION_REQUEST', action);
      return { ...state,
        registrationInProcess: true,

        inn: action.inn,
        titleName: action.titleName,
        fullName: action.fullName,
        realm: action.realm,
        username: action.username,
        email: action.email,

        status: 'Регистрация...'
      }

    case REGISTRATION_SUCCESS:
      if (process.env.NODE_ENV === 'development') console.log('raise action - REGISTRATION_SUCCESS', action);
      return { ...state,
        registrationInProcess: false,
        registrationError: false,
        registrered: true,

        userId: action.userId,
        status: 'Пользователь зарегистрирован'
      }

    case REGISTRATION_ERROR:
      if (process.env.NODE_ENV === 'development') console.log('raise action - REGISTRATION_ERROR', action);
      return { ...state, 
        registrationInProcess: false,
        registrationError: true,
        registrered: false,

        userId: null,
        status: `Ошибка регистрации пользователя: 
          ${action.error.errorCode}
          ${action.error.errorMessage}`
      }

      case REGISTRATION_COMPLETE:
      if (process.env.NODE_ENV === 'development') console.log('raise action - REGISTRATION_COMPLETE', action);
      return { ...state, 
        registrationInProcess: false,
        registrationError: false,
        registrered: false,
        userId: null,
        status: null,
      }

      case REGISTRATION_EMAIL_VERIFY_REQUEST:
      if (process.env.NODE_ENV === 'development') console.log('raise action - REGISTRATION_EMAIL_VERIFY_REQUEST', action);
      return { ...state, 
        userId: action.userId,
        emailVerifyInProcess: true,
        emailVerifyError: false,
        emailVerified: false,
      }

      case REGISTRATION_EMAIL_VERIFY_SUCCESS:
      if (process.env.NODE_ENV === 'development') console.log('raise action - REGISTRATION_EMAIL_VERIFY_SUCCESS', action);
      return { ...state, 
        userId: action.userId,
        emailVerifyInProcess: false,
        emailVerifyError: false,
        emailVerified: true,
      }

      case REGISTRATION_EMAIL_VERIFY_ERROR:
      if (process.env.NODE_ENV === 'development') console.log('raise action - REGISTRATION_EMAIL_VERIFY_ERROR', action);
      return { ...state,
        emailVerifyInProcess: false,
        emailVerifyError: true,
        emailVerified: false,
        userId: null,
        status: `${action.error.errorCode} - ${action.error.errorMessage}`
      }

      default:
      return state;
  }
}
