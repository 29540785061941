import {backendServiceURL} from '../config/backendService'

export const RECEIVABLES_INFO_REQUEST = 'RECEIVABLES_INFO_REQUEST'
export const RECEIVABLES_INFO_REQUEST_SUCCESS = 'RECEIVABLES_INFO_REQUEST_SUCCESS'
export const RECEIVABLES_INFO_REQUEST_ERROR = 'RECEIVABLES_INFO_REQUEST_ERROR'

// Команды API интерфейса
//
// Получить детали дебиторской задолженности - авторизация
export const apiCommandGETReceivablesInfo = backendServiceURL + 'api/v1/Receivables/getReceivables'
