import { connect } from 'react-redux';
import {loginUser, autorizationLogout} from '../actions/authorization';

// Основные визуальные модули
import { AuthorizationView } from '../components/Desktop';

const mapStateToProps = (state) => ({
  token: state.authorization.token,
  login: state.authorization.login,
  isAuthenticated: state.authorization.authorized,
  userId: state.authorization.userId,
  status: state.authorization.status,
  authorizationInProcess: state.authorization.authorizationInProcess,
});

const mapDispathToProps = (dispath) => {
  return {
    doAuthorization: (login, password) => dispath(loginUser(login, password)),
    doLogout: () => dispath(autorizationLogout()),
  };
};

export default connect(
  mapStateToProps,
  mapDispathToProps
)(AuthorizationView)
