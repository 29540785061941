import	{
  NEW_APP_VERSION_AVAILABLE,
  UPDATE_APP_MESSAGE_CLOSED,
}	from	'../constants/application';

const initialState = {
  newAppVersionAvailable: false,
  updateAppMessageClosed: false,
};

export default function application(state = initialState, action) {
  switch (action.type) {
      case NEW_APP_VERSION_AVAILABLE:
        if (process.env.NODE_ENV === 'development') console.log('raise action - NEW_APP_VERSION_AVAILABLE', action);
          return { ...state, 
            newAppVersionAvailable: action.newAppVersionAvailable,
        }

      case UPDATE_APP_MESSAGE_CLOSED:
        if (process.env.NODE_ENV === 'development') console.log('raise action - UPDATE_APP_MESSAGE_CLOSED', action);
          return { ...state,
            updateAppMessageClosed: true,
        }

    default:
      return state;
  }
}
