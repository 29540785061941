import React, { Component } from 'react';


const ActiveOrdersStatisticsView = (props) => {
  const {activeOrdersCount, requestActiveOrdersInProcess, requestActiveOrdersError} = props;
  return (
    <div>
      <b>
        <p>
          {requestActiveOrdersInProcess
            ? 'Получение данных...'
            : null}
          { requestActiveOrdersError
            ? 'Сервис временно не доступен'
            : null
          }
        </p>
      </b>
      {
        !requestActiveOrdersInProcess && activeOrdersCount > 0 &&
        <div>
          <p>Количество заказов <b> { activeOrdersCount }</b></p>
        </div>
      }
    </div>
  );
}

export default class PersonalOrdersOrdersFilter extends Component {
  state = {
    activeOrdersCount: null,
  };
  doGetActiveOrders() {
    this.props.doGetActiveOrders(this.props.token,
      this.props.customers,
      'no filters');
  }

  refreshActiveOrdersCount(props){
    if ( props.requestActiveOrdersSuccess ) {
      let partOfActiveOrders = JSON.parse(localStorage.getItem('ActiveOrdersItems'));
      if (Array.isArray(partOfActiveOrders)) {
        this.setState({
          activeOrdersCount: partOfActiveOrders.length,
        });
      }
    }
  }

  componentWillMount() {
    this.refreshActiveOrdersCount(this.props);
  }

  componentWillReceiveProps(nextProps){
    this.refreshActiveOrdersCount(nextProps);
  }  

  render() {
    const {requestActiveOrdersInProcess,
      requestActiveOrdersError,
      requestActiveOrdersStatus} = this.props;

    return (
      <div>
        <ActiveOrdersStatisticsView 
          activeOrdersCount = {this.state.activeOrdersCount}
          requestActiveOrdersError = {requestActiveOrdersError}
          requestActiveOrdersInProcess = {requestActiveOrdersInProcess}
          requestActiveOrdersStatus = {requestActiveOrdersStatus}
        />
        <button onClick = { this.doGetActiveOrders.bind(this) }
          disabled = { this.props.requestActiveOrdersInProcess }
          className="btn btn-outline-info btn-sm btn-block">
          <i className="fa fa-2x fa-refresh"/>
        </button>
      </div>
    );
  }
}
