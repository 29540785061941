// Основные визуальные модули
import {  ClaimsIndexView  } from '../../components/Desktop/Claims'
import  ClaimsNotFoundView   from '../../components/Desktop/Claims/ClaimsNotFoundView'

// Содержимое основного окна модуля
import ConnectedClaimsList from '../../containers/Claims/ConnectedClaimsList';
import ConnectedClaimsClaimDetails from '../../containers/Claims/ConnectedClaimsClaimDetails';
import ConnectedClaimsCreateNew from '../../containers/Claims/ConnectedClaimsCreateNew';
import ConnectedClaimsClaimCreateEditor from '../../containers/Claims/ConnectedClaimsClaimCreateEditor';
import ConnectedClaimsClaimCreateSucces from '../../containers/Claims/ConnectedClaimsClaimCreateSucces';
import ConnectedClaimsClaimCreateError from '../../containers/Claims/ConnectedClaimsClaimCreateError';
import ConnectedClaimsUndoClaim from '../../containers/Claims/ConnectedClaimsUndoClaim';

// Содержимое окна фильтров модуля
import ConnectedClaimsListFilter from '../../containers/Claims/ConnectedClaimsListFilter';


// Внутренние информационные страницы модуля
import { ClaimsStatesInfo } from '../../components/Desktop/Claims/ClaimsStatics/ClaimsStatesInfo'

// Основной роут модуля
const ModuleRootPath = '/Claims';

// Настройки модуля Claims
const ClaimsModules = [
  {
    caption: 'Обзор документов',
    exact: false,
    privateRoute: true,
    link: ModuleRootPath + '/List',
    path: ModuleRootPath + '/List',
    component: ConnectedClaimsList,
    filterСomponent: ConnectedClaimsListFilter,
  },
  {
    exact: true,
    privateRoute: true,
    path: ModuleRootPath + '/Details/:ClaimId(\\d{1,9})',
    component: ConnectedClaimsClaimDetails,
    filterСomponent: ()=>(null),
  },
  {
    caption: 'Статусы претензий',
    exact: true,
    privateRoute: false,
    path: ModuleRootPath + '/ClaimStatuses',
    component: ClaimsStatesInfo,
    filterСomponent: ()=>(null),
  },
  {
    caption: 'Создать',
    exact: true,
    privateRoute: true,
    path: ModuleRootPath + '/CreateNew',
    link: ModuleRootPath + '/CreateNew',
    component: ConnectedClaimsCreateNew,
    filterСomponent: ()=>(null),
  },
  {
    caption: 'Создать - отображение деталей указанного документа',
    exact: true,
    privateRoute: true,
    path: ModuleRootPath + '/CreateNew/:OutWaybillId(\\d{1,9})',
    component: ConnectedClaimsCreateNew,
    filterСomponent: ()=>(null),
  },
  {
    exact: true,
    privateRoute: true,
    path: ModuleRootPath + '/CreateNew/:OutWaybillId(\\d{1,9})/:OutWaybillLineId(\\d{1,9})',
    component: ConnectedClaimsClaimCreateEditor,
    filterСomponent: ()=>(null),
  },
  {
    exact: true,
    privateRoute: true,
    path: ModuleRootPath + '/CreateNew/Success',
    component: ConnectedClaimsClaimCreateSucces,
    filterСomponent: ()=>(null),
  },
  {
    exact: true,
    privateRoute: true,
    path: ModuleRootPath + '/CreateNew/Error',
    component: ConnectedClaimsClaimCreateError,
    filterСomponent: ()=>(null),
  },
  {
    caption: 'Создать - отображение деталей указанного документа',
    exact: true,
    privateRoute: true,
    path: ModuleRootPath + '/UndoClaim/:ClaimId(\\d{1,9})',
    component: ConnectedClaimsUndoClaim,
    filterСomponent: ()=>(null),
  },
  { // Индексная страница модуля Claims
    exact: false,
    privateRoute: true,
    path: ModuleRootPath + '/',
    component: ClaimsIndexView,
    filterСomponent: ()=>(null),
  },
  { // Сообщение "Объект не найден" - вывод в основное окно модуля
    exact: false,
    privateRoute: true,
    component: ClaimsNotFoundView,
    filterСomponent: ()=>(null),
  },
];

export default ClaimsModules;
