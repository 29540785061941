import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles';

export default class FileUploader extends Component  {
  state = {
    fileObject: undefined,
    fileName: 'Выберите файл с заказом...'
  }


  handleSubmitFile(e) {
    e.preventDefault();
    this.props.onSubmitFile(this.state.fileObject);
  }

  handleFileChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState(
      {
        fileObject: file,
        fileName: file.name,
      }
      );
      this.props.onAfterLoadFile(file, reader.result);
    }
    reader.readAsBinaryString(file)
  }

  render() {
    const { fileName } = this.state;
    return (
      <div className="file-uploader">
        <div className="input-group">
          <div className="custom-file">
            <input type="file"
              className="custom-file-input"
              id="fileUploaderInputGroup"
              onChange={ (e)=>this.handleFileChange(e) }
            />
            <label className="custom-file-label" htmlFor="fileUploaderInputGroup">{fileName}</label>
          </div>
          <div className="input-group-append">
            <button className="btn btn-outline-success" type="button" onClick={ (e)=>this.handleSubmitFile(e) }>Импорт</button>
          </div>
        </div>
      </div>
    )};
}

FileUploader.propTypes = {
  onSubmitFile: PropTypes.func.isRequired,
  onAfterLoadFile: PropTypes.func.isRequired,
}
