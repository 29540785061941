import React from 'react';

export const OutWaybillLineDetailsView = ({ data }) => {
  if (data !== null) {
    const { createNewClaimOutWaybillMeds,
      createNewClaimOutWaybillMedsName,
      createNewClaimOutWaybillSerial,
      createNewClaimOutWaybillAmount,
      createNewClaimOutWaybillLineId } = data;
    return (
      <div className="row">
        <div className="col">
          <p></p>
          <h5><b>Претензия по строке</b></h5>
          <div className="data-horisontal-grid">
            <table className="table table-sm table-responsive data-horisontal-grid-table">
              <tbody>
                <tr className="data-horisontal-grid-item">
                  <td>#</td>
                  <td><b>{ createNewClaimOutWaybillLineId }</b></td>
                </tr>
                <tr className="data-horisontal-grid-item">
                  <td>Артикул</td>
                  <td><b>{ createNewClaimOutWaybillMeds }</b></td>
                </tr>

                <tr className="data-horisontal-grid-item">
                  <td>Наименование</td>
                  <td><b>{ createNewClaimOutWaybillMedsName }</b></td>
                </tr>

                <tr className="data-horisontal-grid-item">
                  <td>Серия</td>
                  <td><b>{ createNewClaimOutWaybillSerial }</b></td>
                </tr>

                <tr className="data-horisontal-grid-item">
                  <td>Количество</td>
                  <td><b>{ createNewClaimOutWaybillAmount }</b></td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  } else return null;
};
