import React from 'react';
import PropTypes from 'prop-types';
import { formatDateTime, formatDate } from '../../../../lib/utils'
import { Link } from 'react-router-dom'


export const PersonalOrdersOrderHeadView = ({ orderData }) => {
  const { commentary, creationDate, orderId, statusName, items, deliveryDate } = orderData;
  const orderSumm = (items.reduce((summ, item)=>(summ += item.price * item.qtty), 0)).toFixed(2);

  return (
    <div>
      <p></p>
      <h5><b> Заказ # { orderId } </b> </h5>
      <div className="data-horisontal-grid">
        <table className="table table-sm table-responsive data-horisontal-grid-table">
          <tbody>
            { commentary &&
            <tr className="data-horisontal-grid-item">
              <td>Комментарий</td>
              <td> <b> { commentary } </b> </td>
            </tr>
            }

            <tr className="data-horisontal-grid-item">
              <td>Дата создания</td>
              <td>{ formatDateTime(creationDate) }</td>

            </tr>
            <tr className="data-horisontal-grid-item">
              <td>Сумма</td>
              <td>{ orderSumm } руб.</td>

            </tr>
            <tr className="data-horisontal-grid-item">
              <td>Доставка</td>
              <td>{ formatDate(deliveryDate) }</td>

            </tr>
            <tr className="data-horisontal-grid-item">
              <td>Статус</td>
              <td>
                <b>
                  <Link to="/PersonalOrders/OrderStatuses" className="internal-page-link-calm">
                    { statusName }
                  </Link>
                </b>
              </td>
            </tr>

            <tr className="data-horisontal-grid-item">
              <td>
                <b>
                  <Link to={ '/PersonalOrders/OrderHistory/' + orderId } className="internal-page-link-calm">
                    История заказа
                    <i className="fa fa-2x fa-history" aria-hidden="true"></i>
                  </Link>
                </b>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  )
};
PersonalOrdersOrderHeadView.propTypes = {
  orderData: PropTypes.object.isRequired,
}
