import React from 'react';
import PropTypes from 'prop-types';

const WithUnlimitedScroll = (ScrolledComponent) => {
  class UnlimitedScroll extends React.Component {

    constructor(props) {
      super(props);
      this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
      window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(event) {
      if (!this.bottomLine) return;
      const target = this.bottomLine;
      const targetPos = target.offsetTop;
      const winHeight = window.innerHeight;
      const scrollToElem = targetPos - winHeight;
      const winScrollTop = window.pageYOffset || document.documentElement.scrollTop;//event.pageY;
      if (winScrollTop > scrollToElem + 100) {
        // если скролл дошел до элемента bottomLine
        this.props.onLoadMore();
      }
    }

    getBottomLineRef = (node)=>(this.bottomLine = node);

    render() {
      return (
        <div>
          <ScrolledComponent { ...this.props }/>
          { !this.props.allDataShown
            ? <button
              className="bottomLine btn btn-link"
              ref={ this.getBottomLineRef }
              onClick={ this.props.onLoadMore }
            >
              <i className="fa fa-angle-double-down" aria-hidden="true"></i>
            </button>
            : null
          }
        </div>)
    }
  }

  UnlimitedScroll.propTypes = {
    onLoadMore: PropTypes.func.isRequired,
    allDataShown: PropTypes.bool.isRequired,
  }
  return UnlimitedScroll;
}

export default WithUnlimitedScroll;
