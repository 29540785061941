import React, { Component } from 'react';
import { LoadingView }  from '../../Main'
import { OutWaybillDetailsGrid } from '../ClaimsOutWaybillDetails'
import { OutWaybillHeadView } from '../ClaimsOutWaybillDetails/OutWaybillHeadView'
import { validateOutWaybillId } from '../../../../lib/utils';
import { Link } from 'react-router-dom'


const OutWaybillDetailsRowControl = ({dataRow, changeRow})=> {
  if (!dataRow.hasClaim) {
    return (
      <button onClick = { ()=>changeRow(dataRow) }
        className="btn btn-outline-info btn-sm"
        title="Создать претензию к строке">
        <i className="fa fa-comment-o" aria-hidden="true"></i>
      </button>
    );
  } else {
    return (
      <Link to={ "/Claims/Details/" + dataRow.hasClaim } className="internal-page-link-calm" title="Получить информацию по претензии к строке">
        <button className="btn btn-outline-info btn-sm">
          <i className="fa fa-commenting-o" aria-hidden="true"></i>
        </button>
      </Link>
    );
  }
}

export default class ClaimsCreateNew extends Component {
  static outWaybillDetails = {};
  state = {
    outWaybillId: null,
    outWaybillLinesLoaded: false,
    outWaybillLinesCount: null,
    badOutWaybillId: { message: null, code: 0 },
  }

  changeOutWaybillRow = (dataRow) =>{
    this.props.createNewClaimSetOutWaybillLineId(dataRow.lineId,
      dataRow.meds, dataRow.medName, dataRow.serial, dataRow.amount, dataRow.lastReturnDate);
    const { history } = this.props;
    history.push(`/Claims/CreateNew/${dataRow.docId}/${dataRow.lineId}`);
  }

  beginCreateNewClaim(outWaybillId){
    let badOutWaybillId = {code: null, message: null};
    validateOutWaybillId(outWaybillId, badOutWaybillId)
    // Если код валидный и не был задан ранее
    // инициализируем создание претензии от расходного документа
    if ((badOutWaybillId.code === 0) && 
        (this.props.createNewClaimOutWaybillId !== this.props.requestOutWaybillId))
      this.props.createNewClaimSetOutWaybillId(outWaybillId);
  }

  tryRenderOutWaybillDetails(newProps){
    if( newProps.requestOutWaybillDetailsSuccess ){
      let lsData = JSON.parse(localStorage.getItem('OutWaybillDetails'));
      if (lsData !== null && typeof lsData === 'object') {
        ClaimsCreateNew.outWaybillDetails = lsData;
        this.setState({
          outWaybillLinesLoaded: true,
          outWaybillLinesCount: lsData.length,
          outWaybillId: this.props.requestOutWaybillId,
        });
      } else {
        ClaimsCreateNew.outWaybillDetails = {};
        this.setState({
          outWaybillLinesLoaded: true,
          outWaybillLinesCount: 0,
          outWaybillId: null,
        });
      }
    }else{
      this.setState({
        outWaybillLinesLoaded: false,
        outWaybillLinesCount: null,
      });
    }
  }

  componentWillReceiveProps(nextProps){
    this.tryRenderOutWaybillDetails(nextProps);
  }

  componentWillMount(){
    const { OutWaybillId } = this.props.match.params;
    // Если происходит возврат на форму то начинать процесс сначала не требуется
    // код документа будет заполнен в адресной строке
    if (OutWaybillId === undefined ){ // 
      this.props.createNewClaimInitProcess(); 
    }
    else
    {
      this.setState({
        outWaybillId : OutWaybillId,
      });

      this.props.getOutWaybillDetails(this.props.token,
        this.props.customers,
        OutWaybillId);
    }
  }

  handleRowSelection = () => {
  }

  getOutWaybillDetails = e => {
    e.preventDefault();
    let badOutWaybillId = {code: null, message: null};
    validateOutWaybillId(this.inputOutWaybillId.value, badOutWaybillId)
    this.setState({
      badOutWaybillId : badOutWaybillId,
      outWaybillId : this.inputOutWaybillId.value,
    });

    if (badOutWaybillId.code === 0) {
        this.props.history.push(`/Claims/CreateNew/${ this.inputOutWaybillId.value }`);
        this.props.getOutWaybillDetails(this.props.token,
          this.props.customers,
          this.inputOutWaybillId.value);
      e.preventDefault();
    }
  }

  changeOutWaybillId = e => {
    const val = e.target.value;
    this.setState({outWaybillId: val, badOutWaybillId: {message: null, code: 0},});
  }

  componentDidUpdate(prevProps) {
    this.beginCreateNewClaim(prevProps.requestOutWaybillId);
  }

  GridRowControls = (row) => (
    <OutWaybillDetailsRowControl
    dataRow = { 
      {
        docId: row.dataRow.DOC, 
        lineId: row.dataRow.LINE_ID, 
        hasClaim: row.dataRow.HAS_CLAIM,

        meds: row.dataRow.MEDS,
        medName: row.dataRow.FULL_NAME,
        serial: row.dataRow.SERNAME,
        amount: row.dataRow.AMOUNT,
        lastReturnDate: row.dataRow.LAST_RETURN_DATE,
      }
    }
     changeRow = {this.changeOutWaybillRow }
    />
  )


  render() {
    const {
      requestOutWaybillDetailsSuccess,
      requestOutWaybillDetailsInProcess, 
      requestOutWaybillDetailsError } = this.props;

    const { 
      outWaybillId, 
      badOutWaybillId, 
      outWaybillLinesLoaded, 
      outWaybillLinesCount } = this.state;
    return (
      <div>


        { (outWaybillId === null) &&
          <div className="row">
            <div className="col">
              <div className="alert alert-warning" role="alert">
                Укажите код расходного документа, в котром обнаружены расхождения
              </div>
            </div>
          </div>
        }

        { requestOutWaybillDetailsError&&
          <div className="row">
            <div className="col">
              <font color="red">
                <p>Ошибка получения данных о деталях расходного документа...</p>
                <p>Сервис временно не доступен.</p>
              </font>
            </div>
          </div>
        }

        {
          !requestOutWaybillDetailsError&&
          requestOutWaybillDetailsInProcess&&
          <div className="row">
            <div className="col">
              <LoadingView text={'Получение данных по расходному документу...'}/>
            </div>
          </div>
        }

        {
          !requestOutWaybillDetailsInProcess&&
          <div className="row">
            <div className="col">
              <form onSubmit={ this.getOutWaybillDetails }>
                { badOutWaybillId.code!==0 &&
                    <span className="text-danger">{ badOutWaybillId.message }</span>
                }
                <div className="input-group mb-3">
                  <input type="text"
                    ref={ (input)=>{this.inputOutWaybillId = input} }
                    className="form-control input-lg"
                    onChange={ this.changeOutWaybillId } 
                    required="required"
                    minLength="6"
                    maxLength="12"
                    value={ outWaybillId||"" }
                    placeholder={(outWaybillId === null)?"Код расходного документа *":"Код расходного документа"}
                    title="Уникальный идентификатор расходного документа"/>
                  <div className="input-group-append">
                    <button type="submit"
                      disabled={ this.props.registrationInProcess }
                      className="btn btn-outline-info btn-block">
                      Получить данные
                    </button>
                  </div>
                </div>
              </form>
              { (outWaybillId === null) &&
                <p>* Уникальный идентификатор расходного документа, например E5242616/<b>20468236</b> (выделен жирным)</p>
              }
            </div>
          </div>
        }

        {
          requestOutWaybillDetailsSuccess&&outWaybillLinesLoaded &&
          <div className="row">
            <div className="col">
              <OutWaybillHeadView data={ ClaimsCreateNew.outWaybillDetails }/>
              <hr/>

              { outWaybillLinesLoaded && (outWaybillLinesCount !== 0) &&
                <div className="row">
                  <div className="col">
                  <div className="alert alert-warning" role="alert">
                    Укажите строку расходного документа, в которой присутствуют расхождения. Для этого нажмите
                       &nbsp; <i className="fa fa-comment-o" title="Создать претензию по строке" aria-hidden="true"></i>
                    <br/>
                    Для просмотра ранее созданной претензии к строке нажмите
                       &nbsp; <i className="fa fa-commenting-o" title="Получить информацию по претензии к строке" aria-hidden="true"></i>
                    </div>
                    </div>
                  </div>
              }

              <OutWaybillDetailsGrid
                gridData= { ClaimsCreateNew.outWaybillDetails }
                onSelectItem= { this.handleRowSelection }
                RowControls= { this.GridRowControls }
              />
            </div>
          </div>
        }

        {
          requestOutWaybillDetailsSuccess && outWaybillLinesLoaded && (outWaybillLinesCount === 0) &&
          <p>Отсутствуют детали по расходному документу, вероятно, указан не верный код.<br/>
            Пожалуйста, уточните код расходного документа.
          </p>
        }

      </div>
    );
  }
}
/*
AMOUNT: 2
DDATE: "2017-06-12T21:00:00.000Z"
DOC: 14858745
DSUM1: 2027.64
FULL_NAME: "Нитрокор табл. подъязычные 0,5мг №40 (Фармстандарт-Лексредства, Россия)"
LST_CNT: 15
MEDS: 334250
SERNAME: "100317"
*/