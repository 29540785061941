import React from 'react';
import { Link } from 'react-router-dom'
export const PersonalOrdersStatusesInfo = () => (
  <div>
    <h3>Статусы заказа</h3>
    Сделав заказ в системе zVision, Вы всегда сможете получать оперативную и достоверную информацию, касающуюся Вашего заказа.
 Заказ выполняется в несколько этапов, каждому из которых присвоен отдельный статус
    <br/><br/>
    <p>Подробные статусы заказа</p>
    <hr/>
    <p><b>«Передан на обработку»</b> — мы получили Ваш заказ и начали работу над ним</p>
    <p><b>«Обрабатывается»</b> - мы обрабатываем Ваш заказ. На этом этапе мы резервируем товар под Ваш заказ, готовим и высылаем необходимые электронные документы, комплектуем и готовим его к отправке по указанному Вами адресу или выдаче</p>
    <p><b>«Доставка»</b> - Ваш заказ находится в пути. Ожидайте доставки в согласованное с Вами время</p>
    <p><b>«Выполнен»</b> - Ваш заказ выполнен. Все необходимые документы оформлены и переданы</p>
    <p><b>«Отменён»</b> - заказ или был отменён, поскольку нам не удалось зарезервировать по нему ни одного товара, или есть причина, которую Вы можете уточнить у своего менеджера</p>
    <hr/>
    <p>Заказы, которые находятся в одном из статусов <b>«Передан на обработку»</b>, <b>«Обрабатывается»</b> или <b>«Доставка»</b> являются <b>"Активными"</b>.</p>
    <p>Наблюдать за состоянием таких заказов можно в разделе
      <Link to="/PersonalOrders/Orders" className="internal-page-link-calm">
        <b> «Активные заказы»</b>
      </Link>
    </p>
    <p><b>Выполненные</b> заказы, и заказы находящиеся в статусе <b>«Отменён»</b> отображаются в разделе история
      <Link to="/PersonalOrders/OrdersHistory" className="internal-page-link-calm">
        <b> «История заказов»</b>
      </Link>
    </p>
  </div>
)
