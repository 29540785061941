import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { CartControlsView } from '../components/Desktop/Main'

class CartControls extends Component {
  static propTypes = {
    dataRow: PropTypes.object.isRequired,
    cart: PropTypes.object.isRequired,
    addToCart: PropTypes.func.isRequired,
    removeFromCart: PropTypes.func.isRequired,
  };

  state = {
    currentVal: 0
  }

  onIncGoods = () => {
    const value = this.state.currentVal;
    const {goodsId, goodsName, goodsPrice, goodsLine} = this.props.dataRow;
    const maxAvailableAmo = goodsLine.AMOUNT;
    if (value > maxAvailableAmo) return;
    this.props.addToCart(
      goodsId,
      goodsName,
      this.state.currentVal + 1,
      goodsPrice,
      goodsLine,
    );
    this.setState({currentVal : this.state.currentVal + 1})
  }

  onDecGoods = () => {
    if (!(this.state.currentVal - 1 >= 0)) return;
    const {goodsId, goodsName, goodsPrice, goodsLine} = this.props.dataRow;
    this.props.addToCart(
      goodsId,
      goodsName,
      this.state.currentVal - 1,
      goodsPrice,
      goodsLine,
    );
    this.setState({currentVal : this.state.currentVal - 1})
  }

  onEdtGoods = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!(value >= 0)) return;
    const {goodsId, goodsName, goodsPrice, goodsLine} = this.props.dataRow;
    const maxAvailableAmo = goodsLine.AMOUNT;
    if (value > maxAvailableAmo) return;
    this.props.addToCart(
      goodsId,
      goodsName,
      value,
      goodsPrice,
      goodsLine,
    );
    this.setState({currentVal : value})
  }

  componentWillMount(){
    if (this.props.dataRow === undefined) return 0;
    if (this.props.cart.items === undefined) return 0;
    // Поиск в текущем состоянии корзины элемента, код которого соответствует коду текущего элемента
    let el = this.props.cart.items.filter((item)=>item.goodsId===this.props.dataRow.goodsId);
    // Извлекаем из корзины текущее заказанное количество для найденного в ней элемента и отрисовываем его
    if (el.length>0) this.setState({currentVal : el[0].goodsAmount});
  }

  render () {
    return (
    <CartControlsView 
      onInc = {this.onIncGoods}
      onDec = {this.onDecGoods}
      onEdit = {this.onEdtGoods}
      currentVal = { this.state.currentVal }
   />
  )}
}

export default CartControls;