import { connect } from 'react-redux';
import { UserProfileNotification } from '../../components/Desktop/UserProfile';
import { getNewNotifications, sendReadNotify, getNotifications, sendFollowNotify } from '../../actions/notifications';

const mapStateToProps = (state) => ({
  authorized: state.authorization.authorized,
  token: state.authorization.token,
  login: state.authorization.login,
  customers: state.authorization.userId,

  /* Эти свойства не должны имплементироваться в данный модуль
  в противном случае возможно возникновение зацикливания
  readNotifyInProcess: state.notifications.readNotifyInProcess,
  readNotifyError: state.notifications.readNotifyError,
  readNotifySuccess: state.notifications.readNotifySuccess,
  readNotifyStatus: state.notifications.readNotifyStatus,
  */

  requestNewNotificationInProcess: state.notifications.requestNewNotificationInProcess,
  requestNewNotificationError: state.notifications.requestNewNotificationError,
  requestNewNotificationSuccess: state.notifications.requestNewNotificationSuccess,
  requestNewNotificationStatus: state.notifications.requestNewNotificationStatus,

  requestNotificationInProcess: state.notifications.requestNotificationInProcess,
  requestNotificationError: state.notifications.requestNotificationError,
  requestNotificationSuccess: state.notifications.requestNotificationSuccess,
  requestNotificationStatus: state.notifications.requestNotificationStatus,

});

const mapDispathToProps = (dispath) => {
  return {
    getNewNotifications:
      ( token )=>
        dispath(getNewNotifications(token)),
    getNotifications:
      ( token )=>
        dispath(getNotifications(token)),
    sendReadNotify:
      ( token, notifyId )=>
        dispath(sendReadNotify(token, notifyId)),
    sendFollowNotify:
      ( token, notifyId )=>
        dispath(sendFollowNotify(token, notifyId)),
  };
};


export default connect(
  mapStateToProps, mapDispathToProps
)(UserProfileNotification)
