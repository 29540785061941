import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ReceivablesInfoFilter extends Component {
  static propTypes = {
    getReceivables: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    customers: PropTypes.number,
  };

  doGetReceivables = ()=> {
    this.props.getReceivables(this.props.token,
      this.props.customers);
  }

  render() {
    const { requestReceivablesInProcess } = this.props;
    return (
      <div>
        <button onClick = { this.doGetReceivables }
          disabled = { requestReceivablesInProcess }
          className="btn btn-outline-info btn-sm btn-block">
          <i className="fa fa-2x fa-refresh"/>
        </button>
      </div>
    );
  }
}
