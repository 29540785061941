
import React, { Component } from 'react';
import { PersonalOrdersOrderHistoryGrid } from '../PersonalOrdersOrderHistoryGrid'
import { LoadingView }  from '../../Main'

export default class PersonalOrderHistory extends Component {
  static orderHistory = {};

  state = {
    orderHistoryLoaded: false,
  }

  tryRenderOrderHistory(props){
    if( props.requestOrderHistorySuccess ){
      let orderHistory = JSON.parse(localStorage.getItem('OrderHistoryItems'));
      if (orderHistory !== null && typeof orderHistory === 'object') {
        if (orderHistory && Array.isArray(orderHistory)) {
          PersonalOrderHistory.orderHistory = orderHistory;
          this.setState({
            orderHistoryLoaded: true,
          });
        }else{
          this.setState({
            orderHistoryLoaded: false,
          });
        }
      }else{
          this.setState({
            orderHistoryLoaded: false,
          });
      }
    }
  }

  doGetOrderHistory(orderId){
    this.props.doGetOrderHistory(this.props.token,
      this.props.customers,
      orderId);
  }

  componentWillReceiveProps(nextProps){
    this.tryRenderOrderHistory(nextProps);
  }
  componentWillMount(){
    const { OrderId } = this.props.match.params;
    this.doGetOrderHistory( OrderId );
  }

  handleRowSelection = ()=> {

  }

  refreshOrderHistory(){
    const { OrderId } = this.props.match.params;
    this.doGetOrderHistory( OrderId );
  }

  render() {
    const { OrderId } = this.props.match.params;
    const {
      requestOrderHistoryError,
      requestOrderHistoryInProcess,
      requestOrderHistorySuccess
    } = this.props;

    return (
      <div>
        {
          requestOrderHistoryError&&
          <div>
            <font color="red">
              <p>Ошибка получения данных истории заказа...</p>
            </font>
          </div>
        }
        {
          !requestOrderHistoryError&&
          requestOrderHistoryInProcess&&
          <LoadingView text={'Получение данных по заказу #' + OrderId + '...'}/>
        }
        {
          requestOrderHistorySuccess&&this.state.orderHistoryLoaded&&
          <div>
            <h3>История заказа</h3>
            <p></p>
            <h5><b> Заказ # { OrderId } </b> </h5>
            <PersonalOrdersOrderHistoryGrid
              gridData= { PersonalOrderHistory.orderHistory }
              onSelectItem= { this.handleRowSelection }
              RowControls= { this.GridRowControls }
            />
          </div>
        }
      </div>
    );
  }
}
