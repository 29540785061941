import React, { Component } from 'react';

import { DataGrid } from '../../Main'
import WithUnlimitedScroll from '../../Main/UnlimitedScroll/UnlimitedScroll'
import { moneyFormat } from '../../../../lib/utils';
import { ReceivablesInfoGridHeader } from './'
import PropTypes from 'prop-types';
import { ReceivablesInfoGridSearch } from './ReceivablesInfoGridSearch'

const ScrolledDataGrid = WithUnlimitedScroll(DataGrid);
const InitialInitRows = 8 // Количество строк при инициализации
const ShowLinesPerStep = 20; // Количество строк при подгрузке

export default class ReceivablesInfoGrid extends Component {
  static propTypes = {
    gridData: PropTypes.array.isRequired,
    onSelectItem: PropTypes.func.isRequired,
    RowControls: PropTypes.func,
  };

  state = {
    totalRowsCount: null,
    filteredRows: null,
    shownRows : InitialInitRows,
    shownPerc: 0,
    receivablesSumByFiltered: 0,
  };
    
/**
 * Получить процент просмотренных данных
 * @param {*Показано строк} shownRows 
 * @param {*Всего строк для отображения} filteredRows 
 */
  getShownPerc(shownRows,filteredRows){
    if(filteredRows===0) return 0;
    return Math.round( shownRows * 100 / (filteredRows))
  }

  
  getSumByFiledName(data, fieldName){
    const sumByFiltered = 
    data.reduce(function(fieldVal, current) {
      return { 
        sum: fieldVal.sum + current[fieldName],
      }
    }, { sum: 0});
    return sumByFiltered.sum;
  }

  /**
   * Смена состояния после фильтрации данных
   * @param {*Состояние} config 
   */
  updateData(config) {
    const { data } = config;
    this.setState({
      ...config, 
      receivablesSumByFiltered: this.getSumByFiledName(data,'SUM_NOT_PAY'),
      filteredRows :data.length, 
      shownRows: data.length < InitialInitRows ? data.length : InitialInitRows,
      shownPerc: this.state.shownRows===0?0:
        this.getShownPerc(
          data.length < InitialInitRows ? data.length : InitialInitRows,
            data.length),
    });
  }

  /**
   * Смена состояния после отображения очередной порции данных
   */
  showMoreLines() {
    if (this.state.shownRows >= this.state.filteredRows) return;
    // рассчет текущего шага для отображения, на случай когда осталось отобразить меньше чем ShowLinesPerStep
    let showLinesPerCurrentStep = Math.min((this.state.filteredRows-this.state.shownRows), ShowLinesPerStep);
    this.setState({
      shownRows: this.state.shownRows + showLinesPerCurrentStep,
      shownPerc: this.getShownPerc(this.state.shownRows + showLinesPerCurrentStep, 
        this.state.filteredRows)
    });
  }

  componentWillMount(){
    let totalRowsCount = this.props.gridData.length;
    this.updateData({
        totalRowsCount: totalRowsCount,
        activeLine: 0,
        term : '',
        data : this.props.gridData});
  }  

  handleItemSelection = (item) => {
    this.setState({activeLine : item});
    this.props.onSelectItem(item);
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col">
            {(this.state.term.length > 0)&&
                <div>
                  <p>показано документов - <b>{this.state.filteredRows}</b> <br/>
                  сумма по выбранным документам - <b>{moneyFormat(this.state.receivablesSumByFiltered)} руб.</b></p>
                  <p/>
                </div>
            }
          </div>
        </div>

        <div className="row">
          <div className="col">
            {
            <ReceivablesInfoGridSearch
              term={ this.state.term }
              data={ this.props.gridData } // Исходный набор данных
              onUpdate={ this.updateData.bind(this) }
              searchField={ 'FULL_NAME_CUST' }
            />}
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col">
            {this.state.data.length>0 &&
            <div className="receivables-info-grid">
            <ScrolledDataGrid
              header = { ReceivablesInfoGridHeader }
              body = { this.state.data }
              onItemClick = { this.handleItemSelection }
              limit = { this.state.shownRows }
              rowControls = { this.props.RowControls }
              onLoadMore = {this.showMoreLines.bind(this)}
              allDataShown = { this.state.shownRows === this.state.filteredRows }/>
            </div>
            }
          </div>
        </div>

        <div className="row">
          <div className="col">
            {this.state.data.length===0 &&  this.state.term &&
            <p>Грузополучатель <b>"{ this.state.term }"</b> отсутсвует</p>
            }
          </div>
        </div>

        <div className="progress fixed-bottom " style={{height: `3px`}}>
            {this.state.shownPerc<100
              ?<div className="progress-bar" role="progressbar" style={{width: `${this.state.shownPerc}%`}}></div>
              :null
            }
        </div>
      </div>
    
    )
  }
}
