export const OrderHistoryGridHeader = [
  { caption: 'Статус',
    fieldName: 'HISTORY_TITLE_ACTION',
    href: '/PersonalOrders/OrderStatuses',
    hint: 'Статус обработки заказа',
    rowHrefTemplate: '/PersonalOrders/OrderStatuses'},
  { caption: 'Дата',
    hint: 'Дата изменения статуса',
    fieldName: 'HISTORY_TIME',
    isDateTimeFormat: true,
  },
];


export { default as PersonalOrdersOrderHistoryGrid } from './PersonalOrdersOrderHistoryGrid';

/*
История заказа
[
  {
    "HISTORY_TITLE_ACTION": "Передан на обработку",
    "HISTORY_TIME": "2018-07-02T12:06:39.000Z"
  },
  ...
]

*/
