import { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './styles';

export default class ModalView extends Component {
  static propTypes = {
    modalRoot: PropTypes.object,
    children: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.modalRoot = this.props.modalRoot;
  }

  componentDidMount() {
    /*
      Элемент портала вставлен в дерево DOM после того, как потомки Modal
    были монтированы, что означает, что потомки будут монтированы в отдельный
    узел DOM.
      Если дочерний компонент требует присоединения к дереву DOM сразу после
    его монтирования, например, для измерения узла DOM или использования
    «autoFocus» в потомке, добавьте состояние в Modal и отрисуйте дочерние
    элементы, после того, как Modal будет вставлен в DOM дерево.
    */
    this.modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    this.modalRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      this.el
    );
  }
}
