import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles';

export default class DropDownObjects extends Component {
  static propTypes = {
    buttonClassName: PropTypes.string,
    buttonTitle: PropTypes.string,
    items: PropTypes.array.isRequired,
    onSelectItem: PropTypes.func,
    listField: PropTypes.string.isRequired,
    keyField: PropTypes.string.isRequired,
    activeItemKey: PropTypes.number
  }

  state = {
    visible: false,
    title: undefined,
    activeItemId: undefined
  }

  onToggleView(){
    if (this.props.enabled)
      this.setState({
        visible: !this.state.visible
      });
  }

  onChangeItem(activeItem){
    const { keyField, listField} = this.props;
    this.setState({
      activeItem: activeItem,
      title: activeItem[this.props.listField],
      visible: false,
      activeItemId: activeItem[keyField],
      activeItemName: activeItem[listField],
    });
    this.props.onSelectItem(activeItem);
  }

  componentWillMount() {
    const {items, keyField, activeItemKey, listField} = this.props;
    const needToActiveItem = items.filter((item)=> item[keyField] === activeItemKey)[0];
    if (needToActiveItem)
    this.setState({
        activeItem: needToActiveItem,
        title: needToActiveItem.fullName,
        visible: false,
        activeItemId: needToActiveItem[keyField],
        activeItemName: needToActiveItem[listField],
    });
  }


  render(){
    const { title, visible, activeItemName } = this.state;
    let buttonUpDownStyleClass= visible ? ' dropup ' : ' dropdown '; // стрелочка на кнопке
    let className=" d-block ";
    if (this.state.visible === false) className = " d-none ";
    const { listField, activeItemKey } = this.props;
    return(
      <div className={ "drop-down-box nav-item " + buttonUpDownStyleClass } >
        <div className={"text-truncate nav-link dropdown-toggle dropup " + this.props.buttonClassName }
          onClick={this.onToggleView.bind(this)} id="dropdownMenuButton">
          { !this.props.enabled && <i className="fa fa-circle-o-notch fa-spin fa-fw"></i> }
          { title||this.props.buttonTitle }
        </div>
        <ul className={"drop-down-objects-list list-group" + className} aria-labelledby="dropdownMenuButton">
        {
          this.props.items.map((items, index) =>{
            let activeItemClass = "";
            if (activeItemKey === items.customers) activeItemClass = " drop-down-objects-active ";
            return (
            <li className={ "drop-down-objects list-group-item " + activeItemClass } key={ index }
                onClick={()=>this.onChangeItem(items)}
            >
              {items[listField]}
            </li>);
            })
        }
        </ul>
      </div>
    );
  }
}
