import React, { Component } from 'react';
export default class PersonalOrdersOrderFilter extends Component {
  render() {
    const { history } = this.props;
    return (
      <div>
        <button
          type="button"
          className="btn btn-link"
          onClick={ history.goBack.bind(this) } >
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
          &nbsp;Заказы
        </button>
      </div>
    );
  }
}
