
import { apiCommandPOSTRegistration, apiCommandGETEmailVerify } from '../constants/registration';
import { validationJSON, makeJSON } from '../lib/utils'

export function requestRegistration( titleName, fullName, inn, realm, username, email ) {
  return {
    type: 'REGISTRATION_REQUEST',
    inn: inn,
    titleName: titleName,
    fullName: fullName,
    realm: realm,
    username: username,
    email: email,
  }
}

export function registrationSuccess(userId) {
  return {
    type: 'REGISTRATION_SUCCESS',
    userId: userId,
  }
}

export function registrationError(errorCode, errorMessage) {
  return {
    type: 'REGISTRATION_ERROR',
    error: {errorCode, errorMessage},
  }
}

export function registrationComplete() {
  return {
    type: 'REGISTRATION_COMPLETE',
  }
}

/* eslint-disable */
/* eslint-enable */
export function registrationUser(titleName, fullName, inn, realm, username, email, password) {
  return function(dispatch) {
    dispatch(requestRegistration(titleName, fullName, inn, realm, username, email));
    let httpStatusCode = null;
    let apiErrorCode = null;
    let apiErrorMessage = null;

    return fetch(apiCommandPOSTRegistration, {
      // cors - Сервис API может быть на любом сервере, same-origin - Тот же самый сервер
      mode: 'cors',
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: makeJSON({
        realm: realm,
        fullName: fullName,
        titleName: titleName,
        inn: inn,
        username: username,
        password: password,
        email: email,
        ztype: 101,
      }),
    })
      .then((response)=>{
        // Извлекаем код ответа HTTP
        httpStatusCode = response.status;
        return response;
      })
      .then(validationJSON) // Раскрывает промис
      .then((data)=> {
        // data содержит ответ от API
        if (httpStatusCode === 200)
          dispatch(registrationSuccess( data.clientId))
        else {
          // data содержит расшифровку ошибки в объекте error
          apiErrorCode = data.error.code;
          apiErrorMessage = data.error.message;
          const error = new Error()
          throw error
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === 'development') console.log('registrationUser() - Error: ', error);
        dispatch(
          registrationError(
            apiErrorCode || httpStatusCode,
            apiErrorMessage || error.message
          ));
      })
  }
}

export function emailVerifyRequest(userId) {
  return {
    type: 'REGISTRATION_EMAIL_VERIFY_REQUEST',
    userId: userId,
  }
}

export function emailVerifySuccess(userId) {
  return {
    type: 'REGISTRATION_EMAIL_VERIFY_SUCCESS',
    userId: userId,
  }
}

export function EmailVerifyError(errorCode, errorMessage) {
  return {
    type: 'REGISTRATION_EMAIL_VERIFY_ERROR',
    error: {errorCode, errorMessage},
  }
}

/* eslint-disable */
/* eslint-enable */
export function doEmailVerifycation(token, userId) {
  return function(dispatch) {
    dispatch(emailVerifyRequest(userId));
    let httpStatusCode = null;
    let apiErrorCode = null;
    let apiErrorMessage = null;
    return fetch(apiCommandGETEmailVerify + '?uid=' + userId + '&token=' + token, {
      // cors - Сервис API может быть на любом сервере, same-origin - Тот же самый сервер
      mode: 'cors',
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response)=>{
        // Извлекаем код ответа HTTP
        httpStatusCode = response.status;
        if (httpStatusCode === 204)
          dispatch(emailVerifySuccess(userId));
        return response;
      })
      .then((response)=>{
        if (httpStatusCode !== 204)
          return validationJSON(response);
      }) // Раскрывает промис
      .then((data)=> {
        if ( data !== undefined ) {
          apiErrorCode = data.error.code;
          apiErrorMessage = data.error.message;
          const error = new Error();
          throw error;
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === 'development') console.log('doEmailVerifycation() - Error: ', error);
        dispatch(
          EmailVerifyError(
            apiErrorCode || httpStatusCode,
            apiErrorMessage || error.message
          ));
      })
  }
}
