import { apiCommandGETClaimsList, apiCommandGETClaimDetails,
  apiCommandGETOutWaybillDetails, apiCommandPOSTCreateClaim,
  apiCommandPATCHUndoClaim } from '../../constants/claims';
import { validationJSON, makeJSON } from '../../lib/utils'
import { checkAuthorization } from '../authorization';

// Получение списка претензий
export function requestClaimsList(customers) {
  return {
    type: 'CLAIMS_LIST_REQUEST',
    customers: Number(customers),
  }
}

export function requestClaimsListSuccess(data) {
  data = data.ClaimsList.slice(0, 1001);
  try {
    window.localStorage.setItem('ClaimsList', JSON.stringify(data));
  } catch (e) {
    alert( 'Ошибка при работе с локальным хранилищем браузера ', e );
  }
  return {
    type: 'CLAIMS_LIST_REQUEST_SUCCESS',
  }
}

export function requestClaimsListError(errorCode, errorMessage) {
  return {
    type: 'CLAIMS_LIST_REQUEST_ERROR',
    error: {errorCode, errorMessage},
  }
}

export function getClaimsList(token, customers) {
  return function(dispatch) {
    dispatch(requestClaimsList(customers));
    let httpStatusCode = null;
    let apiErrorCode = null;
    let apiErrorMessage = null;

    return fetch(apiCommandGETClaimsList + '?customers=' + customers + '&access_token=' + token, {
      // cors - Сервис API может быть на любом сервере, same-origin - Тот же самый сервер
      mode: 'cors',
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response)=>{
        // Извлекаем код ответа HTTP
        httpStatusCode = response.status;
        return response;
      })
      .then((response) => checkAuthorization(dispatch, response))
      .then(validationJSON) // Раскрывает промис
      .then((data)=> {
        // data содержит ответ от API
        if (httpStatusCode === 200) {
          dispatch(requestClaimsListSuccess(data));
        }
        else {
          // data содержит расшифровку ошибки в объекте error
          apiErrorCode = data.error.code;
          apiErrorMessage = data.error.message;
          const error = new Error()
          throw error
        }
      })
      .catch((error) => {
        dispatch(
          requestClaimsListError(
            apiErrorCode || httpStatusCode,
            apiErrorMessage || error.message
          ));
      })
  }
}


// Получение деталей претензии
export function requestClaimDetails(claimId) {
  return {
    type: 'CLAIM_DETAILS_REQUEST',
    claimId: claimId,
  }
}

export function requestClaimDetailsSuccess(data) {
  data = data.ClaimDetails.slice(0, 1001);
  try {
    window.localStorage.setItem('ClaimDetails', JSON.stringify(data));
  } catch (e) {
    alert( 'Ошибка при работе с локальным хранилищем браузера ', e );
  }
  return {
    type: 'CLAIM_DETAILS_REQUEST_SUCCESS',
  }
}

export function requestClaimDetailsError(errorCode, errorMessage) {
  return {
    type: 'CLAIM_DETAILS_REQUEST_ERROR',
    error: {errorCode, errorMessage},
  }
}

export function getClaimDetails(token, customers, claimId) {
  return function(dispatch) {
    dispatch(requestClaimDetails(customers));
    let httpStatusCode = null;
    let apiErrorCode = null;
    let apiErrorMessage = null;

    return fetch(apiCommandGETClaimDetails + '?customers=' + customers + '&claimId=' + claimId + '&access_token=' + token, {
      // cors - Сервис API может быть на любом сервере, same-origin - Тот же самый сервер
      mode: 'cors',
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response)=>{
        // Извлекаем код ответа HTTP
        httpStatusCode = response.status;
        return response;
      })
      .then((response) => checkAuthorization(dispatch, response))
      .then(validationJSON) // Раскрывает промис
      .then((data)=> {
        // data содержит ответ от API
        if (httpStatusCode === 200) {
          dispatch(requestClaimDetailsSuccess(data));
        }
        else {
          // data содержит расшифровку ошибки в объекте error
          apiErrorCode = data.error.code;
          apiErrorMessage = data.error.message;
          const error = new Error()
          throw error
        }
      })
      .catch((error) => {
        dispatch(
          requestClaimDetailsError(
            apiErrorCode || httpStatusCode,
            apiErrorMessage || error.message
          ));
      })
  }
}


// Получение деталей расходного документа
export function initRequestOutWaybillDetails() {
  return {
    type: 'OUT_WAYBILL_DETAILS_INIT',
  }
}

export function requestOutWaybillDetails(outWaybillId) {
  return {
    type: 'OUT_WAYBILL_DETAILS_REQUEST',
    outWaybillId: Number(outWaybillId),
  }
}

export function requestOutWaybillDetailsSuccess(data) {
  data = data.OutWaybillDetails.slice(0, 1001);
  try {
    window.localStorage.setItem('OutWaybillDetails', JSON.stringify(data));
  } catch (e) {
    alert( 'Ошибка при работе с локальным хранилищем браузера ', e );
  }
  return {
    type: 'OUT_WAYBILL_DETAILS_REQUEST_SUCCESS',
  }
}

export function requestOutWaybillDetailsError(errorCode, errorMessage) {
  return {
    type: 'OUT_WAYBILL_DETAILS_REQUEST_ERROR',
    error: {errorCode, errorMessage},
  }
}

export function getOutWaybillDetails(token, customers, waybillId) {
  return function(dispatch) {
    dispatch(requestOutWaybillDetails(waybillId));
    let httpStatusCode = null;
    let apiErrorCode = null;
    let apiErrorMessage = null;

    return fetch(apiCommandGETOutWaybillDetails + '?customers=' + customers + '&waybillId=' + waybillId + '&access_token=' + token, {
      // cors - Сервис API может быть на любом сервере, same-origin - Тот же самый сервер
      mode: 'cors',
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response)=>{
        // Извлекаем код ответа HTTP
        httpStatusCode = response.status;
        return response;
      })
      .then((response) => checkAuthorization(dispatch, response))
      .then(validationJSON) // Раскрывает промис
      .then((data)=> {
        // data содержит ответ от API
        if (httpStatusCode === 200) {
          dispatch(requestOutWaybillDetailsSuccess(data));
        }
        else {
          // data содержит расшифровку ошибки в объекте error
          apiErrorCode = data.error.code;
          apiErrorMessage = data.error.message;
          const error = new Error()
          throw error
        }
      })
      .catch((error) => {
        dispatch(
          requestOutWaybillDetailsError(
            apiErrorCode || httpStatusCode,
            apiErrorMessage || error.message
          ));
      })
  }
}


// Формирование претензии
export function createNewClaimInitProcess() {
  return {
    type: 'CREATE_NEW_CLAIM_INIT_PROCESS',
  }
}

export function createNewClaimSetOutWaybillId(outWaybillId) {
  return {
    type: 'CREATE_NEW_CLAIM_SET_OUT_WAYBILL_ID',
    outWaybillId: Number(outWaybillId),
  }
}

export function createNewClaimSetOutWaybillLineId(outWaybillLineId,
  meds, medName, serial, amount, lastSerialReturnDate ) {
  return {
    type: 'CREATE_NEW_CLAIM_SET_OUT_WAYBILL_LINE_ID',
    outWaybillLineId: Number(outWaybillLineId),
    meds: Number(meds),
    amount: Number(amount),
    medName,
    serial,
    lastSerialReturnDate,
  }
}

export function requestCreateNewClaim() {
  return {
    type: 'CREATE_NEW_CLAIM_REQUEST',
  }
}

export function requestCreateNewClaimSuccess(newClaimId) {
  return {
    type: 'CREATE_NEW_CLAIM_REQUEST_SUCCESS',
    newClaimId: Number(newClaimId),
  }
}

export function requestCreateNewClaimError(errorCode, errorMessage) {
  return {
    type: 'CREATE_NEW_CLAIM_REQUEST_ERROR',
    error: {errorCode, errorMessage},
  }
}

// Подготовка объекта Претензия к сериализации
export function getApiClaimBody(claimData) {
  return claimData;
}

export function createNewClaim(token, claimData) {
  return function(dispatch) {
    dispatch(requestCreateNewClaim());
    let httpStatusCode = null;
    let apiErrorCode = null;
    let apiErrorMessage = null;

    return fetch(apiCommandPOSTCreateClaim + '?access_token=' + token, {
      // cors - Сервис API может быть на любом сервере, same-origin - Тот же самый сервер
      mode: 'cors',
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: makeJSON(getApiClaimBody(claimData)),
    })
      .then((response)=>{
        // Извлекаем код ответа HTTP
        httpStatusCode = response.status;
        return response;
      })
      .then((response) => checkAuthorization(dispatch, response))
      .then(validationJSON) // Раскрывает промис
      .then((data)=> {
        // data содержит ответ от API
        if (httpStatusCode === 200)
          dispatch(requestCreateNewClaimSuccess( data.ClaimId))
        else {
          // data содержит расшифровку ошибки в объекте error
          apiErrorCode = data.error.code;
          apiErrorMessage = data.error.message;
          const error = new Error()
          throw error
        }
      })
      .catch((error) => {
        dispatch(
          requestCreateNewClaimError(
            apiErrorCode || httpStatusCode,
            apiErrorMessage || error.message
          ));
      })
  }
}


// Отмена претензии
export function initUndoClaim() {
  return {
    type: 'UNDO_CLAIM_INIT',
  }
}

export function requestUndoClaim(claimId) {
  return {
    type: 'UNDO_CLAIM_REQUEST',
    claimId: Number(claimId),
  }
}

export function requestUndoClaimSuccess(data) {
  data = data.undoClaimResult.slice(0, 1001);
  try {
    window.localStorage.setItem('UndoClaimDetails', JSON.stringify(data));
  } catch (e) {
    alert( 'Ошибка при работе с локальным хранилищем браузера ', e );
  }
  return {
    type: 'UNDO_CLAIM_REQUEST_SUCCESS',
  }
}

export function requestUndoClaimError(errorCode, errorMessage) {
  return {
    type: 'UNDO_CLAIM_REQUEST_ERROR',
    error: {errorCode, errorMessage},
  }
}

export function undoClaim(token, customers, claimId) {

  return function(dispatch) {
    dispatch(requestUndoClaim(claimId));
    let httpStatusCode = null;
    let apiErrorCode = null;
    let apiErrorMessage = null;

    return fetch(apiCommandPATCHUndoClaim + '?access_token=' + token, {
      // cors - Сервис API может быть на любом сервере, same-origin - Тот же самый сервер
      mode: 'cors',
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: makeJSON({
        customers: Number(customers),
        claimId: Number(claimId),
      }),
    })
      .then((response)=>{
        // Извлекаем код ответа HTTP
        httpStatusCode = response.status;
        return response;
      })
      .then((response) => checkAuthorization(dispatch, response))
      .then(validationJSON) // Раскрывает промис
      .then((data)=> {
        // data содержит ответ от API
        if (httpStatusCode === 200) {
          dispatch(requestUndoClaimSuccess(data));
        }
        else {
          // data содержит расшифровку ошибки в объекте error
          apiErrorCode = data.error.code;
          apiErrorMessage = data.error.message;
          const error = new Error()
          throw error
        }
      })
      .catch((error) => {
        dispatch(
          requestUndoClaimError(
            apiErrorCode || httpStatusCode,
            apiErrorMessage || error.message
          ));
      })
  }
}
