import { apiCommandPOSTSaveClientNeeds } from '../constants/covid19';
import { validationJSON, makeJSON } from '../lib/utils'

export function saveClientNeedsRequest( personFullName,
  personEmail, personPhone, companyName, companyINN, regionName,
  deliveryAddress, needsData ) {
  return {
    type: 'SAVE_CLIENT_NEEDS_REQUEST',
    personEmail, personPhone, companyName, companyINN, regionName,
    deliveryAddress, needsData,
  }
}

export function saveClientNeedsSuccess(needsId) {
  return {
    type: 'SAVE_CLIENT_NEEDS_SUCCESS',
    needsId: needsId,
  }
}

export function saveClientNeedsError(errorCode, errorMessage) {
  return {
    type: 'SAVE_CLIENT_NEEDS_ERROR',
    error: {errorCode, errorMessage},
  }
}

export function saveClientNeeds( personFullName, personEmail, personPhone,
  companyName, companyINN, regionName, deliveryAddress, needsData
) {
  return function(dispatch) {
    dispatch(saveClientNeedsRequest(  personFullName,
      personEmail, personPhone, companyName, companyINN, regionName,
      deliveryAddress, needsData));
    let httpStatusCode = null;
    let apiErrorCode = null;
    let apiErrorMessage = null;

    return fetch(apiCommandPOSTSaveClientNeeds, {
      // cors - Сервис API может быть на любом сервере, same-origin - Тот же самый сервер
      mode: 'cors',
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: makeJSON({
        personFullName: personFullName,
        personEmail: personEmail,
        personPhone: personPhone,
        companyName: companyName,
        companyINN: companyINN,
        regionName: regionName,
        deliveryAddress: deliveryAddress,
        needsData: needsData,
      }),
    })
      .then((response)=>{
        // Извлекаем код ответа HTTP
        httpStatusCode = response.status;
        return response;
      })
      .then(validationJSON) // Раскрывает промис
      .then((data)=> {
        // data содержит ответ от API
        if (httpStatusCode === 200)
          dispatch(saveClientNeedsSuccess( data.needsId))
        else {
          // data содержит расшифровку ошибки в объекте error
          apiErrorCode = data.error.code;
          apiErrorMessage = data.error.message;
          const error = new Error()
          throw error
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === 'development') console.log('saveClientNeeds() - Error: ', error);
        dispatch(
          saveClientNeedsError(
            apiErrorCode || httpStatusCode,
            apiErrorMessage || error.message
          ));
      })
  }
}
