import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LoadingView, DataGrid }  from '../../Main'
import { Link } from 'react-router-dom';
import { FileUploader, DropDownButtonList } from '../../../Desktop/Main';
import { convertWin1251ToUTF8, moneyFormat, diff } from '../../../../lib/utils';

const MAX_IMPORT_ORDER_LINES_COUNT = 100;

export default class PersonalOrdersImportOrder extends Component {
  static propTypes = {
    prepareToImportOrderFromFile: PropTypes.func.isRequired,
    openOrderFileTry: PropTypes.func.isRequired,
    openOrderFileSuccess: PropTypes.func.isRequired,
    openOrderFileError: PropTypes.func.isRequired,
    importOrderApplyError: PropTypes.func.isRequired,
    applyOrder: PropTypes.func.isRequired,

    importOrderTryOpenOrderFile: PropTypes.bool.isRequired,
    importOrderErrorCode: PropTypes.number,
    importOrderStatus: PropTypes.string,
    importOrder: PropTypes.object.isRequired,
    importOrderTryOpenOrderFileProcess: PropTypes.bool.isRequired,
    importOrderRequestApplyOrderInProcess: PropTypes.bool.isRequired,
    importOrderRequestApplyOrderSuccess: PropTypes.bool.isRequired,
    importOrderResultOrderId: PropTypes.number,
  }

  state = {
    orderSumm: undefined,
    orderLinesCount: undefined,
    unparcedItems: [],
    foundUnparcedItems: false,
    processHint: ''
  }

  // импорт загруженных данных
  onSubmitFile(file){
    const { importOrderTryOpenOrderFile,
      importOrderErrorCode,
      importOrder,
    } = this.props;

    if (importOrderTryOpenOrderFile === true){
      if (importOrderErrorCode === 0){

        if (Array.isArray(importOrder.body)){
          const order = {
            items: importOrder.body,
          };

          let orderComment = 'Заказ импортирован из файла "' + file.name+'"';
          if (this.orderCommentInput && this.orderCommentInput.value &&
            this.orderCommentInput.value.length>0) orderComment = this.orderCommentInput.value;

          let props = {
            comment: orderComment
          };

          this.props.applyOrder( this.props.token, order, this.props.customers, props);
        }else{
          this.props.importOrderApplyError('Непредвиденная ошибка при подтверждении импорта заказа',100)
        }
      }else{
        this.props.importOrderApplyError('Пожалуйста, выбирете импортируемый файл соответствующий формату заказа',110)
      }
    }else{
        this.props.importOrderApplyError('Пожалуйста, сначала выбирете импортируемый файл с заказом',120)
    }
  }

  // отображаем загруженные данные
  onPreviewFile(fileObject, data, refreshUploaderElement){
    this.setState({
      unparcedItems: [],
      foundUnparcedItems: false,
    });
    this.parseCSVOrder(fileObject.name,convertWin1251ToUTF8(data));
  }

  // парсинг CSV файла
  parseCSVOrder(fileName, orderText) {
    let processHint = this.state.processHint;
    this.props.openOrderFileTry(fileName,orderText);

    let orderObject = {head: [], body: [] };
    if (!orderText) {
      this.props.openOrderFileError('Файл заказа пуст', 10);
      return;
    }

    const orderRAWRows = orderText.split(/\r?\n|\r/);

    if (!Array.isArray(orderRAWRows)) {
      this.props.openOrderFileError('Ошибка предварительного чтения файла', 11);
      return;
    }

    const orderRAWHead = orderRAWRows[0];

    if ( (!orderRAWHead) || (orderRAWHead.length === 0) ){
      this.props.openOrderFileError('Ошибка предварительного чтения заголовка файла', 12);
      return;
    }

    if (orderRAWRows.length < 2) {
      this.props.openOrderFileError('Файл не корректен (отсутствуют строки данных)', 13);
      return;
    }

    const orderHeadColumns =  orderRAWHead.split(/;/);

    if (orderHeadColumns.length < 3){
      this.props.openOrderFileError('Файл не корректен (менее трех значимых полей в заголовке)', 20);
      return;
    }

    if (orderHeadColumns.length > 5){
      this.props.openOrderFileError('Файл не корректен (в заголовке содержатся лишние поля)', 21);
      return;
    }

    orderObject.head = orderHeadColumns.map((item,index)=>{
      let resItem = {};
      const fieldName = item;
      switch(fieldName) {
        case 'Код товара': 
          resItem = { 
            caption: 'Артикул',
            fieldName: 'MEDS',
            flexWidth: 1,
          };
          break;
        case 'Наименование': 
          resItem = { 
            caption: 'Наименование', 
            fieldName: 'MEDS_NAME',  
            flexWidth: 5, 
          };
          break;
        case 'Количество': 
          resItem = { 
            caption: 'Количество', 
            fieldName: 'AMOUNT',
            flexWidth: 1,
            columnSpacer: 'd-flex justify-content-end mr-3',
          };
          break;
        case 'Цена': 
          resItem = { 
            caption: 'Цена', 
            fieldName: 'PRICE',
            //isCurrency: true,
            currencyName: 'руб.',
            roundTo: 2,
            flexWidth: 1,
            columnSpacer: 'd-flex justify-content-end',
          };
          break;
          case 'Комментарий': 
          resItem = { 
            caption: 'Комментарий', 
            fieldName: 'COMMENTARY',
            flexWidth: 3
          };
          break;
        default:
          resItem[fieldName] = item;
          break;
      };
      return resItem;
    });
    const orderColumnsCount = orderObject.head.length;


    // Далее в строках ищем не более чем orderColumnsCount колонок с данными
    //Отбрасываем загоровочную строку - оставшаяся часть это тело заказа
    orderRAWRows.shift();
    const orderRAWBody = orderRAWRows;
    // Удаляем строки не удовлетворяющие условию
    // 1. Количество колонок не равно количеству колонок заголовка
    let orderBody = orderRAWBody.filter(item=>{
      const orderRowColumns =  item.split(/;/);
      if ((!orderRowColumns)||(orderRowColumns.length !== orderColumnsCount)) 
        return false; else return true;
    });

    // Импорт только первых MAX_IMPORT_ORDER_LINES_COUNT строк заказа
    if ( orderBody.length > MAX_IMPORT_ORDER_LINES_COUNT) 
    processHint = 'Допустимое количество импортируемых строк ' + 
      MAX_IMPORT_ORDER_LINES_COUNT + 
      ', лишние строки будут проигнорированы.';
    orderBody = orderBody.filter((item,index)=>index < MAX_IMPORT_ORDER_LINES_COUNT);

    const badItems = diff(orderRAWRows,orderBody).filter(item=>item.length>0);
    if ( badItems.length > 0 ){
        this.setState({
          unparcedItems: badItems,
          foundUnparcedItems: true,
        });
    }

    // orderBody содержит тело заказа
    const resOrderBody = orderBody.map(
      item=>item.split(/;/).reduce((previousValue, currentItem, index, arr)=>{
        let resItem = {};
        const fieldName = orderObject.head[index].fieldName;
        resItem[fieldName] = currentItem;
        // Приведение к правильному типу данных
        switch(fieldName) {
          case 'MEDS': 
            resItem.MEDS = parseInt(currentItem,10);
            break;
          case 'AMOUNT': 
            resItem.AMOUNT = parseInt(currentItem,10);
            break;
            case 'PRICE':
            resItem.PRICE = parseFloat(String(currentItem).replace(/[,]+/g, '.'));
            break;
          default:
            resItem[fieldName] = currentItem;
            break;
        }
        return {...previousValue, ...resItem };
      }
      ,{})
    );

    orderObject.body = resOrderBody;
    const orderSumm = orderObject.body.reduce(
      ( summ,item, index ) => summ + item.AMOUNT * item.PRICE, 0
    );

    this.setState({
      processHint: processHint,
      orderSumm: moneyFormat(orderSumm) + ' руб.',
      orderLinesCount: orderObject.body.length,
    });

    this.props.openOrderFileSuccess(orderObject);
    return true;
  }

  doResetThisState(){
    this.setState({
      unparcedItems: [],
      foundUnparcedItems: false,
      processHint: ''
    });
  }

  // подготовка к новому импорту
  doPrepareToImport (){
    this.doResetThisState();
    this.props.prepareToImportOrderFromFile();
  }

  componentWillUnmount(){
    this.doPrepareToImport();
  }

  componentWillMount(){
    this.doPrepareToImport();
  }
  
  componentWillReceiveProps(nextProps){
    if (nextProps.importOrderRequestApplyOrderSuccess)
      this.doResetThisState();
  }

  
  setCommentInput(val){
    this.orderCommentInput = val;
  }

  render() {
    const { importOrderTryOpenOrderFile, // Попытка открыть файл с заказом
            importOrderTryApplyOrderFile,
            importOrderErrorCode, // Код статуса процесса импорта
            importOrderStatus, // Статус процесса импорта
            importOrder, // Тело заказа
            importOrderRequestApplyOrderInProcess, // Отправка заказа на сервер - в процессе
            importOrderRequestApplyOrderSuccess, // Отправка заказа на сервер - ok
            importOrderTryOpenOrderFileProcess, // Открытие файла с заказом
            importOrderResultOrderId, // Результирующий код заказа после импорта
     } = this.props;

     return (
      <div>
        { !importOrderRequestApplyOrderSuccess &&
          <FileUploader
            onSubmitFile={ this.onSubmitFile.bind(this) }
            onAfterLoadFile= { this.onPreviewFile.bind(this) }/>
        }
        { importOrderRequestApplyOrderInProcess &&
          <LoadingView text={ 'Отправка заказа...' }/>
        }
        { importOrderTryOpenOrderFileProcess &&
          <LoadingView text={ 'Открытие файла с заказом...' }/>
        }
        <div className='d-flex mt-2'>
          { !importOrderTryOpenOrderFile  &&
            <p className="text-secondary">Здесь будут отображаться импортируемые данные.</p>
          }
          { !importOrderRequestApplyOrderSuccess &&
            importOrderTryOpenOrderFile  && importOrderErrorCode === 0 &&
            <div>
              Сумма заказа: <b>{this.state.orderSumm} </b><br/>
              Количество строк :{this.state.orderLinesCount} <hr/>
              <p className="text-info">{ this.state.processHint }</p>
              <p>
                Если отображаемые данные корректны, Вы можете подвердить заказ или указать другой файл.<br/>
                При необходимости Вы можете указать Комментарий к импортируемому заказу.<br/>
                <b>Для подтверждения заказа нажмите "Импорт".</b>
              </p>
            </div>
          }
        </div>

        { importOrderRequestApplyOrderSuccess &&
          <div>
            <p>Заказ <b>#
            <Link title="Детали заказа" 
              to={ "/PersonalOrders/Order/" + importOrderResultOrderId }
              className="internal-page-link-calm">
            { importOrderResultOrderId } 
            </Link></b> импортирован</p>

            <p>Вы можете наблюдать за его состоянием в разделе
              <Link to="/PersonalOrders/Orders" className="internal-page-link-calm">
                <b> «Активные заказы»</b>
              </Link>
            </p>

            <button type="button" className="btn btn-outline-info d-flex col-1 justify-content-center"
                    onClick={ this.doPrepareToImport.bind(this) }>
                    OK
            </button>
          </div>
        }

        { importOrderErrorCode !== 0 &&
            <p className="text-danger" >{ importOrderStatus }</p>
        }

        { this.state.foundUnparcedItems&&
          <div className="card d-flex mb-4 ">
            <div className="card-header ">
              <p className="text-danger">
                Обнаружены строки, которые не соотвертствуют формату файла заказа.
              </p>
              <p className="text-info">
                Вы можете продолжить импорт заказа со строками, которые успешно идентифицированы.
              </p>
            </div>
            <div className="card-body mt-1 ">
              <DropDownButtonList 
                buttonClassName="btn-sm btn-outline-danger" buttonTitle="детали" items={this.state.unparcedItems}/>
            </div>
          </div>
        }

        { !importOrderRequestApplyOrderSuccess && // Если заказ успешно импортирован скрыть таблицу
          importOrderErrorCode === 0 && !importOrderTryApplyOrderFile &&
          importOrderTryOpenOrderFile&&
          <div>
            <input type="text"
              ref={ (input)=>this.setCommentInput(input) }
              className="form-control mb-2"
              placeholder="Комментарий к заказу..." />
              <div className="import-order-file-grid">
                <DataGrid
                  header = { importOrder.head }
                  body = { importOrder.body }
                  onItemClick = {()=>null}
                  limit = { MAX_IMPORT_ORDER_LINES_COUNT }
                />
              </div>
          </div>
        }

      </div>
    )
  }
}

