import { apiCommandGETNewNotifications,
  apiCommandPOSTViewNotify, apiCommandGETNotifications,
  apiCommandPOSTFollowNotify } from '../constants/notifications';
import { validationJSON, makeJSON } from '../lib/utils'

// Получение списка новых уведомлений
export function requestNewNotifications() {
  return {
    type: 'NEW_NOTIFICATIONS_REQUEST',
  }
}

export function requestNewNotificationsSuccess(data) {
  data = data.NewNotifications.slice(0, 100);
  let newNotificationCount = 0;
  if (Array.isArray(data)) newNotificationCount = data.length;
  try {
    window.localStorage.setItem('NewNotifications', JSON.stringify(data));
    window.localStorage.setItem('NewNotificationsCount', data.length);
  } catch (e) {
    alert( 'Ошибка при работе с локальным хранилищем браузера ', e );
  }
  return {
    type: 'NEW_NOTIFICATIONS_REQUEST_SUCCESS',
    newNotificationCount: newNotificationCount,
  }
}

export function requestNewNotificationsError(errorCode, errorMessage) {
  return {
    type: 'NEW_NOTIFICATIONS_REQUEST_ERROR',
    error: {errorCode, errorMessage},
  }
}

export function getNewNotifications(token) {
  return function(dispatch) {
    dispatch(requestNewNotifications());
    let httpStatusCode = null;
    let apiErrorCode = null;
    let apiErrorMessage = null;

    return fetch(apiCommandGETNewNotifications + '?access_token=' + token, {
      // cors - Сервис API может быть на любом сервере, same-origin - Тот же самый сервер
      mode: 'cors',
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response)=>{
        // Извлекаем код ответа HTTP
        httpStatusCode = response.status;
        return response;
      })
      .then(validationJSON) // Раскрывает промис
      .then((data)=> {
        // data содержит ответ от API
        if (httpStatusCode === 200) {
          dispatch(requestNewNotificationsSuccess(data));
        }
        else {
          // data содержит расшифровку ошибки в объекте error
          apiErrorCode = data.error.code;
          apiErrorMessage = data.error.message;
          const error = new Error()
          throw error
        }
      })
      .catch((error) => {
        dispatch(
          requestNewNotificationsError(
            apiErrorCode || httpStatusCode,
            apiErrorMessage || error.message
          ));
      })
  }
}


// Отметить уведомление как прочитанное
export function readNotifyRequest() {
  return {
    type: 'READ_NOTIFY_REQUEST',
  }
}

export function readNotifyRequestSuccess() {
  let newNotificationsCount = Number(localStorage.getItem('NewNotificationsCount')) - 1;
  window.localStorage.setItem('NewNotificationsCount', newNotificationsCount);
  return {
    type: 'READ_NOTIFY_REQUEST_SUCCESS',
    newNotificationCount: newNotificationsCount,
  }
}

export function readNotifyRequestError(errorCode, errorMessage) {
  return {
    type: 'READ_NOTIFY_REQUEST_ERROR',
    error: {errorCode, errorMessage},
  }
}

export function sendReadNotify(token, notifyId) {
  return function(dispatch) {
    dispatch(readNotifyRequest());
    let httpStatusCode = null;
    let apiErrorCode = null;
    let apiErrorMessage = null;
    return fetch(apiCommandPOSTViewNotify + '?access_token=' + token, {
      // cors - Сервис API может быть на любом сервере, same-origin - Тот же самый сервер
      mode: 'cors',
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: makeJSON({
        notifyId: Number(notifyId),
      }),
    })
      .then((response)=>{
        // Извлекаем код ответа HTTP
        httpStatusCode = response.status;
        return response;
      })
      .then((data)=> {
        // data содержит ответ от API
        if (httpStatusCode === 204) {
          dispatch(readNotifyRequestSuccess());
        }
        else {
          // data содержит расшифровку ошибки в объекте error
          apiErrorCode = data.error.code;
          apiErrorMessage = data.error.message;
          const error = new Error()
          throw error
        }
      })
      .catch((error) => {
        dispatch(
          readNotifyRequestError(
            apiErrorCode || httpStatusCode,
            apiErrorMessage || error.message
          ));
      })
  }
}


// Получение списка уведомлений
export function requestNotifications() {
  return {
    type: 'NOTIFICATIONS_REQUEST',
  }
}

export function requestNotificationsSuccess(data) {
  data = data.Notifications.slice(0, 50);
  try {
    window.localStorage.setItem('Notifications', JSON.stringify(data));
  } catch (e) {
    alert( 'Ошибка при работе с локальным хранилищем браузера ', e );
  }
  return {
    type: 'NOTIFICATIONS_REQUEST_SUCCESS',
  }
}

export function requestNotificationsError(errorCode, errorMessage) {
  return {
    type: 'NOTIFICATIONS_REQUEST_ERROR',
    error: {errorCode, errorMessage},
  }
}

export function getNotifications(token) {
  return function(dispatch) {
    dispatch(requestNotifications());
    let httpStatusCode = null;
    let apiErrorCode = null;
    let apiErrorMessage = null;

    return fetch(apiCommandGETNotifications + '?access_token=' + token, {
      // cors - Сервис API может быть на любом сервере, same-origin - Тот же самый сервер
      mode: 'cors',
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response)=>{
        // Извлекаем код ответа HTTP
        httpStatusCode = response.status;
        return response;
      })
      .then(validationJSON) // Раскрывает промис
      .then((data)=> {
        // data содержит ответ от API
        if (httpStatusCode === 200) {
          dispatch(requestNotificationsSuccess(data));
        }
        else {
          // data содержит расшифровку ошибки в объекте error
          apiErrorCode = data.error.code;
          apiErrorMessage = data.error.message;
          const error = new Error()
          throw error
        }
      })
      .catch((error) => {
        dispatch(
          requestNotificationsError(
            apiErrorCode || httpStatusCode,
            apiErrorMessage || error.message
          ));
      })
  }
}


// Отметить уведомление как прочитанное
export function followNotifyRequest() {
  return {
    type: 'FOLLOWED_NOTIFY_REQUEST',
  }
}

export function followNotifyRequestSuccess() {
  return {
    type: 'FOLLOWED_NOTIFY_REQUEST_SUCCESS',
  }
}

export function followNotifyRequestError() {
  return {
    type: 'FOLLOWED_NOTIFY_REQUEST_ERROR',
  }
}

export function sendFollowNotify(token, notifyId) {
  return function(dispatch) {
    dispatch(followNotifyRequest());
    let httpStatusCode = null;
    return fetch(apiCommandPOSTFollowNotify + '?access_token=' + token, {
      // cors - Сервис API может быть на любом сервере, same-origin - Тот же самый сервер
      mode: 'cors',
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: makeJSON({
        notifyId: Number(notifyId),
      }),
    })
      .then((response)=>{
        // Извлекаем код ответа HTTP
        httpStatusCode = response.status;
        return response;
      })
      .then((data)=> {
        // data содержит ответ от API
        if (httpStatusCode === 204) {
          dispatch(followNotifyRequestSuccess());
        }
        else {
          const error = new Error()
          throw error
        }
      })
      .catch(() => {
        dispatch(
          followNotifyRequestError());
      })
  }
}
