import { connect } from 'react-redux';
import { PersonalOrdersCatalog }  from '../../components/Desktop/PersonalOrders';
import { restoreCatalogState } from '../../actions/Main/PersonalOrders';
import { edtCart, removeFromCart, clearCart, initCart } from '../../actions/cart';

const mapStateToProps = (state) => ({
  authorized: state.authorization.authorized,
  token: state.authorization.token,
  login: state.authorization.login,

  // customers: state.authorization.userId,
  customers: Number(state.authorization.childUserId) || Number(state.authorization.userId),


  requestCatalogInProcess: state.personalOrders.requestCatalogInProcess,
  requestCatalogSuccess: state.personalOrders.requestCatalogSuccess,
  requestCatalogError: state.personalOrders.requestCatalogError,

  catalogRestoredFromLSSuccess: state.personalOrders.catalogRestoredFromLSSuccess,
  catalogRestoreFromLSError: state.personalOrders.catalogRestoreFromLSError,
  catalogRestoreFromLSRequest: state.personalOrders.catalogRestoreFromLSRequest,

  filter: state.personalOrders.filter,
  catalogLinesCount: state.personalOrders.catalogLinesCount,
  requestCatalogStatus: state.personalOrders.requestCatalogStatus,
  catalogRefreshTime: state.personalOrders.catalogRefreshTime,
  catalogExpired: state.personalOrders.catalogExpired,
  cart: state.cart,
});

const mapDispathToProps = (dispath) => {
  return {
    doRefreshCatalog:
      ( localStorageOnly, token, customers, filter )=>
        dispath(restoreCatalogState(localStorageOnly, token, customers, filter)),
    doAddToCart:
      ( goodsId, goodsName, goodsAmount, goodsPrice, goodsLine )=>
        dispath(edtCart(goodsId, goodsName, goodsAmount, goodsPrice, goodsLine )),
    doRemoveFromCart:
      ( goodsId )=>
        dispath(removeFromCart( goodsId )),
    doClearCart: () => dispath(clearCart()),
    doInitCart: () => dispath(initCart()),
  };
};

export default connect(
  mapStateToProps,
  mapDispathToProps
)(PersonalOrdersCatalog)
