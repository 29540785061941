import React, { Component } from 'react';
import { DataGrid, WithUnlimitedScroll } from '../../Main'
import { OrdersHistoryHeader } from '../PersonalOrdersOrdersHistoryGrid'
import PropTypes from 'prop-types';

const ScrolledDataGrid = WithUnlimitedScroll(DataGrid);
const InitialInitRows = 8 // Количество строк при инициализации
const ShowLinesPerStep = 20; // Количество строк при подгрузке

export default class PersonalOrdersOrdersHistoryGrid extends Component {
  static propTypes = {
    gridData: PropTypes.array.isRequired,
    onSelectItem: PropTypes.func.isRequired,
    RowControls: PropTypes.func,
  };

  state = {
    // goodsCatalog : {},
    totalRowsCount: null,
    filteredRows: null,
    shownRows : InitialInitRows,
    shownPerc: 0
  };
    
/**
 * Получить процент просмотренных данных
 * @param {*Показано строк} shownRows 
 * @param {*Всего строк для отображения} filteredRows 
 */
  getShownPerc(shownRows,filteredRows){
    if(filteredRows===0) return 0;
    return Math.round( shownRows * 100 / (filteredRows))
  }
  
  /**
   * Смена состояния после фильтрации данных
   * @param {*Состояние} config 
   */
  updateData(config) {
    const { data } = config;
    this.setState({
      ...config, 
      filteredRows :data.length, 
      shownRows: data.length < InitialInitRows ? data.length : InitialInitRows,
      shownPerc: this.state.shownRows===0?0:
        this.getShownPerc(
          data.length < InitialInitRows ? data.length : InitialInitRows,
            data.length),
    });
  }

  /**
   * Смена состояния после отображения очередной порции данных
   */
  showMoreLines() {
    if (this.state.shownRows >= this.state.filteredRows) return;
    // рассчет текущего шага для отображения, на случай когда осталось отобразить меньше чем ShowLinesPerStep
    let showLinesPerCurrentStep = Math.min((this.state.filteredRows-this.state.shownRows), ShowLinesPerStep);
    this.setState({
      shownRows: this.state.shownRows + showLinesPerCurrentStep,
      shownPerc: this.getShownPerc(this.state.shownRows + showLinesPerCurrentStep, 
        this.state.filteredRows)
    });
  }

  componentWillMount(){
    let totalRowsCount = this.props.gridData.length;
    this.updateData({
        //goodsCatalog: this.props.gridData,
        totalRowsCount: totalRowsCount,
        activeLine: 0,
        term : '',
        data : this.props.gridData});
  }  

  handleItemSelection = (item) => {
    this.setState({activeLine : item});
    this.props.onSelectItem(item);
  }

  render() {
    return (
      <div>

        {/*(this.state.term.length > 0)&&
            <div>
              <p>найдено - {this.state.filteredRows} заказов</p>
            </div>*/
        }

        {/*
        <PersonalOrdersCatalogSearch 
          term={ this.state.term }
          data={ this.props.gridData } // Исходный набор данных
          onUpdate={ this.updateData.bind(this) }
        />*/}

        {this.state.data.length>0 &&
        <div className="orders-history-grid">
          <ScrolledDataGrid
            header = { OrdersHistoryHeader } 
            body = { this.state.data }
            onItemClick = { this.handleItemSelection }
            limit = { this.state.shownRows }
            rowControls = { this.props.RowControls }

            onLoadMore = {this.showMoreLines.bind(this)}
            allDataShown = { this.state.shownRows === this.state.filteredRows }
          />
        </div>
        }

        {/*this.state.data.length===0 &&  this.state.term &&
        <p>В настоящее время <b>"{ this.state.term }"</b> отсутсвует</p>
        */}
        {this.state.shownPerc < 100 &&
          <div className="progress fixed-bottom " style={{height: `3px`}}>
              ?<div className="progress-bar" role="progressbar" style={{width: `${this.state.shownPerc}%`}}></div>
              :null
          </div>
        }
      </div>
    )
  }
}
