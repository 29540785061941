import React, { Component } from 'react';
import { LoadingView }  from '../../Main'
import { ReceivablesInfoGrid } from '../ReceivablesInfo'
import { moneyFormat } from '../../../../lib/utils';

export default class ReceivablesInfo extends Component {
  static receivablesDetails = {};
  state = {
    receivablesLoaded: false,
    receivablesSum: 0,
  }
  /*
  requestReceivablesInProcess: state.receivables.requestReceivablesInProcess,
  requestReceivablesError: state.receivables.requestReceivablesError,
  requestReceivablesSuccess: state.receivables.requestReceivablesSuccess,
  requestReceivablesStatus: state.receivables.requestReceivablesStatus,
  requestReceivablesCustomers: state.receivables.requestReceivablesCustomers,
   */
  tryRenderReceivablesInfo(newProps){
    if( newProps.requestReceivablesSuccess ){
      let receivablesInfo = JSON.parse(localStorage.getItem('ReceivablesInfo'));
      if (receivablesInfo !== null && typeof receivablesInfo === 'object') {
        ReceivablesInfo.receivablesDetails = receivablesInfo;
        const receivablesStat = 
          receivablesInfo.reduce(function(sum, current) {
            return { 
              receivablesSum: sum.receivablesSum + current.SUM_NOT_PAY, 
            }
          }, { receivablesSum: 0});

          this.setState({
            receivablesLoaded: true,
            receivablesSum: receivablesStat.receivablesSum,
          });
        }
      }else{
        this.setState({
          receivablesLoaded: false,
          receivablesSum: null,
        });
      }
  }

  componentWillReceiveProps(nextProps){
    this.tryRenderReceivablesInfo(nextProps);
  }

  doGetReceivablesInfo = () => {
    this.props.getReceivables(this.props.token,
      this.props.customers);
  }

  componentWillMount(){
    this.doGetReceivablesInfo();
  }

  handleRowSelection = () =>{
  }

  render() {
     const {
      requestReceivablesError,
      requestReceivablesInProcess,
      requestReceivablesSuccess,
    } = this.props;
    const {
      receivablesSum,
    } = this.state;

    return (
      <div className="row">
        <div className="col-xl-1 col-lg-0"></div>
        <div className="col-xl-10 col-md">
          {
            requestReceivablesError&&
            <div className="row">
              <div className="col">
                <font color="red">
                  <p>Ошибка получения данных о дебиторской задолженности...</p>
                  <p>Сервис временно не доступен.</p>
                </font>
              </div>
            </div>
          }

          {
            !requestReceivablesError&&
            requestReceivablesInProcess&&
            <div className="row">
              <div className="col mb-2">
                <LoadingView text={'Получение данных о дебиторской задолженности...'}/>
              </div>
            </div>
          }
            { !requestReceivablesInProcess&&
            <div>
              <div className="row no-gutters">
                <div className="col-2">
                  <button onClick = { this.doGetReceivablesInfo }
                    disabled = { requestReceivablesInProcess }
                    className="btn btn-outline-info btn-block btn-sm">
                    <i className="fa fa-2x fa-refresh"/>
                  </button>
                </div>
                { requestReceivablesSuccess&&this.state.receivablesLoaded&& (receivablesSum>0) &&
                  <div className="col ml-3 mt-2">
                    Сумма дебиторской задолженности <b>{ moneyFormat(receivablesSum) + ' руб.' }</b>
                  </div>
                }
              </div>
              <hr/>
            </div>
          }
          {
            requestReceivablesSuccess&&this.state.receivablesLoaded&& (receivablesSum>0) &&
            <div>
              <div className="row no-gutters">
                <div className="col">
                  <ReceivablesInfoGrid
                    gridData= { ReceivablesInfo.receivablesDetails }
                    onSelectItem= { this.handleRowSelection }
                    RowControls= { this.GridRowControls }
                  />
                </div>
              </div>
            </div>
          }
          {
            requestReceivablesSuccess&&this.state.receivablesLoaded&& (receivablesSum === 0) &&
            <div className="row">
              <div className="col">
                <p>В настоящее время дебиторская задолженность отсутствует</p>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}
