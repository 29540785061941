import { openDB } from 'idb';
import { dbName, dbVer } from '../config/systemConfig';

const dbPromise = function (){
  if (!('indexedDB' in window)) {
    return false;
  }
  const dbPromise = openDB(dbName, dbVer, {
    upgrade(db) {
      db.createObjectStore('keyval');
    },
  });
  return dbPromise;
}();

export const idbKeyval = {
  async get(key) {
    if (dbPromise)
      return (await dbPromise).get('keyval', key);else
      return false;
  },
  async set(key, val) {
    if (dbPromise)
      return (await dbPromise).put('keyval', val, key);else
      return false;
  },
  async delete(key) {
    if (dbPromise)
      return (await dbPromise).delete('keyval', key);else
      return false;
  },
  async clear() {
    if (dbPromise)
    return (await dbPromise).clear('keyval');else
    return false;
  },
  async keys() {
    if (dbPromise)
    return (await dbPromise).getAllKeys('keyval');else
    return false;
  },
};

//dbPromise = initDb({dbName, dbVer});
