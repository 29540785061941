
import React from 'react';
import { Route, Redirect } from 'react-router-dom'
import {connect} from 'react-redux';
import	{ AUTHORIZATION_PATH }	from	'../constants/authorization';
import { withRouter } from 'react-router'

const PrivateRoute = ({ 
    authorizationPath : authorizationRedirectPath,
    component: Component, 
    authorized: isAuthorized,
    ...rest }) => {
  authorizationRedirectPath = authorizationRedirectPath
    ?authorizationRedirectPath
    :AUTHORIZATION_PATH;
  return (<Route
    { ...rest }
    render = { props =>
      isAuthorized ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: authorizationRedirectPath,
            state: { fromProtectedRoute: props.location }
          }}
        />
      )
    }
  />
)};

const mapStateToProps = state => ({
  authorized: state.authorization.authorized,
});

export default withRouter(connect(mapStateToProps)(PrivateRoute))
