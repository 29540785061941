import { backendServiceURL } from '../config/backendService'

export const REGISTRATION_REQUEST = 'REGISTRATION_REQUEST'
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS'
export const REGISTRATION_ERROR = 'REGISTRATION_ERROR'
export const REGISTRATION_COMPLETE = 'REGISTRATION_COMPLETE'

export const REGISTRATION_EMAIL_VERIFY_REQUEST = 'REGISTRATION_EMAIL_VERIFY_REQUEST'
export const REGISTRATION_EMAIL_VERIFY_SUCCESS = 'REGISTRATION_EMAIL_VERIFY_SUCCESS'
export const REGISTRATION_EMAIL_VERIFY_ERROR = 'REGISTRATION_EMAIL_VERIFY_ERROR'

// Команды API интерфейса
//
// Clients - post - регистрация нового клиента
export const apiCommandPOSTRegistration = backendServiceURL + 'api/v1/clients'
// Clients - get - подтверждение электронного адреса
export const apiCommandGETEmailVerify = backendServiceURL + 'api/v1/Clients/confirm'
