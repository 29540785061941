import React, { Component } from 'react';
import { DataGrid, WithUnlimitedScroll } from '../../Main'
import { PersonalOrdersCatalogSearch } from '../PersonalOrdersCatalogSearch'
import { GoodsCatalogHeader} from '../PersonalOrdersCatalogGrid'
import PropTypes from 'prop-types';

const ScrolledDataGrid = WithUnlimitedScroll(DataGrid);
const InitialInitRows = 8 // Количество строк при инициализации
const ShowLinesPerStep = 7; // Количество строк при подгрузке

export default class PersonalOrdersCatalogGrid extends Component {
  static propTypes = {
    catalogData: PropTypes.array.isRequired,
    onSelectItem: PropTypes.func.isRequired,
    CartControls: PropTypes.func.isRequired,
  };

  state = {
    //goodsCatalog : {},
    data: {},
    totalRowsCount: null,
    filteredRows: null,
    shownRows : InitialInitRows,
    shownPerc: 0
  };
    
/**
 * Получить процент просмотренных данных
 * @param {*Показано строк} shownRows 
 * @param {*Всего строк для отображения} filteredRows 
 */
  getShownPerc(shownRows,filteredRows){
    if(filteredRows===0) return 0;
    return Math.round( shownRows * 100 / (filteredRows))
  }  
  
  /**
   * Смена состояния после фильтрации данных
   * @param {*Состояние} config 
   */
  updateData(config) {
    const { data } = config;
    this.setState({
      ...config, 
      filteredRows :data.length, 
      shownRows: data.length < InitialInitRows ? data.length : InitialInitRows,
      shownPerc: this.state.shownRows===0?0:
        this.getShownPerc(
          data.length < InitialInitRows ? data.length : InitialInitRows,
            data.length),
    });
  }

  /**
   * Смена состояния после отображения очередной порции данных
   */
  showMoreLines() {
    if (this.state.shownRows >= this.state.filteredRows) return;
    // рассчет текущего шага для отображения, на случай когда осталось отобразить меньше чем ShowLinesPerStep
    let showLinesPerCurrentStep = Math.min((this.state.filteredRows-this.state.shownRows), ShowLinesPerStep);
    this.setState({
      shownRows: this.state.shownRows + showLinesPerCurrentStep,
      shownPerc: this.getShownPerc(this.state.shownRows + showLinesPerCurrentStep, 
        this.state.filteredRows)
    });
  }

  componentWillMount(){
    let totalRowsCount = this.props.catalogData.length;
    this.updateData({
        totalRowsCount: totalRowsCount,
        activeLine: 0,
        term : '',
        data : this.props.catalogData});
  }  

  handleItemSelection = (item) => {
    this.setState({activeLine : item});
    this.props.onSelectItem(item);
  }

  render() {
    return (
      <div>
        {
        /*
        <p>totalRowsCount - {this.state.totalRowsCount}</p>
        <p>filteredRows - {this.state.filteredRows}</p>
        <p>shownRows - {this.state.shownRows}</p>
        <p>shownRowsPerc - {this.state.shownPerc}</p>
        */
        }
        <div>
          {(this.state.term.length > 0)&&
          <p>найдено - {this.state.filteredRows} товаров</p>
          }
        </div>
        <PersonalOrdersCatalogSearch 
          term={ this.state.term }
          data={ this.props.catalogData } // Исходный набор данных
          onUpdate={ this.updateData.bind(this) }
        />

        {this.state.data.length>0 &&
        <div className="goods-catalog-grid small">
          <ScrolledDataGrid
            header = { GoodsCatalogHeader } 
            body = { this.state.data }
            onItemClick = { this.handleItemSelection }
            limit = { this.state.shownRows }
            rowControls = { this.props.CartControls }
            keyFieldName = {'MEDS'}
            onLoadMore = {this.showMoreLines.bind(this)}
            allDataShown = { this.state.shownRows === this.state.filteredRows }
          />
        </div>
        }

        {this.state.data.length===0 && this.state.term &&
        <p>В настоящее время <b>"{ this.state.term }"</b> отсутсвует</p>
        }

        <div className="progress fixed-bottom " style={{height: `3px`}}>
          {this.state.shownPerc<100
            ?<div className="progress-bar" role="progressbar" style={{width: `${this.state.shownPerc}%`}}></div>
            :null
          }
        </div>
      </div>
    )
  }
}
