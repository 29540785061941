export const GoodsCatalogHeader = [
  { caption: 'Наименование',
    fieldName: 'MEDS_NAME',
    flexWidth: 2,
    columnSpacer: 'ml-1',
  },
  /* { caption: 'Произв.',
    fieldName: 'PRODUCERS_NAME',
    flexWidth: 1,
    columnSpacer: 'ml-0',
  },
  { caption: 'Страна',
    fieldName: 'COUNTRIES_NAME',
    flexWidth: 1,
    columnSpacer: 'ml-2',
  }, */
  { caption: 'Производитель',
    fieldName: 'PROD_AND_COUNTRY',
    flexWidth: 2,
    columnSpacer: 'ml-0',
  },
  { caption: 'Годен до',
    fieldName: 'BEST_BEFORE',
    flexWidth: 1,
    columnSpacer: 'ml-4',
  },
  { caption: 'Цена',
    fieldName: 'PRICE',
    isCurrency: true,
    currencyName: 'р',
    roundTo: 1,
    flexWidth: 1,
    columnSpacer: 'd-flex ml-2 justify-content-end',
  },
  { caption: 'Остаток',
    fieldName: 'AMOUNT',
    flexWidth: 1,
    columnSpacer: 'd-flex justify-content-end',
  },
  /* { caption: 'T/х',
    hint: 'Температурные условия хранения',
    fieldName: 'STORE_TEMPERATURE',
    flexWidth: 1,
    columnSpacer: 'ml-0',
  },
  { caption: 'Норма отпуска',
    hint: 'Норма',
    fieldName: 'NORMA',
    flexWidth: 1,
    columnSpacer: 'ml-0',
  }, */
  { caption: 'Норма отпуска / T/х / Маркировка',
    hint: 'Норма',
    fieldName: 'INFO1',
    flexWidth: 1,
    columnSpacer: 'ml-0',
  },
  { caption: 'НДС',
    hint: 'НДС',
    fieldName: 'NDS',
    flexWidth: 1,
    columnSpacer: 'd-flex justify-content-end ml-0',
  },
  { caption: 'Цена реестра',
    hint: 'Цена реестра ЖНВЛП',
    fieldName: 'REG_PRICE',
    flexWidth: 1,
    columnSpacer: 'd-flex justify-content-end ml-0',
  },

  { caption: '',
    hint: 'Управление заказом',
    fieldName: 'ROW_CONTROLS',
    flexWidth: 1,
    columnSpacer: 'ml-0',
  },
];

export { default as PersonalOrdersCatalogGrid } from './PersonalOrdersCatalogGrid';

/*
AMOUNT: 824
BEST_BEFORE: "01.09.2022"
COUNTRIES_NAME: "Словения"
EAN: "3838957090976"
MEDS: 60000
MEDS_NAME: "5-нок табл. п.о. 50мг N50"\
NDS: 10
NORMA: 1
PRICE: 175.02
PRODUCERS_NAME: "Лек ДД"
QUANTITY_PACK: 200
QUANTITY_PACK_SMALL: 10
REG_PRICE_RUR: null
VITAL_MED: 0
inCart: 0
*/
