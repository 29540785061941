import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute } from '..';

// Настройки модуля
import UserProfileModules from '../../routes/UserProfile/UserProfileIndex';
// Строка меню модуля
import { MenuLine as Menu} from '../../components/Desktop';

export const UserProfileMenu = () => (
  <Menu items={ UserProfileModules } />
)

export const UserProfileRoutes = () => (
  // <Switch> - т.к. внутри Receivables могут быть вызваны не существующие маршруты
  <Switch>
    {UserProfileModules.map(
      (props, i)=>(
        props.privateRoute ?
          <PrivateRoute key={ i } { ...props }/> :
          <Route key={ i } { ...props }/>
      )
    )}
  </Switch>
)

export const UserProfileFilter = () => (
  UserProfileModules.map(
    (props, i)=>(
      props.privateRoute ?
        <PrivateRoute key={ i } { ...props } component = { props.filterСomponent }/> :
        <Route        key={ i } { ...props } component = { props.filterСomponent }/>
    )
  )
)

export function getNotificationCount() {
  let newNotificationsCount = Number(localStorage.getItem('NewNotificationsCount'));
  return newNotificationsCount > 0 ? newNotificationsCount : null;
}

export function foundNewNotification() {
  let newNotificationsCount = Number(localStorage.getItem('NewNotificationsCount'));
  return newNotificationsCount > 0 ? <i className="fa fa-comment-o" aria-hidden="true"></i> : null;
}
