import {backendServiceURL} from '../config/backendService'

export const AUTHORIZATION_REQUEST = 'AUTHORIZATION_REQUEST'
export const AUTHORIZATION_SUCCESS = 'AUTHORIZATION_SUCCESS'
export const AUTHORIZATION_ERROR = 'AUTHORIZATION_ERROR'
export const AUTHORIZATION_LOGOUT = 'AUTHORIZATION_LOGOUT'
export const AUTHORIZATION_PATH = '/Authorization'


export const CHILD_ACOUNTS_REQUEST = 'CHILD_ACOUNTS_REQUEST'
export const CHILD_ACOUNTS_REQUEST_SUCCESS = 'CHILD_ACOUNTS_REQUEST_SUCCESS'
export const CHILD_ACOUNTS_REQUEST_ERROR = 'CHILD_ACOUNTS_REQUEST_ERROR'

export const SET_WORK_USER_ID = 'SET_WORK_USER_ID'

// Команды API интерфейса
//
// Login - авторизация
export const apiCommandPOSTLogin = backendServiceURL + 'api/v1/clients/login?include=user'
export const apiCommandGETGetMyId = backendServiceURL + 'api/v1/clients/GetMyId'
// Clients - get - список дочерних аккаунтов
export const apiCommandGETChildAccounts = backendServiceURL + 'api/v1/Clients/GetChildClients'
