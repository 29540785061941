// Основные визуальные модули
import {  ReceivablesIndexView  } from '../../components/Desktop/Receivables'
import  ReceivablesNotFoundView   from '../../components/Desktop/Receivables/ReceivablesNotFoundView'

// Содержимое основного окна модуля
import ConnectedReceivablesInfo from '../../containers/Receivables/ConnectedRecivablesInfo';

// Содержимое окна фильтров модуля
import ConnectedRecivablesInfoFilter from '../../containers/Receivables/ConnectedRecivablesInfoFilter';


// Внутренние информационные страницы модуля
// import { ReceivablesStatusesInfo } from '../../components/Desktop/PersonalOrders/PersonalOrdersStatics'

// Основной роут модуля
const ReceivablesPath = '/Receivables';

// Настройки модуля Receivables
const ReceivablesModules = [
  {
    caption: 'Информация о задолженности',
    exact: true,
    privateRoute: true,
    link: ReceivablesPath + '/Info',
    path: ReceivablesPath + '/Info',
    component: ConnectedReceivablesInfo,
    filterСomponent: ConnectedRecivablesInfoFilter,
  },
  { // Индексная страница модуля PersonalOrders
    exact: false,
    privateRoute: true,
    path: ReceivablesPath + '/',
    component: ReceivablesIndexView,
    filterСomponent: ()=>(null),
  },
  { // Сообщение "Объект не найден" - вывод в основное окно модуля
    exact: false,
    privateRoute: true,
    component: ReceivablesNotFoundView,
    filterСomponent: ()=>(null),
  },
];

export default ReceivablesModules;
