import React, { Component } from 'react';


const OrdersHistoryStatisticsView = (props) => {
  const {ordersHistoryItemsCount, requestOrdersHistoryInProcess, requestOrdersHistoryError} = props;
  return (
    <div>
      <b>
        <p>
          {requestOrdersHistoryInProcess
            ? 'Получение данных...'
            : null}
          { requestOrdersHistoryError
            ? 'Сервис временно не доступен'
            : null
          }
        </p>
      </b>
      {
        !requestOrdersHistoryInProcess && ordersHistoryItemsCount > 0 &&
        <div>
          <p>Количество заказов <b> { ordersHistoryItemsCount }</b></p>
        </div>
      }
    </div>
  );
}

export default class PersonalOrdersOrdersHistoryFilter extends Component {
  state = {
    ordersHistoryItemsCount: null,
  };

  doGetOrdersHistory() {
    this.props.getOrdersHistory(this.props.token,
      this.props.customers,
      'no filters');
  }

  refreshOrdersHistoryCount(props){
    if ( props.requestOrdersHistorySuccess ) {
      let partOfHistoryOrders = JSON.parse(localStorage.getItem('OrdersHistoryItems'));
      if (Array.isArray(partOfHistoryOrders)) {
        this.setState({
          ordersHistoryItemsCount: partOfHistoryOrders.length,
        });
      }
    }
  }

  componentWillMount() {
    this.refreshOrdersHistoryCount(this.props);
  }

  componentWillReceiveProps(nextProps){
    this.refreshOrdersHistoryCount(nextProps);
  }  

  render() {
    const {requestOrdersHistoryInProcess,
      requestOrdersHistoryError,
      requestOrdersHistoryStatus} = this.props;

    return (
      <div>
        <OrdersHistoryStatisticsView 
          ordersHistoryItemsCount = {this.state.ordersHistoryItemsCount}
          requestOrdersHistoryError = {requestOrdersHistoryError}
          requestOrdersHistoryInProcess = {requestOrdersHistoryInProcess}
          requestOrdersHistoryStatus = {requestOrdersHistoryStatus}
        />
        <button onClick = { this.doGetOrdersHistory.bind(this) }
          disabled = { this.props.requestOrdersHistoryInProcess }
          className="btn btn-outline-info btn-sm btn-block">
          <i className="fa fa-2x fa-refresh"/>
        </button>
      </div>
    );
  }
}
