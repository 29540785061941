import React from 'react';
import { Link } from 'react-router-dom';
import './styles';
import logoHny from './img/logo-hny.png';
import logo from './img/logo.png';
import { newYearLogoPeriod } from '../../../../config/systemConfig';

function checkDateEntrance(date, period) {
  const periods = period.split(',');
  return (date >= new Date(periods[0]) && date <= new Date(periods[1]))
    ? true
    : false;
}

export const LogoView = () => (
  <Link to="/">
    { checkDateEntrance(new Date(), newYearLogoPeriod) ?
      <img src={ logoHny } className="pull-left z-app-logo" alt="zVision"/> :
      <img src={ logo } className="pull-left z-app-logo" alt="zVision"/>
    }
  </Link>
)
