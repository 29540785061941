import React, { Component } from 'react';
import PropTypes from 'prop-types';
/**
 * Каждый модуль имеет три основных компонента
 * Routes - маршруты
 * Menu - меню
 * Filter - фильтры
 */
import { UserProfileRoutes, UserProfileMenu, UserProfileFilter }
  from '../../../containers/UserProfile';

export default class UserProfileView extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
  }

  componentWillMount() {
    this.props.getNewNotifications(this.props.token);
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-xl-1 col-lg-0 module-menu-line-color border-bottom"></div>
          <div className="col module-menu-line-color border-bottom">
            {/* Главное меню модуля */}
            <UserProfileMenu/>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-3 col-lg-0">
            {/* Дополнительное окно модуля */}
            <UserProfileFilter/>
          </div>
          <div className="col-xl-7 col-md">
            {/* Основное окно модуля */}
            <UserProfileRoutes/>
          </div>
        </div>
      </div>
    );
  }
}
