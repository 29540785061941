import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PersonalOrdersActiveOrdersGrid } from '../PersonalOrdersActiveOrdersGrid'
import { LoadingView }  from '../../Main'
import { Link } from 'react-router-dom';

export default class PersonalOrdersActiveOrders extends Component {
  static propTypes = {
    doGetActiveOrders: PropTypes.func.isRequired,
  }

  static activeOrders = {}; 
  state = {
    activeOrdersLoaded: false,
    activeOrdersCount: null,
  }

  tryRenderActiveOrders(newProps){
    if( newProps.requestActiveOrdersSuccess ){
      let partOfActiveOrders = JSON.parse(localStorage.getItem('ActiveOrdersItems'));
      if (Array.isArray(partOfActiveOrders)) {
        PersonalOrdersActiveOrders.activeOrders = partOfActiveOrders.slice(0,10000);
        this.setState({
          activeOrdersLoaded: true,
          activeOrdersCount: partOfActiveOrders.length,
        });
      }
    }
  }

  doGetActiveOrders(){
    this.props.doGetActiveOrders(this.props.token,
      this.props.customers,
      'no filters');
  }

  componentWillReceiveProps(nextProps){
    this.tryRenderActiveOrders(nextProps);
  }

  componentWillMount(){
    this.doGetActiveOrders();
  }

  handleRowSelection= () => {

  }

  GridRowControls = (item) => (
    <div className="col-1">
      <Link to={ '/PersonalOrders/Order/'+item.dataRow.orderId } title="Детали заказа">
        <i className="fa fa-sliders" aria-hidden="true"></i>
      </Link>
    </div>
  )

  render() {
    const {
      requestActiveOrdersError,
      requestActiveOrdersInProcess,
      requestActiveOrdersSuccess
    } = this.props;

    return (
      <div>
        {
          requestActiveOrdersError&&
          <div>
            <font color="red">
              <p>Ошибка получения данных активных заказов...</p>
              <p>Сервис временно не доступен.</p>
            </font>
          </div>
        }
        {
          !requestActiveOrdersError&&
          requestActiveOrdersInProcess&&
          <LoadingView text='Получение данных...'/>
        }
        {requestActiveOrdersSuccess&&this.state.activeOrdersLoaded&&
        <PersonalOrdersActiveOrdersGrid
          gridData= { PersonalOrdersActiveOrders.activeOrders }
          onSelectItem= { this.handleRowSelection }
          RowControls= { this.GridRowControls }
        />
        }
        {
          this.state.activeOrdersCount === 0 &&
          <div>
            <p> В настоящее время <Link to="/PersonalOrders/OrderStatuses"> Активные </Link>
              заказы отсутсвуют </p>
          </div>
        }
      </div>
    )
  }
}
