import UserProfileView from '../../components/Desktop/UserProfile/UserProfileView';
import { connect } from 'react-redux';
import { getNewNotifications } from '../../actions/notifications';

const mapStateToProps = (state) => ({
  token: state.authorization.token,
  // newNotificationCount - ОБЯЗАТЕЛЬНО! передается для перерисовки меню когда изменится количество
  // но внутри модуля явно не используется
  newNotificationCount: state.notifications.newNotificationCount,
});

const mapDispathToProps = (dispath) => {
  return {
    getNewNotifications:
      ( token )=>
        dispath(getNewNotifications( token )),
  };
};

export default connect(
  mapStateToProps, mapDispathToProps
)(UserProfileView)
