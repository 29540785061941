import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ClaimsListFilter extends Component {
  static propTypes = {
    getClaimsList: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    customers: PropTypes.number,
  };

  doGetClaimsList = ()=> {
    this.props.getClaimsList(this.props.token,
      this.props.customers);
  }

  render() {
    const { requestClaimsListInProcess } = this.props;
    return (
      <div>
        <button onClick = { this.doGetClaimsList }
          disabled = { requestClaimsListInProcess }
          className="btn btn-outline-info btn-sm btn-block">
          <i className="fa fa-2x fa-refresh"/>
        </button>
      </div>
    );
  }
}
