import React, { Component } from 'react';
import { LogoView, FooterView } from '..';
import PropTypes from 'prop-types';
import { LoadingView }  from '../Main'

const EmailVerifycationError = ( { message } ) => (
  <ul className="list-group">
    <li className="list-group-item alert-danger d-flex">
      <i className="fa fa-exclamation-triangle fa-2x" aria-hidden="true"></i>
      <span className="ml-3">
        Ошибка подтверждения электронного адреса<br/>
        Обратитесь к Вашему персональному менеджеру
      </span >
    </li>
    <li className="list-group-item d-flex">
      <i className="fa fa-cog fa-2x" aria-hidden="true"></i>
      <span className="ml-3">Подробности: { message }</span >
    </li>
  </ul>
)

const EmailVerifycationSuccess = () => (
  <div className="alert alert-info zalert-info-card" role="alert">
    <h5>Электроный адрес успешно подтвержден</h5>
    <hr/>
    <p>В настоящее время <strong>запрос на активацию</strong> Вашего личного кабинета <strong>направлен специалисту</strong></p>
    <p>Мы обязательно сообщим, когда Ваш профиль будет полностью готов к работе</p>
    <hr/>
    <h6>Добро пожаловать в сервис zVision</h6>
  </div>
)

class RegistrationEmailVerifyView extends Component {
  static propTypes = {
    doEmailVerifycation: PropTypes.func.isRequired
  }

  componentWillMount(){
    const searchString = this.props.location.search.substr(1); // Удаляется первый символ "?"
    const params = new URLSearchParams(searchString);
    const token = params.get("token");
    const uid = params.get("uid");
    this.props.doEmailVerifycation (token, uid);
  }

  render() {
    const { emailVerified, emailVerifyError, emailVerifyInProcess, status } = this.props;
    return (
      <div className="zApp">
        <div className="container">
            <div className="row">
                <div className="col-xl-3 col-lg-0">
                  <LogoView/>
                </div>
                <div className="col-xl-6 col-md">
                  <h3>Подтверждение электронного адреса</h3>
                  <hr/>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-3 col-lg-0"></div>
                <div className="col-xl-6 col-md">
                {
                  emailVerifyError && <EmailVerifycationError message={ status }/>
                }
                {
                  emailVerifyInProcess&&
                  <LoadingView text='Запрос подтверждения почтового адреса...'/>
                }
                {
                  emailVerified && <EmailVerifycationSuccess/>
                }
                </div>
            </div>
        </div>
        <FooterView/>
      </div>
    );
  }
}

export default RegistrationEmailVerifyView;
