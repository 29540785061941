import { connect } from 'react-redux';
import { UserProfileState } from '../../components/Desktop/UserProfile';
import { getNewNotifications } from '../../actions/notifications';

const mapStateToProps = (state) => ({
  authorized: state.authorization.authorized,
  token: state.authorization.token,
  login: state.authorization.login,
  customers: state.authorization.userId,

  requestNewNotificationInProcess: state.notifications.requestNewNotificationInProcess,
  requestNewNotificationError: state.notifications.requestNewNotificationError,
  requestNewNotificationSuccess: state.notifications.requestNewNotificationSuccess,
  requestNewNotificationStatus: state.notifications.requestNewNotificationStatus,
  newNotificationCount: state.notifications.newNotificationCount,
});


const mapDispathToProps = (dispath) => {
  return {
    getNewNotifications:
      ( token )=>
        dispath(getNewNotifications( token )),
  };
};

export default connect(
  mapStateToProps, mapDispathToProps
)(UserProfileState)
