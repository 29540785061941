import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LogoView, FooterView, MainViewHelpView,MainAboutView,
  NavMainMenuCollapsable } from '..';
import './styles';
import { Route } from 'react-router-dom';
import { PrivateRoute, Cart } from '../../../../containers';
import { foundNewNotification } from '../../../../containers/UserProfile';

// Главная страница
import { MainViewIndexView } from './MainViewIndexView';

// Подключаемые модули
// Индивидуальные заказы
import { PersonalOrdersView } from '../../PersonalOrders'
// Дебиторская задолженность
import { ReceivablesView } from '../../Receivables'
//Претензии
import { ClaimsView } from '../../Claims'
//Профиль пользователя (для работы с Notifications потребовалось изначально подключение к Store )
//import { UserProfileView } from '../../UserProfile'  // Не подключенный к STORE
import { UserProfile } from '../../../../containers/'; // Подключенный к STORE
import ModalView from '../ModalView/ModalView';
import Toast from '../Toast/Toast';

const modalRoot = document.getElementById('app-toast-notification');

const UpdateAppMessage = ( { history, onCloseMessage } )=>{
  const onClick = ()=> {
    window.localStorage.setItem('NewVersionAvailable', 'N');
    history.push('/UpdateApp');
  }
  return (
    <Toast title="Обновление zVision" 
      message="Доступна новая версия zVision"
      visible={ true }
      onClick={ onClick }
      onCloseMessage={ onCloseMessage }
      buttonTitle="Обновить"/>
  );
}

class DesktopMainView extends Component {
  static propTypes = {
    systemVersion: PropTypes.string.isRequired,
    systemName: PropTypes.string.isRequired,
    newAppVersionAvailable: PropTypes.bool,
  }

  static menuItems = [];
  static authMenuItems = [];

  state = {
    collapsedMainMenu: true,
  }

  componentWillMount(){
    this.menuItems = [
      //{ caption: 'О системе', link: '/About' },
      { caption: 'Индивидуальные заказы', link: '/PersonalOrders' },
      { caption: 'Дебиторская задолженность', link: '/Receivables' },
      { caption: 'Претензии', link: '/Claims'},
      //{ caption: 'Обновления', link: '/Help', attention: false },
    ];

    this.authMenuItems = [];

    if (this.props.authorized) {
      this.authMenuItems.push(
        { caption: 'Личный кабинет', link: '/Profile',
          badgeVal: foundNewNotification,
        });
      this.authMenuItems.push({
        caption: 'Выход', link: '/Authorization/Logout',
        picture: ()=>(<i className="fa fa-sign-out" aria-hidden="true"></i>)
      });
    } else {
      this.authMenuItems.push({ caption: 'Регистрация', link: '/Registration' });
      this.authMenuItems.push({ 
        caption: 'Войти',
        link: '/Authorization',
        justified: true,
        picture: ()=>(<i className="fa fa-sign-in" aria-hidden="true"></i>)
      });
    }
  }

  // Сохранием предыдущий маршрут
  componentWillReceiveProps(nextProps) {
    window.previousLocation = this.props.location.pathname;
  }

  onToggleCollapse(){
    this.setState({
      collapsedMainMenu : !this.state.collapsedMainMenu
    });
  }

  render() {
    return (
      <div className="zApp">
        { (this.props.newAppVersionAvailable && !this.props.updateAppMessageClosed) &&
          <ModalView modalRoot={ modalRoot }>
            <UpdateAppMessage history={ this.props.history } onCloseMessage = {this.props.doCloseUpdateAppMessage}/>
          </ModalView>
        }
        <header className="border-bottom">
          <NavMainMenuCollapsable LogoView={LogoView} leftItems={this.menuItems} rightItems={this.authMenuItems} 
          collapsed={ this.state.collapsedMainMenu }
          onToggleCollapse = { ()=>this.onToggleCollapse() }
          />
        </header>

        <main className="app-modules" >
          {/*Если не выбран никакой модуль выводим MainViewIndexView*/}
          <Route exact path="/" component={ ()=>MainViewIndexView(this.props.authorized) } />
          <Route exact path="/index.html" component={ ()=>MainViewIndexView(this.props.authorized) } />
          {/*Список функциональных модулей*/}
          {
          <div>
            <PrivateRoute path="/Profile" component={ UserProfile /*UserProfileView*/ } />
            <PrivateRoute path="/PersonalOrders" component={ PersonalOrdersView } />
            <PrivateRoute path="/Receivables" component={ ReceivablesView } />
            <PrivateRoute path="/Claims" component={ ClaimsView } />
            <PrivateRoute path="/Cart" component={ Cart } />
            <Route exact path="/Help" component={ MainViewHelpView } />
            <Route exact path="/About" component={ ()=>MainAboutView(this.props.systemVersion) } />
          </div>
          }
        </main>
        <FooterView/>
      </div>
    );
  }
}

export default DesktopMainView;
