import React from 'react';
import { systemURL } from '../config/systemConfig';
import { checkAppUpdate } from '../actions/application';

export const killServiceWorkers = ()=>{
  if (window.navigator && navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations()
      .then(function(registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
  }
  return <p>kill ServiceWorkers</p>;
}

export const cleanCache = ()=>{
  if (window.navigator && navigator.serviceWorker) {
    caches.keys().then(function(cacheNames) {
      cacheNames.forEach(function(cacheName) {
        caches.delete(cacheName);
      });
    });
  }
  return <p>ServiceWorkers cache cleaned</p>;
}

export const f1 = ()=>{
  if (window.navigator && navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations()
      .then(function(registrations) {
        for (let registration of registrations) {
          console.log('Зарегистрированный ServiceWorker ', registration);
        }
      });
  }
  if (caches) {
    caches.keys().then(keys => { keys.forEach(key => console.log('Кэшированный URL ', key)) })
  }

  return <p>Read details in console</p>;
}

export const refreshAll = ()=>{
  // Очистка кэша ServiceWorker'ов
  if (window.navigator && navigator.serviceWorker) {
    caches.keys().then(function(cacheNames) {
      cacheNames.forEach(function(cacheName) {
        caches.delete(cacheName);
      });
    });
  }

  // Разрегистрация ServiceWorker'ов
  if (window.navigator && navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations()
      .then(function(registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
  }

  let timerId = null;
  timerId = setInterval(()=>{
    clearTimeout(timerId);
    window.location = systemURL;
  }, 200);

  return (
    <div className="container-fluid">
      <div className="row align-items-center" style={ {height: '100vh'} }>
        <div className="col-sm col-md-3"></div>
        <div className="col-sm col-md-6 ">
          <font color="silver" className="small">
            <div className="text-center mb-2">Приложение обновляется, пожалуйста подождите</div>
            <div className="progress">
              <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={ {width: '100%'} }>
                zVision updating ...
              </div>
            </div>
          </font>
        </div>
        <div className="col-sm col-md-3"></div>
      </div>
    </div>
  );
}

export class UdpateConfig {
  constructor(store) {
    this.store = store;
  }

  onUpdate( /* registration */ ) {
    // console.log('Доступно обновление приложения');
    window.localStorage.setItem('NewVersionAvailable', 'Y');
    this.store.dispatch(checkAppUpdate(true));
    //console.log('UdpateConfig.store - onUpdate')
  }

  onSuccess( /* registration */ ) {
    // console.log('Обновление успешно установлено');
    window.localStorage.setItem('NewVersionAvailable', 'N');
    this.store.dispatch(checkAppUpdate(false));
    //console.log('UdpateConfig.store - onSuccess');
  }
}
