import React from 'react';
import PropTypes from 'prop-types';
import './styles/CartControlsView.css';
import { Link } from 'react-router-dom';

export const CartControlsView = ({onInc, onDec, onEdit, currentVal})=> {
  let cartAmountValInput = undefined;

  function setCartAmountValInput(val) {
    cartAmountValInput = val;
  }

  function onFocus() {
    if (cartAmountValInput.value === cartAmountValInput.placeholder) {
      cartAmountValInput.select();
    }
  }

  return (
    <div >
      <input
        value={ currentVal }
        type="number"
        className="form-control cart-controls-input-amo"
        placeholder="0"
        onChange={ onEdit }
        onFocus={ onFocus }
        ref={ (input)=>setCartAmountValInput(input) }
      />
    </div>
  );
}

CartControlsView.propTypes = {
  onInc: PropTypes.func.isRequired,
  onDec: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  currentVal: PropTypes.number,
};

export const CartWidgetView = ({cartSum, cartGoodsQnt, doApplyCart})=> {
  return (
    <div className={ (cartGoodsQnt === 0) ? 'disabled-link' : null }>
      <div className="logoline__cartCover">
        <div className="logoline__cart">
          <Link to="/Cart" className="logoline__multicart__numbers logoline__multicart__numbers_3" title="Товаров в корзине">
            { cartGoodsQnt }
          </Link>
          {
            (cartSum > 0) &&
            <div className="logoline__cart__links">
              <Link to="/Cart" className="cart_summ" title="Перейти в корзину">
                { cartSum } р.
              </Link>
              {/*
                <button className="btn btn-link btn-sm" onClick = { doApplyCart }>
                  Оформить заказ
                </button>
              */}
              <p>
                <Link to="/Cart" className="cart_summ" title="Перейти в корзину">
                  Оформить заказ
                </Link>
              </p>
            </div>
          }
          {
            (cartSum === 0) &&
            <div className="logoline__cart__links">
              <p>Корзина пуста</p>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

CartWidgetView.propTypes = {
  cartSum: PropTypes.number.isRequired,
  cartGoodsQnt: PropTypes.number.isRequired,
  doApplyCart: PropTypes.func,
};
