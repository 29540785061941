import React, { Component } from 'react';
export default class PersonalOrdersImportOrderFilter extends Component {
  static propTypes = {
  };

  render() {
    return (
      <div className="d-flex ml-5 mt-2">
        <a title="Образец файла с заказом формат csv" 
            href="https://wss.zdravservice.ru/files/order.csv"
            className="internal-page-link-calm">
          <b>Образец файла</b>
        </a>
      </div>
    );
  }
}
