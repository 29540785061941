import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export const CartSuccessView = ( { orderId } )=> {
  return (
    <div className="row">
      <div className="col-xl-3 col-lg-0"></div>
      <div className="col-xl-7 col-md">
        <div className="row">
          <div className="col-10">
            <div className="alert alert-info zalert-info-card" role="alert">
              <h5>Заказ #{ orderId } оформлен</h5>
              <hr/>
              <p>
            Для отслеживания состояния заказа&nbsp;
                <Link to={ '/PersonalOrders/Order/' + orderId } className="internal-page-link-calm" >
                  <b>#{ orderId }</b>
                </Link>
             &nbsp;можно воспользоваться формой&nbsp;
                <Link to={ '/PersonalOrders/Orders' } className="internal-page-link-calm" >
                  <b>Активные заказы</b>
                </Link>
              </p>
            </div>
            <ul className="list-group">
              <li className="list-group-item d-flex">
                <i className="fa fa-clock-o fa-2x" aria-hidden="true"></i>
                <span className="ml-3">Мы получили Ваш заказ и начали работу над ним<br/>
              Оформленному заказу присвоен статус &nbsp;
                  <Link to={ '/PersonalOrders/OrderStatuses' } className="internal-page-link-calm" >
                    <b>«Передан на обработку»</b>
                  </Link>
                </span >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

CartSuccessView.propTypes = {
  orderId: PropTypes.number.isRequired,
};
