
export function checkHttpStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else {
    const error = new Error(response.statusText)
    error.response = response
    throw error
  }
}

export function checkAPIStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else {
    const error = new Error(response.statusText)
    error.response = response
    throw error
  }
}

export function validationJSON(response) {
  return response.json()
}

export function makeJSON(data) {
  return JSON.stringify(data)
}

export function validateInn(inn, error) {
  error.code = 0;
  let result = false;
  if (typeof inn === 'number') {
    inn = inn.toString();
  } else if (typeof inn !== 'string') {
    inn = '';
  }
  if (!inn.length) {
    error.code = 1;
    error.message = 'ИНН пуст';
  } else if (/[^0-9]/.test(inn)) {
    error.code = 2;
    error.message = 'ИНН может состоять только из цифр';
  } else if ([10, 12].indexOf(inn.length) === -1) {
    error.code = 3;
    error.message = 'ИНН может состоять только из 10 или 12 цифр';
  } else {
    let checkDigit = function(inn, coefficients) {
      let n = 0;
      for (let i in coefficients) {
        n += coefficients[i] * inn[i];
      }
      return parseInt(n % 11 % 10, 10);
    };
    switch (inn.length) {
      case 10:
        // eslint-disable-next-line no-case-declarations
        let n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
        if (n10 === parseInt(inn[9], 10)) {
          result = true;
        }
        break;
      case 12:
        // eslint-disable-next-line no-case-declarations
        let n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
        // eslint-disable-next-line no-case-declarations
        let n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
        if ((n11 === parseInt(inn[10], 10)) && (n12 === parseInt(inn[11], 10))) {
          result = true;
        }
        break;
      default: result = false;
    }
    if (!result) {
      error.code = 4;
      error.message = 'Неправильное контрольное число';
    }
  }
  return result;
}

export function validatePassword(pass, passConfirm, error) {
  error.code = 0;
  if (pass.length === 0) {
    error.code = 1;
    error.message = 'Пароль не указан';
    return false;
  }

  if (pass !== passConfirm) {
    error.code = 2;
    error.message = 'Введенные пароли не совпадают';
    return false;
  }

  if (pass.length < 6) {
    error.code = 3;
    error.message = 'Длинна пароля не может быть менее 6 символов';
    return false;
  }
}

export function formatDate(tryDate) {
  let rDate = new Date(Date.parse(tryDate));

  if (isNaN(rDate)) return tryDate;

  let dd = rDate.getDate();
  if (dd < 10) dd = '0' + dd;

  let mm = rDate.getMonth() + 1;
  if (mm < 10) mm = '0' + mm;

  let yy = rDate.getFullYear() % 100;
  if (yy < 10) yy = '0' + yy;

  return dd + '.' + mm + '.' + yy;
}

export function formatDateTime(tryDateTime) {
  let rDate = new Date(Date.parse(tryDateTime));

  if (isNaN(rDate)) return tryDateTime;

  let dd = rDate.getDate();
  if (dd < 10) dd = '0' + dd;

  let mm = rDate.getMonth() + 1;
  if (mm < 10) mm = '0' + mm;

  let yy = rDate.getFullYear() % 100;
  if (yy < 10) yy = '0' + yy;

  let hh = rDate.getHours();
  if (hh < 10) hh = '0' + hh;

  let mi = rDate.getMinutes();
  if (mi < 10) mi = '0' + mi;

  return dd + '.' + mm + '.' + yy + ' ' + hh + ':' + mi;
}


export function convertWin1251ToUTF8(data) {
  let iconvLite = require('iconv-lite');
  let result = undefined;
  try {
    data = new Buffer.from(data, 'binary');
    data = iconvLite.encode(iconvLite.decode(data, 'win1251'), 'utf-8');
    data = String(data);
    // data = String(convert(data, 'utf-8', 'win1251', true));
    result = data;
  } catch (E) {
    console.error(E);
    result = data;
  }
  return result;
}

export function moneyFormat(n) {
  return parseFloat(n).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1 ').replace('.', ',');
}

// Пересечение массивов
export function intersection(A, B) {
  let m = A.length;
  let n = B.length;
  let c = 0;
  let C = [];
  for (let i = 0; i < m; i++) {
    let j = 0;
    let k = 0;
    while (B[j] !== A[i] && j < n) j++;
    while (C[k] !== A[i] && k < c) k++;
    if (j !== n && k === c) C[c++] = A[i];
  }
  return C;
}

// Разность массивов
export function diff(A, B) {
  let M = A.length;
  let N = B.length;
  let c = 0;
  let  C = [];
  for (let i = 0; i < M; i++) {
    let j = 0;
    let k = 0;
    while (B[j] !== A[i] && j < N) j++;
    while (C[k] !== A[i] && k < c) k++;
    if (j === N && k === c) C[c++] = A[i];
  }
  return C;
}

// Объединение массивов
export function sum(A, B) {
  let M = A.length;
  let N = B.length;
  let count = 0;
  let C = [];
  C = A;
  count = M;
  for (let i = 0; i < N; i++) {
    let plus = false;
    for (let j = 0; j < M; j++)
      if (C[j] === B[i]) {plus = true; break;}
    if (plus === false) C[count++] = B[i];
  }
  return C;
}

// Симметрическая разность массивов
export function symmetricDiff(A, B) {
  let M = A.length;
  let N = B.length;
  let c = 0;
  let C = [];
  for (let i = 0; i < M; i++) {
    let j = 0;
    let k = 0;
    while (B[j] !== A[i] && j < N) j++;
    while (C[k] !== A[i] && k < c) k++;
    if (j === N && k === c) C[c++] = A[i];
  }
  for (let i = 0; i < N; i++) {
    let j = 0;
    let k = 0;
    while (A[j] !== B[i] && j < M) j++;
    while (C[k] !== B[i] && k < c) k++;
    if (j === M && k === c) C[c++] = B[i];
  }
  return C;
}

export function validateOutWaybillId(WaybillId, error) {
  error.code = 0;

  if ((WaybillId === null) || (WaybillId.length === 0)) {
    error.code = 1;
    error.message = 'Не указан код документа';
    return false;
  }

  if (/[^0-9]/.test(WaybillId)) {
    error.code = 3;
    error.message = 'Код документа может состоять только из цифр';
    return false;
  }

  if (WaybillId.length < 7) {
    error.code = 2;
    error.message = 'Длинна кода документа не может быть менее 7 символов';
    return false;
  }

  if ( WaybillId.length > 9 ) {
    error.code = 2;
    error.message = 'Длинна кода документа не может быть более 9 символов';
    return false;
  }
}

// Проверка на число (целое или дробное)
function isNumber(value) {
  if ((undefined === value) || (null === value)) {
    return false;
  }
  if (typeof value === 'number') {
    return true;
  }
  return !isNaN(value - 0);
}

/*
// Проверка на целое число
function isInteger(value) {
  if ((undefined === value) || (null === value)) {
    return false;
  }
  return value % 1 == 0;
} */

export function validateAmount(amount, error) {
  error.code = 0;
  if (amount.length === 0) {
    error.code = 1;
    error.message = 'Не указано количество';
    return false;
  }

  if (!isNumber(amount)) {
    error.code = 2;
    error.message = 'Количество должно задаваться числом';
    return false;
  }
}

export function xssfilter(s) {
  let div = document.createElement('div');
  div.innerHTML = s;

  // Удаление опасных элементов
  let result = document.evaluate('//script|//link|//iframe|//frameset|//frame|//applet|//object|//embed', div, null,
    XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);
  let i;

  for (i = 0; i < result.snapshotLength; i++) {
    const dangerousElement = result.snapshotItem(i);
    dangerousElement.parentNode.removeChild(dangerousElement);
  }

  // Удаление обработчиков
  result = document.evaluate('//*[@onclick or @onmouseover or @onfocus or @onblur or @onmouseout or @ondoubleclick or @onload or @onunload]', div, null,
    XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);
  for (i = 0; i < result.snapshotLength; i++) {
    const dangerousElement = result.snapshotItem(i);
    dangerousElement.removeAttribute('onFocus');
    dangerousElement.removeAttribute('onBlur');
    dangerousElement.removeAttribute('onClick');
    dangerousElement.removeAttribute('onMouseOver');
    dangerousElement.removeAttribute('onMouseOut');
    dangerousElement.removeAttribute('onDoubleClick');
    dangerousElement.removeAttribute('onLoad');
    dangerousElement.removeAttribute('onUnload');
  }

  // Удаление гиперссылок с встроенными скриптами на javascript, jscript, vbscript
  result = document.evaluate("//a[starts-with(translate(@href, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz'), 'javascript')]|//a[starts-with(translate(@href, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz'), 'jscript')]|//a[starts-with(translate(@href, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz'), 'vbscript')]", div, null,
    XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);
  for (i = 0; i < result.snapshotLength; i++) {
    const dangerousElement = result.snapshotItem(i);
    dangerousElement.setAttribute('href', '#');
  }

  // Удаление картинок с встроенными скриптами на javascript, jscript, vbscript
  result = document.evaluate("//img[starts-with(translate(@src, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz'), 'javascript')]|//img[starts-with(translate(@src, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz'), 'jscript')]|//img[starts-with(translate(@src, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz'), 'vbscript')]", div, null,
    XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);
  for (i = 0; i < result.snapshotLength; i++) {
    const dangerousElement = result.snapshotItem(i);
    dangerousElement.setAttribute('src', '#');
  }

  // Удаление стилей с включающих вычисления
  result = document.evaluate("//*[contains(translate(@style, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz'), 'expression')]", div, null,
    XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);
  for (i = 0; i < result.snapshotLength; i++) {
    const dangerousElement = result.snapshotItem(i);
    dangerousElement.parentNode.removeChild(dangerousElement);
    dangerousElement.removeAttribute('style');
  }

  // Удаление стилей с включающих вычисления
  result = document.evaluate("//*[contains(translate(@style, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz'), 'behavior')]", div, null,
    XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);
  for (i = 0; i < result.snapshotLength; i++) {
    const dangerousElement = result.snapshotItem(i);
    dangerousElement.parentNode.removeChild(dangerousElement);
    dangerousElement.removeAttribute('style');
  }

  return div.innerHTML;
}

export function getLocalStorageUsedSpace() {
  let allStrings = '';
  for (let key in window.localStorage) {
    // allStrings += key;
    if (window.localStorage.hasOwnProperty(key)) {
      allStrings += window.localStorage[key];
    }
  }
  let sizeLS = allStrings.length;

  let arr = ['bytes', 'KB', 'MB', 'GB', 'TB']; // Define Units
  let sizeUnit = 0;
  while (sizeLS > 1024) { // To get result in Proper Unit
    sizeUnit++;
    sizeLS /= 1024;
  }
  return sizeLS.toFixed(2) + ' ' + arr[sizeUnit];
}

// Экранирование символов для regexp
export function regExpEscape(literal_string) {
  // return literal_string.replace(/[-[\]{}()*+!<=:?.\/\\^$|#\s,]/g, '\\$&');
  return literal_string.replace(/[-[\]{}()*+!<=:?./\\^$|#\s,]/g, '\\$&');
}
