import { connect } from 'react-redux';
import { ReceivablesInfoFilter } from '../../components/Desktop/Receivables';
import { getReceivables } from '../../actions/Main/Receivables';

const mapStateToProps = (state) => ({
  authorized: state.authorization.authorized,
  token: state.authorization.token,
  login: state.authorization.login,
  customers: state.authorization.userId,

  requestReceivablesInProcess: state.receivables.requestReceivablesInProcess,
  requestReceivablesError: state.receivables.requestReceivablesError,
  requestReceivablesSuccess: state.receivables.requestReceivablesSuccess,
  requestReceivablesStatus: state.receivables.requestReceivablesStatus,
  requestReceivablesCustomers: state.receivables.requestReceivablesCustomers,
});

const mapDispathToProps = (dispath) => {
  return {
    getReceivables:
      ( token, customers )=>
        dispath(getReceivables(token, customers )),
  };
};

export default connect(
  mapStateToProps, mapDispathToProps
)(ReceivablesInfoFilter)
