export const ClaimsListGridHeader = [
  { caption: 'Код претензии',
    hint: 'Код документа "Претензия"',
    fieldName: 'DOC',
    flexWidth: 2,
    rowHrefTemplate: '/Claims/Details/{%DOC}',
  },
  { caption: 'Дата',
    hint: 'Дата документа',
    fieldName: 'DDATE',
    isDateTimeFormat: true,
    flexWidth: 2,
  },
  { caption: 'Состояние документа',
    fieldName: 'STATE',
    hint: 'Состояние',
    flexWidth: 6,
    href: '/Claims/ClaimStatuses',
  },
  { caption: 'Расходный документ',
    hint: 'Код расходного документ',
    fieldName: 'DPARENT',
    flexWidth: 2,
    rowHrefTemplate: '/Claims/CreateNew/{%DPARENT}',
  },

];

export { default as ClaimsListGrid } from './ClaimsListGrid';
