// Основные визуальные модули
import { PersonalOrderNotFoundView, PersonalOrderIndexView } from '../../components/Desktop/PersonalOrders'

// Содержимое основного окна модуля
import ConnectedPersonalOrdersCatalog        from '../../containers/PersonalOrders/ConnectedPersonalOrdersCatalog';
import ConnectedPersonalOrdersActiveOrders   from '../../containers/PersonalOrders/ConnectedPersonalOrdersActiveOrders';
import ConnectedPersonalOrdersOrder          from '../../containers/PersonalOrders/ConnectedPersonalOrdersOrder';
import ConnectedPersonalOrdersOrdersHistory from '../../containers/PersonalOrders/ConnectedPersonalOrdersOrdersHistory';
import ConnectedPersonalOrdersOrderHistory   from '../../containers/PersonalOrders/ConnectedPersonalOrdersOrderHistory';
import ConnectedPersonalOrdersImportOrder   from '../../containers/PersonalOrders/ConnectedPersonalOrdersImportOrder';

// Содержимое окна фильтров модуля
import ConnectedPersonalOrdersCatalogFilter from '../../containers/PersonalOrders/ConnectedPersonalOrdersCatalogFilter';
import ConnectedPersonalOrdersOrdersFilter  from '../../containers/PersonalOrders/ConnectedPersonalOrdersOrdersFilter';
import ConnectedPersonalOrdersOrderFilter   from '../../containers/PersonalOrders/ConnectedPersonalOrdersOrderFilter';
import ConnectedPersonalOrdersOrdersHistoryFilter   from '../../containers/PersonalOrders/ConnectedPersonalOrdersOrdersHistoryFilter';
import ConnectedPersonalOrdersOrderHistoryFilter   from '../../containers/PersonalOrders/ConnectedPersonalOrdersOrderHistoryFilter';
import PersonalOrdersImportOrderFilter   from '../../containers/PersonalOrders/ConnectedPersonalOrdersImportOrderFilter';

// Внутренние информационные страницы модуля
import { PersonalOrdersStatusesInfo } from '../../components/Desktop/PersonalOrders/PersonalOrdersStatics'

// Основной роут модуля
const PersonalOrdersPath = '/PersonalOrders';

// Настройки модуля PersonalOrders
const PersonalOrdersModules = [
  {
    caption: 'Каталог',
    exact: false,
    privateRoute: true,
    link: PersonalOrdersPath + '/Catalog',
    path: PersonalOrdersPath + '/Catalog',
    component: ConnectedPersonalOrdersCatalog,
    filterСomponent: ConnectedPersonalOrdersCatalogFilter,
  },
  {
    caption: 'Активные заказы',
    exact: false,
    privateRoute: true,
    link: PersonalOrdersPath + '/Orders',
    path: PersonalOrdersPath + '/Orders',
    component: ConnectedPersonalOrdersActiveOrders,
    filterСomponent: ConnectedPersonalOrdersOrdersFilter,
  },
  {
    caption: 'История заказов',
    exact: false,
    privateRoute: true,
    link: PersonalOrdersPath + '/History',
    path: PersonalOrdersPath + '/History',
    component: ConnectedPersonalOrdersOrdersHistory,
    filterСomponent: ConnectedPersonalOrdersOrdersHistoryFilter,
  },
  {
    exact: true,
    privateRoute: true,
    path: PersonalOrdersPath + '/Order/:OrderId(\\d{1,6})',
    component: ConnectedPersonalOrdersOrder,
    filterСomponent: ConnectedPersonalOrdersOrderFilter,
  },
  {
    exact: true,
    privateRoute: true,
    path: PersonalOrdersPath + '/OrderHistory/:OrderId(\\d{1,6})',
    component: ConnectedPersonalOrdersOrderHistory,
    filterСomponent: ConnectedPersonalOrdersOrderHistoryFilter,
  },
  {
    caption: 'Импорт данных',
    exact: true,
    privateRoute: true,
    link: PersonalOrdersPath + '/ImportOrder',
    path: PersonalOrdersPath + '/ImportOrder',
    component: ConnectedPersonalOrdersImportOrder,
    filterСomponent: PersonalOrdersImportOrderFilter,
  },
  {
    caption: 'Статусы заказов',
    exact: true,
    privateRoute: true,
    path: PersonalOrdersPath + '/OrderStatuses',
    component: PersonalOrdersStatusesInfo,
    filterСomponent: ()=>(null),
  },
  { // Индексная страница модуля PersonalOrders
    exact: false,
    privateRoute: true,
    path: PersonalOrdersPath + '/',
    component: PersonalOrderIndexView,
    filterСomponent: ()=>(null),
  },
  { // Сообщение "Объект не найден" - вывод в основное окно модуля
    exact: false,
    privateRoute: true,
    component: PersonalOrderNotFoundView,
    filterСomponent: ()=>(null),
  },
];
export default PersonalOrdersModules;
