import React from 'react';
import PropTypes from 'prop-types';
import { formatDate, moneyFormat } from '../../../../lib/utils'

export const OutWaybillHeadView = ({ data }) => {
  if (data.length > 0) {
    const { DOC, DSUM1, DDATE, LST_CNT } = data[0];
    return (
      <div>
        <p></p>
        <h5><b> Расходный документ # { DOC } </b> </h5>
        <div className="data-horisontal-grid">
          <table className="table table-sm table-responsive data-horisontal-grid-table">
            <tbody>

              <tr className="data-horisontal-grid-item">
                <td>Сумма по документу</td>
                <td><b>{ moneyFormat(DSUM1) + ' р.' }</b></td>
              </tr>

              <tr className="data-horisontal-grid-item">
                <td>Дата создания</td>
                <td><b>{ formatDate(DDATE) }</b></td>
              </tr>

              <tr className="data-horisontal-grid-item">
                <td>Количество строк</td>
                <td><b>{ LST_CNT }</b></td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    )
  } else return null;
};

OutWaybillHeadView.propTypes = {
  data: PropTypes.array.isRequired,
}
