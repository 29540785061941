import React, { Component } from 'react';
// import { LoadingView } from '../..';
import PropTypes from 'prop-types';

export const DataStatisticsView = (props) => {
  return (
    <div>
      <div className="row">
        <div className="col">
          <b>
            { props.requestCatalogError &&
              props.requestCatalogStatus
            }
          </b>
        </div>
      </div>
      { !props.requestCatalogInProcess &&
        <div>
          <div className="row mt-3">
            <div className="col-auto text-left">Обновлен</div>
            <div className="col text-right small"><b>{props.catalogRefreshTime}</b></div>
          </div>

          <div className="row mt-3">
            <div className="col-auto text-left">Количество строк</div>
            <div className="col text-right small"><b>{props.catalogLinesCount}</b></div>
          </div>

          <div className="row mt-3">
            <div className="col-auto text-left">ERP идентификатор</div>
            <div className="col text-right small"><b>{props.catalogClientId}</b></div>
          </div>

          <div className="row mt-3">
            <div className="col">
              { props.catalogExpired === false 
                 ?<div className="alert alert-success text-center">Актуален</div>
                 :<div className="alert alert-danger  text-center">Устарел</div>
              }
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default class PersonalOrdersCatalogFilter extends Component {
  static propTypes = {
    doRefreshCatalog: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    customers: PropTypes.number,
  };

  doRefreshCatalog = (event) => {
    this.props.doRefreshCatalog(false, this.props.token, this.props.customers, 'no filter');
    event.preventDefault();
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col">
            <DataStatisticsView
              catalogRefreshTime={ this.props.catalogRefreshTime }
              catalogLinesCount={ this.props.catalogLinesCount }
              catalogExpired={ this.props.catalogExpired }
              requestCatalogInProcess={ this.props.requestCatalogInProcess }
              requestCatalogError={ this.props.requestCatalogError }
              requestCatalogStatus={ this.props.requestCatalogStatus }
              catalogClientId={ this.props.catalogClientId }
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col">
            <div>
            { !this.props.requestCatalogInProcess &&
              <button onClick = { this.doRefreshCatalog }
                disabled = { this.props.requestCatalogInProcess }
                className="btn btn-outline-info btn-block">
                <i className="fa fa-2x fa-refresh"/>
              </button>
            }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
