import { combineReducers } from 'redux';
import authorization from './authorization';
import registration from './registration';
import cart from './cart';
import personalOrders from './personalOrders';
import passwordReset from './passwordReset';
import receivables from './receivables';
import claims from './claims';
import notifications from './notifications';
import application from './application';
import covid19 from './covid19'

export default combineReducers({
  authorization,
  registration,
  personalOrders,
  receivables,
  claims,
  cart,
  passwordReset,
  notifications,
  application,
  covid19,
})
