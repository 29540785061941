import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute } from '..';

// Настройки модуля
import ReceivablesModules from '../../routes/Receivables/ReceivablesIndex';
// Строка меню модуля
import { MenuLine as Menu} from '../../components/Desktop';

export const ReceivablesMenu = () => (
  <Menu items={ ReceivablesModules } />
)

export const ReceivablesRoutes = () => (
  // <Switch> - т.к. внутри Receivables могут быть вызваны не существующие маршруты
  <Switch>
    {ReceivablesModules.map(
      (props, i)=>(
        props.privateRoute ?
          <PrivateRoute key={ i } { ...props }/> :
          <Route key={ i } { ...props }/>
      )
    )}
  </Switch>
)

export const ReceivablesFilter = () => (
  ReceivablesModules.map(
    (props, i)=>(
      props.privateRoute ?
        <PrivateRoute key={ i } { ...props } component = { props.filterСomponent }/> :
        <Route        key={ i } { ...props } component = { props.filterСomponent }/>
    )
  )
)
