import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom'
import { LogoView, FooterView } from '..';
import { systemVersion } from '../../../config/systemConfig';


export default class AuthorizationView extends Component {
  static propTypes = { //Заглушка для линтера (((
    doAuthorization: PropTypes.func.isRequired
  }

  doAPIAuthorization = (event) => {
    event.preventDefault();//Чтобы не перезагружалась форма
    this.props.doAuthorization(this.emailInput.value, this.passwordInput.value);
  }

  componentWillMount(){
    // Если задан маршрут Authorization/Logout то осуществляется выход из системы
    let doLogout = this.props.location.pathname === "/Authorization/Logout";
    if (doLogout) this.props.doLogout();
  }

  readAuthorizationStatus(){
    const authorizationStatus = this.props.status;
    if (authorizationStatus===null) return authorizationStatus;
    if(~authorizationStatus.indexOf('Авторизация'))return '';

    if(~authorizationStatus.indexOf('NetworkError'))
    return 'Ошибка авторизации: сервер временно не доступен'; else
    return authorizationStatus;
  }

  render() {
    let fromProtectedRoute = this.props.location.state?
      this.props.location.state.fromProtectedRoute.pathname:'/';
    const { authorizationInProcess } = this.props;
    return (
      <div className="zApp">
        <div className="container app-modules">
            { this.props.isAuthenticated ?
                <Redirect to={ fromProtectedRoute }/> : 
                true
            }
            <div className="row">
                <div className="col-lg-3 col-md-0">
                  <LogoView/>
                </div>
                <div className="col-lg-6 col-md mt-2">
                  <h3>Вход в систему</h3>
                  <p className="text-danger">{ this.readAuthorizationStatus() }</p>
                </div>
                <div className="col-3 mt-3">
                  <Link to="/Registration"><span className="text-nowrap">Регистрация в системе</span></Link>
                </div>
            </div>

            <div className="row">
                <div className="col">
                  <div className="d-md-none">
                    <hr/>
                  </div>
                </div>
            </div>


            <div className="row">
                <div className="col-lg-3 col-md-1"></div>
                <div className="col-lg-6 col-md-10">

                  <form onSubmit={ this.doAPIAuthorization }>
                    <div className="form-group">
                      <input type="text"
                        ref={ (input)=>{this.emailInput = input} }
                        className="form-control input-lg"
                        required="required"
                        placeholder="Email" />
                    </div>
                    <div className="form-group">
                      <input type="password"
                        ref={ (input)=>{this.passwordInput = input} }
                        className="form-control input-lg"
                        placeholder="Пароль" />
                    </div>
                  <div className="row">
                    <div className="col-6">
                      <Link to="/PasswordReset/Request"><b>Забыли пароль?</b></Link>
                    </div>
                    <div className="col-lg col-md-0">
                      <button type="submit"
                        disabled={authorizationInProcess}
                        className="btn btn-outline-info btn-block">
                            {(authorizationInProcess) &&
                            <div>
                                Авторизация...&nbsp;
                                <i className="fa fa-circle-o-notch fa-spin fa-lg fa-fw"></i>
                            </div>
                            }
                            {(!authorizationInProcess) &&
                            <div>
                              Вход
                            </div>
                            }
                        </button>
                    </div>
                  </div>
                  </form>
                </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-3 col-md-0"></div>
              <div className="col-lg-6 col-md">
                <div className="alert alert-info zalert-info-card" role="alert">
                  Сейчас Вы пользуетесь версией <b>zVision { systemVersion }</b>
                  <hr/>
                  <p>
                    Для получения новой версии <small>(если она уже доступна)</small> Вы можете нажать сочетание клавиш <br/>
                    <kbd> Ctrl</kbd> <kbd>F5</kbd> или <kbd> Ctrl</kbd> <kbd>R</kbd>
                  </p>
                </div>
              </div>
            </div>
        </div>
        <FooterView/>
      </div>
    );
  }
}