import React, { Component } from 'react';
import { LoadingView }  from '../../Main'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';

export default class ClaimsUndoClaim extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    customers: PropTypes.number.isRequired,
    doInitUndoClaim: PropTypes.func.isRequired,
  };

  componentWillUnmount() {
    this.props.doInitUndoClaim();
  }

  doUndoClaim = () => {
    const { ClaimId } = this.props.match.params;
    this.props.doUndoClaim(this.props.token,
      this.props.customers,
      ClaimId);
  }

  render() {
    const { ClaimId } = this.props.match.params;
    const {
      requestUndoClaimInProcess,
      requestUndoClaimError,
      requestUndoClaimSuccess,
      requestUndoClaimStatus,
      undoClaimId } = this.props;

    return (
      <div>
        <div className="row">
          <div className="col">
            <Link to={ '/Claims/Details/' + ClaimId } className="internal-page-link-calm">
              <div
                disabled = { requestUndoClaimInProcess }
                className="btn btn-outline-info btn-sm">
                <i className="fa fa-arrow-left" aria-hidden="true"></i>
                 &nbsp;Документ #{ ClaimId }
              </div>
            </Link>
          </div>
        </div>
        <hr/>

        {
          !requestUndoClaimError &&
          requestUndoClaimInProcess &&
          <div className="row">
            <div className="col">
              <LoadingView text={ 'Отмена претензии...' }/>
            </div>
          </div>
        }

        {
          requestUndoClaimError &&
          <div className="row">
            <div className="col">
              <font color="red">
                <p>{requestUndoClaimStatus}</p>
              </font>
            </div>
          </div>
        }

        {
          requestUndoClaimSuccess &&
          <div className="row">
            <div className="col">
              <div className="alert alert-warning" role="alert">
                Претензия <b># { ClaimId }</b> успешно отменена
              </div>
            </div>
          </div>
        }

        { !undoClaimId &&
        <div className="row">
          <div className="col">
            <div className="alert alert-warning" role="alert">
              Если Вы хотите отменить претензию <b># { ClaimId }</b>, пожалуйста нажмите кнопку "Отменить претензию"
            </div>
            <div onClick = { this.doUndoClaim }
              disabled = { requestUndoClaimInProcess }
              className="btn btn-outline-success btn-sm">
              <i className="fa fa-thumbs-o-up"/>&nbsp;
              Отменить претензию
            </div>
          </div>
        </div>
        }

      </div>
    )
  }
}
