import React from 'react';
import { LogoView, FooterView } from '..';
import { Link } from 'react-router-dom';
import { systemName, systemVersion } from '../../../config/systemConfig';

export const PasswordResetSuccessView = () => {
  return (
    <div className="zApp">
      <div className="container app-modules">

        <div className="row">
          <div className="col-lg-3 col-md-0">
            <LogoView/>
          </div>
          <div className="col-lg-6 col-md mt-2">
            <h3>Изменение пароля</h3>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="d-md-none">
              <hr/>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-0"></div>
          <div className="col-lg-6 col-md-0">
            <div className="alert alert-success zalert-info-card" role="alert">
              <p>Новый пароль успешно сохранен</p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-0"></div>
          <div className="col-lg-6 col-md-0">
            <div className="row no-gutters">
              <div className="col-md-6 col-sm ml-auto">
                <Link to="/Authorization" className="internal-page-link-calm-nu" title="Авторизация">
                  <button type="submit" className="btn btn-outline-info btn-sm btn-block">
                    Авторизация
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-3 col-md-0"></div>
          <div className="col-lg-6 col-md">
            <div className="alert alert-info zalert-info-card" role="alert">
                Сейчас Вы пользуетесь версией <b>{ systemName } { systemVersion }</b>
              <hr/>
              <p>
                  Для получения новой версии <small>(если она уже доступна)</small> Вы можете нажать сочетание клавиш <br/>
                <kbd> Ctrl</kbd> <kbd>F5</kbd> или <kbd> Ctrl</kbd> <kbd>R</kbd>
              </p>
            </div>
          </div>
        </div>

      </div>
      <FooterView/>
    </div>
  );

}
