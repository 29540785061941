import React from 'react';
import PropTypes from 'prop-types';
import { regExpEscape } from '../../../../lib/utils';

export const ClaimsListGridSearch = ({ term, data, onUpdate, searchField }) => {
  const dataSearch = e => {
    const value = e.target.value.toLowerCase();
    const arr = value.split('-');
    let searchParam1 = arr[0];
    let searchParam2 = arr[1];
    searchParam2 = searchParam2 ? ('.*' + regExpEscape(searchParam2) + '.*' ) : '.*';
    const searchString = '^.*' + regExpEscape(searchParam1) + searchParam2 + '$';
    const filter = data.filter(good => {
      const val = String(good[searchField]);
      return val.toLowerCase().search(searchString) !== -1;
    });
    onUpdate({
      data: filter,
      active: 0,
      term: value,
    });
  };

  return (
    <div className="searchbar form-group">
      <div className="d-flex pt-0">
        <font color="silver">
          <p>Поиск осуществляется по коду расходного документа, например E5242616/<b>20468236</b> (выделен жирным)</p>
        </font>
      </div>
      <input
        value={ term }
        type="text"
        className="form-control"
        placeholder="Расходный документ..."
        onChange={ dataSearch }
        title='Для поиска по двум фрагментам наименования используется символ "-"'
      />
    </div>
  );
}

ClaimsListGridSearch.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  term: PropTypes.string,
  searchField: PropTypes.string,
};
