import { connect } from 'react-redux';
import { PersonalOrdersOrder }  from '../../components/Desktop/PersonalOrders';
import { getOrderDetails } from '../../actions/Main/PersonalOrders';

const mapStateToProps = (state) => ({
  authorized: state.authorization.authorized,
  token: state.authorization.token,
  login: state.authorization.login,

  // customers: state.authorization.userId,
  customers: state.authorization.childUserId || state.authorization.userId,

  requestOrderDetailsInProcess: state.personalOrders.requestOrderDetailsInProcess,
  requestOrderDetailsError: state.personalOrders.requestOrderDetailsError,
  requestOrderDetailsSuccess: state.personalOrders.requestOrderDetailsSuccess,
  requestOrderDetailsStatus: state.personalOrders.requestOrderDetailsStatus,
  orderIdForDetails: state.personalOrders.orderIdForDetails,
});

const mapDispathToProps = (dispath) => {
  return {
    doGetOrderDetails:
      ( token, customers, orderId )=>
        dispath(getOrderDetails(token, customers, orderId )),
  };
};

export default connect(
  mapStateToProps,
  mapDispathToProps
)(PersonalOrdersOrder)
