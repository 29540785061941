import { connect } from 'react-redux';
import { autorizationLogout } from '../actions/authorization';
import { сloseUpdateAppMessage } from '../actions/application';
import { DesktopMainView as MainView} from '../components/Desktop';
import { systemVersion, systemName } from '../config/systemConfig'

const mapStateToProps = (state) => ({
  systemName: systemName,
  systemVersion: systemVersion,
  newAppVersionAvailable: state.application.newAppVersionAvailable,
  updateAppMessageClosed: state.application.updateAppMessageClosed,
  authorized: state.authorization.authorized,
  token: state.authorization.token,
  login: state.authorization.login,
});

const mapDispathToProps = (dispath) => {
  return {
    doLogout: () => dispath(autorizationLogout()),
    doCloseUpdateAppMessage: () => dispath(сloseUpdateAppMessage()),
  };
};

export default connect(
  mapStateToProps,
  mapDispathToProps
)(MainView)
