import React from 'react';
import { LogoView, FooterView } from '..';

export const RegistrationSuccessView = () => (
  <div className="zApp">
    <div className="container">
      <div className="row">
        <div className="col-xl-3 col-lg-0">
          <LogoView/>
        </div>
        <div className="col-xl-6 col-md mt-2">
          <h3>Подтверждение электронного адреса</h3>
        </div>
        <div className="col-3"></div>
      </div>
      <div className="row">
        <div className="col-xl-3 col-lg-0"></div>
        <div className="col-xl-6 col-md mt-2">
          <h5>Благодарим Вас за использование нашего сервиса</h5>
          <div className="alert alert-info zalert-info-card" role="alert">
            <p>
              Для завершения регистрации подтвердите Ваш электронный адрес
            </p>
            <hr/>
            <p>
              Необходимая для этого <strong>информация направлена</strong> на указанный Вами <strong>электронный адрес</strong> службой <a href="https://zvision.zdravservice.ru/Authorization">zVision</a>
            </p>
          </div>
          <ul className="list-group">
            <li className="list-group-item alert alert-danger d-flex">
              <i className="fa fa-exclamation-triangle fa-2x" aria-hidden="true"></i>
              <span className="ml-3">Если Вы не видите письма в папке входящих, пожалуйста, проверьте папку «спам»</span >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <FooterView/>
  </div>
)
