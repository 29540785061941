import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles';

export default class DropDownItems extends Component {
  static propTypes = {
    buttonClassName: PropTypes.string,
    buttonTitle: PropTypes.string,
    items: PropTypes.object.isRequired,
    onSelectItem: PropTypes.func,
  }

  state = {
    visible: false,
    title: undefined,
    activeItemId: undefined
  }

  onToggleView(){
    this.setState({
      visible: !this.state.visible
    });
  }

  onChangeItem(activeItemId){
    this.setState({
      activeItemId: activeItemId,
      title: this.props.items[activeItemId],
      visible: false,
    });
    this.props.onSelectItem(activeItemId);
  }

  render(){
    const { title, visible, activeItemId } = this.state;
    let buttonUpDownStyleClass= visible ? ' dropup ' : ' dropdown '; // стрелочка на кнопке
    let className=" d-block ";
    if (this.state.visible === false) className = " d-none ";
    return(
      <div className={ buttonUpDownStyleClass } >
        <div className={"btn dropdown-toggle dropup " + this.props.buttonClassName }
          onClick={this.onToggleView.bind(this)} id="dropdownMenuButton">
          { title||this.props.buttonTitle }
        </div>
        <ul className={"drop-down-items-list list-group" + className} aria-labelledby="dropdownMenuButton">
        {
          Object.keys(this.props.items).map((items, index) =>{
            let activeItemClass = "";
            if (activeItemId === items) activeItemClass = " drop-down-items-active ";
            return (
            <li className={ "drop-down-items list-group-item " + activeItemClass } key={ index }
                onClick={()=>this.onChangeItem(items)}
            >
              {this.props.items[items]}
            </li>);
            })
        }
        </ul>
      </div>
    );
  }
}
