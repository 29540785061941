import { connect } from 'react-redux';
import { ClaimsClaimCreateError } from '../../components/Desktop/Claims';

import { createNewClaimInitProcess } from '../../actions/Main/Claims';

const mapStateToProps = (state) => ({
  authorized: state.authorization.authorized,
  token: state.authorization.token,
  login: state.authorization.login,
  // customers: state.authorization.userId,
  customers: state.authorization.childUserId || state.authorization.userId,
});

const mapDispathToProps = (dispath) => {
  return {
    createNewClaimInitProcess:
      ( )=>
        dispath(createNewClaimInitProcess( )),
  };
};


export default connect(
  mapStateToProps, mapDispathToProps
)(ClaimsClaimCreateError)
