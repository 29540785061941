import { connect } from 'react-redux';
import { saveClientNeeds } from '../actions/covid19';

// Основные визуальные модули
import { Covid19View } from '../components/Desktop';

const mapStateToProps = (state) => ({
  saveClientNeedsProcess: state.covid19.saveClientNeedsProcess,
  saveClientNeedsError: state.covid19.saveClientNeedsError,
  saveClientNeedsSuccess: state.covid19.saveClientNeedsSuccess,
  saveClientNeedsStatus: state.covid19.saveClientNeedsStatus,
  newClientNeedsId: state.covid19.needsId,
});

const mapDispathToProps = (dispath) => {
  return {
    saveClientNeeds: ( personFullName, personEmail, personPhone,
      companyName, companyINN, regionName, deliveryAddress, needsData ) => dispath(
      saveClientNeeds( personFullName, personEmail, personPhone,
        companyName, companyINN, regionName, deliveryAddress, needsData )
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispathToProps
)(Covid19View)
