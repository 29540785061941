import React, { Component } from 'react';
/**
 * Каждый модуль имеет три основных компонента
 * Routes - маршруты
 * Menu - меню
 * Filter - фильтры
 */
import { PersonalOrdersRoutes, PersonalOrdersMenu, PersonalOrdersFilter }
  from '../../../containers/PersonalOrders';

export default class PersonalOrdersView extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-xl-1 col-lg-0 module-menu-line-color border-bottom"></div>
          <div className="col module-menu-line-color border-bottom">
            {/* Главное меню модуля */}
            <PersonalOrdersMenu/>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-1 col-lg-0"></div>
          <div className="col-2">
            {/* Дополнительное окно модуля */}
            <PersonalOrdersFilter/>
            <br/>
          </div>
          <div className="col-xl-7 col-md">
            {/* Основное окно модуля */}
            <PersonalOrdersRoutes/>
          </div>
        </div>
      </div>
    );
  }
}
