import	{
  REQUEST_PASSWORD_RESET_REQUEST,
  REQUEST_PASSWORD_RESET_SUCCESS,
  REQUEST_PASSWORD_RESET_ERROR,

  PROCESS_PASSWORD_RESET_REQUEST,
  PROCESS_PASSWORD_RESET_SUCCESS,
  PROCESS_PASSWORD_RESET_ERROR,  
  REQUEST_PASSWORD_RESET_INIT,
}	from	'../constants/passwordReset';

const initialState = {
  requestPasswordResetInProcess: false,
  requestPasswordResetError: false,
  requestPasswordResetSuccess: false,
  requestPasswordResetStatus: null,
  email: null,

  processPasswordResetInProcess: false,
  processPasswordResetError: false,
  processPasswordResetSuccess: false,
  processPasswordResetStatus: null,
};

export default function passwordReset(state = initialState, action) {
  switch (action.type) {

    // Запрос токена для язменения забытого пароля
    case REQUEST_PASSWORD_RESET_REQUEST:
      if (process.env.NODE_ENV === 'development') console.log('raise action - REQUEST_PASSWORD_RESET_REQUEST', action);
      return { ...state, 
        requestPasswordResetInProcess: true,
        requestPasswordResetError: false,
        requestPasswordResetSuccess: false,
        email: action.email,
        requestPasswordResetStatus: 'Запрос на восстановление забытого пароля...'
      }

    case REQUEST_PASSWORD_RESET_SUCCESS:
      if (process.env.NODE_ENV === 'development') console.log('raise action - REQUEST_PASSWORD_RESET_SUCCESS', action);
      return { ...state, 
        requestPasswordResetInProcess: false,
        requestPasswordResetError: false,
        requestPasswordResetSuccess: true,

        requestPasswordResetStatus: 'Запрос на восстановление забытого пароля обработан.'
      }

    case REQUEST_PASSWORD_RESET_ERROR:
      if (process.env.NODE_ENV === 'development') console.log('raise action - REQUEST_PASSWORD_RESET_ERROR', action);
      return { ...state, 
        requestPasswordResetInProcess: false,
        requestPasswordResetError: true,
        requestPasswordResetSuccess: false,

        requestPasswordResetStatus: `Ошибка выполнения запроса на восстановление забытого пароля: 
          ${action.error.errorCode}
          ${action.error.errorMessage}`
      }

      case REQUEST_PASSWORD_RESET_INIT:
      if (process.env.NODE_ENV === 'development') console.log('raise action - REQUEST_PASSWORD_RESET_INIT', action);
      return { ...state, 
        requestPasswordResetInProcess: false,
        requestPasswordResetError: false,
        requestPasswordResetSuccess: false,
        requestPasswordResetStatus: null,
        email: null,
      }



    // Изменение забытого пароля с задействованием токена
    case PROCESS_PASSWORD_RESET_REQUEST:
      if (process.env.NODE_ENV === 'development') console.log('raise action - PROCESS_PASSWORD_RESET_REQUEST', action);
      return { ...state, 
        processPasswordResetInProcess: true,
        processPasswordResetError: false,
        processPasswordResetSuccess: false,
        processPasswordResetStatus: 'Запрос на изменение забытого пароля...'
      }

    case PROCESS_PASSWORD_RESET_SUCCESS:
      if (process.env.NODE_ENV === 'development') console.log('raise action - PROCESS_PASSWORD_RESET_SUCCESS', action);
      return { ...state, 
        processPasswordResetInProcess: false,
        processPasswordResetError: false,
        processPasswordResetSuccess: true,

        processPasswordResetStatus: 'Запрос на изменение забытого пароля обработан.'
      }

    case PROCESS_PASSWORD_RESET_ERROR:
      if (process.env.NODE_ENV === 'development') console.log('raise action - PROCESS_PASSWORD_RESET_ERROR', action);
      return { ...state, 
        processPasswordResetInProcess: false,
        processPasswordResetError: true,
        processPasswordResetSuccess: false,

        processPasswordResetStatus: `Ошибка выполнения запроса на изменение забытого пароля: 
          ${action.error.errorCode}
          ${action.error.errorMessage}`
      }

    default:
      return state;
  }
}
