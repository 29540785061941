import React, { Component } from 'react';
import { LoadingView }  from '../../Main'
//import { ReceivablesInfoGrid } from '../ReceivablesInfo'
import { ClaimsListGrid } from '../ClaimsList'
import { Link } from 'react-router-dom'

export default class ClaimsList extends Component {
  static claimsListDetails = {};
  state = {
    claimsListLoaded: false,
    claimsCount: null,
  }

  /*
  requestClaimsListInProcess: state.claims.requestClaimsListInProcess,
  requestClaimsListError: state.claims.requestClaimsListError,
  requestClaimsListSuccess: state.claims.requestClaimsListSuccess,
  requestClaimsListStatus: state.claims.requestClaimsListStatus,
   */
  
  tryRenderClaimsList(newProps){
    if( newProps.requestClaimsListSuccess ){
      let lsData = JSON.parse(localStorage.getItem('ClaimsList'));
      if (lsData !== null && typeof lsData === 'object') {
        ClaimsList.claimsListDetails = lsData;
          this.setState({
            claimsListLoaded: true,
            claimsCount: lsData.length
          });
        }
      }else{
        this.setState({
          claimsListLoaded: false,
          claimsCount: null,
        });
      }
  }

  componentWillReceiveProps(nextProps){
    this.tryRenderClaimsList(nextProps);
  }

  doGetClaimsList(){
    this.props.getClaimsList(this.props.token,
      this.props.customers);
  }

  componentWillMount(){
    this.doGetClaimsList();
  }

  handleRowSelection(row){
    //this.props.history.push('/Claims/Details/'+row.DOC);
  }

  render() {
     const {
      requestClaimsListInProcess,
      requestClaimsListError,
      requestClaimsListSuccess
    } = this.props;

    const {
      claimsCount
    } = this.state;

    return (
      <div>
        <Link to="/Claims/CreateNew" className="internal-page-link-calm">
          <div 
            disabled = { requestClaimsListInProcess }
            className="btn btn-outline-success btn-sm">
            <i className="fa fa-share-square-o"/>
            &nbsp;Создать претензию
          </div>
        </Link>
        <hr/>

        {
          requestClaimsListError&&
          <div>
            <font color="red">
              <p>Ошибка получения данных о претензиях...</p>
              <p>Сервис временно не доступен.</p>
            </font>
          </div>
        }

        {
          !requestClaimsListError&&
          requestClaimsListInProcess&&
          <LoadingView text={'Получение данных о претензиях...'}/>
        }

        {
          requestClaimsListSuccess&&this.state.claimsListLoaded &&
          <div>
            <ClaimsListGrid
              gridData= { ClaimsList.claimsListDetails }
              onSelectItem= { this.handleRowSelection.bind(this) }
              RowControls= { this.GridRowControls }
            />
          </div>
        }
        {
          requestClaimsListSuccess&&this.state.claimsListLoaded && (claimsCount === 0) &&
          <p>В настоящее время претензии отсутствуют</p>
        }



      </div>
    );
  }
}
