import { connect } from 'react-redux';
import { CartView } from '../components/Desktop/Main';
import { edtCart, removeFromCart, clearCart } from '../actions/cart';

import { applyCart, initCart } from '../actions/cart';


// Меню модуля который является родительским элементом интернет магазина или каталога
import { PersonalOrdersMenu as ParentTradeModuleMenu } from './PersonalOrders';

export const cartGridHeader = [
  { caption: 'Наименование',
    fieldName: 'MEDS_NAME',
    flexWidth: 2,
  },
  { caption: 'Производитель',
    fieldName: 'PRODUCERS_NAME',
    flexWidth: 2,
  },
  { caption: 'Страна',
    fieldName: 'COUNTRIES_NAME',
    flexWidth: 2,
  },
  { caption: 'Годен до',
    fieldName: 'BEST_BEFORE',
    flexWidth: 2,
  },
  { caption: 'Цена',
    fieldName: 'PRICE',
    isCurrency: true,
    currencyName: 'руб.',
    roundTo: 2,
    flexWidth: 1,
    columnSpacer: 'd-flex justify-content-end',
  },
  { caption: 'Остаток',
    fieldName: 'AMOUNT',
    flexWidth: 1,
    columnSpacer: 'd-flex ml-3 justify-content-end',
  },
  { caption: 'Заказ',
    hint: '',
    fieldName: 'ROW_CONTROLS',
    flexWidth: 1,
    columnSpacer: 'ml-2',
  },
];

// Поля необходимые для работы контролов корзины
export const cartControlsKeyFieldsMap = { goodsId: 'MEDS', goodsName: 'MEDS_NAME', goodsPrice: 'PRICE' };

const mapStateToProps = (state) => ({
  authorized: state.authorization.authorized,
  token: state.authorization.token,
  login: state.authorization.login,
  // customers: state.authorization.userId,
  customers: state.authorization.childUserId || state.authorization.userId,

  cart: state.cart,
  cartGridHeader: cartGridHeader, // Настройки колонок корзины
  ParentTradeModuleMenu: ParentTradeModuleMenu,
  cartControlsKeyFieldsMap: cartControlsKeyFieldsMap,
});

const mapDispathToProps = (dispath) => {
  return {
    doAddToCart:
      ( goodsId, goodsName, goodsAmount, goodsPrice )=>
        dispath(edtCart(goodsId, goodsName, goodsAmount, goodsPrice )),
    doRemoveFromCart:
      ( goodsId ) =>
        dispath(removeFromCart( goodsId )),
    doClearCart: () => dispath(clearCart()),
    doInitCart: () => dispath(initCart()),
    doApplyCart: (token, cart, clientId, props) =>
      dispath(applyCart(token, cart, clientId, props )),
  };
};

export default connect(
  mapStateToProps,
  mapDispathToProps
)(CartView)
