import { apiCommandPOSTLogin, apiCommandGETGetMyId,
  apiCommandGETChildAccounts } from '../constants/authorization';
import { validationJSON, makeJSON } from '../lib/utils'
import { systemVersion } from '../config/systemConfig'
import { getNewNotifications } from './notifications';

import { getGoodsCatlog } from '../actions/Main/PersonalOrders';
import { clearCart } from './cart';
import { idbKeyval } from '../lib/db';


// Если выяснится, что токен не валидный, то произойдет autorizationLogout()
function checkSessionOnce(store, token) {
  fetch(apiCommandGETGetMyId + '?access_token=' + token, {
    // cors - Сервис API может быть на любом сервере, same-origin - Тот же самый сервер
    mode: 'cors',
    method: 'GET',
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Cache-control': 'no-cache',
    },
  })
    .then((response)=>{
      if (response.status !== 200)
        store.dispatch(autorizationLogout())
    })
    .catch(() => store.dispatch(autorizationLogout())
    );
}

export function checkSession(store) {
  // Сессия была начата ранее
  if (localStorage.getItem('sessionStart') !== null) {
    // у клиента типа "Оптовый Коммерческий Клиент" ДОЛЖЕН!!! userId должен быть > 0
    if (parseInt(localStorage.getItem('userId'), 10) === 0)
      store.dispatch(autorizationLogout()); else return true;
  } else return false;
}

export function checkAuthorization(dispatch, response) {
  return response.status === 401 ? dispatch(autorizationLogout()) : response;
}

export const z = (a) => typeof(a) === 'string' ?
  a.split('').map(i => String.fromCharCode(i.charCodeAt(0) ^ 1)).join('') : null;

export function tryRestoreSession(store) {
  if (checkSession(store) === true) {
    const token = localStorage.getItem('token');
    const login = localStorage.getItem('login');
    const userId = localStorage.getItem('userId');
    store.dispatch(autorizationRestore(login, token, userId));
    store.dispatch(getNewNotifications(z(token)));
    // Запускаем проверку токена при старте системы ( синхронное выполнение )
    checkSessionOnce(store, z(token));
  }
  store.subscribe( () => checkSession(store) );
}

export function requestAutorization() {
  return {
    type: 'AUTHORIZATION_REQUEST',
    authorizationInProcess: true,
  }
}

export function autorizationSuccess(login, token, userId) {
  window.localStorage.setItem('token', z(token));
  window.localStorage.setItem('login', login);
  window.localStorage.setItem('userId', userId);
  const sessionStartSec = (Date.now() / 1000);
  window.localStorage.setItem('sessionStart', sessionStartSec);
  return {
    type: 'AUTHORIZATION_SUCCESS',
    token: token,
    login: login,
    userId: userId,
  }
}

export function autorizationRestore(login, token, userId) {
  window.localStorage.setItem('token', token);
  window.localStorage.setItem('login', login);
  window.localStorage.setItem('userId', userId);
  return {
    type: 'AUTHORIZATION_SUCCESS',
    token: z(token),
    login: login,
    userId: Number(userId),
  }
}

function makeAuthorizationMessage(errorCode, errorMessage) {
  switch (errorCode) {
    case 'LOGIN_FAILED_EMAIL_NOT_VERIFIED':
      return 'Ошибка авторизации: Тебуется подтвердить эдектронный адрес';
    case 'LOGIN_FAILED':
      return 'Ошибка авторизации: Не корректные данные для авторизации';
    default:
      return `Ошибка авторизации: 
      ${errorCode} 
      ${errorMessage}`;
  }
}

export function autorizationError(errorCode, errorMessage) {
  return {
    type: 'AUTHORIZATION_ERROR',
    authorizationError: true,
    error: {errorCode, errorMessage},
    userMessage: makeAuthorizationMessage(errorCode, errorMessage),
  }
}

export function autorizationLogout() {
  localStorage.clear();
  idbKeyval.clear();
  return {
    type: 'AUTHORIZATION_LOGOUT',
  }
}

/* eslint-disable */
/* eslint-enable */
export function loginUser(username, password) {
  return function(dispatch) {
    dispatch(requestAutorization());
    let httpStatusCode = null;
    let apiErrorCode = null;
    let apiErrorMessage = null;
    return fetch(apiCommandPOSTLogin + '&ver=' + systemVersion, {
      // cors - Сервис API может быть на любом сервере, same-origin - Тот же самый сервер
      mode: 'cors',
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Cache-control': 'no-cache',
      },
      body: makeJSON({email: username, password: password}),
    })
      .then((response)=>{
        // Извлекаем код ответа HTTP
        httpStatusCode = response.status;
        return response;
      })
      .then(validationJSON) // Раскрывает промис
      .then((data)=> {
        // data содержит ответ от API
        if (httpStatusCode === 200) {
          dispatch(autorizationSuccess(username, data.id, data.user.customers));
          dispatch(getNewNotifications(data.id));
        }
        else {
          // data содержит расшифровку ошибки в объекте error
          apiErrorCode = data.error.code;
          apiErrorMessage = data.error.message;
          const error = new Error()
          throw error
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === 'development') console.log('loginUser() - Error: ', error);
        dispatch(
          autorizationError(
            apiErrorCode || httpStatusCode,
            apiErrorMessage || error.message
          ));
      })
  }
}


// Получение списка дочерних аккаунтов
export function requestChildAccouts() {
  return {
    type: 'CHILD_ACOUNTS_REQUEST',
  }
}

export function requestChildAccoutsSuccess(childAccounts) {
  try {
    window.localStorage.setItem('ChildAccounts', JSON.stringify(childAccounts));
  } catch (e) {
    alert( 'Ошибка при работе с локальным хранилищем браузера' );
  }
  return {
    type: 'CHILD_ACOUNTS_REQUEST_SUCCESS',
  }
}

export function requestChildAccoutsError(errorCode, errorMessage) {
  return {
    type: 'CHILD_ACOUNTS_REQUEST_ERROR',
    requestCatalogError: true,
    error: {errorCode, errorMessage},
  }
}

export function getChildAccouts(token) {
  return function(dispatch) {
    dispatch(requestChildAccouts());
    let httpStatusCode = null;
    let apiErrorCode = null;
    let apiErrorMessage = null;

    return fetch(apiCommandGETChildAccounts +
        '?access_token=' + token, {
      // cors - Сервис API может быть на любом сервере, same-origin - Тот же самый сервер
      mode: 'cors',
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response)=>{
        // Извлекаем код ответа HTTP
        httpStatusCode = response.status;
        return response;
      })
      // .then((response) => checkAuthorization(dispatch, response))
      .then(validationJSON) // Раскрывает промис
      .then((data)=> {
        // data содержит ответ от API
        if (httpStatusCode === 200) {
          dispatch(requestChildAccoutsSuccess(data.childClients));
        }
        else {
          // data содержит расшифровку ошибки в объекте error
          apiErrorCode = data.error.code;
          apiErrorMessage = data.error.message;
          const error = new Error()
          throw error
        }
      })
      .catch((error) => {
        dispatch(
          requestChildAccoutsError(
            apiErrorCode || httpStatusCode,
            apiErrorMessage || error.message
          ));
      })
  }
}

// Задание в качестве рабочего кода контрагента дочернего кода контрагента
export function setWorkChildClientId(childUserId) {
  return {
    type: 'SET_WORK_USER_ID',
    childUserId: childUserId,
  }
}

export function setWorkClientId(token, childUserId) {
  return function(dispatch) {
    dispatch(setWorkChildClientId(childUserId));
    dispatch(getGoodsCatlog( token, childUserId, null, null));
    dispatch(clearCart());
  }
}
