export const ReceivablesInfoGridHeader = [
  { caption: 'Плательщик',
    fieldName: 'FULL_NAME',
    hint: 'Плательщик по документу',
    flexWidth: 2,
  },
  { caption: 'Грузополучатель',
    fieldName: 'FULL_NAME_CUST',
    hint: 'Грузополучатель по документу',
    flexWidth: 2,
  },
  { caption: '№ документа',
    hint: 'Номер документа',
    fieldName: 'FULL_DOC_ID',
    flexWidth: 2,
    //columnSpacer: 'mr-1',
  },
  { caption: 'Дата',
    hint: 'Дата документа',
    fieldName: 'DDATE',
    isDateFormat: true,

  },
  { caption: 'Сумма',
    fieldName: 'DSUM',
    isCurrency: true,
    roundTo: 2,
    currencyName: 'р',
    hint: 'Сумма по документу',

  },
  { caption: 'Просроч. дней',
    fieldName: 'DDF',
    hint: 'Количество дней просрочки',
    columnSpacer: 'text-center',
    
  },
  { caption: 'Дата оплаты',
    hint: 'Дата оплаты',
    fieldName: 'DDATE2',
    isDateFormat: true,
    //columnSpacer: 'ml-3',
   
  },
  { caption: 'Не оплачено',
    hint: 'Не оплачено по документу',
    isCurrency: true,
    roundTo: 2,
    currencyName: 'р',
    fieldName: 'SUM_NOT_PAY',

    //columnSpacer: 'mr-2',
  },
  { caption: 'Накоп. итог',
    hint: 'Не оплачено - накопительным итогом',
    isCurrency: true,
    roundTo: 2,
    currencyName: 'р',
    fieldName: 'CUMULATIVE_SUM_NOT_PAY',

  },
];

export { default as ReceivablesInfoGrid } from './ReceivablesInfoGrid';

/*
Дебиторская задолженность
[
  {
    "DOC":20346480,
    "ZID":0,
    "DCODE":"E1412130",
    "FULL_NAME_CUST":"ООО \"Анкор\" (Абинск, пр. Комсомольский, 93)",
    "INTERNAL_NAME_CUST":"Абинск ООО \"Анкор\" (Абинск, пр. Комсомольский, 93)",
    "FULL_NAME":"Абинск ООО \"Анкор\"",
    "DSUM":4171.33,
    "DDATE":"2018-09-30T21:00:00.000Z",
    "DDATE2":"2018-10-14T21:00:00.000Z",
    "DELAY_OF_PAYMENT":14,
    "SUM_NOT_PAY":4171.33,
    "SUMM_OVER":0,
    "DDF":-7,
    "TENDERS":0,
    "G_KONTR":null,
    "DATE_CONTRACT":null,
    "10 дней":0,
    "30 дней":0,
    "45 дней":0,
    "60 дней":0,
    "90 дней":0,
    "Просрочено":0,
    "№ конкурса":0,
    "№ ГК":null,
    "№ Дата ГК":null
  },
  ...
]

*/
