export const systemVersion = 'v1.3.7'
export const systemName = 'zVision'
export const systemHostName = 'zvision.zdravservice.ru'
export const systemAddress = 'https://zvision.zdravservice.ru'
export const sessionTTL = 86400 // sec
export const waitForServiceWorkerCache = 5000 // sec
export const newYearLogoPeriod = '2023-12-15,2024-01-13'
export const systemURL =
  process.env.NODE_ENV === 'development'
    ? 'https://localhost:3000'
    : 'https://zvision.zdravservice.ru/';

export const dbName = 'zVision-keyval-store';
export const dbVer = 1;
