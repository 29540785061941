// Варианты формулировки претензии
export const claimsTroubles = {
  10401: 'Брак (бой, не товарный внешний вид)',
  10403: 'Недовложение',
//  10406: 'Не та серия',
  10407: 'Заводское недовложение',
//  10405: 'Срок годности',
  10424: 'Излишки (все привезли, но есть лишний непонятный товар)',
  10402: 'Пересорт (вместо того, что требовалось, привезли что-то не то)',
  10404: 'Перевложение (заказывали одно количество, но привезли больше чем нужно)',
  10428: 'Федеральная забраковка',
  10429: 'Маркировка',
}
