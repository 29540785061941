import	{
  CATALOG_REQUEST,
  CATALOG_REQUEST_SUCCESS,
  CATALOG_REQUEST_ERROR,

  // Попытка восстановить состояние каталога из LocalStorage
  RESTORE_CATALOG_FROM_LS_REQUEST,
  // Отказ восстановить состояние каталога из LocalStorage
  RESTORE_CATALOG_FROM_LS_ERROR,
  // Удалось восстановить состояние каталога из LocalStorage
  RESTORE_CATALOG_FROM_LS_SUCCESS,

  ACTIVE_ORDERS_REQUEST,
  ACTIVE_ORDERS_REQUEST_SUCCESS,
  ACTIVE_ORDERS_REQUEST_ERROR,

  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_REQUEST_SUCCESS,
  ORDER_DETAILS_REQUEST_ERROR,

  ORDERS_HISTORY_REQUEST,
  ORDERS_HISTORY_REQUEST_SUCCESS,
  ORDERS_HISTORY_REQUEST_ERROR,

  ORDER_HISTORY_REQUEST,
  ORDER_HISTORY_REQUEST_SUCCESS,
  ORDER_HISTORY_REQUEST_ERROR,

  IMPORT_ORDER_OPEN_ORDER_FILE_TRY,
  IMPORT_ORDER_OPEN_ORDER_FILE_SUCCESS,
  IMPORT_ORDER_OPEN_ORDER_FILE_ERROR,

  IMPORT_ORDER_APPLY_REQUEST,
  IMPORT_ORDER_APPLY_SUCCESS,
  IMPORT_ORDER_APPLY_ERROR,
  IMPORT_ORDER_PREPARE,
} from '../constants/personalOrders';

const initialState = {
  catalogRefreshTime: null,
  requestCatalogInProcess: false,
  requestCatalogError: false,
  requestCatalogSuccess: false,
  requestCatalogStatus: null,
  requestCatalogFilter: null,
  catalogLinesCount: null,

  catalogRestoreFromLSRequest: null,
  catalogRestoredFromLSSuccess: null,
  catalogRestoreFromLSError: null,
  catalogRestoreFromLSStatus: null,
  catalogExpired: null,

  requestActiveOrdersInProcess: false,
  requestActiveOrdersError: false,
  requestActiveOrdersSuccess: false,
  requestActiveOrdersStatus: null,
  requestActiveOrdersFilter: null,

  requestOrderDetailsInProcess: false,
  requestOrderDetailsError: false,
  requestOrderDetailsSuccess: false,
  requestOrderDetailsStatus: null,
  orderIdForDetails: null,

  requestOrdersHistoryInProcess: false,
  requestOrdersHistoryError: false,
  requestOrdersHistorySuccess: false,
  requestOrdersHistoryFilter: null,
  requestOrdersHistoryStatus: null,

  requestOrderHistoryInProcess: false,
  requestOrderHistoryError: false,
  requestOrderHistorySuccess: false,
  requestOrderHistoryCustomers: null,
  requestOrderHistoryOderId: null,
  requestOrderHistoryStatus: null,

  importOrderStatus: null,//Статус процесса импорта
  importOrderErrorCode: 0,//Код статуса процесса импорта
  importOrderFileName: null,// Имя импортируемого файла
  importRAWOrderData: null, //Текст извлеченный из файла
  importOrder: {
    head: [],
    body: [],
  }, //Тело заказа
  importOrderTryOpenOrderFile: false,// Попытка открыть файл с заказом
  importOrderTryOpenOrderFileProcess: false,// Открытие файла с заказом
  importOrderTryOpenOrderFileSuccess: false,// Попытка открыть файл с заказом удачная
  importOrderTryOpenOrderFileError: false,// Попытка открыть файл с заказом не удачная
  importOrderTryApplyOrderFile: false,// Попытка подтвердить импорт файла заказа и отправить его на сервер
  importOrderRequestApplyOrderInProcess : false,// Отправка заказа на сервер - в процессе
  importOrderRequestApplyOrderSuccess: false,// Отправка заказа на сервер - ok
  importOrderRequestApplyOrderError: false,// Отправка заказа на сервер - ошибка
  importOrderResultOrderId: null,//Результирующий код заказа после импорта
};

export default function personalOrders(state = initialState, action) {
  let catalogRefreshTime = null;
  switch (action.type) {

    case CATALOG_REQUEST:
      if (process.env.NODE_ENV === 'development') console.log('raise action - CATALOG_REQUEST', action);
      return { ...state, 
        requestCatalogInProcess: true,
        requestCatalogError: false,
        catalogRefreshTime: null,
        requestCatalogFilter: action.requestCatalogFilter,
        catalogLinesCount: null,
        requestCatalogStatus: 'Получение каталога товаров...',
        catalogClientId : action.clientId,
      }

    case CATALOG_REQUEST_SUCCESS:
      if (process.env.NODE_ENV === 'development') console.log('raise action - CATALOG_REQUEST_SUCCESS', action);
      catalogRefreshTime = new Date(action.catalogRefreshTime.toString()).toLocaleString("ru");
      return { ...state,
        requestCatalogInProcess: false,
        requestCatalogSuccess: true,
        requestCatalogError: false,
        requestCatalogFilter: action.requestCatalogFilter,
        catalogRefreshTime: catalogRefreshTime,
        catalogLinesCount: action.catalogLinesCount,
        requestCatalogStatus: 'Каталог товаров успешно получен',
        catalogExpired: action.expired,
      }

    case CATALOG_REQUEST_ERROR:
      if (process.env.NODE_ENV === 'development') console.log('raise action - CATALOG_REQUEST_ERROR', action);
      return { ...state, 
        requestCatalogInProcess: false,
        requestCatalogSuccess: false,
        requestCatalogError: true,
        catalogLinesCount: null,

        requestCatalogStatus: `Ошибка получение каталога товаров: 
          ${action.error.errorCode}
          ${action.error.errorMessage}`,
        catalogExpired: true,
      }

    case RESTORE_CATALOG_FROM_LS_REQUEST:
      if (process.env.NODE_ENV === 'development') console.log('raise action - RESTORE_CATALOG_FROM_LS_REQUEST', action);
      return { ...state,
        catalogRestoreFromLSRequest: true,
        catalogRestoredFromLSSuccess: false,
        catalogRestoreFromLSError: false,
        catalogRestoreFromLSStatus: 'Восстановление каталога из LS',
      }

      // Отказ восстановить состояние каталога из LocalStorage
      case RESTORE_CATALOG_FROM_LS_ERROR:
      if (process.env.NODE_ENV === 'development') console.log('raise action - RESTORE_CATALOG_FROM_LS_ERROR', action);
      return { ...state,
        catalogRestoreFromLSRequest: false,
        catalogRestoredFromLSSuccess: false,
        catalogRestoreFromLSError: true,
        catalogRestoreFromLSStatus:  `Ошибка восстановления каталога из LS: 
        ${action.error.errorCode}
        ${action.error.errorMessage}`,
      }

      // Удалось восстановить состояние каталога из LocalStorage
      case RESTORE_CATALOG_FROM_LS_SUCCESS:
      if (process.env.NODE_ENV === 'development') console.log('raise action - RESTORE_CATALOG_FROM_LS_SUCCESS', action);
      catalogRefreshTime = new Date(action.catalogRefreshTime.toString()).toLocaleString("ru");
      return { ...state,
        requestCatalogInProcess: false,
        requestCatalogSuccess: true,
        //requestCatalogError: false,
        requestCatalogFilter: action.requestCatalogFilter,
        catalogRefreshTime: catalogRefreshTime,
        catalogLinesCount: action.catalogLinesCount,
        //requestCatalogStatus: 'Каталог успешно обновлен',
        catalogClientId: action.clientId,
        catalogRestoreFromLSRequest: false,
        catalogRestoredFromLSSuccess: true,
        catalogRestoreFromLSError: false,
        catalogRestoreFromLSStatus: 'Каталог товаров успешно получен из LS',
        catalogExpired: action.expired,
      }


    case ACTIVE_ORDERS_REQUEST:
      if (process.env.NODE_ENV === 'development') console.log('raise action - ACTIVE_ORDERS_REQUEST', action);
      return { ...state, 
        requestActiveOrdersInProcess: true,
        requestActiveOrdersError: false,
        requestActiveOrdersSuccess: false,
        requestActiveOrdersStatus: 'Получение списка активных заказов',
        requestActiveOrdersFilter: action.activeOrdersFilter,
      }

    case ACTIVE_ORDERS_REQUEST_SUCCESS:
      if (process.env.NODE_ENV === 'development') console.log('raise action - ACTIVE_ORDERS_REQUEST_SUCCESS', action);
      return { ...state, 
        requestActiveOrdersInProcess: false,
        requestActiveOrdersError: false,
        requestActiveOrdersSuccess: true,
        requestActiveOrdersStatus: 'Cписок активных заказов получен',
      }

    case ACTIVE_ORDERS_REQUEST_ERROR:
      if (process.env.NODE_ENV === 'development') console.log('raise action - ACTIVE_ORDERS_REQUEST_ERROR', action);
      return { ...state, 
        requestActiveOrdersInProcess: false,
        requestActiveOrdersError: true,
        requestActiveOrdersSuccess: false,
        requestActiveOrdersStatus: `Ошибка получения списка активных заказов: 
          ${action.error.errorCode}
          ${action.error.errorMessage}`,
      }



    case ORDER_DETAILS_REQUEST:
      if (process.env.NODE_ENV === 'development') console.log('raise action - ORDER_DETAILS_REQUEST', action);
      return { ...state, 
        requestOrderDetailsInProcess: true,
        requestOrderDetailsError: false,
        requestOrderDetailsSuccess: false,
        requestOrderDetailsStatus: 'Получение деталей заказа',
        orderIdForDetails: action.orderId,
      }

    case ORDER_DETAILS_REQUEST_SUCCESS:
      if (process.env.NODE_ENV === 'development') console.log('raise action - ORDER_DETAILS_REQUEST_SUCCESS', action);
      return { ...state, 
        requestOrderDetailsInProcess: false,
        requestOrderDetailsError: false,
        requestOrderDetailsSuccess: true,
        requestOrderDetailsStatus: 'Детали заказа получены',
      }

    case ORDER_DETAILS_REQUEST_ERROR:
      if (process.env.NODE_ENV === 'development') console.log('raise action - ORDER_DETAILS_REQUEST_ERROR', action);
      return { ...state, 
        requestOrderDetailsInProcess: false,
        requestOrderDetailsError: true,
        requestOrderDetailsSuccess: false,
        requestOrderDetailsStatus: `Ошибка получения деталей заказа: 
          ${action.error.errorCode}
          ${action.error.errorMessage}`,
      }


      case ORDERS_HISTORY_REQUEST:
      if (process.env.NODE_ENV === 'development') console.log('raise action - ORDERS_HISTORY_REQUEST', action);
      return { ...state, 
        requestOrdersHistoryInProcess: true,
        requestOrdersHistoryError : false,
        requestOrdersHistorySuccess: false,
        requestOrdersHistoryStatus: 'Получение истории заказов',
        requestOrdersHistoryFilter: action.requestOrdersHistoryFilter
      }

    case ORDERS_HISTORY_REQUEST_SUCCESS:
      if (process.env.NODE_ENV === 'development') console.log('raise action - ORDERS_HISTORY_REQUEST_SUCCESS', action);
      return { ...state, 
        requestOrdersHistoryInProcess: false,
        requestOrdersHistoryError: false,
        requestOrdersHistorySuccess: true,
        requestOrdersHistoryStatus: 'История заказов получена',
      }

    case ORDERS_HISTORY_REQUEST_ERROR:
      if (process.env.NODE_ENV === 'development') console.log('raise action - ORDERS_HISTORY_REQUEST_ERROR', action);
      return { ...state, 
        requestOrdersHistoryInProcess: false,
        requestOrdersHistoryError: true,
        requestOrdersHistorySuccess: false,
        requestOrdersHistoryStatus: `Ошибка получения истории заказов: 
          ${action.error.errorCode}
          ${action.error.errorMessage}`,
      }

      case ORDER_HISTORY_REQUEST:
      if (process.env.NODE_ENV === 'development') console.log('raise action - ORDER_HISTORY_REQUEST', action);
      return { ...state, 
        requestOrderHistoryInProcess: true,
        requestOrderHistoryError : false,
        requestOrderHistorySuccess: false,
        requestOrderHistoryCustomers: action.requestOrderHistoryCustomers,
        requestOrderHistoryOderId: action.requestOrderHistoryOrderId,
        requestOrderHistoryStatus: 'Получение истории заказа',
      }

    case ORDER_HISTORY_REQUEST_SUCCESS:
      if (process.env.NODE_ENV === 'development') console.log('raise action - ORDER_HISTORY_REQUEST_SUCCESS', action);
      return { ...state, 
        requestOrderHistoryInProcess: false,
        requestOrderHistoryError: false,
        requestOrderHistorySuccess: true,
        requestOrderHistoryCustomers: action.requestOrderHistoryCustomers,
        requestOrderHistoryOderId: action.requestOrderHistoryOrderId,
        requestOrderHistoryStatus: 'История заказа получена',
      }

    case ORDER_HISTORY_REQUEST_ERROR:
      if (process.env.NODE_ENV === 'development') console.log('raise action - ORDER_HISTORY_REQUEST_ERROR', action);
      return { ...state,
        requestOrderHistoryInProcess: false,
        requestOrderHistoryError: true,
        requestOrderHistorySuccess: false,
        requestOrderHistoryStatus: `Ошибка получения истории заказа: 
          ${action.error.errorCode}
          ${action.error.errorMessage}`,
      }



    case IMPORT_ORDER_OPEN_ORDER_FILE_TRY:
      if (process.env.NODE_ENV === 'development') console.log('raise action - IMPORT_ORDER_OPEN_ORDER_FILE_TRY', action);
      return { ...state,
        importOrderTryOpenOrderFile: true,
        importOrderStatus: 'Импорт файла',//Статус процесса
        importOrderErrorCode: 0,//Код статуса процесса импорта
        importOrderFileName: action.fileName,// Имя импортируемого файла
        importRAWOrderData: action.rawOrderData,
        importOrder: {
          head: [],
          body: [],
        },
        importOrderTryOpenOrderFileProcess: true,// Открытие файла с заказом
        importOrderTryOpenOrderFileSuccess: false,// Попытка открыть файл с заказом удачная
        importOrderTryOpenOrderFileError: false,// Попытка открыть файл с заказом не удачная
        importOrderTryApplyOrderFile: false,// Попытка подтвердить импорт файла заказа и отправить его на сервер
        importOrderResultOrderId: null,//Результирующий код заказа после импорта
      }

    case IMPORT_ORDER_OPEN_ORDER_FILE_SUCCESS:
      if (process.env.NODE_ENV === 'development') console.log('raise action - IMPORT_ORDER_OPEN_ORDER_FILE_SUCCESS', action);
      return { ...state,
        importOrderStatus: 'Формат файла соответствует формату заказа',//Статус процесса
        importOrderErrorCode: 0,//Код статуса процесса импорта
        importOrderTryOpenOrderFileProcess: false,// Открытие файла с заказом
        importOrderTryOpenOrderFileSuccess: true,// Попытка открыть файл с заказом удачная
        importOrderTryOpenOrderFileError: false,// Попытка открыть файл с заказом не удачная
        importOrder: action.order,
      }

    case IMPORT_ORDER_OPEN_ORDER_FILE_ERROR:
      if (process.env.NODE_ENV === 'development') console.log('raise action - IMPORT_ORDER_OPEN_ORDER_FILE_ERROR', action);
      return { ...state,
        importOrderStatus: action.importStatus,//Статус процесса импорта
        importOrderErrorCode: action.errorCode,//Код статуса процесса импорта
        importOrderTryOpenOrderFileProcess: false,// Открытие файла с заказом
        importOrderTryOpenOrderFileSuccess: false,// Попытка открыть файл с заказом удачная
        importOrderTryOpenOrderFileError: true,// Попытка открыть файл с заказом не удачная
      }

    case IMPORT_ORDER_APPLY_REQUEST:
      if (process.env.NODE_ENV === 'development') console.log('raise action - IMPORT_ORDER_APPLY_REQUEST', action);
      return { ...state, 
        importOrderTryApplyOrderFile: true,// Попытка подтвердить импорт файла заказа и отправить его на сервер        
        importOrderRequestApplyOrderInProcess: true,// Отправка заказа на сервер - в процессе
        importOrderRequestApplyOrderError : false,// Отправка заказа на сервер - ошибка
        importOrderRequestApplyOrderSuccess: false,// Отправка заказа на сервер - ok
        importOrderStatus: 'Импорт заказа на сервер',
        importOrderErrorCode: 0,
      }

    case IMPORT_ORDER_APPLY_SUCCESS:
      if (process.env.NODE_ENV === 'development') console.log('raise action - IMPORT_ORDER_APPLY_SUCCESS', action);
      return { ...state,
        importOrderRequestApplyOrderInProcess: false,
        importOrderRequestApplyOrderError: false,
        importOrderRequestApplyOrderSuccess: true,
        importOrderStatus: 'Заказ успешно импортирован',
        importOrderResultOrderId: action.importedOrderId,
        importOrderErrorCode: 0,
      }

    case IMPORT_ORDER_APPLY_ERROR:
      if (process.env.NODE_ENV === 'development') console.log('raise action - IMPORT_ORDER_APPLY_ERROR', action);
      return { ...state,
        importOrderRequestApplyOrderInProcess: false,
        importOrderRequestApplyOrderError: true,
        importOrderRequestApplyOrderSuccess: false,
        importOrderStatus: `Ошибка импорта заказа на сервер: ${action.error.errorMessage}`,
        importOrderErrorCode: action.error.errorCode
      }

      case IMPORT_ORDER_PREPARE:
      if (process.env.NODE_ENV === 'development') console.log('raise action - IMPORT_ORDER_PREPARE', action);
      return { ...state,
        importOrderStatus: null,//Статус процесса импорта
        importOrderErrorCode: 0,//Код статуса процесса импорта
        importOrderFileName: null,// Имя импортируемого файла
        importRAWOrderData: null, //Текст извлеченный из файла
        importOrder: {
          head: [],
          body: [],
        }, //Тело заказа
        importOrderTryOpenOrderFile: false,// Попытка открыть файл с заказом
        importOrderTryOpenOrderFileProcess: false,// Открытие файла с заказом
        importOrderTryOpenOrderFileSuccess: false,// Попытка открыть файл с заказом удачная
        importOrderTryOpenOrderFileError: false,// Попытка открыть файл с заказом не удачная
        importOrderTryApplyOrderFile: false,// Попытка подтвердить импорт файла заказа и отправить его на сервер
        importOrderRequestApplyOrderInProcess : false,// Отправка заказа на сервер - в процессе
        importOrderRequestApplyOrderSuccess: false,// Отправка заказа на сервер - ok
        importOrderRequestApplyOrderError: false,// Отправка заказа на сервер - ошибка
        importOrderResultOrderId: null,//Результирующий код заказа после импорта
      }

    default:
      return state;
  }
}
