import { connect } from 'react-redux';
import { processPasswordResetRequest } from '../actions/passwordReset';

// Основные визуальные модули
import { PasswordResetProcessView } from '../components/Desktop';

const mapStateToProps = (state) => ({
  processPasswordResetInProcess: state.passwordReset.processPasswordResetInProcess,
  processPasswordResetError: state.passwordReset.processPasswordResetError,
  processPasswordResetSuccess: state.passwordReset.processPasswordResetSuccess,
  processPasswordResetStatus: state.passwordReset.processPasswordResetStatus,
});

const mapDispathToProps = (dispath) => {
  return {
    doPasswordResetProcess: (token, password) => dispath(processPasswordResetRequest(token, password)),
  };
};

export default connect(
  mapStateToProps,
  mapDispathToProps
)(PasswordResetProcessView)
